<template>   
    <section v-if="$route.name === 'HotelGroupUsersList'" id="list">
        <LayoutList
            v-model="usersFromApi"
            :table="{
                layout: {
                    hasHeader: { desktop: true, mobile: false }
                },
                headers: tableHeaders,
                rows: tableRows,
                loading: isLoading
            }"
            :button="{
                label: $t('addMember'),
                icon: require('@/assets/Company/add-member.svg'),
                style: {
                    backgroundColor: 'var(--theme-light)!important',
                    color: 'var(--theme-dark)!important',
                    border: '1px solid var(--theme-border)!important',
                    borderRadius: '8px!important',
                    gap: '8px',
                    padding: '5px 10px',
                    minWidth: '200px'
                }
            }"
            :searchBar="false"
            @rowClicked="rowClicked"
            @addItem="addItem"
        >
        </LayoutList>
    </section>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import { useCompanyUsersStore, useAlertStore } from "@/stores";
import LayoutList from '@/components/LayoutList.vue';

export default {
    components: {
        LayoutList,
    },
    data() {
        return {
            tableHeaders: [
                {
                    icon: require('@/assets/Company/userManageIcon.svg'),
                    text: this.$t('name')
                },
                {
                    icon: require('@/assets/Company/lock-fill.svg'),
                    text: this.$t('permissions')
                },
                {
                    icon: require('@/assets/Company/tags.svg'),
                    text: this.$t('role')
                }
            ],
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'SimpleText', field: 'permissions' },
                { type: 'SimpleText', field: 'role' }
            ],
            usersFromApi: [],
            isLoading: false
        }
    },
    setup() {
        const companyUsersStore = useCompanyUsersStore();
        const alertStore = useAlertStore();
        return {
            companyUsersStore,
            alertStore
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'HotelGroupUsersList') {
            next(); 
            return; // Prevents the code below from running
        }
        console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        // When I load the child page, all the previous pages also load their content. 
        // It's essential to include a condition in the mounted hook to ensure it only mounts when the specific page is loaded.
        if(this.$route.name === 'HotelGroupUsersList') {
            await this.fetchData();
        }
    },
    methods: {
        async fetchData() {
            try {
                this.isLoading = true;
                await this.companyUsersStore.getList();
                this.usersFromApi = this.companyUsersStore.getListOutput.map((user) => ({
                    id: user.objectId,
                    name: { 
                        image: user?.picture ?? require('@/assets/no-image.jpg'), 
                        firstText: user?.name, 
                        secondText: user?.email 
                    },
                    permissions: 'Member',
                    role: 'Revenue Manager'
                }));
            } catch (error) {
                this.alertStore.error(error);
                this.isLoading = false;
            }
            finally {
                this.isLoading = false;
            }
        },
        rowClicked(obj) {
            console.log('row clicked', obj);
            this.$router.push({ name: 'HotelGroupUserEdit', params: { userId: obj.id } });
        },
        addItem() {
            console.log("open popup")
        }
    }
}
</script>