<template>
    <VueDatePicker 
        :model-value="modelValue"
        :format="'dd/MM/yyyy'" 
        :enable-time-picker="false" 
        class="w-auto cursor-pointer" 
        :range="range" 
        :class="{'date': typeForm != 1}"
        @update:model-value="handleDate"
    >
        <template #trigger>
            <template  v-if="typeForm == 1">
                <div class="date-picker d-flex">
                    <div class="wrapper">
                        <template v-if="!date">
                            <img src="@/assets/Pickup/calendar.svg" alt="calendar" width="16" height="16">
                            <span class="label">{{label}}</span>
                        </template>
                        <template v-else>
                            <img src="@/assets/Pickup/calendar.svg" alt="calendar" width="16" height="16">
                            <span class="label">{{activeDate}}</span>
                        </template>
                    </div>
                    <div class="arrow">
                        <img src="@/assets/Pickup/chevron-down.svg" alt="chevron-down">
                    </div>
                </div>
            </template>
            <template v-if="typeForm == 2">
                <div class="d-flex align-items-center justify-content-between gap-2">
                    <template v-if="!date">
                        <div class="col d-flex align-items-center">
                            <span class="label">{{label}}</span>
                        </div>
                    </template>
                    <template v-else>
                        {{activeDate}}
                    </template>
                    <div class="col-auto d-flex align-items-center">
                        <img class="icon" src="@/assets/Pickup/calendar-check.svg" width="16" height="16" alt="calendar">
                    </div>
                </div>
            </template>
        </template>
    </VueDatePicker>
    <button 
        v-if="selectedButton" 
        class="selected"
        :class="{'disabled': !date}" 
        @click="confirmSelection" 
    >
        <div class="d-flex align-items-center justify-content-center">
            <img class="icon" src="@/assets/Pickup/check2-circle.svg" width="12" height="12"
                alt="check">
            <span class="label mx-2">{{$t('select')}}</span>
        </div>
    </button>
  </template>
  
  <script>
  import VueDatePicker from '@vuepic/vue-datepicker';
  
  export default {
    components: {
      VueDatePicker
    },
    props: {
        modelValue: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        typeForm: {
            type: Number,
            default: 1,
            required: true
        },
        selectedButton: {
            type: Boolean,
            default: false
        },
        range: Object
    },
    data() {
        return {
            date: this.modelValue || null
        };
    },
    computed: {
        activeDate() {
            if (Array.isArray(this.date)) { // range date
                let dateFormat = this.date.map(date => new Date(date));
                if (dateFormat[0] && dateFormat[1]) {
                    const startDate = dateFormat[0].toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    });
                    const endDate = dateFormat[1].toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    });
                    return `${startDate} - ${endDate}`;
                }
            } else { // single date
                let dateFormat = new Date(this.date); // assume que é uma data única
                const day = dateFormat.getDate().toString().padStart(2, '0');
                const month = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
                const year = dateFormat.getFullYear();
                return `${day}/${month}/${year}`;
            }
            // Default return statement if none of the conditions are met
            return ''; // or whatever default value you want to return
        }
    },
    methods: {
        confirmSelection() {
            // console.log("confirm selection")
            if(this.date) this.$emit('confirm-selection', this.date);
        },
        handleDate(newDate) {
            if(Array.isArray(this.date)) { // range date
                this.date = newDate.map(date => date.toISOString().substring(0, 10)); // yyyy-MM-dd
            } else { // single date
                this.date = newDate.toISOString().substring(0, 10); // yyyy-MM-dd
            }
        }
    },
    watch: {
        modelValue: {
            handler(newVal) {
                this.date = newVal;
                // console.log("modelValue", this.date)
            },
            deep: true
        },
        date: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal);
            },
            deep: true
        }
    },
  };
  </script>
  
  <style scoped>
  .date-picker .wrapper {
    display: flex;
    gap: 8px;
    padding: 6px 12px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #CED4DA;
    background-color: transparent;
    align-items: center;
}
.date-picker .wrapper .label,
.date .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #6C757D;
}
.date-picker .arrow {
    padding: 6px 12px 6px 12px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #CED4DA;
    border-left: none;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.date {
    max-width: 180px;
    min-width: 150px;
    padding: 5px 9px 5px 9px;
    border-radius: 5px;
    border: 1px solid #CED4DA;
}
.selected {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 180px;
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    border: 1px solid #DEE2E6;
    margin: 30px auto;
    background-color: #F8F9FA;
}
.selected .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #6C757D;
}
@media screen and (max-width: 991px) {
    .dp__main,
    .date-picker .wrapper {
        width: 100%!important;
    }
}
</style>
  