<template>
    <section id="metric">
        <template v-if="isMobile">
            <div class="sidebar-toggle border-bottom" @click="menuStore.toggleSubMenu">
                <img src="@/assets/arrow-right-fill.svg" class="arrow-icon" :class="{ open: menuStore.isSubMenuOpen }" alt="">
                <span class="page-title-mobile">{{ $t('Chain View') }} - {{ metricTitle }}</span>
            </div>
        </template>
        <div class="container-fluid mt-5 mt-md-0">
            <div class="row border-bottom p-4">
                <div class="col-12">
                    {{ metric }}
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { useResponsive } from '@/composables/responsive.js';
import { useMenuStore } from '@/stores';

export default {
    name: 'ChainComponent',
    // 'metric' prop defined in the route.js file
    props: {
        metric: {
            type: String,
            required: true
        }
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const menuStore = useMenuStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            menuStore
        }
    },
    computed: {
        metricTitle() {
            switch(this.metric) {
                case 'RoomNights':
                    return this.$t('Room Nights');
                case 'Occupancy':
                    return this.$t('Occupancy');
                case 'Revenue':
                    return this.$t('Revenue');
                case 'Adr':
                    return this.$t('ADR');
                case 'RevPAR':
                    return this.$t('RevPar');
                case 'All':
                    return this.$t('All');
                default:
                    return '';
            }
        },
    }
}
</script>
<style scoped>
.sidebar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transition: transform 0.3s ease;
}
.arrow-icon.open {
    transform: rotate(-180deg);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
</style>