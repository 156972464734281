// Composable functions are a way to encapsulate and share logic across components.
import { ref, onMounted, onUnmounted, computed } from 'vue';

export function useResponsive() {
    // state encapsulated and managed by the composable
    const widthWindow = ref(window.innerWidth);

    function updateWidth() {
        widthWindow.value = window.innerWidth;
    }

    // computed ref
    const isMobile = computed(() => {
        return widthWindow.value < 768
    })

    const isTablet = computed(() => {
        return widthWindow.value >= 768 && widthWindow.value < 1024
    })

    const isDesktop = computed(() => {
        return widthWindow.value >= 1024
    })

    // lifecycle hooks
    onMounted(() => {
        window.addEventListener('resize', updateWidth);
    })
    
    onUnmounted(() => {
        window.removeEventListener('resize', updateWidth);
    })

  // expose managed state as return value
  return { isMobile, isTablet, isDesktop}
}