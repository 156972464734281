<template>
    <div v-show="showModal" class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div v-if="closeIcon" class="pop-up-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp">
                </div>
                <div class="modal-body">
                    <slot name="body" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        closeIcon: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            drag: false
        };
    },
    mounted() {
        document.addEventListener('mousedown', this.handleMouseDown);
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-mask')) {
                this.closePopUp();
            }
            this.drag = false;
        },
        handleMouseDown() {
            this.drag = false;
        },
        handleMouseMove() {
            this.drag = true;
        }
    },
    watch: {
        showModal(val) {
            if (val) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        }
    }
};
</script>

<style scoped>
/* Base */
.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease;
}

.modal-wrapper {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    width: 500px;
    box-shadow: 0px 4px 30px 0px #00000014;
    border-radius: 20px;
}

.modal-container {
    background-color: #fff;
    box-shadow: 0 0 8px 0 #303942;
    transition: all .3s ease;
    padding: 1rem;
}

/* Close */
.pop-up-close {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 575px) {
    .modal-mask {
        height: unset;
    }
    .modal-wrapper {
        min-width: unset;
        width: 100%;
        border-radius: 0;
    }
    .modal-container{
        height: calc(100vh - 60px);
    }
}
</style>