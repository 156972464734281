<template>
    <section>
      <template v-if="isMobile">
          <div class="header-mobile border-bottom">
              <img class="img-fluid arrow-icon" :src="require('@/assets/MyProfile/acc-settings.svg')" alt="competitors icon">
              <span class="page-title-mobile">{{ $t('My Profile') }} </span>
          </div>
      </template>
      <PageSubMenu class="d-none d-md-block" :sections="menuSections" />
      <div class="container-fluid mt-5">
        <div class="row">
          <!-- <div class="buttons-div-style">
            <CustomButton 
              class="btn mt-2 d-flex gap-2" 
              :label="'Delete Account'"
              :buttonType="'theme-light'" 
              @click="showPopupDelete"
              :rightIcon="require('@/assets/MyProfile/delete-acc-gray.svg')" 
              :buttonStyle="{
                borderRadius: '8px!important',
                gap: '8px',
                padding: '6px 35px'
              }"
            />
            <CustomButton 
              class="btn mt-2 d-flex gap-2" 
              :label="'Export personal data'"
              :buttonType="'primary'" 
              @click="showPopupExport"
              :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
              :buttonStyle="{
                borderRadius: '8px!important',
                gap: '8px',
                padding: '6px 35px'
              }"
            />
          </div> -->
          <PersonalInformation ref="personalInfoRef" :userInfo="itemsFromApi" />
          <div class="buttons-div-style mb-5">
            <CustomButton 
              class="btn mt-2 d-flex gap-2" 
              :label="$t('Save')"
              :buttonType="'secondary'" 
              @click="saveAccountChanges"
              :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
              :buttonStyle="{
                borderRadius: '8px!important',
                gap: '8px',
                padding: '6px 35px'
              }"
            />
          </div>
          <DividerComponent class="my-5" />
          <SecuritySettings ref="securitySettingsRef" :userInfo="itemsFromApi" />
          <DividerComponent class="my-5" />
          <AccountNotifications ref="accountNotificationsRef"/>
          <CustomPopup v-show="isPopupVisibleDelete" @popUpClosed="isPopupVisibleDelete = false" 
            :img="require('@/assets/MyProfile/delete-account.svg')" 
            :titlePopup="'To delete your account, you will need to delete the company or transfer it to another administrator.'">
            <template v-slot:body>
              <CustomButton 
                class="btn mt-2 d-flex gap-2" 
                :label="'Go back'"
                :buttonType="'theme-light'" 
                @click="isPopupVisibleDelete = false" 
                :rightIcon="require('@/assets/MyProfile/goBackRotatedArrow.svg')" 
                :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
                }"
              />
            </template>
          </CustomPopup> 
          <CustomPopup v-show="isPopupVisibleExport" @popUpClosed="isPopupVisibleExport = false" 
            :img="require('@/assets/MyProfile/export-info.svg')" 
            :titlePopup="'Your information has been successfully exported.'">
            <template v-slot:body>
              <CustomButton 
                class="btn mt-2 d-flex gap-2" 
                :label="'Go back to Dashboard'"
                :buttonType="'secondary'" 
                @clickBtn="sendToHome" 
                :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
                }"
              />
            </template>
          </CustomPopup> 
          <CustomPopup v-show="isPopupVisibleSave" @popUpClosed="isPopupVisibleSave = false" 
            :img="require('@/assets/MyProfile/infoSaved.svg')" 
            :titlePopup="$t('AllYourSettingsHaveBeenSuccessfullySaved')">
            <template v-slot:body>
              <CustomButton 
                class="btn mt-2 d-flex gap-2" 
                :label="$t('GoBackToDashboard')"
                :buttonType="'secondary'" 
                @clickBtn="sendToHome" 
                :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
                }"
              />
            </template>
          </CustomPopup> 
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { nextTick } from 'vue';
  import { useUsersStore, useAlertStore } from "@/stores";
  import DividerComponent from '@/components/DividerComponent.vue';
  import AccountNotifications from '@/components/MyProfile/AccountNotifications.vue';
  import PersonalInformation from '@/components/MyProfile/PersonalInformation.vue';
  import SecuritySettings from '@/components/MyProfile/SecuritySettings.vue';
  import CustomPopup from '@/components/popups/CustomPopup.vue';
  import CustomButton from '@/components/CustomButton.vue';
  import PageSubMenu from '@/components/PageSubMenu.vue';
  import { useResponsive } from '@/composables/responsive.js';
  
  export default {
    data() {
      return {
        showAccountSettings: false,
        showBilling: false,
        showFolders: false,
        isPopupVisible: false,
        isPopupVisibleDelete: false,
        isPopupVisibleExport: false,
        isPopupVisibleSave: false,
        itemsFromApi: [],
        menuSections: [
          {
            title: this.$t('My Profile'),
            items: [
              { label: this.$t("accountSettings"), icon: require('@/assets/MyProfile/acc-settings.svg'), pathName: 'AccountSettingsPage' },
              { label: this.$t("billing"), icon: require('@/assets/MyProfile/billing-icon.svg'), pathName: 'UpgradePlanPage' }
            ]
          }
        ]
      }
    },
    components: {
      DividerComponent,
      AccountNotifications,
      PersonalInformation,
      SecuritySettings,
      CustomPopup,
      CustomButton,
      PageSubMenu
    },
    setup() {
      const { isMobile} = useResponsive();
      const usersStore = useUsersStore();
      const alertStore = useAlertStore();
      return {
        isMobile,
        usersStore,
        alertStore
      }
    },
    async mounted() {
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          await this.usersStore.getUserData();
          nextTick(() => {
            this.itemsFromApi = this.usersStore.getListOutput;
          });
        } catch (error) {
          this.alertStore.error(error);
        }
      },
      async saveAccountChanges() {
        try {
          const personalInfo = this.$refs.personalInfoRef;

          // Prepare the payload with proper fallback values
          const payload = {
            picture: personalInfo.picture,
            name: personalInfo.name || 'Unknown',
            phoneNumber: personalInfo.phoneNumber || 'No Phone',
            currency: personalInfo.selectedItems.currency?.keyName || 'Unknown Currency',
            language: personalInfo.selectedItems.language?.keyName || 'Unknown Language',
            country: personalInfo.selectedItems.country?.keyName || 'Unknown Country',
          };

          // Send payload to backend
          await this.usersStore.changeMe(payload);

          // Update the user object in localStorage
          let userStorage = JSON.parse(localStorage.getItem('user')) || {};
          userStorage.user.language = payload.language;
          userStorage.user.phoneNumber = payload.phoneNumber;
          userStorage.user.country = payload.country;
          userStorage.user.currency = payload.currency;
          localStorage.setItem('user', JSON.stringify(userStorage));
          this.usersStore.updateHeaderUserInformation(payload);
          // Dynamically update the language in the application
          this.$i18n.locale = payload.language.toLowerCase(); // Set the i18n locale

          // Show success popup
          this.isPopupVisibleSave = true;
        } catch (error) {
          console.error('Error saving account changes:', error);
        }
      },
      sendToHome() {
        // The ideal solution would be to use router.push.
        // However, with router.push, the currency defined in vue-i18n wasn't updating 
        // because vue-i18n had already been initialized.
        // So we can restart the entire app, including vue-i18n, and the currency will load its value from local storage.
        window.location.href = '/'; 
        // this.$router.push({ name: 'DashboardPage' });
      },
      sendToReportsPage() {
        this.$router.push({ name: 'ReportsPage' });
      },
      sendToBillingPage() {
        this.$router.push({ name: 'BillingPage' });
      },
      showPopup() {
        this.isPopupVisible = true;
      },
      showPopupDelete() {
        this.isPopupVisibleDelete = true;
      },
      showPopupExport() {
        this.isPopupVisibleExport = true;
      }
    },
  }
  </script>
  
  <style scoped>
  .header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
  #user-name {
    color: var(--theme-dark, #212529);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .options-span {
    color: var(--theme-secondary, #6C757D);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  #divider-style {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
  #delete-acc-span {
    color: var(--theme-danger, #DC3545);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .buttons-div-style{
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    gap: 1rem;
  }
  @media only screen and (max-width: 767px) {
    .buttons-div-style{
        justify-content: center;
    }
  }
  </style>
  