<template>
  <BaseSign>
    <template v-slot:content>
      <Form v-show="newPasswordSuccess == false" class="form" @submit="onSubmit" :validation-schema="schema"
        v-slot="{ errors, isSubmitting }">
        <div class="col-12">
          <img class="icon" src="@/assets/authentication/sign-up/resetPassword.svg" alt="">
          <div class="my-5">
            <span class="title-primary">{{ $t('createNewPassword') }}</span>
            <br>
            <span class="title-secondary">{{ $t('yourNewPasswordMustBeDifferentFromPreviousUsedPasswords.') }}</span>
          </div>
        </div>
        <ValidationAlerts v-if="hasError(errors, 'password') || hasError(errors, 'passwordConfirmation')"
          :alertType="'error'" class="my-2" :title="getErrorMessages(errors, ['password', 'passwordConfirmation'])" />
        <div class="text-start my-3">
          <p class="validation" :class="{ 'validation--passed': password.length >= 8 }">
            <i class="frmIcon" :class="password.length >= 8 ? 'bi bi-check' : 'bi bi-x-circle'"></i>
            {{ $t('mustBeAtLeast8Characters') }}
          </p>
          <p class="validation" :class="{ 'validation--passed': has_uppercase }">
            <i class="frmIcon" :class="has_uppercase ? 'bi bi-check' : 'bi bi-x-circle'"></i>
            {{ $t('hasACapitalLetter') }}
          </p>
          <p class="validation" :class="{ 'validation--passed': has_lowercase }">
            <i class="frmIcon" :class="has_lowercase ? 'bi bi-check' : 'bi bi-x-circle'"></i>
            {{ $t('hasALowercaseLetter') }}
          </p>
          <p class="validation" :class="{ 'validation--passed': has_number }">
            <i class="frmIcon" :class="has_number ? 'bi bi-check' : 'bi bi-x-circle'"></i>
            {{ $t('hasANumber') }}
          </p>
          <p class="validation" :class="{ 'validation--passed': has_special }">
            <i class="frmIcon" :class="has_special ? 'bi bi-check' : 'bi bi-x-circle'"></i>
            {{ $t('hasASpecialCharacter') }}
          </p>
        </div>
        <div class="form-group">
          <label for="password">{{ $t('password') }}<span class="asterisk">*</span></label>
          <Field name="password" type="password" v-slot="{ field }">
            <div id="input-password">
              <input id="password" v-bind="field" type="password" class="form-control input-form"
                :class="{ 'is-invalid': hasError(errors, 'password') }" :placeholder="$t('password')" v-model="password"
                @input="password_check" />
              <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                @click="switchVisibility('password')" src="@/assets/MyProfile/visibilityPassword-icon.svg" alt="">
            </div>
          </Field>
          <div class="d-flex align-items-center gap-2 text-start" :class="{ 'mt-3': badgeColor }" ref="strengthBadge">
            <i class="frmIcon" :class="{ 'bi bi-check-circle-fill': badgeColor }" :style="{ color: badgeColor }"></i>
            <span :style="{ color: badgeColor }">{{ strengthText }}</span>
          </div>
        </div>
        <div class="form-group">
          <label for="password-confirmation">{{ $t('repeatPassword') }}<span class="asterisk">*</span></label>
          <Field name="passwordConfirmation" type="password" v-slot="{ field }">
            <div id="input-password-confirmation">
              <input id="password-confirmation" v-bind="field" type="password" class="form-control input-form"
                :class="{ 'is-invalid': hasError(errors, 'passwordConfirmation') }"
                :placeholder="$t('passwordConfirmation')" />
              <img class="cursor-pointer" v-show="!hasError(errors, 'passwordConfirmation')" id="visibility-icon"
                @click="switchVisibility('password-confirmation')" src="@/assets/MyProfile/visibilityPassword-icon.svg"
                alt="">
            </div>
          </Field>
        </div>
        <div class="form-group">
          <button type="submit" class="w-100" v-bind:class="{ disabled: loading }"
            :disabled="isSubmitting"> <span v-show="!loading">{{ $t('submit') }}</span>
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </Form>
      <div v-if="newPasswordSuccess">
        <div class="col-12">
          <div class="d-none d-md-block col-12">
            <img class="icon" src="@/assets/authentication/create-new-Password/new-pass-success.svg" alt="icon">
          </div>
        </div>
        <div class="d-flex flex-column gap-3 my-4">
          <span class="title-primary">{{ $t('passwordChanged') }}</span>
          <span class="title-secondary color-secondary">{{ $t('enterEmailForVerificationCode.') }}</span>
        </div>
        <ValidationAlerts class="my-4" alertType="success" :title="$t('yourPasswordHasBeenSuccessfullyChanged.')" />
        <div class="d-flex justify-content-center align-items-center gap-2 mt-5 cursor-pointer" @click="goToSignIn">
          <img src="@/assets/authentication/create-new-Password/arrow-left.svg" alt="icon">
          <span class="back-login-btn">{{ $t('backToLogin') }}</span>
        </div>
      </div>
      <div class="col-12 space-top text-start">
        <span :style="{ fontSize: '0.8rem', wordBreak: 'break-word' }">{{ $t('protectedByReCAPTCHAAndSubjectToThe') }}
          <a class="privacy-policy" href="">{{ $t('revenueMasterPrivacyPolicyAndTermsOfService.') }} </a>
        </span>
      </div>
    </template>
  </BaseSign>
</template>

<script>
import BaseSign from "@/components/BaseSign.vue";
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { useAlertStore, useUsersStore } from '@/stores';
import ValidationAlerts from '@/components/ValidationAlerts.vue';

export default {
  components: {
    Form,
    Field,
    BaseSign,
    ValidationAlerts
  },
  setup() {
    const alertStore = useAlertStore();
    const usersStore = useUsersStore();

    return {
      alertStore,
      usersStore
    };
  },
  data() {
    return {
      newPasswordSuccess: false,
      loading: false,
      password: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false
    }
  },
  computed: {
    schema() {
      return Yup.object({
        password: Yup.string()
          .min(8, this.$t('passwordMustBeAtLeast8Characters'))
          .test('has_number', this.$t('passwordMustHaveANumber'), value => /\d/.test(value))
          .test('has_lowercase', this.$t('passwordMustHaveALowercaseLetter'), value => /[a-z]/.test(value))
          .test('has_uppercase', this.$t('passwordMustHaveAnUppercaseLetter'), value => /[A-Z]/.test(value))
          .test('has_special', this.$t('passwordMustHaveASpecialCharacter'), value => /[!@#$%^&*()_+=.-]/.test(value))
          .required(this.$t('passwordRequired')),
        passwordConfirmation: Yup.string().required(this.$t('passwordConfirmationIsARequiredField')).oneOf([Yup.ref('password')], this.$t('passwordsDoNotMatch'))
      })
    },
    apiErrors: function () {
      return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : this.alertStore.alert ? this.alertStore.alert : {};
    }
  },
  methods: {
    sendToSignUp() {
      this.$router.push({ name: 'SignUp' });
    },
    async onSubmit(values) {
      this.alertStore.clear();
      this.loading = true;
      const { password } = values;
      try {
        await this.usersStore.login(password); // review
        this.newPasswordSuccess = true;
        this.loading = false;
      }
      catch (error) {
        this.alertStore.error(error);
        this.loading = false;
      }
    },
    switchVisibility(selector) {
      const passwordField = document.querySelector(`#${selector}`)
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    },
    hasError(errors, label) {
      return errors[label] || this.hasApiError(label);
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    },
    getErrorMessages(errors, labels) {
      const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
      return errorMessages.length ? errorMessages.join('<br>') : errorMessages.concat(this.apiErrors.output);
    },
    goToSignIn() {
      this.alertStore.clear();
      this.newPasswordSuccess = false;
      this.$router.push({ name: 'SignIn' });
    },
    StrengthChecker(password) {
      const requirements = [
        /\d/, // At least one digit
        /[a-z]/, // At least one lowercase letter
        /[A-Z]/, // At least one uppercase letter
        /[!@#$%^&*()_+=.-]/, // At least one special character
        /.{8,}/, // Must be at least 8 characters 
      ];
      const fulfilledRequirements = requirements.reduce(
        (count, requirement) => (requirement.test(password) ? count + 1 : count),
        0
      );

      if (fulfilledRequirements === 5) {
        this.badgeColor = "green";
        this.strengthText = this.$t('strongPassword');
      } else if (fulfilledRequirements >= 3) {
        this.badgeColor = "blue";
        this.strengthText = this.$t('mediumPassword');
      } else {
        this.badgeColor = "red";
        this.strengthText = this.$t('weakPassword');
      }
    },
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*()_+=.-]/.test(this.password);
      this.$refs.strengthBadge.style.display = "block";
      this.StrengthChecker(this.password);
      if (this.password.length !== 0) {
        this.$refs.strengthBadge.style.display != "block";
      } else {
        this.$refs.strengthBadge.style.display = "none";
      }
    }
  }
}
</script>

<style scoped>
.title-primary {
  font-size: 20px;
  font-weight: 900;
  word-break: break-word;
}

.title-secondary {
  font-size: 15px;
  color: var(--theme-secondary);
  word-break: break-word;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  justify-content: flex-start;
}

.asterisk {
  color: var(--theme-primary);
  margin-left: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-form {
  max-width: 500px;
}

.privacy-policy {
  color: var(--theme-primary);
}

.space-top {
  margin-top: 5rem;
}

.form-group button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--theme-primary);
  text-decoration: none;
  text-align: center;
}

.form-group a {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--theme-primary) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.icon {
  width: 130px;
}

#input-password,
#input-password-confirmation {
  position: relative;
}

#visibility-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.back-login-btn {
  color: var(--theme-secondary);
}


.validation {
  font-size: 13px;
}

.validation--passed {
  color: #717b85;
}

.frmIcon {
  color: #EB0029;
}

.validation--passed .frmIcon {
  color: #0fa140;
}
</style>