<template>
  <div class="col" v-for="(statistic, index) in statistics" :key="index">
    <div class="block">
      <div class="content p-3">
        <p class="title">{{ statistic.title }}</p>
        <p class="value">{{ statistic.value }}</p>
        <p class="value-comparison">{{ statistic.comparison }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'StatisticsBlocks',
    props: {
      statistics: Array
    },
  };
</script>
<style scoped>
.block {
  min-width: 200px;
  border-radius: 8px;
  border: 0px 0px 1px 0px;
  box-shadow: 0px 4px 20px 0px #0000000D;
  background-color: #FFFFFF;
}
.block .content .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6C757D;
}
.block .content .value {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  color: #6C757D;
}
.block .content .value-comparison {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6C757D;
}
</style>
  