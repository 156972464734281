<template>
    <section>
      Smart Pricing
    </section>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>