<template>   
    <section v-if="$route.name === 'IntegrationsPage'" id="list">
        <LayoutList
            v-model="itemsFromApi"
            :table="{
                layout: {
                    hasHeader: { desktop: true, mobile: true }
                },
                headers: tableHeaders,
                rows: tableRows,
                loading: loading
            }"
            :hasTitle="{
                title: $t('integrations'),
                icon: require('@/assets/HotelGroup/integrationsIcon.svg')
            }"
            :button="{
                label: $t('addIntegration'),
                icon: require('@/assets/HotelGroup/addIcon.svg'),
                style: {
                    color: '#FFFFFF',
                    borderRadius: '8px!important',
                    gap: '8px',
                    padding: '5px 10px',
                    minWidth: '200px'
                }
            }"
            :searchBar="false"
            @addItem="addItem"
            @rowClicked="rowClicked"
        >
             <template v-slot:actions="{ item, rowIndex, actionType }">
                <div v-if="actionType === 'delete'">
                    <i @click.stop="handleDeleteIntegration(item, rowIndex)" class="bi bi-dash-circle-fill cursor-pointer" style="font-size: 20px; color:#6C757D"></i>
                </div>
            </template>
            <!-- <template v-slot:actions>
                <div class="d-flex justify-content-center">
                    <CustomButton class="btn mt-2 d-flex gap-2" 
                        :label="$t('delete')"
                        buttonType="theme-light"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                        @clickBtn="openDeletePopup()"
                    />
                </div>
            </template> -->
        </LayoutList>
        <!-- Create Popup -->
        <CustomPopup 
            v-show="isPopupVisibleCreate" 
            v-model:option="selectedValue"
            :img="require('@/assets/Popup/integrationPopupIcon.svg')"
            :titlePopup="$t('Add new integration')"
            :labelInput="$t('New Integration')"
            :inputRequired="true"
            :inputType="'select'"
            :placeholderText="$t('Choose new integration')"
            :options="integrationsOptions"
            @popUpClosed="resetForm"
        >
            <template v-slot:form>
                <Form ref="form" class="form" @submit="addIntegrationEvent" v-slot="{ errors }">
                    <div class="d-flex flex-column gap-2">
                        <template v-if="selectedValue === 'Mews'">
                            <div class="field">
                                <label class="mb-2" for="clientToken">{{ $t('Insert Client Token') }}<span class="asterisk"> *</span></label>
                                <Field 
                                    name="clientToken" 
                                    class="form-control" 
                                    :class="{ 'is-invalid': errors.clientToken }"
                                    v-model="form.clientToken"
                                    :placeholder="$t('Insert Client Token')"
                                    rules="required"
                                />
                                <ErrorMessage name="clientToken" class="text-danger" />   
                            </div>
                            <div class="field">
                                <label class="mb-2" for="accessToken">{{ $t('Insert Access Token') }}<span class="asterisk"> *</span></label>
                                <Field
                                    name="accessToken"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.accessToken }"
                                    v-model="form.accessToken"
                                    :placeholder="$t('Insert Access Token')"
                                    rules="required"
                                />
                                <ErrorMessage name="accessToken" class="text-danger" />
                            </div>
                        </template>
                        <template v-else-if="selectedValue === 'Lighthouse'">
                            <div class="field">
                                <label class="mb-2" for="apiToken">{{ $t('Insert API Token') }}<span class="asterisk"> *</span></label>
                                <Field
                                    name="apiToken"
                                    class="form-control"
                                    :class="{ 'is-invalid': errors.ApiToken }"
                                    v-model="form.apiToken"
                                    :placeholder="$t('Insert API Token')"
                                    rules="required"
                                />
                                <ErrorMessage name="apiToken" class="text-danger" />
                            </div>
                        </template>
                        <h6 v-if="form.apiError !== ''" class="text-danger">{{ form.apiError }}</h6>
                        <button v-if="selectedValue !== ''" type="submit" :class="{'disabled': form.loading}" class="btn mt-4">
                            <span v-if="!form.loading">{{ $t('Add') }}</span>
                            <span v-else>
                                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                <span>{{ $t('loading') }}</span>
                            </span>
                        </button>
                    </div>
                </Form>
            </template>
        </CustomPopup> 
        <!-- Delete Integration -->
        <!-- <CustomPopup 
            v-show="isPopupVisibleDeleteSecond" 
            @popUpClosed="isPopupVisibleDeleteSecond = false"
            :img="require('@/assets/Popup/integrationPopupIcon.svg')"
            :titlePopup="'Delete Integration'"
            :descriptionPopup="'Are you sure you want to delete this integration?'"
            >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="'Cancel'" 
                        @clickBtn="cancelDelete()"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }"
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="'Yes'" 
                        @clickBtn="confirmDelete()"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                </div>
            </template>
        </CustomPopup>  -->
    </section>
    <router-view></router-view>
</template>

<script>
import LayoutList from '@/components/LayoutList.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
// import CustomButton from '@/components/CustomButton.vue';
import { useIntegrationStore, useAlertStore } from "@/stores";
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        LayoutList,
        CustomPopup,
        Form,
        Field,
        ErrorMessage,
        // CustomButton
    },
    setup() {
        const integrationStore = useIntegrationStore();
        const alertStore = useAlertStore();

        return {
            integrationStore,
            alertStore
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'IntegrationsPage') {
            next(); 
            return; // Prevents the code below from running
        }
        // console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        if(this.$route.name === 'IntegrationsPage') {
            await this.fetchData();
        }
    },
    data() {
        return {
            isPopupVisibleCreate: false,
            isPopupVisibleDeleteSecond: false,
            selectedValue: '', // Store the selected value
            tableHeaders: [
                {
                    text: 'Name',
                    icon: require('@/assets/HotelGroup/integrationsIcon.svg'),
                },
                {
                    text: 'Type',
                    icon: require('@/assets/HotelGroup/integrationsIcon.svg'),
                },
            ],
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'SimpleText', field: 'type' },
                { type: 'Action', field: 'delete', hasHeader: false}
            ],
            itemsFromApi: [],
            integrationsOptions: [
                { value: 'Mews', label: 'Mews' },
                { value: 'Lighthouse', label: 'Lighthouse' },
                // { value: 'DEdge', label: 'DEdge'}
            ],
            loading: false,
            form: {
                clientToken: '',
                accessToken: '',
                client: 'Revenue Master 1.0.0',
                apiToken: '',
                loading: false,
                apiError: null
            },
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                await this.integrationStore.getIntegrations();
                await this.integrationStore.getCompanyIntegrations();

                // Remove integrations that are already added to the company
                this.integrationsOptions = this.integrationsOptions.filter((option) => {
                    // Check if there's any integration with the same type as option.value
                    return !this.integrationStore.companyIntegrationsOutput?.integrations.some((integration) => 
                        integration.type === option.value
                    );
                });

                // Update the list of integrations
                this.itemsFromApi = this.integrationStore?.companyIntegrationsOutput?.integrations
                    ? this.integrationStore.companyIntegrationsOutput.integrations.map((integration) => ({
                        id: integration.objectId,
                        name: { image: require('@/assets/integrations/integrationTableIcon.svg'), firstText: integration.name },
                        type: integration.type
                    }))
                    : [];

                // remove when api for light house is ready
                this.form.apiToken = this.integrationStore.integrationsOutput?.integrations.Lighthouse.DataRequired.ApiToken.Example;

            } catch (error) {
                this.alertStore.error(error);
            } finally {
                this.loading = false;
            }
        },
        addItem() {
            this.isPopupVisibleCreate = true;
        },
        openDeletePopup() {
            this.isPopupVisibleDeleteSecond = true;
        },
        handleSelectChanged(value) {
            this.selectedValue = value;
            // You can now use `selectedValue` as needed
        },
        async addIntegrationEvent(values) {
            const { clientToken, accessToken, apiToken } = values;

            const selectedIntegration = this.selectedValue; // from the select dropdown
            let tokenPayload = null
            if(selectedIntegration === 'Mews') {
                tokenPayload = {
                    ClientToken: clientToken,
                    AccessToken: accessToken,
                    Client: this.form.client
                }
            } else if(selectedIntegration === 'Lighthouse') {
                tokenPayload = {
                    ApiToken: apiToken
                }
            }
            
            const payload = {
                name: `${this.selectedValue} integration`,
                type: this.selectedValue,
                data: tokenPayload
            }
            
            this.form.loading = true;
            // Add the integration to the company
            try {
                await this.integrationStore.addCompanyIntegration(payload);

                // Update the list of integrations
                await this.integrationStore.getCompanyIntegrations();

                this.itemsFromApi = this.integrationStore?.companyIntegrationsOutput?.integrations
                    ? this.integrationStore.companyIntegrationsOutput.integrations.map((integration) => ({
                        id: integration.objectId,
                        name: { 
                            image: require('@/assets/HotelGroup/integrationsIcon.svg'), 
                            firstText: integration.name 
                        },
                        type: integration.type
                    }))
                    : [];

                // Remove integrations that are already added to the company
                this.integrationsOptions = this.integrationsOptions.filter((option) => {
                    // Check if there's any integration with the same type as option.value
                    return !this.integrationStore.companyIntegrationsOutput?.integrations.some((integration) => 
                        integration.type === option.value
                    );
                });

                // Close the popup
                this.isPopupVisibleCreate = false;
                this.$refs.form.resetForm();
                this.selectedValue = '';

            } catch (error) {
                this.form.apiError = error.message.replace(/"/g, '');
                // console.log(error);
            } finally {
                this.form.loading = false;
            }
        },
        resetForm() {
            this.isPopupVisibleCreate = false;
            this.$refs.form.resetForm();
            this.selectedValue = '';
            this.form.loading = false;
        },
        async handleDeleteIntegration(item) {
            try {
                const payload = {
                    type: item.type
                }
                await this.integrationStore.deleteCompanyIntegration(payload);
                // Update the list of integrations
                await this.integrationStore.getCompanyIntegrations();
                this.itemsFromApi = this.integrationStore?.companyIntegrationsOutput?.integrations
                    ? this.integrationStore.companyIntegrationsOutput.integrations.map((integration) => ({
                        id: integration.objectId,
                        name: { 
                            image: require('@/assets/HotelGroup/integrationsIcon.svg'), 
                            firstText: integration.name 
                        },
                        type: integration.type
                    }))
                    : [];

                    // add the deleted integration back to the options
                    this.integrationsOptions.push({ value: item.type, label: item.type });

            } catch (error) {
                this.alertStore.error(error);
            }
        },
        cancelDelete() {
            this.$router.push({ name: 'IntegrationSettingsList' });
        },
        confirmDelete() {
            this.$router.push({ name: 'IntegrationSettingsList' });
        },
        rowClicked(obj) {
            const type = obj.type.toLowerCase();
            this.$router.push({ name: 'IntegrationPage', params: { integrationType: type} });
        }
    },
}
</script>
<style scoped>
.asterisk {
    color: var(--theme-danger);
}
.form,
input::placeholder {
    font-size: 1rem;
}
.form button {
    width: 100%;
    background-color: var(--theme-primary);
    border-radius: 4px;
    color: white;
}
.form button:active {
    background-color: var(--theme-primary);
    color: white;
}
</style>