<template>
  <ul v-show="isOpen" class="dropdown-menu p-4" :class="dynamicClass" ref="dropdownCheckBox">
    <div class="d-flex flex-column gap-2">
      <li class="dropdown-item cursor-pointer p-2" v-for="(item, index) in items" :key="index" @click="handleItemClick(index)">
        <div class="d-flex align-items-center justify-content-between gap-3">
            <img v-if="avatar" :src="item.avatar">
            <span class="label">{{ item.label }}</span>
            <input @click="handleItemClick(index)" id="checkbox" type="checkbox" :checked="item.checked" @click.stop>
        </div>
      </li>
    </div>
    <button 
      v-if="selectedButton" 
      class="selected" 
      :class="{'disabled': !hasSelectedItem && !selectedItems.length}" 
      @click="confirmSelection" 
    >
      <div class="d-flex align-items-center justify-content-center">
        <img class="icon" src="@/assets/Pickup/check2-circle.svg" width="12" height="12" alt="check">
        <span class="label mx-2">{{$t('select')}}</span>
      </div>
    </button>
  </ul>
</template>
<script>

export default {
  props: {
    isOpen: Boolean,
    customClass: Array,
    items: Array,
    avatar: {
      type: Boolean,
      default: false
    },
    selectedButton: {
      type: Boolean,
      default: false
    },
    selectedItems: Array
  },
  mounted() {
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
  computed: {
    dynamicClass() {
      return [...this.customClass, { 'show': this.isOpen }];
    },
    hasSelectedItem() {
      return this.items.some(item => item.checked);
    }
  },
  methods: {
    closeDropdownOnClickOutside(event) {
      if (this.isOpen && this.$refs.dropdownCheckBox && !this.$refs.dropdownCheckBox.contains(event.target)) {
        this.$emit('close-dropdown');
      }
    },
    handleItemClick(index) {
      this.$emit('item-click', index);
    },
    confirmSelection() {
      // Emit an event to indicate that the user has confirmed the selection or when selectedItems has items
      if(this.hasSelectedItem || this.selectedItems.length) this.$emit('confirm-selection', this.items);
    },
  },
};
</script>
<style scoped>
.dropdown-menu .dropdown-item {
  border-bottom: 1px solid var(--theme-border);
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: rgba(108, 117, 125, .05);
  border-radius: 5px;
  color: #212529;
}

.dropdown-menu .dropdown-item .label {
  font-size: .7rem;
  font-weight: 400;
  line-height: 24px;
  color: #212529;
}
#checkbox {
  accent-color: var(--theme-primary);
}
.selected {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 180px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  border: 1px solid #DEE2E6;
  margin: 30px auto;
  background-color: #F8F9FA;
}
.selected .label {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #6C757D;
}
</style>