<template>
  <section>
      <div class="container-fluid">
          <div class="row">
              <div class="container-list p-0">
                  <TableComponent 
                    v-model="itemsFromApi"
                    :layout="{
                      hasHeader: { desktop: true, mobile: false }
                    }" 
                    :tableHeaders="tableHeaders" 
                    :tableRows="tableRows"
                  >
                      <template v-slot:title>
                          <div class="row my-4 px-4">
                                <div class="col-12 col-lg-6 d-flex px-3 gap-3">
                                    <span class="table-title">Demand</span>
                                    <div>
                                        <DropdownButton :label="$t('Crystal Breeze Resort')" @clickBtn="toggleDropdown('hotel')" :icon="require('@/assets/Market/hotel-icon.svg')"/>    
                                        <CheckboxList 
                                            :isOpen="isDropdownHotelOpen" 
                                            @close-dropdown="isDropdownHotelOpen = false"
                                            :customClass="['hotel-dropdown-menu']" 
                                            :items="dropdownHotelItems"
                                            @item-click="handleCheckboxClick($event, 'dropdownHotelItems')" 
                                            :avatar="true"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 d-flex justify-content-end px-5 gap-3 hotel-dropdown dropdown no-select">
                                    <div>
                                        <DropdownButton :label="$t('Porto')" @clickBtn="toggleDropdown('location')"/>    
                                        <CheckboxList 
                                            :isOpen="isDropdownLocationOpen" 
                                            @close-dropdown="isDropdownLocationOpen = false"
                                            :customClass="['location-dropdown-menu']" 
                                            :items="dropdownHotelItems"
                                            @item-click="handleCheckboxClick($event, 'dropdownHotelItems')" 
                                            :avatar="true"
                                        />
                                    </div>
                                    <div>
                                        <DropdownButton :label="$t('website')" @clickBtn="toggleDropdown('website')" />
                                        <CheckboxList 
                                            :isOpen="isDropdownWebsiteOpen" 
                                            @close-dropdown="isDropdownWebsiteOpen = false"
                                            :customClass="['website-dropdown-menu']" 
                                            :items="dropdownHotelItems"
                                            @item-click="handleCheckboxClick($event, 'dropdownHotelItems')" 
                                            :avatar="true"
                                        />
                                    </div>
                                </div>
                          </div>
                          <div class="row my-4">
                              <div class="col-12 col-lg-6 d-flex px-5 gap-2 align-items-center">
                                <img src="@/assets/Market/market-demand-icon.svg" alt="">
                                <span class="sub-title">Market Demand</span>
                              </div>
                              <div class="col-12 col-lg-6 d-flex justify-content-end px-5">
                                <DateButton @update-date="updateDateProperty('metricsTableDate', $event)" typeForm="1" :label="$t('date')" />
                              </div>
                          </div>
                      </template>
                  </TableComponent>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DateButton from '@/components/DateButton.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import CheckboxList from '@/components/CheckboxList.vue';
export default {
  components: {
      TableComponent,
      DateButton,
      DropdownButton,
      CheckboxList
  },
  data() {
      return {
          selectedItems: [],
          tableHeaders: [
              {
                  icon: require('@/assets/Market/date-icon.svg'),
                  text: 'Date'
              },
              {
                  icon: require('@/assets/Market/hotel-icon.svg'),
                  text: 'Crystal Breeze Resort'
              },
              {
                  icon: require('@/assets/Market/market-demand-icon.svg'),
                  text: 'Market Demand'
              }
          ],
          tableRows: [
              { type: 'SimpleText', field: 'date' },
              { type: 'SimpleText', field: 'hotel' },
              { type: 'SimpleText', field: 'marketDemand' },
          ],
          itemsFromApi: [
              { id: 0, date: 'Sun 13', hotel: '113€', marketDemand: '56%' },
              { id: 1, date: 'Sun 14', hotel: '113€', marketDemand: '56%' },
              { id: 2, date: 'Sun 15', hotel: '113€', marketDemand: '56%' },
          ],
          // hotel dropdown
          isDropdownHotelOpen: false,
          isDropdownWebsiteOpen: false,
          isDropdownLocationOpen: false,
          dropdownHotelItems: [
              {
                  id: 0,
                  label: "Cystal Breeze Resort California 1 ",
                  avatar: require('@/assets/avatar.png')
              },
              {
                  id: 1,
                  label: "Cystal Breeze Resort California 2",
                  avatar: require('@/assets/avatar.png')
              }
          ],
      }
  },
  methods: {
      updateDateProperty(propertyName, newValue) {
        this[propertyName] = newValue;
      },
      toggleDropdown(dropdownName) {
        this.isDropdownPickupOpen = dropdownName === "pickup" && !this.isDropdownPickupOpen;
        this.isDropdownMetricsOpen = dropdownName === "metrics" && !this.isDropdownMetricsOpen;
        this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
        this.isDropdownWebsiteOpen = dropdownName === "website" && !this.isDropdownWebsiteOpen;
        this.isDropdownLocationOpen = dropdownName === "location" && !this.isDropdownLocationOpen;
      },
      handleCheckboxClick(index, propertyName) {
        const selectedItem = this[propertyName][index];
        selectedItem.checked = !selectedItem.checked;

        // Check if the item is already in the selectedHotelItems array
        const selectedIndex = this.selectedHotelItems.findIndex(item => item.id === selectedItem.id);

        if (selectedItem.checked && selectedIndex === -1) {
            // If the item is selected and not in the array, add it
            this.selectedHotelItems.push(selectedItem);
        } else if (!selectedItem.checked && selectedIndex !== -1) {
            // If the item is deselected and in the array, remove it
            this.selectedHotelItems.splice(selectedIndex, 1);
        }
      },
  },
}
</script>

<style scoped>
.container-list {
  border-radius: 15px 15px 0px 0px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
.sub-title {
    color: var(--Theme-Secondary, #6C757D);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.pickup-dropdown-menu {
    min-width: 240px;
    left: 210px;
    top: -30px;
}

.metrics-dropdown-menu {
    min-width: 300px;
    left: -320px;
    top: 0;
}

.hotel-dropdown-menu {
    min-width: 450px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 0px #00000013;
    left: 200px;
    top: 120px;
}
.location-dropdown-menu {
    min-width: 450px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 0px #00000013;
    right: 0;
    top: 50px;
}
.website-dropdown-menu {
    min-width: 450px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 0px #00000013;
    right: 0;
    top: 50px;
}
</style>