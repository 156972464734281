<template>
    <div class="container-fluid">
        <div id="week-days" class="row">
            <div class="col week-days-style">Sunday</div>
            <div class="col week-days-style">Monday</div>
            <div class="col week-days-style">Tuesday</div>
            <div class="col week-days-style">Wednesday</div>
            <div class="col week-days-style">Thursday</div>
            <div class="col week-days-style">Friday</div>
            <div class="col week-days-style">Saturday</div>
        </div>
        <div class="row day-row" v-for="(row,index) in rows" :key="index">
            <div class="col day" v-for="day in row" :key="day">
                {{ day }}
                <div @click="displayPopup(event)" class="event" v-for="event in getEvents(day)" :key="event.id">
                    <span class="cursor-pointer">{{ event.name }}</span>
                </div>
            </div>
        </div>
        <EditPopupEvent 
          v-show="showEditPopupEvent" 
          @popUpClosed="showEditPopupEvent = false"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-2 mt-5 btns-div">
                  <CustomButton 
                      class="w-100 three-dots" 
                      :buttonType="'theme-light'" 
                      :label="$t('decline')" 
                      @clickBtn="showEditPopupEvent = false"
                      :rightIcon="require('@/assets/Market/delete-icon.svg')"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
                  <CustomButton 
                      class="w-100 three-dots" 
                      :buttonType="'primary'" 
                      :label="$t('accept')" 
                      @clickBtn="showEditPopupEvent = false"
                      :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </EditPopupEvent> 
    </div>
</template>

<script>
 import EditPopupEvent from '@/components/Market/EditPopupEvent.vue';
 import CustomButton from '@/components/CustomButton.vue';
export default {
    components: {
      EditPopupEvent,
      CustomButton
    },
    data() {
        return {
            showEditPopupEvent: false,
        }
    },
    props:
    {
        month: {
            type: Number,
            required: true
        },
        year: {
            type: Number,
            required: true
        },
        events: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        daysInMonth() {
            return new Date(this.year, this.month, 0).getDate();
        },
        firstDay() {
            return new Date(this.year, this.month - 1, 1).getDay();
        },
        rows() {
            let rows = [];
            let days = 1;
            let row = [];
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 7; j++) {
                    if (i === 0 && j < this.firstDay) {
                        row.push('');
                    } else if (days > this.daysInMonth) {
                        row.push('');
                    } else {
                        row.push(days);
                        days++;
                    }
                }
                rows.push(row);
                row = [];
            }
            return rows;
        }
    },
    methods:{
        getEvents(day){
            return this.events.filter(event => event.day === day && event.month === this.month && event.year === this.year);
        },
        displayPopup(event){
            console.log(event)
            this.showEditPopupEvent = true;
        }
    }
}

</script>

<style scoped>
#week-days {
    border: 1px solid whitesmoke;
    padding: 20px 0;
    text-align: center;
}

.day-row {
    min-height: 100px;
    gap: 1px;
}

.day {
    padding: 12px;
    border: 1px solid whitesmoke;
}

.event
{
    background-color: red;
    color: white;
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
}
.week-days-style {
    color: var(--Theme-Secondary, #6C757D);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}
</style>