<template>
  <section>
    <div class="p-2 p-sm-3">
        <TableComponent 
            v-model="itemsFromApi" 
            :layout="{
                hasHeader: { desktop: false, mobile: false }
            }" 
            :tableRows="tableRows"
        >
            <template v-slot:title>
                <div class="d-flex d-lg-none align-items-center justify-content-between mx-0 mx-sm-2 my-2 my-sm-4">
                    <div class="d-flex align-items-center gap-2">
                        <img src="@/assets/SearchMobile/search-icon.svg" alt="">
                        <span class="table-title">Search</span>
                    </div>
                </div>
                <DividerComponent class="d-block d-md-none my-3" />
                <div class="col mt-5">
                    <div class="form-group">
                        <input type="text" class="form-control search-input"
                            placeholder="Search here...">
                    </div>
                </div>
                <div class="mt-5">
                    <span id="recent-searches-span">Recent Searches</span>
                </div>
            </template>
        </TableComponent>
        <div class="d-flex justify-content-center mt-5">
            <span id="delete-search-span">Delete Search History</span>
        </div>
    </div>
  </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DividerComponent from '@/components/DividerComponent.vue';
export default {
    components: {
        TableComponent,
        DividerComponent
    },
    data() {
        return {
            selectedItems: [],
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
            ],
            itemsFromApi: [
                { id: 0, name: { image: require('@/assets/SearchMobile/search-avatar.svg'), firstText: 'Segmented Pickup', secondText: '20 min Ago' } },
                { id: 1, name: { image: require('@/assets/SearchMobile/search-avatar.svg'), firstText: 'Segmented Pickup', secondText: '20 min Ago' } },
                { id: 2, name: { image: require('@/assets/SearchMobile/search-avatar.svg'), firstText: 'Segmented Pickup', secondText: '20 min Ago' } },
            ],
        }
    },
}
</script>

<style scoped>
.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
.editDots {
    width: 20px;
}

.form-control.search-input {
    padding-left: 40px;
    background-image: url('@/assets/HotelGroup/searchBtn.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 10px center;
}
#recent-searches-span{
    color: var(--gray-600, #6C757D);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}
.search-input {
    width: 300px;
}
#delete-search-span{
    color: var(--theme-primary, #A81800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;   
    text-decoration-line: underline;
}
.btn-create {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 8px;
    background: var(--theme-primary, #A81800);
    color: var(--gray-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    text-decoration: none;
}

@media (max-width: 575.98px) {
    .form-control.search-input {
        font-size: 13px;
        background-size: 15px 15px;
        padding-left: 35px;
    }
}

@media only screen and (max-width: 767px) {
    .search-input {
        width: 100%;
    }
}
</style>