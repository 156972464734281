<template>
    <section v-if="$route.name === 'HotelGroupEditPage'" id="form">
        <template v-if="isMobile">
            <div @click="$router.push({name: 'CompanySettingsPage'})" class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/arrow-right-fill.svg')" alt="Edit hotel group icon">
                <span class="page-title-mobile">{{ $t('editGroup') }} </span>
            </div>
        </template>
        <LayoutForm 
            class="mt-5 mt-lg-0"
            :hasPageActions="true"
            :pageLayout="{
                title: $t('editGroup'),
                icon: require('@/assets/Company/pencil.svg'),
                firstAction: { icon: require('@/assets/HotelGroup/x-circle.svg'), label: $t('cancel') },
                secondAction: { icon: require('@/assets/HotelGroup/saveBtn.svg'), label: $t('save'), loading: loadingForm },
            }"
            :threeDotsSettings="threeDotsSettings"
            @pageActionClick="pageActionClick($event)" 
            @firstActionClick="firstActionClick" 
            @secondActionClick="secondActionClick"
        >
            <template v-slot:dynamic-content>
                <div class="row p-0 p-md-3 border-bottom">
                    <div class="file-picker">
                        <img 
                            v-if="Object.keys(formData.picture).length" 
                            class="cursor-pointer img-fluid rounded-circle" 
                            :src="formData.picture.url || parseImageData(formData?.picture)" 
                            @click="showimagePicker = true"
                        >                       
                        <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                            <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !formData.picture.url && imageError }">
                                {{ $t('Choose Image') }}
                                <pre class="m-0 text-muted">1:1</pre>
                            </span>   
                        </div>
                        <Form ref="myForm" class="form mb-3" :validation-schema="validationSchema" v-slot="{ errors }">
                            <div class="first-input">
                                <label class="label" for="name">{{ $t('Name') }}</label>
                                <Field
                                    v-model="formData.name"
                                    type="text"
                                    name="name"
                                    v-slot="{ field }"
                                    @input="validateField('name')"
                                >
                                    <input
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.name }"
                                        v-bind="field"
                                        :placeholder="$t('Insert Name')"
                                    />
                                </Field>  
                                <ErrorMessage name="name" class="text-danger" />                            
                            </div>
                        </Form>
                    </div>
                </div>
                <div class="row">
                    <TableComponent 
                        v-model="hotelsOfGroup" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyHotelsRows" 
                        :clickableRows="false"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="hotel-icon" src="@/assets/HotelGroup/hotelsIconTitle.svg" alt="">
                                        <span class="table-title">{{ $t('hotels') }}</span>
                                    </div>
                                </div>
                                <div v-if="hotelStore.getHotelListOutput && hotelStore.getHotelListOutput.length && !isMobile" class="col d-flex justify-content-end">
                                    <CustomButton 
                                        :leftIcon="require('@/assets/HotelGroup/addIcon.svg')"
                                        :label="$t('Add')" 
                                        @clickBtn="openModal('hotel')"
                                        :buttonStyle="{
                                            borderRadius: '8px!important',
                                            gap: '8px',
                                            color: '#FFFFFF',
                                            padding: '6px 12px'
                                        }" 
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-slot:actions="{ item, rowIndex, actionType }">  
                            <div v-if="actionType === 'delete'">
                                <i @click.stop="handleDeleteHotel(item, rowIndex)" class="bi bi-dash-circle-fill cursor-pointer" style="font-size: 20px; color:#6C757D"></i>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <CustomButton 
                            :leftIcon="require('@/assets/HotelGroup/addIcon.svg')"
                            :label="$t('Add')" 
                            @clickBtn="openModal('hotel')"
                            :buttonStyle="{
                                width: '100%',
                                borderRadius: '8px!important',
                                gap: '8px',
                                color: '#FFFFFF',
                                padding: '6px 12px'
                            }" 
                        />
                    </div>
                </div>
                <!-- <div class="row border-bottom  p-3">
                    <TableComponent 
                        v-model="usersFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyUserRows" 
                        :clickableRows="false"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="hotel-icon" src="@/assets/Users/usersIcon.svg" alt="">
                                        <span class="table-title">{{ $t('Users') }}</span>
                                    </div>
                                </div>
                                <div v-if="usersFromApi && usersFromApi.length && !isMobile" class="col d-flex gap-3 justify-content-end">
                                    <router-link class="btn-custom" :to="{ name: 'HotelGroupUsersList'}">
                                        <img src="@/assets/Company/arrow-up-right.svg" alt="icon">
                                        {{ $t('manageUsers')}}
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <router-link class="btn-custom" :to="{ name: 'HotelGroupUsersList'}">
                            <img src="@/assets/Company/arrow-up-right.svg" alt="icon">
                            {{ $t('manageUsers')}}
                        </router-link>
                    </div>
                </div> -->
            </template>
        </LayoutForm>
        <!-- ModalAddItems -->
        <ModalAddItems 
            v-show="modalVisible" 
            :itemsList="currentItemsList"
            @popUpClosed="closeModal"
        >
            <template v-slot:actions="{item}">
                <img
                    class="add-icon cursor-pointer"
                    src="@/assets/ControlerAssets/addCompany.svg"
                    alt="Add"
                    @click="handleAddItem(item)"
                >
            </template>
        </ModalAddItems>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false"
            :titlePopup="$t('changeHotelGroupPicture')"
            :descriptionPopup="$t('Here you can change the photo of your hotel group to make it more easily recognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="selectedPicture"
                        :placeholder="require('@/assets/Company/placeholder-2.png')" 
                        :ratioWidth="1" 
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': selectedPicture?.url === formData?.picture?.url }"
                        :label="$t('changePicture')"
                            buttonType="theme-light" 
                            :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
                            @clickBtn="onChooseImageClicked" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- Delete Hotel group -->
        <CustomPopup 
            v-show="showDeleteHotelGroupPopup" 
            @popUpClosed="showDeleteHotelGroupPopup = false"
            :img="require('@/assets/Popup/deleteHotelGroup.svg')"
            :descriptionPopup="$t('Are you sure you want to delete this Hotel Group?')"
        >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('cancel')"
                        @clickBtn="showDeleteHotelGroupPopup = false" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }" 
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('yes')"
                        @clickBtn="removeHotelGroup" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- cancel Hotel Group -->
        <CustomPopup 
            v-show="showCancelChangesPopup" 
            @popUpClosed="showCancelChangesPopup = false"
            :img="require('@/assets/Popup/cancelSave.svg')"
            :descriptionPopup="$t('Are you sure you want to cancel it? All changes will be deleted.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('No')"
                        @clickBtn="showCancelChangesPopup = false" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }" 
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')"
                        @clickBtn="cancelChanges" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- save Hotel Group -->
        <CustomPopup 
          v-show="showSaveChangesPopup" 
          @popUpClosed="showSaveChangesPopup = false"
          :img="require('@/assets/Popup/successSave.svg')"
          :descriptionPopup="$t('yourHotelGroupHasBeenSavedSuccessfully!')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="$t('close')" 
                      @clickBtn="closeSavePopup"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
    </section>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import { useHotelGroupStore, useAlertStore, useHotelStore } from "@/stores";
import LayoutForm from '@/components/LayoutForm.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import TableComponent from '@/components/TableComponent.vue';
import ModalAddItems from '@/components/popups/ModalAddItems.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useResponsive } from '@/composables/responsive.js';

export default {
    components: {
        LayoutForm,
        CustomPopup,
        CustomButton,
        ImagePicker,
        TableComponent,
        ModalAddItems,
        Form,
        Field,
        ErrorMessage
    },
    setup() {
        const hotelGroupStore = useHotelGroupStore();
        const alertStore = useAlertStore();
        const hotelStore = useHotelStore();
        const { isMobile } = useResponsive();

        return {
            hotelGroupStore,
            alertStore,
            hotelStore,
            isMobile
        }
    },
    data() {
        return {
            hotelGroupId: this.$route.params.hotelGroupId,
            formData: {
                name: '',
                picture: {},
            },
            originalData: {},
            selectedPicture: {},
            loading: false,
            loadingForm: false,
            imageError: false,
            // actions settings
            threeDotsSettings: [
                {
                    id: 0,
                    icon: require('@/assets/Popup/smallDeleteBtn.svg'),
                    alt: 'Delete',
                    label: this.$t('Delete Hotel Group'),
                    type: 'Remove'
                },
            ],
            showSaveChangesPopup: false,
            showCancelChangesPopup: false,
            showDeleteHotelGroupPopup: false,
            showimagePicker: false,
            // hotel table
            bodyHotelsRows: [
                { type: 'ImageWithText', field: 'hotel' },
                { type: 'SimpleText', field: 'location' },
                { type: 'Action', field: 'delete', hasHeader: false }
                ],
            hotelsOfGroup: [],
            // user table
            bodyUserRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'ImageWithText', field: 'role' },
            ],
            usersFromApi: [],
            modalVisible: false,
            modalType: '',
            availableHotels: []
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'HotelGroupEditPage') {
            next(); 
            return; // Prevents the code below from running
        }
        console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        // It's essential to include a condition in the mounted hook to ensure it only mounts when the specific page is loaded.
        if(this.$route.name === 'HotelGroupEditPage') {
            await this.fetchData();
        }
    },
    computed: {
        currentItemsList() {
            return this.modalType === 'hotel' ? this.availableHotels : [];
        },
        validationSchema() {
            return {
                name: 'required'
            }
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                if (!this.hotelGroupId) {
                    this.$router.push({ name: 'CompanySettingsPage' });
                    return; // Exit the function early to prevent further code execution
                }
                await this.hotelGroupStore.getHotelGroup(this.hotelGroupId);
                this.formData = {
                    name: this.hotelGroupStore.getHotelGroupOutput.name,
                    picture: this.hotelGroupStore.getHotelGroupOutput.picture && Object.keys(this.hotelGroupStore.getHotelGroupOutput.picture).length ? this.hotelGroupStore.getHotelGroupOutput.picture : {}
                }
                // Save original data
                this.selectedPicture = this.formData.picture;
                this.originalData = JSON.parse(JSON.stringify(this.formData));

                // users of the group
                this.usersFromApi = this.hotelGroupStore.getHotelGroupOutput.users.map(user => ({
                    id: user.objectId,
                    name: { 
                        image: user?.picture ?? require('@/assets/no-image.jpg'), 
                        firstText: user?.name, 
                        secondText: user?.pivot?.jobTitle ?? this.$t('jobTitleMissing')
                    },
                    role: { 
                        image: require('@/assets/Company/lock.svg'), 
                        firstText: user?.pivot?.role ?? this.$t('roleMissing')
                    }
                }));

                // hotels of the group
                this.hotelsOfGroup = this.hotelGroupStore.getHotelGroupOutput.hotels.map(hotel => ({
                    id: hotel.objectId,
                    hotel: {
                        image: hotel.picture ?? require('@/assets/no-image.jpg'),
                        firstText: hotel.name,
                    },
                    location: hotel.location ?? this.$t('locationMissing'),
                }));

                // get all hotels
                await this.hotelStore.getList();
                // Filtering hotels that are not associated with the group
                this.availableHotels = this.hotelStore.getHotelListOutput.filter(availableHotel => {
                    return !this.hotelsOfGroup.some(hotel => hotel.id === availableHotel.objectId);
                });

            } catch (error) {
                this.alertStore.error(error);
            }
            finally {
                this.loading = false;
            }
        },
        pageActionClick(index) {
            if (index == 0) {
                this.showDeleteHotelGroupPopup = true;
            } 
        },
        // cancel changes
        firstActionClick() {
            this.showCancelChangesPopup = true;
        },
        cancelChanges() {
            // Reset form data to original data
            this.formData = JSON.parse(JSON.stringify(this.originalData));
            this.selectedPicture = this.formData.picture;
            this.showCancelChangesPopup = false;
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        },
        // save changes
        async secondActionClick() {
            // submit form and manually validate
            // const { valid, errors } = await this.$refs.myForm.validate();
            const { valid } = await this.$refs.myForm.validate();
            if (!Object.keys(this.formData.picture).length) {
                this.imageError = true;
                return;
            }
            if(valid) {
                // console.log('Form is valid');
                this.imageError = false;
                this.loadingForm = true;
                
                const pictureObject = typeof this.formData.picture === 'object' && this.formData.picture !== null
                    ? { ...this.formData.picture }
                    : null;

                const payload = {
                    name: this.formData.name,
                    picture: pictureObject ?? this.formData.picture,
                    hotelsId: this.hotelsOfGroup.map(hotel => hotel.id),
                    // usersId: this.usersFromApi.map(user => user.id),
                };
                
                if (pictureObject) {
                    if(pictureObject.url) delete pictureObject.url;
                    payload.picture = JSON.stringify(pictureObject);
                }
                
                try{
                    await this.hotelGroupStore.editHotelGroup(this.hotelGroupId, payload);
                    this.originalData = JSON.parse(JSON.stringify(this.formData)); // update original data
                    this.showSaveChangesPopup = true;
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                } catch (error) {
                    alert(this.$t('Something went wrong! Please try again.'));
                    this.alertStore.error(error);
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                } finally {
                    this.loadingForm = false;
                }

            } else {
                this.loadingForm = false;
                // console.log('Form has errors:', errors);
            }
        },
        onChooseImageClicked() {
            this.formData.picture = this.selectedPicture;
            this.showimagePicker = false;
        },
        validateField(fieldName) {
            this.$refs.myForm.validate(fieldName); // Validate field when user types
        },
        closeSavePopup() {
            this.showSaveChangesPopup = false;
            this.$router.push({ name: 'CompanySettingsPage' });
        },
        async removeHotelGroup() {
            try{
                await this.hotelGroupStore.deleteHotelGroup(this.hotelGroupId);
                this.showDeleteHotelGroupPopup = false;
                this.$router.push({ name: 'CompanySettingsPage' });
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        openModal(type) {
            this.modalType = type;
            this.modalVisible = true;
        },
        closeModal() {
            this.modalVisible = false;
            this.modalType = '';
        },
        handleAddItem(item) {
            if (this.modalType === 'hotel') {
                this.addHotel(item);
            }
        },
        addHotel(item) {            
            // push new object with required fields to hotelsOfGroup
            this.hotelsOfGroup.push({
                id: item.objectId,
                hotel: {
                    image: item.picture ?? require('@/assets/no-image.jpg'),
                    firstText: item.name
                },
                location: item.location
            });
            // Remove the item from availableHotels (the popup)
            this.availableHotels = this.availableHotels.filter(hotelItem => hotelItem.objectId !== item.objectId);
        },
        handleDeleteHotel(item, index) {
            // Remove the item from hotels
            this.hotelsOfGroup.splice(index, 1);
            // Reinsert the item into availableHotels
            this.availableHotels.push({
                objectId: item.id,
                picture: item.hotel.image,
                name: item.hotel.firstText,
                location: item.location
            });
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    }
}
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transform: rotate(-180deg);
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.text-danger,
input::placeholder,
.first-input .label {
    color: var(--theme-secondary);
    font-size: .8rem;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
}

.table-title {
    color: var(--theme-dark, #212529);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}

.btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-dark);
    border-radius: 8px;
    border: 1px solid var(--theme-border);
    background: var(--theme-light);
    font-size: .9rem;
    text-decoration: none;
}

.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.file-picker img,
.file-picker .placeholder-img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}

.first-input .form-control {
    width: 290px;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
@media (min-width: 992px) {
    .btn-custom{
        min-width: 200px
    }
}
@media (max-width: 767px) {
    .first-input .form-control,
    .form {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
    .file-picker {
        margin-top: 1rem;
    }
}
</style>