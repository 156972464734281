<template>
    <section id="forecast">
        <template v-if="isMobile">
            <PageSubMenu class="mobile-side-menu" :class="{ open: menuStore.isSubMenuOpen }" :sections="menuSections" />
        </template>
        <template v-else>
            <PageSubMenu :sections="menuSections" />
        </template>
        <!-- General or Segmented component to display content -->
        <router-view />
    </section>
</template>
<script>
import PageSubMenu from '@/components/PageSubMenu.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useMenuStore } from '@/stores';

export default {
    components: {
        PageSubMenu
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const menuStore = useMenuStore();
        
        return {
            isMobile,
            isTablet,
            isDesktop,
            menuStore
        }
    },
    data() {
        return {
            menuSections: [
                {
                    title: this.$t('General Forecast'),
                    items: [
                        { label: 'Room Nights', icon: require('@/assets/Pickup/rn-icon.svg'), pathName: 'GeneralForecastRoomNights' },
                        { label: 'Occupancy', icon: require('@/assets/Pickup/occupancy-icon.svg'), pathName: 'GeneralForecastOccupancy' },
                        { label: 'Revenue', icon: require('@/assets/Pickup/revenue-icon.svg'), pathName: 'GeneralForecastRevenue' },
                        { label: 'Adr', icon: require('@/assets/Pickup/adr-icon.svg'), pathName: 'GeneralForecastAdr' },
                        { label: 'RevPar', icon: require('@/assets/Pickup/revPar-icon.svg'), pathName: 'GeneralForecastRevPar' },
                        { label: 'All', icon: require('@/assets/Pickup/collection-fill.svg'), pathName: 'GeneralForecastAll' }
                    ]
                },
                {
                    title: this.$t('Segmented Forecast'),
                    items: [
                        { label: 'Room Nights', icon: require('@/assets/Pickup/rn-icon.svg'), pathName: 'SegmentedForecastRoomNights' },
                        { label: 'Occupancy', icon: require('@/assets/Pickup/occupancy-icon.svg'), pathName: 'SegmentedForecastOccupancy' },
                        { label: 'Revenue', icon: require('@/assets/Pickup/revenue-icon.svg'), pathName: 'SegmentedForecastRevenue' },
                        { label: 'Adr', icon: require('@/assets/Pickup/adr-icon.svg'), pathName: 'SegmentedForecastAdr' },
                        { label: 'RevPar', icon: require('@/assets/Pickup/revPar-icon.svg'), pathName: 'SegmentedForecastRevPar' },
                        { label: 'All', icon: require('@/assets/Pickup/collection-fill.svg'), pathName: 'SegmentedForecastAll' }
                    ]
                }
            ]
        }
    },
    mounted() {
        if(this.isMobile && this.menuStore.isSubMenuOpen) {
            this.menuStore.isSubMenuOpen = false;
        }
    },
    watch: {
        '$route'(to, from) {
            if(to.name !== from.name) {
                this.menuStore.isSubMenuOpen = false;
            }
        }
    }
}
</script>
<style scoped>
/* Mobile side menu */
.mobile-side-menu {
  position: fixed!important;
  top: 106px!important;
  left: -250px!important;
  height: calc(100vh - 165px)!important;
  min-height: calc(100vh - 165px)!important;
  width: 250px!important;
  border-right: unset!important;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1)!important;
}

.mobile-side-menu.open {
  left: 0!important;
}

/* @supports code is important for solving the height issue on iOS Safari and Chrome */
@supports (height: 100dvh) {
.mobile-side-menu {
    height: calc(100dvh - 165px)!important;
    min-height: calc(100dvh - 165px)!important;
    overflow-y: auto;
  }
}
</style>