import { COMPANYUSERS_SERVICE } from "@/services/companyUsers.service.js";
import { defineStore } from 'pinia'

export const useCompanyUsersStore = defineStore('companyUsers', {
    state: () => ({
        getListOutput: [],
        getUserDetailsOutput: [],
    }),
    actions: {
        async getList() {
            const response = await COMPANYUSERS_SERVICE.getList();
            this.getListOutput = response;
        },
        async getUserDetailsById(id) {
            const response = await COMPANYUSERS_SERVICE.getUserDetailsById(id);
            this.getUserDetailsOutput = response;
        },
        async createCompanyUsers() {
            await COMPANYUSERS_SERVICE.createCompanyUsers();
        },
        async editCompanyUsers() {
            await COMPANYUSERS_SERVICE.editCompanyUsers();
        },
        async deleteCompanyUsersBySlug(slug) {
            await COMPANYUSERS_SERVICE.deleteCompanyUsersBySlug(slug);
        },
        async inviteUsers(email) {
            return await COMPANYUSERS_SERVICE.inviteUsers(email);
        },
        async acceptInvitation(token) {
            return await COMPANYUSERS_SERVICE.acceptInvitation(token);
        },
        async rejectInvitation(token) {
            return await COMPANYUSERS_SERVICE.rejectInvitation(token);
        },
    }
});