<template>
    <div v-show="showModal" class="modal-mask">
        <div class="modal-wrapper">
            <div class="popup">
                <div class="pop-up-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp" alt="Close">
                </div>
                <div class="modal-container">
                    <div class="d-flex justify-content-between mt-4 mb-2 flex-wrap">
                        <div class="form-group my-2 my-lg-0">
                            <input
                                type="text"
                                class="form-control search-input"
                                placeholder="Search here..."
                                v-model="searchTerm"
                            >
                        </div>
                        <!-- Items per page selection -->
                        <div id="items-per-page" class="d-flex align-items-center my-2 my-lg-0">
                            <label for="rowsPerPage" class="me-2">{{ $t('Rows per page') }}</label>
                            <select v-model="rowsPerPage" @change="changeRowsPerPage" class="form-select form-select-sm">
                                <option v-for="option in rowsPerPageOptions" :key="option" :value="option">
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="content" v-if="paginatedItems && paginatedItems.length">
                        <slot name="notice"></slot>
                        <div
                            class="item-row d-flex justify-content-between align-items-center py-2 my-2"
                            v-for="(item, index) in paginatedItems"
                            :key="index"
                        >
                            <div class="col-2 d-none d-sm-flex justify-content-start">
                                <img
                                    :src="parseImageData(item.image) ?? require('@/assets/no-image.jpg')"
                                    class="img-fluid"
                                    style="width: 20px; height: 20px; object-fit: cover;"
                                >
                            </div>
                            <div class="col-7 col-sm-8 d-flex align-items-center">
                                <span id="name-span">{{ item.name }}</span>
                            </div>
                            <div class="col-5 col-sm-2 d-flex justify-content-end" :class="{'disabled': disabled}">
                                <slot name="actions" :item="item"></slot>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="loading" class="d-flex justify-content-center align-items-center" style="height: 284px;">
                        <div class="spinner-border color-primary" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center align-items-center py-4" style="height: 284px;">
                        <span>{{ $t('No data available') }}</span>
                    </div>
                    
                    <!-- Pagination Controls at the bottom -->
                    <div v-if="paginatedItems && paginatedItems.length" id="pagination-controls" class="pagination-controls">
                        <div class="d-flex justify-content-between align-items-center">
                            <!-- Current page range and total items -->
                            <div id="range-items">
                                {{ (currentPage - 1) * rowsPerPage + 1 }} - {{ Math.min(currentPage * rowsPerPage, filteredItems.length) }} {{
                                $t('of') }} {{ filteredItems.length }}
                            </div>
                            <!-- Navigation Buttons -->
                            <div class="d-flex justify-content-end">
                                <button @click="goToFirstPage" :disabled="currentPage === 1" class="btn btn-light btn-sm">
                                    <i class="bi bi-chevron-double-left" :class="{'disabled': currentPage === 1}"></i>
                                </button>
                                <button @click="goToPreviousPage" :disabled="currentPage === 1" class="btn btn-light btn-sm ms-2">
                                    <i class="bi bi-chevron-left" :class="{'disabled': currentPage === 1}"></i>
                                </button>
                                <button @click="goToNextPage" :disabled="currentPage === totalPages" class="btn btn-light btn-sm ms-2">
                                    <i class="bi bi-chevron-right" :class="{'disabled': currentPage === totalPages}"></i>
                                </button>
                                <button @click="goToLastPage" :disabled="currentPage === totalPages" class="btn btn-light btn-sm ms-2">
                                    <i class="bi bi-chevron-double-right" :class="{'disabled': currentPage === totalPages}"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useResponsive } from '@/composables/responsive';

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        itemsList: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const { isMobile } = useResponsive();
        return { isMobile };
    },
    data() {
        return {
            searchTerm: "",
            currentPage: 1,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 15, 20],
            drag: false
        };
    },
    mounted() {
        document.addEventListener('mousedown', this.handleMouseDown);
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    computed: {
        filteredItems() {
            return this.itemsList.filter(item =>
                item.name && item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * this.rowsPerPage;
            const end = this.currentPage * this.rowsPerPage;
            return this.filteredItems.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.rowsPerPage);
        }
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        goToFirstPage() {
            this.currentPage = 1;
        },
        goToLastPage() {
            this.currentPage = this.totalPages;
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        changeRowsPerPage() {
            this.currentPage = 1; // Reset to page 1 when rows per page changes
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            if(imageDataString === undefined || imageDataString === null) return null;
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    // console.log('Parsed Data:', parsedData);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                // If JSON parsing fails, return the original string
                // console.error('Invalid JSON string:', e);
                return imageDataString;
            }
        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-mask')) {
                this.closePopUp();
            }
            this.drag = false;
        },
        handleMouseDown() {
            this.drag = false;
        },
        handleMouseMove() {
            this.drag = true;
        }
    },
    watch: {
        // Navigate back if the current page becomes empty
        paginatedItems(newItems) {
            // If the current page is empty and we're not on the first page, go back one page
            if (newItems.length === 0 && this.currentPage > 1) {
                this.currentPage--;
            }
        },
        searchTerm() {
            this.currentPage = 1; // Reset page to 1 on new search
        },
        showModal(val) {
            if (val) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        }
    }
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-wrapper {
    padding: 1rem;
    overflow: hidden;
}

.popup {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    min-width: 600px;
    max-width: 600px;
    padding: 1.5rem;
    min-height: 460.5px;
    display: flex;
    flex-direction: column;
}

.pop-up-close {
    display: flex;
    justify-content: flex-end;
}

.modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 500px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
 .content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.pagination-controls {
    margin-top: auto;
    padding-top: 1rem;
}

.item-row {
    border-bottom: 1px solid #DEE2E6;
    padding: 1rem 0;
}

#name-span {
    font-size: .9rem;
    font-weight: 500;
    color: #212529;
}

.form-control.search-input {
    background-image: url('@/assets/HotelGroup/searchBtn.svg');
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 35px;
    font-size: 13px;
    min-width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.add-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

#items-per-page {
    white-space: nowrap;
}

#range-items,
#items-per-page label {
    font-size: .9rem;
    font-weight: 500;
    color: #6C757D;
}

@media (max-width: 767px), (max-height: 680px) {
    .modal-mask {
        align-items: start;
    }
    .modal-wrapper {
        height: 100vh;
    }
    .popup {
        padding: 1rem;
        width: 100%;
        height: 100%;
        min-height: unset;
        overflow-y: auto;
        min-width: unset;
    }
}

@media (max-width: 767px) {
    .content {
        max-height: 675px;
    }
    #name-span,
    #items-per-page label {
        font-size: 0.8rem;
    }
    .form-group,
    #items-per-page {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .modal-mask {
        display: unset;
        justify-content: unset;
        align-items: unset;
    }
}
</style>
