<template>
  <label class="container" :style="styleSwitchContainer">
      <input
          class="input"
          v-bind="$attrs"
          type="checkbox"
          v-model="value"
      />
      <span class="switch"></span>
      <span class="label">{{ value ? labelWhenActive : labelWhenInactive }}</span>
  </label>
</template>

<script>
  export default {
    name: "SwitchButtonControl",
    inheritAttrs: false,
    props: {
      labelWhenActive: {
        type: String,
        required: true,
      },
      labelWhenInactive: {
        type: String,
        required: true,
      },
      modelValue: {
        type: Boolean,
        required: true,
      },
      styleSwitchContainer: Object,
    },
    data() {
      return {
        value: this.modelValue,
      }
    },
    watch:
    {
      value(newValue) {
        // Emit the update:modelValue event with the new value
        this.$emit("update:modelValue", newValue);
      },
    }
  };
</script>

<style scoped>
.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.label {
  margin-left: 12px;
  color: #1A202C;
  min-width: 100px;
}
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.switch {
  --switch-container-width: 50px;
  --switch-size: calc(var(--switch-container-width) / 2);

  /* Vertically center the inner circle */
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  /* Make the container element rounded */
  border-radius: var(--switch-size);
  background-color: #E2E8F0;
  transition: background-color 0.25s ease-in-out;
}

.switch::before {
  content: "";
  position: absolute;
  /* Move a little bit the inner circle to the right */
  left: 1px;
  height: calc(var(--switch-size) - 4px);
  width: calc(var(--switch-size) - 4px);
  /* Make the inner circle fully rounded */
  border-radius: 9999px;
  background-color: white;
  transition: transform 0.375s ease-in-out;
}
.input:checked + .switch {
  /* Teal background */
  background-color: #4FD1C5;
}

.input:checked + .switch::before {
  border-color: #4FD1C5;
  /* Move the inner circle to the right */
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}
@media only screen and (max-width: 767px) {
  
.container {
  justify-content: center;
}
}
</style>