<template>
  <section>
    <div class="container-fluid">
        <div class="row">
          <div class="col-12 d-flex align-items-center p-2 w-auto d-md-none d-flex" @click="sendToMyProfileMobile">
            <img src="@/assets/HotelGroup/backBtn.svg" alt="">
          </div>
          <DividerComponent class="my-5 d-md-none d-flex"/>
          <div class="col-12 col-md-6 d-flex gap-2" id="page-title">
            <BoldTitle :icon="require('@/assets/MyProfile/active-sessions-icon.svg')" :title="$t('Active Sessions')"/>
          </div>
          <!-- Button Desktop -->
          <div class="col-12 col-md-6 d-none d-md-flex" id="save-btn-div">
            <CustomButton 
              class="btn mt-2 d-flex gap-2" 
              label="See all"
              buttonType="theme-light" 
              @clickBtn="clickBtn" 
              :rightIcon="require('@/assets/HotelGroup/seeAll.svg')" 
              :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
              }"
            />
          </div>
          <div class="col-12" id="col-two-factor">
            <div id="col-mobile-stack">
              <div class="col col-two-factor-items">
                <button class="btn-user-status d-none d-md-flex">Active</button>
              </div>
              <div class="col col-two-factor-items">
                <span>17.172.224.47</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Porto</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Google Chrome</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Mac OS</span>
              </div>
            </div>
            <div class="col">
              <SwitchButtonControl 
                :labelWhenActive="'Connect'" 
                :labelWhenInactive="'Disconnect'" 
              />
            </div>
          </div>
          <div class="col-12" id="col-two-factor">
            <div id="col-mobile-stack">
              <div class="col col-two-factor-items">
                <button class="btn-user-status d-none d-md-flex">Active</button>
              </div>
              <div class="col col-two-factor-items">
                <span>17.172.224.47</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Porto</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Google Chrome</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Mac OS</span>
              </div>
            </div>
            <div class="col">
              <SwitchButtonControl 
                :labelWhenActive="'Connect'" 
                :labelWhenInactive="'Disconnect'" 
              />
            </div>
          </div>
          <div class="col-12" id="col-two-factor">
            <div id="col-mobile-stack">
              <div class="col col-two-factor-items">
                <button class="btn-user-status d-none d-md-flex">Active</button>
              </div>
              <div class="col col-two-factor-items">
                <span>17.172.224.47</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Porto</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Google Chrome</span>
              </div>
              <div class="col col-two-factor-items">
                <span>Mac OS</span>
              </div>
            </div>
            <div class="col">
              <SwitchButtonControl 
                :labelWhenActive="'Connect'" 
                :labelWhenInactive="'Disconnect'" 
              />
            </div>
          </div>
          <!-- Button Mobile -->
          <div class="col-12 col-md-6 d-md-none d-flex" id="save-btn-div">
              <CustomButton 
                class="btn mt-2 d-flex gap-2 btn-save-mobile" 
                label="Save" @clickBtn="clickBtn" 
                :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                :buttonStyle="{
                    borderRadius: '8px!important',
                    gap: '8px',
                    color: '#FFFFFF',
                    padding: '6px 35px'
                }"
              />
            </div>
        </div>
    </div>
  </section>
</template>
  
<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import SwitchButtonControl from '@/components/SwitchButtonControl.vue';
import CustomButton from '@/components/CustomButton.vue';
  export default {
    components: {
      BoldTitle,
      DividerComponent,
      SwitchButtonControl,
      CustomButton
    },
    methods: {
      sendToMyProfileMobile() {
          this.$router.go(0)
      },
    },
  }
</script>
  
<style scoped>
#save-btn-div{
  display: flex;
  justify-content: end;
}
#col-two-factor{
  border-radius: 8px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  align-items: center;
}
#col-mobile-stack{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}
.col-two-factor-items{
  flex-grow: 1;
  flex-basis: 200;
}
.btn-user-status{
    display: flex;
    padding: 4.2px 7.8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--theme-success, #198754);
    color: var(--gray-white, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    border: none;
}
@media only screen and (max-width: 1025px) {
  #col-mobile-stack{
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  #page-title{
    justify-content: center;
  }
  #save-btn-div{
    justify-content: center;
    margin-top: 2rem;
  }
  #two-factor-img{
    width: 40px;
    height: 40px;
  }
  .btn-save-mobile{
    width: inherit !important;
  }
  #col-two-factor{
    align-items: center;
  }
  #col-mobile-stack{
    width: inherit;
    flex-direction: column;
    text-align: center;
  }
  .col-switch{
    display: flex;
    justify-content: center !important;
  }
}
</style>