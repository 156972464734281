<template>
  <section>
    <div class="container-fluid">
        <div class="row row-style">
          <!-- <DividerComponent class="my-5 d-md-none d-flex"/> -->
          <div class="col-12 d-flex gap-2" id="page-title">
            <BoldTitle :icon="require('@/assets/MyProfile/security-settings.svg')" :title="$t('Security Info')"/>
          </div>
          <div class="col-12 col-md-6 mt-5 d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column w-100">
              <div class="d-flex gap-2 mb-3">
                <img src="@/assets/MyProfile/email-icon.svg" alt="">
                <span>{{$t("email")}}</span>
              </div>
              <input type="text" disabled class="form-control" v-model="email" readonly>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-5 d-flex align-items-end justify-content-center">
            <div class="d-flex flex-column w-100">
              <CustomButton 
                :buttonType="'secondary'" 
                :label="$t('ChangePassword')" 
                @clickBtn="openChangePasswordPopup" 
                :buttonStyle="{
                    borderRadius: '8px!important',
                    gap: '8px',
                    padding: '6px 35px'
                }"
              />
            </div>
          </div>
          <div class="col-12 d-flex align-items-center mt-5 flex-wrap" id="col-two-factor">
            <div class="col-12 col-lg-6 d-flex align-items-center gap-3" id="col-info">
              <img id="two-factor-img" src="@/assets/MyProfile/two-factor.svg" alt="">
              <span>{{$t("2-factor-authentication")}}</span>
                <button class="d-flex" :class="[two_fa ? 'btn-user-status-active' : 'btn-user-status-inactive']">
                {{$t(two_fa ? 'Active' : 'Inactive')}}
                </button>
            </div>
            <!-- <div class="col-12 col-lg-6">
              <SwitchButtonControl 
                :labelWhenActive="'Active'" 
                :labelWhenInactive="'Inactive'" 
                @click="updateChecked"
              />
            </div> -->
          </div>
          <!-- Custom Popup for Change Password -->
          <CustomPopup 
            v-show="isChangePwdVisible" 
            @popUpClosed="isChangePwdVisible = false"
            :img="require('@/assets/MyProfile/changePwd.svg')"
            :titlePopup="$t('ChangePassword')"
          >
            <template v-slot:body>
              <div class="d-flex flex-column gap-3">
                <label for="">{{ $t("CurrentPassword") }}</label>
                <PasswordInputToggleVisibility 
                  class="w-100"
                  v-model="current_password"
                  :placeholder="$t('CurrentPassword')"
                />
                <label for="">{{ $t("NewPassword") }}</label>
                <PasswordInputToggleVisibility 
                  class="w-100"
                  v-model="new_password"
                  :placeholder="$t('NewPassword')"
                />
                <label for="">{{ $t("ConfirmNewPassword") }}</label>
                <PasswordInputToggleVisibility 
                  class="w-100"
                  v-model="new_password_confirmation"
                  :placeholder="$t('ConfirmNewPassword')"
                />
                <CustomButton 
                  class="btn mt-2 d-flex gap-2" 
                  :label="$t('changePassword')"
                  :buttonType="'secondary'" 
                  @click="handlePasswordChange" 
                  :buttonStyle="{
                      borderRadius: '8px!important',
                      gap: '8px',
                      padding: '6px 35px'
                  }"
                />
              </div>
            </template>
          </CustomPopup>
          <!-- Custom Popup for Password Change Success -->
          <CustomPopup 
            v-show="isPasswordChangeSuccessVisible" 
            @popUpClosed="isPasswordChangeSuccessVisible = false"
            :img="require('@/assets/MyProfile/successSave.svg')"
            :titlePopup="$t('passwordSuccessfullyChanged')">
            <template v-slot:body>
              <CustomButton 
                  class="btn mt-2 d-flex gap-2" 
                  :label="$t('GoToDashboard')"
                  :buttonType="'theme-light'" 
                  @click="sendToDashboard" 
                  :rightIcon="require('@/assets/MyProfile/goToDashboardPopupIcon.svg')" 
                  :buttonStyle="{
                      borderRadius: '8px!important',
                      gap: '8px',
                      padding: '6px 35px'
                  }"
              />
            </template>
          </CustomPopup>
          <!-- Custom Popup for Password Change Error -->
          <CustomPopup 
            v-show="isPasswordChangeErrorVisible" 
            @popUpClosed="isPasswordChangeErrorVisible = false"
            :img="require('@/assets/MyProfile/error.svg')"
            :titlePopup="$t('failedToChangePasswordPleaseTryAgain')">
            <template v-slot:body>
              <CustomButton 
                  class="btn mt-2 d-flex gap-2" 
                  :label="$t('close')"
                  :buttonType="'bg-danger'" 
                  @click="isPasswordChangeErrorVisible = false" 
                  :buttonStyle="{
                      borderRadius: '8px!important',
                      gap: '8px',
                      padding: '6px 35px'
                  }"
              />
            </template>
          </CustomPopup>
        </div>
    </div>
  </section>
</template>
  
<script>
import BoldTitle from '@/components/BoldTitle.vue';
// import DividerComponent from '@/components/DividerComponent.vue';
// import SwitchButtonControl from '@/components/SwitchButtonControl.vue';
import CustomButton from '@/components/CustomButton.vue';
import PasswordInputToggleVisibility from '@/components/PasswordInputToggleVisibility';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import { useUsersStore, useAlertStore } from "@/stores";
import { boolean } from 'yup';

export default {
  components: {
    BoldTitle,
    // DividerComponent,
    // SwitchButtonControl,
    CustomButton,
    PasswordInputToggleVisibility,
    CustomPopup
  },
  data() {
    return {
      isPopupVisible: false,
      isChecked: false,
      isChangePwdVisible: false,
      isPasswordChangeSuccessVisible: false,
      isPasswordChangeErrorVisible: false,
      email: '',
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
      two_fa: boolean,
    }
  },
  props: {
    userInfo: {
      type: Object,
      required: true
    }
  },
  setup() {
    const usersStore = useUsersStore();
    const alertStore = useAlertStore();
    return {
      usersStore,
      alertStore
    }
  },
  methods: {
    updateChecked(newValue) {
      this.isChecked = !newValue.srcElement._modelValue;
    },
    sendToDashboard() {
      this.$router.push({ name: 'DashboardPage' });
    },
    openChangePasswordPopup() {
      this.isChangePwdVisible = true;
    },
    async handlePasswordChange() {
      try {
        if (!this.current_password || !this.new_password || !this.new_password_confirmation) {
          throw new Error('All fields must be filled in.');
        }
        if (this.new_password !== this.new_password_confirmation) {
          throw new Error('New password and confirmation do not match.');
        }
        await this.usersStore.passwordChange(this.current_password, this.new_password, this.new_password_confirmation);
        this.isPasswordChangeSuccessVisible = true;
        this.isChangePwdVisible = false;
      } catch (error) {
        console.error('Password change failed:', error);
        this.isPasswordChangeErrorVisible = true;
      }
    },
  },
  watch: {
    userInfo: {
      handler() {
        this.email = this.userInfo.email;
        this.two_fa = this.userInfo.two_fa;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
  
<style scoped>
#save-btn-div {
  display: flex;
  justify-content: end;
}
#col-two-factor {
  border-radius: 8px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
}
.btn-user-status-active {
  display: flex;
  padding: 4.2px 7.8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--theme-success, #198754);
  color: var(--gray-white, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border: none;
}
.btn-user-status-inactive {
  display: flex;
  padding: 4.2px 7.8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--theme-danger);
  color: var(--gray-white, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  border: none;
}
@media only screen and (max-width: 767px) {
  .row-style {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #page-title {
    justify-content: center;
  }
  #save-btn-div {
    justify-content: center;
    margin-top: 2rem;
  }
  #two-factor-img {
    width: 40px;
    height: 40px;
  }
  .btn-save-mobile {
    width: inherit !important;
  }
  #col-two-factor {
    align-items: center !important;
    width: 80%;
  }
  #col-info {
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
</style>
