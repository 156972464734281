/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const INTEGRATION_SERVICE = {
    getIntegrationsList,
    getCompanyIntegrations,
    getCompanyIntegrationById,
    addCompanyIntegration,
    deleteCompanyIntegration
};

/* Services */
// get list of all integrations
async function getIntegrationsList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, "integrations"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
// get list of company integrations
async function getCompanyIntegrations() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, "company/integrations"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
// get list of company integration by id
async function getCompanyIntegrationById(id) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, "company/integrations", id]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
// create a new integration for the company
async function addCompanyIntegration(payload) 
{
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, "company/integrations"]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}

// delete
async function deleteCompanyIntegration(type) 
{
    const requestOptions = {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(type),
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, "company/integrations"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}