<template>
    <section id="competitor_analysis">
        <template v-if="isMobile">
            <div class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/Breadcrumbs/competitors.svg')" alt="competitors icon">
                <span class="page-title-mobile">{{ $t('Competitors') }} </span>
            </div>
        </template>
        <div class="container-fluid mt-5 mt-md-0">
            <LayoutWrapper>
                <div class="row border-bottom py-3 p-md-3">
                    <div v-if="!isMobile" class="col-12 col-lg-6 d-flex align-items-center gap-2 mb-3 mb-lg-0">
                        <img class="img-fluid" :src="require('@/assets/Breadcrumbs/competitors.svg')" alt="competitors icon" width="18">
                        <span class="page-title me-3">{{ $t('Competitors') }}</span>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="d-flex justify-content-start justify-content-lg-end">
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="getLabel('hotel', 'Hotel')" 
                                    :icon="require('@/assets/Pickup/house.svg')"
                                    @clickBtn="toggleDropdown('hotel')"
                                />
                                <DropdownMenu
                                    v-model="selectedItems['hotel']"
                                    :configuration=" {
                                        items: hotelOptions,
                                        isDropdownOpen: dropdownStates.hotel,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="dropdownStates.hotel = false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-3 p-md-3">
                    <div class="row">
                        <div class="col-12 col-lg-4 mb-3">
                            <DateButton v-model="date" typeForm="1" range />
                        </div>
                        <div class="col-12 col-lg-8 mb-3">
                            <div class="d-flex flex-wrap justify-content-end gap-3">
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('channel', 'Channel')" 
                                        :hasValue="selectedItems['channel']"
                                        @resetFilter="resetFilter('channel', 'filters[ota]')"
                                        @clickBtn="toggleDropdown('channel')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['channel']"
                                        :configuration=" {
                                            items: channelOptions,
                                            isDropdownOpen: dropdownStates.channel,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.channel = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('dayOfWeek', 'Day of week')" 
                                        :hasValue="selectedItems['dayOfWeek']"
                                        @clickBtn="toggleDropdown('dayOfWeek')"
                                        @resetFilter="resetFilter('dayOfWeek', 'dayOfWeek')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['dayOfWeek']"
                                        :configuration=" {
                                            items: dayOfWeekOptions,
                                            isDropdownOpen: dropdownStates.dayOfWeek,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.dayOfWeek = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('los', 'LOS')" 
                                        :hasValue="selectedItems['los']"
                                        @resetFilter="resetFilter('los', 'filters[los]')"
                                        @clickBtn="toggleDropdown('los')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['los']"
                                        :configuration=" {
                                            items: losOptions,
                                            isDropdownOpen: dropdownStates.los,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.los = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('meal', 'Meal')" 
                                        :hasValue="selectedItems['meal']"
                                        @resetFilter="resetFilter('meal', 'filters[mealType]')"
                                        @clickBtn="toggleDropdown('meal')" 
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['meal']"
                                        :configuration=" {
                                            items: mealOptions,
                                            isDropdownOpen: dropdownStates.meal,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.meal = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('pax', 'Pax')" 
                                        :hasValue="selectedItems['pax']"
                                        @clickBtn="toggleDropdown('pax')" 
                                        @resetFilter="resetFilter('pax', 'filters[persons]')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['pax']"
                                        :configuration=" {
                                            items: paxOptions,
                                            isDropdownOpen: dropdownStates.pax,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.pax = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="selectedItems['range'].keyValue ? `${$t('Last')} ${selectedItems['range'].keyValue} ${$t('days')}`: getLabel('range', 'Range')" 
                                        :hasValue="selectedItems['range']"
                                        @clickBtn="toggleDropdown('range')"
                                        @resetFilter="resetFilter('range','range')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['range']"
                                        :configuration=" {
                                            items: rangeOptions,
                                            isDropdownOpen: dropdownStates.range,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.range = false"
                                    >
                                        <template v-slot:customize="{item, active}">
                                            <div v-if="!active" id="customize" class="d-flex align-items-center">
                                                <img class="icon me-2" .src="item.icon" width="12" height="12" alt="pencil">
                                                <span class="label">{{ item.label }}</span>
                                            </div>
                                            <div v-if="active" id="customize" class="d-flex flex-column gap-2">
                                                <div class="d-flex align-items-center gap-2">
                                                    <span class="label">{{ $t('Last') }}</span>
                                                    <input class="form-control" v-model="customRange">
                                                    <span class="label">{{ $t('days') }}</span>
                                                </div>
                                                <button class="btn" @click="applyCustomRange">{{ $t('Apply') }}</button>
                                            </div>
                                        </template>
                                    </DropdownMenu>
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="getLabel('room', 'Room')" 
                                        :hasValue="selectedItems['room']"
                                        @clickBtn="toggleDropdown('room')" 
                                        @resetFilter="resetFilter('room', 'filters[roomType]')"
                                        style="min-width: unset"
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['room']"
                                        :configuration=" {
                                            items: roomOptions,
                                            isDropdownOpen: dropdownStates.room,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="dropdownStates.room = false"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <WidgetWrapper
                                widget-id="competitorsAnalysis"
                                :loading="widgetStates.competitorsAnalysis.loading"
                                :error="widgetStates.competitorsAnalysis.error"
                                integration="Lighthouse"
                                @retry="fetchCharts(queryParams)"
                                > 
                                <LineChart 
                                    :chartTitle="$t('competitorsAnalysis')" 
                                    :chartData="competitorsAnalysisChart?.chartData" 
                                    :chartOptions="competitorsAnalysisChart?.chartOptions"
                                    :customStyle="{
                                        width: competitorsAnalysisChart?.chartOptions?.width
                                    }" 
                                />
                            </WidgetWrapper>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">  
                            <WidgetWrapper 
                                wrapperClass="table-widget-wrapper"
                                widget-id="totalTable"
                                :loading="widgetStates.totalTable.loading"
                                :error="widgetStates.totalTable.error"
                                integration="Lighthouse"
                                @retry="populateTableData"
                            >
                                <TableComponent
                                    v-model="itemsFromApi" 
                                    :layout="{
                                        hasHeader: { desktop: true, mobile: true }
                                    }" 
                                    :tableHeaders="tableHeaders" 
                                    :tableRows="tableRows"
                                >
                                    <template v-slot:title>
                                        <div class="row py-3 px-4 border-bottom align-items-center">
                                            <div class="col-12">
                                                <h5 class="table-title fw-bold m-0">{{ $t('Total Table') }}</h5>
                                            </div>
                                        <!-- <div class="col-6">
                                            <CustomButton 
                                                class="ms-auto"
                                                :type="1" 
                                                buttonType="theme-light"
                                                :rightIcon="require('@/assets/BudgetAnalysis/createPageReport.svg')" 
                                                :buttonStyle="{
                                                    borderRadius: '8px!important',
                                                    padding: '5px'
                                                }" 
                                            />
                                        </div> -->
                                        </div>
                                    </template>
                                </TableComponent>
                            </WidgetWrapper>
                        </div>
                    </div>
                </div>
            </LayoutWrapper>
        </div>
    </section>
</template>
<script>
import ChartSetup from '@/helpers/chart-setup.js';
import LineChart from '@/components/Charts/LineChart.vue';
import DateButton from "@/components/DateButton.vue";
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useMetricsStore, useHotelStore } from "@/stores";
import TableComponent from '@/components/TableComponent.vue';
// import CustomButton from '@/components/CustomButton.vue';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import WidgetWrapper from '@/components/WidgetWrapper.vue';

dayjs.extend(isoWeek);

export default {
    components: {
        LineChart,
        DateButton,
        DropdownMenu,
        DropdownButton,
        TableComponent,
        // CustomButton,
        LayoutWrapper,
        WidgetWrapper
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const metricsStore = useMetricsStore();
        const hotelStore = useHotelStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            metricsStore,
            hotelStore
        }
    },
    data() {
        return {
            // hotel dropdown
            dropdownStates: {
                hotel: false,
                channel: false,
                dayOfWeek: false,
                los: false,
                meal: false,
                pax: false,
                range: false,
                room: false
            },
            selectedItems: {
                hotel: {},
                channel: {},
                dayOfWeek: {},
                los: {},
                meal: {},
                pax: {},
                range: {},
                room: {}
            },
            customRange: null,
            // chart data
            competitorsAnalysisChart: null,
            // date
            date: null,
            // widget states
            widgetStates: {
                competitorsAnalysis: {
                    loading: true,
                    error: null
                },
                totalTable: {
                    loading: true,
                    error: null
                }
            },
            // table
            tableHeaders: [],
            tableRows: [],
            itemsFromApi: [],
            integrationError: null,
            dynamicFilters: {}
        }
    },
    async mounted () {
        await this.hotelStore.getList(); // Fetch hotels from API
        this.setDefaultDropdownSelections(); // set default dropdown selections
        await this.fetchCharts(this.queryParams); // Fetch charts data
        
        this.populateTableHeaders();
        await this.populateTableData();
    },
    computed: {
        hotelOptions() {
            return {
                header: [],
                body: this.hotelStore?.getHotelListOutput?.filter(hotel => hotel.lighthouseId !== null).map(hotel => ({
                    id: hotel.lighthouseId,
                    label: hotel.name,
                    image: hotel.picture ?? require('@/assets/no-image.jpg')
                }))
            }
        },
        channelOptions() {
            return {
                header: [],
                body: [
                    { id: 0, label: this.$t("Booking"), key: 'bookingdotcom' },
                    // { id: 1, label: this.$t("Website"), key: 'website' },
                    { id: 2, label: this.$t("Expedia"), key: 'expedia' }
                ]
            }
        },
        dayOfWeekOptions() {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            // Check if `this.date` is valid
            if (!this.date || !this.date[0] || !this.date[1]) {
                return {
                    header: [],
                    body: [] // Return an empty array if there's no valid date range
                };
            }

            const startDate = dayjs(new Date(this.date[0])); // Get the start date
            const endDate = dayjs(new Date(this.date[1])); // Get the end date

            const availableDays = []; // Array to store available days in the range

            let currentDay = startDate.clone();
            while (currentDay.isBefore(endDate) || currentDay.isSame(endDate)) {
                const dayIndex = currentDay.day(); // Get day index (0-6)

                // Check if the day already exists in the array to avoid duplicates
                if (!availableDays.some(day => day.id === dayIndex)) {
                    availableDays.push({
                        id: dayIndex,
                        label: this.$t(daysOfWeek[dayIndex]), // Dynamically translate day
                        keyName: daysOfWeek[dayIndex].toLowerCase() // Convert to lowercase keyName
                    });
                }

                currentDay = currentDay.add(1, 'day'); // Move to the next day
            }

            // Sort the days so Monday (1) comes first and Sunday (0) comes last
            availableDays.sort((a, b) => {
                // Move Sunday (id 0) to the end of the list
                if (a.id === 0) return 1;
                if (b.id === 0) return -1;
                return a.id - b.id;
            });

            return {
                header: [],
                body: availableDays // Sorted list of available days
            };
        },
        losOptions() {
            let losBody = [];
            for (let i = 1; i <= 45; i++) {
                losBody.push({
                    id: i,
                    label: i,
                    key: i
                });
            }
            
            return {
                header: [],
                body: losBody
            };
        },
        mealOptions() {
            return {
                header: [],
                body: [
                    { id: 1, label: this.$t("Breakfast included"), key: 1 },
                    { id: 2, label: this.$t("Half board included"), key: 2 },
                    { id: 3, label: this.$t("Full board included"), key: 3 },
                    { id: 4, label: this.$t("All inclusive"), key: 4 },
                    { id: 5, label: this.$t("Room only"), key: 5 }
                ]

            }
        },
        paxOptions() {
            let paxBody = [];
            for (let i = 1; i <= 10; i++) {
                paxBody.push({
                    id: i,
                    label: i,
                    key: i
                });
            }
            
            return {
                header: [],
                body: paxBody
            }
        },
        rangeOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: this.$t('Yesterday'),
                        keyName: 'yesterday'
                    },
                    {
                        id: 1,
                        label: this.$t('Last 2 days'),
                        keyName: 'last2days'
                    },
                    {
                        id: 2,
                        label: this.$t('Last 3 days'),
                        keyName: 'last3days'
                    },
                    {
                        id: 3,
                        label: this.$t('Last 4 days'),
                        keyName: 'last4days'
                    },
                    {
                        id: 4,
                        label: this.$t('Customize'),
                        icon: require('@/assets/Pickup/pencil-square.svg'),
                        keyName: 'customize',
                        keyValue: null
                    }
                ]
            }
        },
        roomOptions() {
            return {
                header: [],
                body: [
                    { id: 0, label: this.$t("Dormitory"), key: 'dormitory' },
                    { id: 1, label: this.$t("Shared bathroom"), key: 'sharedbathroom' },
                    { id: 2, label: this.$t("Suite"), key: 'suite' },
                    { id: 3, label: this.$t("Family"), key: 'family' },
                    { id: 4, label: this.$t("Apartment"), key: 'apartment' },
                    { id: 5, label: this.$t("Parkandfly"), key: 'parkandfly' },
                    { id: 6, label: this.$t("Camper"), key: 'camper' },
                    { id: 7, label: this.$t("Villa"), key: 'villa' },
                    { id: 8, label: this.$t("Bungalow"), key: 'bungalow' },
                    { id: 9, label: this.$t("Budget"), key: 'budget' },
                    { id: 10, label: this.$t("Premium"), key: 'premium' },
                    { id: 11, label: this.$t("Standard"), key: 'standard' },
                    { id: 12, label: this.$t("Single"), key: 'single' },
                ]
            }
        },
        // query params for fetching data
        queryParams() {
            return {
                hotelId: this.selectedItems?.hotel?.id ?? null,
                fromDate: this.date[0],
                ...this.dynamicFilters
            }
        },
    },
    methods: {
        setDefaultFilterDate() {
            const currentDate = new Date();
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 7);

            // Function to format a date as YYYY-MM-DD
            const formatDate = (date) => {
                return date.toISOString().substring(0, 10);
            };

            // yyyy-MM-dd
            const dateFormat = {
                startsAt: formatDate(currentDate),
                endsAt: formatDate(futureDate)
            };
            this.date = [dateFormat.startsAt, dateFormat.endsAt]; // Set the default date range to the current date and 7 days ahead
        },
        toggleDropdown(dropdownName) {
            this.dropdownStates[dropdownName] = !this.dropdownStates[dropdownName];
        },
        setDefaultDropdownSelections() {
            this.setDefaultFilterDate(); // Set the default date range
            // Set default selection for 'hotel' dropdown
            if (this.hotelOptions?.body.length > 0) {
                this.selectedItems.hotel = this.hotelOptions.body[0];
            }
        },
        async fetchCompetitorsAnalysis(queryParams) {
            try {
                this.widgetStates.competitorsAnalysis.loading = true;
                this.widgetStates.competitorsAnalysis.error = null;
                this.widgetStates.totalTable.loading = true;
                this.widgetStates.totalTable.error = null;

                await this.metricsStore.fetchCompetitorsAnalysis(queryParams);
            } catch (error) {
                this.widgetStates.competitorsAnalysis.error = error;
                this.widgetStates.totalTable.error = error;
            } finally {
                this.widgetStates.competitorsAnalysis.loading = false;
                this.widgetStates.totalTable.loading = false;
            }
        },
        generateRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        async fetchCharts(queryParams) {
            const setupChart = (outputData, keyName, setupFunction, customOptions = {}) => {
                if (outputData.labels && outputData.datasets) {
                    let { labels = [], datasets = [] } = outputData;

                    // Define date range (including full day for endsAt)
                    const startsAt = new Date(this.date[0]);
                    const endsAt = new Date(this.date[1]);
                    endsAt.setHours(23, 59, 59, 999); // Include the entire day for endsAt

                    // Generate an array of dates within the range
                    const dateArray = [];
                    for (let d = startsAt; d <= endsAt; d.setDate(d.getDate() + 1)) {
                        dateArray.push(new Date(d).toISOString().split('T')[0]); // Format date to YYYY-MM-DD
                    }

                    // Align datasets with dateArray
                    datasets = datasets.map(dataset => {
                        const newData = dateArray.map(date => {
                            const dateIndex = labels.indexOf(date); // Check if the date exists in the original labels
                            return dateIndex !== -1 ? dataset.data[dateIndex] : 0; // Use the value or 0 if not available
                        });

                        return {
                            ...dataset,
                            data: newData,
                        };
                    });

                    // Use the dateArray as the new labels
                    labels = dateArray;

                    // Generate random colors for each dataset if necessary
                    if (datasets.length > 0) {
                        datasets = datasets.map(dataset => {
                            const randomColor = this.generateRandomColor();
                            return {
                                ...dataset,
                                backgroundColor: randomColor,
                                borderColor: randomColor,
                            };
                        });
                    }

                    // Setup the chart with the aligned data
                    this[`${keyName}Chart`] = setupFunction(labels, datasets, customOptions);

                    // Adjust chart size dynamically
                    this.adjustChartSize(keyName, labels.length);
                } else {
                    // If no valid data, initialize an empty chart
                    this[`${keyName}Chart`] = setupFunction([], [], customOptions);
                }
            };

            // Call API to fetch competitors' analysis data
            await this.fetchCompetitorsAnalysis(queryParams);

            // Setup the chart with fetched data
            setupChart(
                this.metricsStore.competitorsAnalysisOutput,
                'competitorsAnalysis',
                ChartSetup.getCompetitorsAnalysisChartData,
                { 
                    datasetsOptions: [
                        { backgroundColor: '#A81800', borderColor: "#A81800" }, 
                        { backgroundColor: "#B74431", borderColor: "#B74431" },
                        { backgroundColor: "#C77062", borderColor: "#C77062" },
                        { backgroundColor: "#D69D93", borderColor: "#D69D93" },
                        { backgroundColor: "#E6C9C4", borderColor: "#E6C9C4" },
                        { backgroundColor: "#EEDFDD", borderColor: "#EEDFDD" },
                    ]
                }
            );
        },
        adjustChartSize(keyName, totalLabels) {
            const chart = this[`${keyName}Chart`];
            const minimumLabelThreshold = chart.chartOptions.indexAxis === 'y' ? 10 : 20;

            if (this.isMobile) {
                const threshold = Math.floor(totalLabels * 0.5);
                const baseWidth = totalLabels * 50;
                const dynamicWidth = baseWidth + ((totalLabels - threshold) * 50);
                chart.chartOptions.width = `${dynamicWidth}px`;
            } else {
                if (totalLabels > minimumLabelThreshold) {
                    const threshold = Math.floor(totalLabels * 0.5);

                    if (totalLabels > threshold) {
                        if (this[`${keyName}Chart`].chartOptions.indexAxis === 'y') {
                            // Dynamic base height calculation based on the total labels
                            const baseHeight = totalLabels * 33; // Each label gets 33px height by default
                            const dynamicHeight = baseHeight + ((totalLabels - threshold) * 50); // Adjust height based on the threshold
                            this[`${keyName}Chart`].chartOptions.height = `${dynamicHeight}px`;
                        } else {
                            // Dynamic base width calculation based on the total labels
                            const baseWidth = totalLabels * 50; // Each label gets 50px width by default
                            const dynamicWidth = baseWidth + ((totalLabels - threshold) * 50); // Adjust width based on the threshold
                            this[`${keyName}Chart`].chartOptions.width = `${dynamicWidth}px`;
                        }
                    }
                }
            }
        },
        getLabel(key, label) {
            const item = this.selectedItems[key];
            return item && item.label ? item.label : this.$t(label);
        },
        async populateTableData() {
            // Check if competitorsAnalysisOutput is defined
            const competitorsAnalysisOutput = this.metricsStore.competitorsAnalysisOutput;

            if (!competitorsAnalysisOutput || !competitorsAnalysisOutput.labels || !competitorsAnalysisOutput.datasets) {
                console.error('Competitors analysis output is not defined or is missing labels/datasets');
                return; // Exit the function if labels or datasets are not available
            }

            // Destructure labels and datasets from competitorsData for easy access
            const { labels, datasets } = competitorsAnalysisOutput;

            // Assume this.date is an array with two dates, destructure startDate and endDate
            const [startDate, endDate] = this.date;

            // Filter labels based on the date range, and then map to generate row data
            this.itemsFromApi = labels
                .filter(labelDate => this.isDateInRange(labelDate, startDate, endDate)) // Only keep labels within the date range
                .map((date, rowIndex) => this.createRowData(date, rowIndex, datasets));  // Create row data for each filtered label
        },
        populateTableHeaders() {
            // Check if competitorsAnalysisOutput and datasets are defined
            const competitorsAnalysisOutput = this.metricsStore.competitorsAnalysisOutput;
            if (!competitorsAnalysisOutput || !competitorsAnalysisOutput.datasets) {
                console.error('Datasets are not defined');
                return; // Exit the function if datasets are not available
            }

            const { datasets } = competitorsAnalysisOutput;

            // Create table headers, starting with 'Date'
            this.tableHeaders = [
                { 
                    icon: require('@/assets/Pickup/calendar-icon.svg'), 
                    text: 'Date' 
                },
                ...datasets.map(({ label }) => ({
                    icon: null, 
                    text: label 
                }))
            ];

            let competitorIndex = 1; // Initialize competitor index separately

            // Define table rows structure
            this.tableRows = [
                { type: 'BoldText', field: 'date' }, // First field is 'date'
                ...datasets.map(({ label }) => {
                    if (label === 'My Hotel') {
                        return { type: 'SimpleText', field: 'myHotel' }; // Field for 'My Hotel'
                    } else {
                        return { type: 'SimpleText', field: `comp${competitorIndex++}` }; // Competitor fields start from comp1
                    }
                })
            ];

            // console.log('table headers', this.tableHeaders);
            // console.log('table rows', this.tableRows);
        },
        // Helper function to check if a date falls within the specified range (startDate to endDate)
        isDateInRange(date, startDate, endDate) {
            const currentDate = new Date(date); // Convert string date to Date object
            return currentDate >= new Date(startDate) && currentDate <= new Date(endDate); // Check if it's within range
        },
        createRowData(date, rowIndex, datasets) {
            const dateObj = new Date(date);
            const formattedDate = `${dateObj.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}`;
            const row = { date: formattedDate };
            let competitorIndex = 1;

            datasets.forEach(dataset => {
                const value = dataset.data[rowIndex];
                if (dataset.label === 'My Hotel') {
                    row['myHotel'] = value !== undefined && !isNaN(value) ? this.$n(value, 'currency') : '-';
                } else {
                    row[`comp${competitorIndex++}`] = value !== undefined && !isNaN(value) ? this.$n(value, 'currency') : '-';
                }
            });

            return row;
        },
        
        applyCustomRange() {
            if (isNaN(this.customRange)) {
                alert(this.$t('Please enter a valid number.'));
                this.customRange = null;
                return;
            }
    
            // Verificar se o valor é menor que 5 dias
            else if (this.customRange < 5) {
                alert(this.$t('The minimum value is 5 days.'));
                this.customRange = null;
                return;
            }

            const today = new Date();
            const formatDate = (date) => date.toISOString().split('T')[0];

            this.date = [formatDate(new Date(today.setDate(today.getDate() - this.customRange))), formatDate(new Date())];
            this.selectedItems.range.keyValue = this.customRange;
            this.dropdownStates.range = false;
        },
        async resetFilter(selectedItem, queryParamKey) {
            this.selectedItems[selectedItem] = {};
            this.removeFilter(queryParamKey);
            if(queryParamKey === 'range') {
                if(this.customRange) this.customRange = null; // Reset custom range if it's set
                this.setDefaultFilterDate(); // Set the default date range
            } else {
                await this.fetchCharts(this.queryParams); // Fetch charts data
                await this.populateTableData(); // Populate table data
            }
        },
        addFilter(key, value) {
            this.dynamicFilters[key] = value;
        },
        removeFilter(key) {
            delete this.dynamicFilters[key];
        }
    },
    watch: {
        async 'selectedItems.channel'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('filters[ota]', newVal.key);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.dayOfWeek'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('dayofweek', newVal.keyName);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.los'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('filters[los]', newVal.key);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.meal'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('filters[mealType]', newVal.key);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.pax'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('filters[persons]', newVal.key);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.range'(newVal) {
            if (newVal) {
                if(Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty (when range filter is reset)
                // console.log("RANGE", newVal);
                // reset all ohter filters if range is selected
                if(Object.keys(this.selectedItems.dayOfWeek).length > 0) {
                    this.selectedItems.dayOfWeek = {};
                }

                // set the date range based on the selected range
                const today = new Date();
                const formatDate = (date) => date.toISOString().split('T')[0];
                switch (newVal.keyName) {
                    case 'yesterday':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 1))), formatDate(new Date())];
                        break;
                    case 'last2days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 2))), formatDate(new Date())];
                        break;
                    case 'last3days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 3))), formatDate(new Date())];
                        break;
                    case 'last4days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 4))), formatDate(new Date())];
                        break;
                }
            }
        },
        async 'selectedItems.room'(newVal) {
            if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty
            if(newVal) {
                this.addFilter('filters[roomType]', newVal.key);
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'selectedItems.hotel'(newVal, oldVal) {
            if(Object.keys(oldVal).length === 0) return;
            if(newVal) {
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        async 'date'(newVal, oldVal) {
            if(!oldVal) return;
            if(newVal) {
                await this.fetchCharts(this.queryParams);
                await this.populateTableData();
            }
        },
        isMobile(val) {
            if (val) {
                // Define date range (including full day for endsAt)
                const startsAt = new Date(this.date[0]);
                const endsAt = new Date(this.date[1]);
                endsAt.setHours(23, 59, 59, 999); // Include the entire day for endsAt
    
                // Generate an array of dates within the range
                const labels = [];
                for (let d = startsAt; d <= endsAt; d.setDate(d.getDate() + 1)) {
                    labels.push(new Date(d).toISOString().split('T')[0]); // Format date to YYYY-MM-DD
                }
    
                // Recalculate the chart size only when isMobile changes
                this.adjustChartSize('competitorsAnalysis', labels.length || 0);
            }
        },
        isTablet(val) {
            if(val) {
                // Define date range (including full day for endsAt)
                const startsAt = new Date(this.date[0]);
                const endsAt = new Date(this.date[1]);
                endsAt.setHours(23, 59, 59, 999); // Include the entire day for endsAt
    
                // Generate an array of dates within the range
                const labels = [];
                for (let d = startsAt; d <= endsAt; d.setDate(d.getDate() + 1)) {
                    labels.push(new Date(d).toISOString().split('T')[0]); // Format date to YYYY-MM-DD
                }
    
                // Recalculate the chart size only when isTablet changes
                this.adjustChartSize('competitorsAnalysis', labels.length || 0);
            }
        },
        isDesktop(val) {
            if(val) {
                this.competitorsAnalysisChart.chartOptions.width = '100%';
            }
        }
    },
}
</script>
<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.chart-wrapper {
    width: 100%;
    border-radius: 15px;
    height: calc(100vh - 20rem); 
    max-height: calc(100vh - 20rem);      
    background: #FFF;
    box-shadow: 0px 4px 30px 0px #00000014;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chart-wrapper::-webkit-scrollbar {
    display: none;
}
a {
    color: var(--theme-primary);
    font-size: .9rem;
}
.page-title {
    color: var(--theme-secondary);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    word-break: break-all;
}
.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
#customize .label {
    font-size: .8rem;
    font-weight: 400;
    line-height: 24px;
    color: #6C757D;
}
.dropdown-item:hover #customize .label{
    color: var(--theme-primary);
}
.dropdown-item:hover #customize .icon {
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.dropdown-item.active #customize .label {
    color: #fff;
}
.dropdown-item.active #customize .icon {
    filter: brightness(0) invert(1);
} 
#customize button {
    background-color: var(--theme-primary);
    color: white;
    font-size: .8rem;
    font-weight: 400;
    line-height: 24px;
}
#customize .form-control {
    font-size: .8rem;
    font-weight: 400;
    line-height: 24px;
    width: 45%;
}
@media (max-width: 767.98px){
    .chart-wrapper {
        max-height: 600px;
    }
    .dropdown{
        width: 100%;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
}
</style>