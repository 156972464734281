import { COMPANY_SERVICE } from "@/services/company.service.js";
import { defineStore } from 'pinia'

export const useCompanyStore = defineStore('company', {
        state: () => ({
            getListOutput: [],
            loading: false
        }),
        getters: {
            companyOutput: (state) => {
                return state.getListOutput ? state.getListOutput : [];
            },
        },
        actions: {
            async getCompanyDetails() {
                return await COMPANY_SERVICE.getCompanyDetails();
            },
            async getCompanyById(id) {
                return await COMPANY_SERVICE.getCompanyById(id);
            },
            async createCompany(payload) {
                this.loading = true;
                try{
                    await COMPANY_SERVICE.createCompany(payload);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    throw e;
                }
            },
            async editCompany(payload) {
                this.loading = true;
                try{
                    await COMPANY_SERVICE.editCompany(payload);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    throw e;
                }
            },
            async deleteCompany() {
                await COMPANY_SERVICE.deleteCompany();
            },
        }
    
});