<template>
    <section id="budget">
        <template v-if="isMobile">
            <div class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/Breadcrumbs/budget.svg')" alt="budget icon">
                <span class="page-title-mobile">{{ $t('budget') }} </span>
            </div>
        </template>
        <div class="container-fluid mt-5 mt-md-0">
            <LayoutWrapper>
                <div class="row border-bottom py-3 p-md-3">
                    <div v-if="!isMobile" class="col-12 col-md-6 d-flex align-items-center gap-2">
                        <img class="img-fluid" :src="require('@/assets/Breadcrumbs/budget.svg')" alt="Budget Icon" width="18">
                        <span class="page-title me-3">{{ $t('budget') }}</span>
                    </div>
                    <div class="col-12 col-md-6 d-flex align-items-center justify-content-end">
                        <div class="dropdown">
                            <DropdownButton 
                                :label="getLabel('hotel', 'Hotel')" 
                                :icon="require('@/assets/Pickup/house.svg')"
                                @clickBtn="toggleDropdown('hotel')"
                            />
                            <DropdownMenu
                                v-model="selectedItems['hotel']"
                                :configuration=" {
                                    items: hotelOptions,
                                    isDropdownOpen: dropdownStates.hotel,
                                    hasHeader: false,
                                    hasBody: true,
                                    openDropdownPosition: 'right',
                                    selectionMode: 'single'
                                }"
                                @close-dropdown="dropdownStates.hotel = false"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="Object.keys(selectedItems.hotel).length" class="py-3 p-md-3">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="d-flex align-items-center">
                                <VueDatePicker 
                                    class="d-block d-lg-flex"
                                    v-model="selectedItems.year" 
                                    year-picker
                                >
                                    <template #trigger>
                                            <div class="date-picker d-flex">
                                                <div class="wrapper">
                                                    <img src="@/assets/Pickup/calendar.svg" alt="calendar" width="16" height="16">
                                                    <span class="label">{{formattedYear}}</span>
                                                </div>
                                                <div class="arrow">
                                                    <img src="@/assets/Pickup/chevron-down.svg" alt="chevron-down">
                                                </div>
                                            </div>
                                    </template>
                                </VueDatePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-3 p-md-3">
                    <div class="container-fluid">
                        <LayoutWrapper>
                            <template v-if="itemsFromApi.length">
                                <TableComponent 
                                    v-model="itemsFromApi" 
                                    :layout="{
                                        hasHeader: { desktop: true, mobile: true }
                                    }" 
                                    :tableHeaders="tableHeaders" 
                                    :tableRows="tableRows"
                                    :tableExpandedRows="tableExpandedRows"
                                    :allowNewLine="true"
                                    :allowDeleteLine="true"
                                    :filters="filters"
                                    @addExpandedRow="addNewLine"
                                    @removeExpandedRow="removeExpandedRow"
                                >
                                    <template v-slot:title>
                                        <div class="row p-4 border-bottom align-items-center gy-3 gy-lg-0">
                                            <div class="col-12 col-lg-6 d-flex">
                                                <h5 class="table-title">{{ $t('Budget Yearly Table') }}</h5>
                                            </div>
                                            <div class="col-12 col-lg-6 d-none d-md-flex justify-content-end">
                                                <button class="btn btn-primary" @click="deleteBudget">{{ `${$t('Delete')} ${this.selectedItems.year} ${$t('Budget')}` }}</button>
                                            </div>
                                        </div>
                                    </template>
                                </TableComponent>
                                <div class="row">
                                    <div class="col-12 d-flex d-md-none mt-2">
                                        <button class="btn btn-primary" @click="deleteBudget">{{ `${$t('Delete')} ${this.selectedItems.year} ${$t('Budget')}` }}</button>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="loadingTable">
                                <div class="table-wrapper row p-4">
                                    <div class="col-12 d-flex align-items-center justify-content-center">
                                        <div class="spinner-border color-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row p-4 border-bottom align-items-center">
                                    <div class="col-12">
                                        <h5 class="table-title">{{ $t('Budget Yearly Table') }}</h5>
                                    </div>
                                </div>
                                <div class="table-wrapper row p-4">
                                    <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                                        <template v-if="this.hotelStore?.getHotelListOutput?.some(hotel => hotel.mewsId === null)">
                                            <p>{{ $t('Integration does not exist.') }}</p>
                                            <router-link :to="{name: 'IntegrationsPage'}">
                                                {{ $t('Add Integrations') }}
                                            </router-link>
                                        </template>
                                        <p v-else>{{ $t('No data available.') }}</p>
                                        <button v-if="Object.keys(selectedItems.hotel).length" class="btn btn-primary" @click="setupBudget">{{ $t('Set Up Budget') }}</button>
                                    </div>
                                </div>
                            </template>
                        </LayoutWrapper>
                    </div>
                </div>
            </LayoutWrapper>
        </div>
    </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useHotelStore } from "@/stores";
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    components: {
        TableComponent,
        DropdownButton,
        LayoutWrapper,
        DropdownMenu,
        VueDatePicker
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const hotelStore = useHotelStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            hotelStore
        }
    },
    data() {
        return {
            tableHeaders: [
                {
                    icon: require('@/assets/FoldersPage/file-name-type.svg'),
                    text: 'Date'
                },
                {
                    icon: require('@/assets/FoldersPage/file-name-type.svg'),
                    text: 'Inventory'
                },
                {
                    icon: require('@/assets/FoldersPage/date-icon.svg'),
                    text: 'RN'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'Occ'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'Revenue'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'ADR'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'RevPar'
                },
            ],
            tableRows: [
                { type: 'Date', field: 'date' },
                { type: 'SimpleText', field: 'inventory', typeField: 'integer' },
                { type: 'SimpleText', field: 'rn', typeField: 'integer' },
                { type: 'SimpleText', field: 'occ', typeField: 'percentage' },
                { type: 'SimpleText', field: 'revenue', typeField: 'currency' },
                { type: 'SimpleText', field: 'adr', typeField: 'currency' },
                { type: 'SimpleText', field: 'revPar', typeField: 'currency' },
            ],
            tableExpandedRows: [
                { type: 'BoldText', field: 'segmented', allowAdd: true, inputType: 'text', typeField: 'string' },
                { type: 'SimpleText', field: 'inventory', allowAdd: true, inputType: 'number', typeField: 'integer' },
                { type: 'SimpleText', field: 'rn', allowAdd: true, inputType: 'number', typeField: 'integer' },
                { type: 'SimpleText', field: 'occ', allowAdd: false, inputType: 'number', typeField: 'percentage' },
                { type: 'SimpleText', field: 'revenue', allowAdd: true, inputType: 'number', typeField: 'currency' },
                { type: 'SimpleText', field: 'adr', allowAdd: false, inputType: 'number', typeField: 'currency' },
                { type: 'SimpleText', field: 'revPar', allowAdd: false, inputType: 'number', typeField: 'currency' },
            ],
            itemsFromApi: [],
            dropdownStates: {
                hotel: false
            },
            // selected items
            selectedItems: {
                hotel: {},
                year: new Date().getFullYear().toString()
            },
            // Important to alert the table component that there was a change in one of the filters
            filters: {},
            loadingTable: false
        }
    },
    async mounted() {
        try {
            this.loadingTable = true;
            await this.hotelStore.getList(); // Fetch hotels from API
            this.setDefaultDropdownSelections(); // Set the default dropdown selections
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingTable = false;
        }
    },
    computed: {
        hotelOptions() {
            return {
                header: [],
                body: this.hotelStore?.getHotelListOutput?.filter(hotel => hotel.mewsId !== null).map(hotel => ({
                    ...hotel,
                    id: hotel.mewsId,
                    label: hotel.name,
                    image: hotel.picture ?? require('@/assets/no-image.jpg'),
                }))
            }
        },
        formattedYear() {
            return `${this.$t('Year')} ${this.selectedItems.year}`;
        }
    },
    methods: {
        setDefaultDropdownSelections() {
            // Set default selection for 'hotel' dropdown
            if (this.hotelOptions?.body.length > 0) {
                this.selectedItems.hotel = this.hotelOptions.body[0];
            }
        },
        toggleDropdown(dropdownName) {
            this.dropdownStates[dropdownName] = !this.dropdownStates[dropdownName];
        },
        getLabel(key, label) {
            const item = this.selectedItems[key];
            return item && item.label ? item.label : this.$t(label);
        },
        generateMonths() {
            const year = this.selectedItems.year;
            const monthKeys = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            // Returns the array with each month formatted
            return monthKeys.map((monthKey) => `${this.$t(monthKey)} ${year}`);
        },
        setupBudget() {
            const months = this.generateMonths();

            // Populate the itemsFromApi array with months and initial values
            months.forEach((month) => {
                this.itemsFromApi.push({
                    date: month,
                    inventory: 0,
                    rn: 0,
                    occ: 0,
                    revenue: 0,
                    adr: 0,
                    revPar: 0,
                    // expandedRows: [
                    //     { segmented: 'BAR', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    //     { segmented: 'NFR', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    //     { segmented: 'Early Booking', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    //     { segmented: 'Corporate', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    //     { segmented: 'Complimentary', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    //     { segmented: 'Long Stay', inventory: 0, rn: 0, occ: 0, revenue: 0, adr: 0, revPar: 0 },
                    // ]
                });
            });

            // Ensure selectedYear is a string to prevent issues with object keys
            const year = this.selectedItems.year.toString();

            // Create the dataToSend object with the selected year
            const dataToSend = {
                [year]: this.itemsFromApi // Use the selected year as the key
            };

            // Merge the new data with the existing budget (if any)
            const budget = {
                ...(this.selectedItems.hotel.budget || {}), // Ensure the current budget exists
                ...dataToSend // Add the new year data to the budget
            };

            // Send the updated budget data to the server
            this.hotelStore.editHotel(this.selectedItems.hotel.id, { 
                budget 
            });

            // update the selectedItems.hotel.budget with the new budget
            this.selectedItems.hotel.budget = budget;
        },
        // Method to fill the table with budget data for the selected year only
        setupBudgetWithData(budget) {
            const selectedYear = this.selectedItems.year.toString(); // Ensure selectedYear is a string

            // Check if the selected year exists in the budget
            if (Object.prototype.hasOwnProperty.call(budget, selectedYear)) {
                const monthsData = budget[selectedYear];
                
                // Fill the table with data for the selected year
                monthsData.forEach((monthData) => {
                    this.itemsFromApi.push({
                        date: monthData.date,
                        inventory: monthData.inventory || 0,
                        rn: monthData.rn || 0,
                        occ: monthData.occ || 0,
                        revenue: monthData.revenue || 0,
                        adr: monthData.adr || 0,
                        revPar: monthData.revPar || 0,
                        expandedRows: monthData.expandedRows || []
                    });
                });
            } else {
                // Logic for when the selected year is not present in the budget, if needed
                // console.log(`The year ${selectedYear} is not present in the budget.`);
            }
        },
        addNewLine(item, index) {
            // If expandedRows doesn't exist, initialize it
            if (!this.itemsFromApi[index].expandedRows) {
                this.itemsFromApi[index].expandedRows = [];
            }

            // Create a copy of the expandedRows array to ensure reactivity
            const expandedRows = [...this.itemsFromApi[index].expandedRows];

            // Add the new item to the expandedRows array
            expandedRows.push(item);

            // Update expandedRows in the item
            this.itemsFromApi[index].expandedRows = expandedRows;

            // Calculate the updated values for the item
            this.calculateOcc(item); 
            this.calculateADR(item); 
            this.calculateRevPar(item);

            // Update the item values in the itemsFromApi array
            this.itemsFromApi[index].inventory += item.inventory || 0;
            this.itemsFromApi[index].rn += item.rn || 0;
            this.itemsFromApi[index].occ = this.calculateTotalOccupancy();
            this.itemsFromApi[index].revenue += item.revenue || 0;
            this.itemsFromApi[index].adr = this.calculateAverageADR();
            this.itemsFromApi[index].revPar = this.calculateAverageRevPar();

            // Ensure selectedYear is a string to avoid issues with object keys
            const year = this.selectedItems.year.toString();

            // Prepare the dataToSend object with the selected year
            const dataToSend = {
                [year]: this.itemsFromApi // Use the selected year as the key
            };

            // Merge the new data with the existing budget (if any)
            const budget = {
                ...(this.selectedItems.hotel.budget || {}), // Ensure the current budget exists
                ...dataToSend // Add the new year data to the budget
            };

            // Send the updated data to the server
            this.hotelStore.editHotel(this.selectedItems.hotel.id, { 
                budget 
            });
            // update the selectedItems.hotel.budget with the new budget
            this.selectedItems.hotel.budget = budget;
        },
        // Method to calculate occupancy (RN / Inventory)
        calculateOcc(item) {
            if (item.inventory > 0) {
                item.occ = ((item.rn / item.inventory));
            } else {
                item.occ = 0;
            }
        },
        // Method to calculate ADR (Revenue / RN)
        calculateADR(item) {
            if (item.rn > 0 && item.revenue) {
                // Ensure item.revenue is a string and clean up the currency symbol
                const revenue = item.revenue || 0;
                item.adr = (revenue / item.rn);
            } else {
                item.adr = 0;
            }
        },
        // Method to calculate RevPar (Revenue / Inventory)
        calculateRevPar(item) {
            if (item.inventory > 0 && item.revenue) {
                const revenue = item.revenue || 0;
                item.revPar = (revenue / item.inventory);
            } else {
                item.revPar = 0;
            }
        },
        // Method to calculate the total of a field
        calculateTotal(field) {
            return this.itemsFromApi.reduce((total, item) => {
                return total + item[field] || 0;
            }, 0);
        },
        // Method to calculate the total occupancy (RN / Inventory)
        calculateTotalOccupancy(totalInventory, totalRN) {      
            const totalInv = totalInventory ?? this.calculateTotal('inventory');
            const totalRoomNights = totalRN ?? this.calculateTotal('rn');      
            return totalInv > 0 ? (totalRoomNights / totalInv) : 0;
        },
        // Method to calculate the average ADR (Revenue / RN)
        calculateAverageADR(totalRevenue, totalRN) {   
            const totalRev = totalRevenue ?? this.calculateTotal('revenue');
            const totalRoomNights = totalRN ?? this.calculateTotal('rn'); 
            return totalRoomNights > 0 ? (totalRev / totalRoomNights) : 0;
        },
        // Method to calculate the average RevPar (Revenue / Inventory)
        calculateAverageRevPar(totalInventory, totalRevenue) {
            const totalInv = totalInventory ?? this.calculateTotal('inventory');
            const totalRev = totalRevenue ?? this.calculateTotal('revenue');
            return totalInv > 0 ? (totalRev / totalInv) : 0;
        },
        removeExpandedRow(rowIndex, expandedRowIndex) {
            // Copy the array and remove the item to trigger reactivity
            const expandedRows = [...this.itemsFromApi[rowIndex].expandedRows];
            const removedRow = expandedRows.splice(expandedRowIndex, 1)[0];

            // Set the new array on expandedRows to force reactivity
            this.itemsFromApi[rowIndex].expandedRows = expandedRows;

            // Update selected item properties
            this.itemsFromApi[rowIndex].inventory -= removedRow.inventory;
            this.itemsFromApi[rowIndex].rn -= removedRow.rn;
            this.itemsFromApi[rowIndex].occ = this.calculateTotalOccupancy(this.itemsFromApi[rowIndex].inventory, this.itemsFromApi[rowIndex].rn);
            this.itemsFromApi[rowIndex].revenue -= removedRow.revenue;
            this.itemsFromApi[rowIndex].adr = this.calculateAverageADR(this.itemsFromApi[rowIndex].revenue, this.itemsFromApi[rowIndex].rn);
            this.itemsFromApi[rowIndex].revPar = this.calculateAverageRevPar(this.itemsFromApi[rowIndex].inventory, this.itemsFromApi[rowIndex].revenue);
            
            const updatedBudget = {
                ...this.selectedItems.hotel.budget,
                [this.selectedItems.year]: this.itemsFromApi
            };

            // Send the updated data to the server
            this.hotelStore.editHotel(this.selectedItems.hotel.id, { budget: updatedBudget });
            // update the selectedItems.hotel.budget with the new budget
            this.selectedItems.hotel.budget = updatedBudget;
        },
        deleteBudget() {
            // Ensure selectedYear is a string to avoid issues with object keys
            const year = this.selectedItems.year.toString();

            // Prepare the dataToSend object with the selected year
            const dataToSend = {
                [year]: [] // Use the selected year as the key
            };

            // Merge the new data with the existing budget (if any)
            const budget = {
                ...(this.selectedItems.hotel.budget || {}), // Ensure the current budget exists
                ...dataToSend // Add the new year data to the budget
            };

            // Send the updated data to the server
            this.hotelStore.editHotel(this.selectedItems.hotel.id, { 
                budget 
            });
            // update the selectedItems.hotel.budget with the new budget
            this.selectedItems.hotel.budget = budget;
        }
    },
    watch: {
        'selectedItems.hotel': {
            handler: function (newVal, oldVal) {
                this.filters = {}; // IMPORTANT - Clears the filters to restore the object if the object is not empty
                if(Object.keys(oldVal).length && newVal.name !== oldVal.name) {
                    this.filters = newVal;
                }
                if (newVal) {
                    this.itemsFromApi = []; // Clears the table
                    // Checks if the budget is not null
                    if (newVal.budget !== null) {
                        // Checks if the selected year is present in the budget
                        const selectedYear = this.selectedItems.year; // The selected year (should be in the state or as a variable)
                        
                        // If the year is present in the budget, calls the method to fill the table
                        if (Object.prototype.hasOwnProperty.call(newVal.budget, selectedYear)) {
                            this.setupBudgetWithData(newVal.budget);
                        } else {
                            // console.log(`The year ${selectedYear} is not present in the budget.`);
                            // Logic to handle when the year is not in the budget, if needed
                        }
                    } else {
                        // console.log('The selected hotel does not have a budget.');
                        // Logic to handle when the hotel does not have a budget, if needed
                    }
                }
            },
            deep: true
        },
        'selectedItems.year'(newVal) {
            if (newVal) {
                this.itemsFromApi = []; // Clears the table
                this.filters = {year: newVal}; // pass the year to the filters object to close expanded rows
                // Checks if the hotel and the budget are defined
                if (this.selectedItems.hotel && this.selectedItems.hotel.budget !== null) {
                    // Checks if the selected year exists in the budget safely
                    if (Object.prototype.hasOwnProperty.call(this.selectedItems.hotel.budget, newVal)) {
                        this.setupBudgetWithData(this.selectedItems.hotel.budget);
                    } else {
                        // console.log(`Year ${newVal} not found in the budget.`);
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.page-title {
    color: var(--theme-secondary);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    word-break: break-all;
}
.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
.date-picker .wrapper {
    display: flex;
    gap: 8px;
    padding: 6px 12px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #CED4DA;
    background-color: transparent;
    align-items: center;
}
.date-picker .wrapper .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #6C757D;
}
.date-picker .arrow {
    padding: 6px 12px 6px 12px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #CED4DA;
    border-left: none;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-primary {
    background-color: var(--theme-primary);
    border-color: var(--theme-primary);
    color: white;
}
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
    background-color: var(--theme-primary);
    border-color: var(--theme-primary);
    color: white;
}
.table-wrapper{
    height: calc(100vh - 18rem);
    min-height: calc(100vh - 18rem);
}
a{
    color: var(--theme-primary);
}
@media screen and (max-width: 767.98px) {
    .dp__main,
    .date-picker .wrapper {
        width: 100%!important;
    }
    .btn-primary {
        width: 100%;
        font-size: .8rem;
    }
    .dropdown{
        width: 100%;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
}
</style>