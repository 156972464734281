<template>
  <section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 my-5">
                <BoldTitle :title="$t('EditPlan')"/>
            </div>
        </div>
        <div id="div-global">
            <div class="col d-flex gap-2 p-5">
                <BoldTitle :icon="require('@/assets/Billing/subTitle-table.svg')" :title="$t('UpgradePlan')"/>
            </div>
            <DividerComponent id="divider" class="my-3"/>
            <div v-if="userStorage.user?.companies[0]?.plan" class="row">
                <div class="col-12 my-3 d-flex flex-column gap-2 align-items-center justify-content-center">
                    <span id="subscription-type">{{ $t("managePayment") }}</span>
                    <CustomButton 
                        class="btn mt-2 gap-2" 
                        :label="$t('managePlan')"
                        @clickBtn="managePayments()"
                        buttonType="text-primary" 
                        :buttonStyle="{
                            borderRadius: '0 !important',
                        }"
                    />
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                    <span id="plan-title">{{$t("plans")}}</span>
                    <span id="plan-desc">{{$t("changeYourCurrentWorkspacePlan")}}</span>
                    <div class="row">
                        <div class="col-12 my-2">
                            <div class="switch-container" @click="toggleSwitch">
                                <div :class="{ 'switch-active': isMonthActive, 'switch-inactive': !isMonthActive }">{{$t("Montly")}}</div>
                                <div :class="{ 'switch-inactive': isMonthActive, 'switch-active': !isMonthActive }">{{ $t("Yearly") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9" id="div-three-columns">
                    <div class="col-12 col-lg-3 my-5 col-individual-style" v-for="(plan, index) in itemsFromApi" :key="index">
                        <div class="d-flex flex-row gap-2 flex-wrap">
                            <span id="subscription-type">{{ plan.name }}</span>
                            <div id="discount-div">
                                <span id="discount-span">{{$t("20%off")}}</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex flex-column">
                            <span v-if="isMonthActive === false" id="price-span">$<span id="number-price">{{ plan.prices.yearly }}</span></span>
                            <span v-if="isMonthActive === true" id="price-span">$<span id="number-price">{{ plan.prices.monthly }}</span></span>
                            <span id="payment-period">{{$t("perUser/MonthBilledAnnually")}}</span>
                            <CustomButton 
                                class="btn mt-2 gap-2" 
                                :label="$t('upgradePlan')"
                                @clickBtn="sendToPayment(plan.objectId, isMonthActive ? 'monthly' : 'yearly')"
                                buttonType="text-primary" 
                                :buttonStyle="{
                                    borderRadius: '0 !important',
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <PlansTable></PlansTable>
        </div>
    </div>
    <EditPlanForm v-show="isPopupCurrentPlanVisible" @popUpClosed="isPopupCurrentPlanVisible = false"></EditPlanForm>
  </section>
</template>

<script>
import { nextTick } from 'vue';
import { useSubscriptionStore, useAlertStore } from "@/stores";
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import EditPlanForm from '@/components/popups/EditPlanForm.vue';
import PlansTable from '@/components/BillingPage/PlansTable.vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
    components: {
      BoldTitle,
      DividerComponent,
      CustomButton,
      EditPlanForm,
      PlansTable,
    },
    data() {
        return {
            isPopupCurrentPlanVisible: false,
            isMonthActive: true,
            itemsFromApi: [],
            userStorage: JSON.parse(localStorage.getItem('user')) ?? {},
            stripe: null,
        }
    },
    setup() {
        const subscriptionStore = useSubscriptionStore();
        const alertStore = useAlertStore();
        return {
            subscriptionStore,
            alertStore
        }
    },
    async mounted() {
        await this.fetchData();
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    },
    methods: {
        async fetchData() {
            try {
                await this.subscriptionStore.getList();
                nextTick(() => {
                    this.itemsFromApi = this.subscriptionStore.getListOutput;
                });
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        sendToBilling() {
            this.$router.push({ name: 'BillingPage' });
        },
        toggleSwitch() {
            this.isMonthActive = !this.isMonthActive;
        },
        async sendToPayment(planId, isMonthActive) {
            try {
                const frequency = isMonthActive 

                // Execute the purchasePlan action in your subscription store with the selected priceId
                const response = await this.subscriptionStore.purchasePlan(planId, frequency );

                // Check the response from the action
                if (response.OK && response.RedirectUrl) {
                    // Redirect to the Stripe Checkout URL
                    window.location.href = response.RedirectUrl;
                } else {
                    throw new Error('Unable to process payment. Please try again later.');
                }
            } catch (error) {
                console.error('Error in sendToPayment:', error.message);
                // Display error message to the user
                alert(`Error: ${error.message}`);
                // Alternatively, you can use a better UI element for user notification, e.g., a toast notification
            }
        },
        async managePayments() {
            try {

                // Execute the purchasePlan action in your subscription store with the selected priceId
                const response = await this.subscriptionStore.managePayments();

                // Check the response from the action
                if (response.OK ) {
                    console.log('response managePayment:', response);  // Debugging log
                } else {
                    throw new Error('Unable to process manage payment. Please try again later.');
                }
            } catch (error) {
                console.error('Error in sendToPayment:', error.message);
                // Display error message to the user
                alert(`Error: ${error.message}`);
                // Alternatively, you can use a better UI element for user notification, e.g., a toast notification
            }
        },
    },
}
</script>

<style scoped>
.divider {
    stroke-width: 1px;
    stroke: var(--theme-primary);
    padding: 0;
    margin: 0;
    color: var(--theme-primary) !important;
    background-color: var(--theme-primary) !important;
}
#divider {
    stroke-width: 1px;
    stroke: var(--theme-secondary);
    padding: 0;
    margin: 0;
    color: var(--theme-secondary) !important;
    background-color: var(--theme-secondary) !important;
}
.col-individual-style{
    padding: 10px;
}
#price-span{
    color: var(--Theme-Dark, #212529);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
#number-price{
    color: var(--Theme-Dark, #212529);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}
#discount-div{
    background-color: #D1E7DD;
    padding: 2px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#discount-span{
    color: #006837;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
}
#subscription-type{
    color: var(--Theme-Dark, #212529);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
#payment-period{
    color: var(--gray-600, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
#list-title{
    color: var(--theme-dark, #212529);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    margin-top: 1.5rem;
}
.list-items{
    color: var(--gray-600, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
#div-global{
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    padding: 0 !important;
}

#div-three-columns{
    display: flex;
    justify-content: center;
    align-items: center;
}
#more-details{
    color: var(--theme-dark, #212529);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
#view-pricing-page{
    color: var(--theme-primary, #A81800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}
#plan-title{
    color: var(--Theme-Dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    padding-left: 3rem;
}
#plan-desc{
    color: var(--Gray-600, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 2rem;
    padding-left: 3rem;
}
.switch-container {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
  
.switch-active,
.switch-inactive {
    width: 70px;
    height: 30px;
    transition: background-color 0.3s ease;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--Theme-Secondary, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 0 !important;
}

.switch-active {
    background-color: #d3d3d3;
}

.switch-inactive {
    background-color: rgb(245, 245, 245);
}
@media only screen and (max-width: 1310px) {
    #number-price{
        font-size: 15px;
    }
}
@media only screen and (max-width: 991px) {
    #div-three-columns{
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
}
</style>