/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const METRICS_SERVICE = {
    getHotelSummary,
    getBookingWindow,
    getLengthOfStay,
    getTotalPerformance,
    getTotalEvolution,
    getCompetitorsAnalysis,
    pickupTable,
    getLighthouseHotels,
    getMewsHotels
    // createMetric,
    // updateMetric,
    // removeMetric
};

// Root service to build API url
const API_ROOT_SERVICE = "metrics";

/* Services */
async function getHotelSummary(queryParams) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "kpi"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getBookingWindow(queryParams) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "booking-window"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getLengthOfStay(queryParams) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "length-of-stay"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getTotalPerformance(queryParams) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "total-performance"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getTotalEvolution(queryParams) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "total-evolution"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getCompetitorsAnalysis(queryParams) {
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "competitors-analysis-chart"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function pickupTable(queryParams) {
    const requestOptions = {
        method: 'GET'
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "pickup-table"]);

    return await API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}
async function getLighthouseHotels() {
    const requestOptions = {
        method: 'GET'
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "/object/Hotel?type=Lighthouse"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getMewsHotels() {
    const requestOptions = {
        method: 'GET'
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "/object/Service/EnterpriseId"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}

// async function createMetric(payload) 
// {
//     const requestOptions = {
//         method: 'POST',
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(payload),
//     };

//     let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "metrics"]);
    
//     return await API_HELPER.loggedApiCall(url, null, requestOptions);
// }
// async function removeMetric(payload) 
// {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(payload),
//     };

//     let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "metrics"]);
    
//     return await API_HELPER.loggedApiCall(url, null, requestOptions);
// }
// async function updateMetric(payload) 
// {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(payload),
//     };

//     let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "metrics"]);
    
//     return await API_HELPER.loggedApiCall(url, null, requestOptions);
// }