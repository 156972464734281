<template>
    <section id="metric">
        <template v-if="isMobile">
            <div class="sidebar-toggle border-bottom" @click="menuStore.toggleSubMenu">
                <img src="@/assets/arrow-right-fill.svg" class="arrow-icon" :class="{ open: menuStore.isSubMenuOpen }" alt="">
                <span class="page-title-mobile">{{ $t('Segmented Pickup') }} - {{ metricTitle }}</span>
            </div>
        </template>
        <div class="container-fluid mt-5 mt-md-0">
            <LayoutWrapper>
                <div class="row border-bottom py-3 p-md-3">
                    <div v-if="!isMobile" class="col-12 col-lg-6 d-flex align-items-center gap-2 mb-3 mb-lg-0">
                        <img class="img-fluid" :src="require('@/assets/sideBar/pickup.svg')" alt="" width="18">
                        <span class="page-title me-3">{{ $t('Segmented Pickup') }}</span>
                        <!-- <CustomButton 
                            class="ms-auto ms-xl-0"
                            :label="$t('createPageReport')" 
                            :buttonType="'theme-light'" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '4px 30px'
                            }" 
                        /> -->
                    </div>
                    <div class="col-12 col-lg-6 d-flex align-items-center justify-content-end">
                        <div class="dropdown">
                            <DropdownButton 
                                :label="getLabel('hotel', 'Hotel')" 
                                :icon="require('@/assets/Pickup/house.svg')"
                                @clickBtn="toggleDropdown('hotel')"
                            />
                            <DropdownMenu
                                v-model="selectedItems['hotel']"
                                :configuration=" {
                                    items: hotelOptions,
                                    isDropdownOpen: dropdownStates.hotel,
                                    hasHeader: false,
                                    hasBody: true,
                                    openDropdownPosition: 'right',
                                    selectionMode: 'single'
                                }"
                                @close-dropdown="dropdownStates.hotel = false"
                            />
                        </div>
                    </div> 
                </div>
                <div class="py-3 p-md-3">
                    <div class="row">
                        <div class="col-12 col-xxl-5 mb-3">
                            <div class="d-flex align-items-center">
                                <DateButton v-model="date" typeForm="1" range/>
                            </div>
                        </div>
                        <div class="col-12 col-xxl-7 d-flex justify-content-start justify-content-xxl-end gap-3 flex-wrap mb-3">
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="getLabel('week', 'Week')" 
                                    :hasValue="selectedItems['week']"
                                    @clickBtn="toggleDropdown('week')"
                                    @resetFilter="resetFilter('week')"
                                />
                                <DropdownMenu
                                    v-model="selectedItems['week']"
                                    :configuration=" {
                                        items: weekOptions,
                                        isDropdownOpen: dropdownStates.week,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="dropdownStates.week = false"
                                />
                            </div>
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="getLabel('dayOfWeek', 'Day of week')" 
                                    :hasValue="selectedItems['dayOfWeek']"
                                    @clickBtn="toggleDropdown('dayOfWeek')"
                                    @resetFilter="resetFilter('dayOfWeek')"
                                />
                                <DropdownMenu
                                    v-model="selectedItems['dayOfWeek']"
                                    :configuration=" {
                                        items: dayOfWeekOptions,
                                        isDropdownOpen: dropdownStates.dayOfWeek,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="dropdownStates.dayOfWeek = false"
                                />
                            </div>
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="selectedItems['range'].keyValue ? `${$t('Last')} ${selectedItems['range'].keyValue} ${$t('days')}`: getLabel('range', 'Range')" 
                                    :hasValue="selectedItems['range']"
                                    @clickBtn="toggleDropdown('range')"
                                    @resetFilter="resetFilter('range')"
                                />
                                <DropdownMenu
                                    v-model="selectedItems['range']"
                                    :configuration=" {
                                        items: rangeOptions,
                                        isDropdownOpen: dropdownStates.range,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="dropdownStates.range = false"
                                >
                                    <template v-slot:customize="{item, active}">
                                        <div v-if="!active" id="customize" class="d-flex align-items-center">
                                            <img class="icon me-2" .src="item.icon" width="12" height="12" alt="pencil">
                                            <span class="label">{{ item.label }}</span>
                                        </div>
                                        <div v-if="active" id="customize" class="d-flex flex-column gap-2">
                                            <div class="d-flex align-items-center gap-2">
                                                <span class="label">{{ $t('Last') }}</span>
                                                <input class="form-control" v-model="customRange">
                                                <span class="label">{{ $t('days') }}</span>
                                            </div>
                                            <button class="btn" @click="applyCustomRange">{{ $t('Apply') }}</button>
                                        </div>
                                    </template>
                                </DropdownMenu>
                            </div>
                        </div>
                    </div>
                    <template v-if="metric !== 'All'">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <WidgetWrapper
                                    widget-id="doughnutTotalPerformance"
                                    :loading="widgetStates.doughnutTotalPerformance.loading"
                                    :error="widgetStates.doughnutTotalPerformance.error"
                                    @retry="fetchPerformanceData(queryParams)"
                                >
                                    <DoughnutChart
                                        :chartTitle="$t('Segments')" 
                                        :chartData="totalPerformanceDoughnutChart?.chartData" 
                                        :chartOptions="totalPerformanceDoughnutChart?.chartOptions"
                                    />
                                </WidgetWrapper>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <WidgetWrapper
                                    widget-id="barTotalPerformance"
                                    :loading="widgetStates.barTotalPerformance.loading"
                                    :error="widgetStates.barTotalPerformance.error"
                                    @retry="fetchPerformanceData(queryParams)"
                                >
                                    <BarChart 
                                        :chartTitle="$t('Bar Segments')" 
                                        :chartData="totalPerformanceBarChart?.chartData" 
                                        :chartOptions="totalPerformanceBarChart?.chartOptions" 
                                        :customStyle="{
                                            width: totalPerformanceBarChart?.chartOptions?.with
                                        }" 
                                    />
                                </WidgetWrapper>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <WidgetWrapper
                                    widget-id="bookingWindow"
                                    :loading="widgetStates.bookingWindow.loading"
                                    :error="widgetStates.bookingWindow.error"
                                    @retry="fetchBookingwidthAndLengthOfStay"
                                >
                                    <LineChart 
                                        :chartTitle="$t('bookingWindow')" 
                                        :chartData="bookingWindowChart?.chartData" 
                                        :chartOptions="bookingWindowChart?.chartOptions" 
                                        :growth="bookingWindowChart?.variation" 
                                        :customStyle="{
                                            width: bookingWindowChart?.chartOptions.width
                                        }"
                                    />
                                </WidgetWrapper>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <WidgetWrapper
                                    widget-id="lengthOfStay"
                                    :loading="widgetStates.lengthOfStay.loading"
                                    :error="widgetStates.lengthOfStay.error"
                                    @retry="fetchBookingwidthAndLengthOfStay"
                                >
                                    <BarChart 
                                        :chartTitle="$t('lengthOfStay')" 
                                        :chartData="lengthOfStayChart?.chartData" 
                                        :chartOptions="lengthOfStayChart?.chartOptions" 
                                        :growth="lengthOfStayChart?.variation" 
                                        :customStyle="{
                                            width: lengthOfStayChart?.chartOptions.width
                                        }" 
                                    />
                                </WidgetWrapper>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <WidgetWrapper 
                                    wrapperClass="table-widget-wrapper"
                                    widget-id="totalTable"
                                    :loading="widgetStates.totalTable.loading"
                                    :error="widgetStates.totalTable.error"
                                    @retry="fetchTotalTable"
                                >
                                    <TableComponent 
                                        v-model="formattedItems" 
                                        :layout="{
                                            hasHeader: { desktop: true, mobile: true }
                                        }" 
                                        :tableHeaders="tableHeaders" 
                                        :tableRows="tableRows"
                                        :tableExpandedRows="tableExpandedRows"
                                        :allowDeleteLine="false"
                                    >
                                        <template v-slot:title>
                                            <div class="row py-3 px-4 border-bottom align-items-center">
                                                <div class="col-12">
                                                    <h5 class="table-title fw-bold m-0">{{ $t('Total Table') }}</h5>
                                                </div>
                                                <!-- <div class="col-6">
                                                    <CustomButton 
                                                        class="ms-auto"
                                                        :type="1" 
                                                        buttonType="theme-light"
                                                        :rightIcon="require('@/assets/BudgetAnalysis/createPageReport.svg')" 
                                                        :buttonStyle="{
                                                            borderRadius: '8px!important',
                                                            padding: '5px'
                                                        }" 
                                                    />
                                                </div> -->
                                            </div>
                                        </template>
                                    </TableComponent>
                                </WidgetWrapper>
                            </div>
                        </div>
                    </template>
                </div>
            </LayoutWrapper>
        </div>  
    </section>
</template>

<script>
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import ChartSetup from '@/helpers/chart-setup.js';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import DateButton from '@/components/DateButton.vue';
import DoughnutChart from '@/components/Charts/DoughnutChart.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useMetricsStore, useHotelStore, useMenuStore } from "@/stores";
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import BarChart from '@/components/Charts/BarChart.vue';
import LineChart from '@/components/Charts/LineChart.vue';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import TableComponent from '@/components/TableComponent.vue';
import WidgetWrapper from '@/components/WidgetWrapper.vue';

dayjs.extend(isoWeek);

export default {
    components: {
        DropdownMenu,
        DropdownButton,
        DateButton,
        DoughnutChart,
        LayoutWrapper,
        BarChart,
        LineChart,
        TableComponent,
        WidgetWrapper
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const metricsStore = useMetricsStore();
        const hotelStore = useHotelStore();
        const menuStore = useMenuStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            metricsStore,
            hotelStore,
            menuStore
        }
    },
    props: {
        metric: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dropdownStates: {
                hotel: false,
                range: false,
                dayOfWeek: false,
                week: false
            },
            // selected items
            selectedItems: {
                range: {},
                dayOfWeek: {},
                week: {},
                hotel: {}
            },
            // widget states
            widgetStates: {
                doughnutTotalPerformance: {
                    loading: true,
                    error: null
                },
                barTotalPerformance: {
                    loading: true,
                    error: null
                },
                bookingWindow: {
                    loading: true,
                    error: null
                },
                lengthOfStay: {
                    loading: true,
                    error: null
                },
                totalTable: {
                    loading: true,
                    error: null
                }
            },
            // segments chart
            totalPerformanceDoughnutChart: null,
            totalPerformanceBarChart: null,
            bookingWindowChart: null,
            lengthOfStayChart: null,
            // date
            date: null,
            isDatePickerChanging: false,
            // table
            tableHeaders: [
                {
                    icon: require('@/assets/Pickup/calendar-icon.svg'),
                    text: 'Date'
                },
                {
                    icon: require('@/assets/Pickup/rn-icon.svg'),
                    text: 'RN'
                },
                {
                    icon: require('@/assets/Pickup/occupancy-icon.svg'),
                    text: 'Occ'
                },
                {
                    icon: require('@/assets/Pickup/revenue-icon.svg'),
                    text: 'Revenue'
                },
                {
                    icon: require('@/assets/Pickup/adr-icon.svg'),
                    text: 'ADR'
                },
                {
                    icon: require('@/assets/Pickup/revPar-icon.svg'),
                    text: 'RevPar'
                }
            ],
            tableRows: [
                { type: 'Date', field: 'date' },
                { type: 'Variation', field: 'rn' },
                { type: 'Variation', field: 'occ' },
                { type: 'Variation', field: 'revenue' },
                { type: 'Variation', field: 'adr' },
                { type: 'Variation', field: 'revPar' }
            ],
            tableExpandedRows: [
                { type: 'BoldText', field: 'name' },
                { type: 'Variation', field: 'rn' },
                { type: 'Variation', field: 'occ' },
                { type: 'Variation', field: 'revenue' },
                { type: 'Variation', field: 'adr' },
                { type: 'Variation', field: 'revPar' }
            ],
            tableItemsFromApi: []
        }
    },
    async mounted() {
        await this.hotelStore.getList(); // Fetch hotels from API
        this.setDefaultDropdownSelections(); // Set the default dropdown selections
        if(this.metric !== 'All') {
            await this.fetchPerformanceData(this.queryParams); // Fetch the segmented charts data
        } else {
            this.fetchTotalTable();
            this.fetchBookingwidthAndLengthOfStay(); // Fetch the segmented charts data
        }
    },
    computed: {
        metricTitle() {
            switch(this.metric) {
                case 'RoomNights':
                    return this.$t('Room Nights');
                case 'Occupancy':
                    return this.$t('Occupancy');
                case 'Revenue':
                    return this.$t('Revenue');
                case 'Adr':
                    return this.$t('ADR');
                case 'RevPAR':
                    return this.$t('RevPar');
                case 'All':
                    return this.$t('All');
                default:
                    return '';
            }
        },
        hotelOptions() {
            return {
                header: [],
                body: this.hotelStore?.getHotelListOutput?.filter(hotel => hotel.mewsId !== null).map(hotel => ({
                    id: hotel.mewsId,
                    label: hotel.name,
                    image: hotel.picture ?? require('@/assets/no-image.jpg')
                }))
            }
        },
        rangeOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: this.$t('Yesterday'),
                        keyName: 'yesterday'
                    },
                    {
                        id: 1,
                        label: this.$t('Last 2 days'),
                        keyName: 'last2days'
                    },
                    {
                        id: 2,
                        label: this.$t('Last 3 days'),
                        keyName: 'last3days'
                    },
                    {
                        id: 3,
                        label: this.$t('Last 4 days'),
                        keyName: 'last4days'
                    },
                    {
                        id: 4,
                        label: this.$t('Customize'),
                        icon: require('@/assets/Pickup/pencil-square.svg'),
                        keyName: 'customize',
                        keyValue: null
                    }
                ]
            }
        },
        dayOfWeekOptions() {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            // Check if `this.date` is valid
            if (!this.date || !this.date[0] || !this.date[1]) {
                return {
                    header: [],
                    body: [] // Return an empty array if there's no valid date range
                };
            }

            const startDate = dayjs(new Date(this.date[0])); // Get the start date
            const endDate = dayjs(new Date(this.date[1])); // Get the end date

            const availableDays = []; // Array to store available days in the range

            let currentDay = startDate.clone();
            while (currentDay.isBefore(endDate) || currentDay.isSame(endDate)) {
                const dayIndex = currentDay.day(); // Get day index (0-6)

                // Check if the day already exists in the array to avoid duplicates
                if (!availableDays.some(day => day.id === dayIndex)) {
                    availableDays.push({
                        id: dayIndex,
                        label: this.$t(daysOfWeek[dayIndex]), // Dynamically translate day
                        keyName: daysOfWeek[dayIndex].toLowerCase() // Convert to lowercase keyName
                    });
                }

                currentDay = currentDay.add(1, 'day'); // Move to the next day
            }

            // Sort the days so Monday (1) comes first and Sunday (0) comes last
            availableDays.sort((a, b) => {
                // Move Sunday (id 0) to the end of the list
                if (a.id === 0) return 1;
                if (b.id === 0) return -1;
                return a.id - b.id;
            });

            return {
                header: [],
                body: availableDays // Sorted list of available days
            };
        },
        weekOptions() {
            // Function to get the total number of weeks in a given year
            const getTotalWeeksInYear = (year) => {
                const lastDayOfYear = new Date(year, 11, 31); // December 31st
                return getWeekNumber(lastDayOfYear); // Last week of the year
            };

            // Function to calculate the week number of a given date
            const getWeekNumber = (date) => {
                const oneJan = new Date(date.getFullYear(), 0, 1); // January 1st
                const days = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000)); // Days since January 1st
                return Math.ceil((days + oneJan.getDay() + 1) / 7); // Calculate the week number
            };

            // Get the current year dynamically
            const currentYear = new Date().getFullYear();

            const totalWeeks = getTotalWeeksInYear(currentYear); // Get the total number of weeks for the current year

            const options = {
                header: [],
                body: []
            };

            // Loop to generate weeks dynamically
            for (let i = 1; i <= totalWeeks; i++) {
                options.body.push({
                    id: i, // The ID is the week number
                    label: `${this.$t('Week')} ${i}` // Label for each week
                });
            }

            return options;
        },
        // query params for fetching data
        queryParams() {
            return {
                hotelId: this.selectedItems?.hotel?.id ?? null,
                startsAt: this.date[0],
                endsAt: this.date[1],
                metric: this.metric !== 'All' ? this.metric : null
            }
        },
        tableQueryParams() {
            return {
                hotelId: this.selectedItems?.hotel?.id ?? null,
                startsAt: this.date[0],
                endsAt: this.date[1],
            }
        },
        formattedItems() {
            return this.tableItemsFromApi.map(item => {
                return {
                    date: item.date,
                    rn: {
                        value: this.$n(item.rn.value, 'integer'),
                        variation: this.$n(item.rn.variation, 'percent')
                    },
                    occ: {
                        value: this.$n(item.occ.value, 'percent'),
                        variation: this.$n(item.occ.variation, 'percent')
                    },
                    revenue: {
                        value: this.$n(item.revenue.value, 'currency'),
                        variation: this.$n(item.revenue.variation, 'currency')
                    },
                    adr: {
                        value: this.$n(item.adr.value, 'currency'),
                        variation: this.$n(item.adr.variation, 'currency')
                    },
                    revPar: {
                        value: this.$n(item.revPar.value, 'currency'),
                        variation: this.$n(item.revPar.variation, 'currency')
                    },
                    expandedRows: item.segments.map(segment => ({
                        name: segment.name,
                        rn: {value: this.$n(segment.rn.value, 'integer'), variation: this.$n(segment.rn.variation, 'percent')},
                        occ: {value: this.$n(segment.occ.value, 'percent'), variation: this.$n(segment.occ.variation, 'percent')},
                        revenue: {value: this.$n(segment.revenue.value), variation: this.$n(segment.revenue.variation)},
                        adr: {value: this.$n(segment.adr.value, 'currency'), variation: this.$n(segment.adr.variation, 'currency')},
                        revPar: {value: this.$n(segment.revPar.value, 'currency'), variation: this.$n(segment.revPar.variation, 'currency')}
                    }))
                };
            });
        }
    },
    methods: {
        getDatesInRange(startDate, endDate) {
            const dates = [];
            const currentDate = new Date(startDate);
            const end = new Date(endDate);

            while (currentDate <= end) {
                dates.push(currentDate.toISOString().split('T')[0]);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return dates;
        },
        fetchTotalTable() {
            this.widgetStates.totalTable.loading = true;
            this.metricsStore.pickupTable(this.tableQueryParams)
            .then(() => {
                this.setupTable();
            })
            .catch((error) => {
                console.log(error);
                this.widgetStates.totalTable.error = error;
            })
            .finally(() => {
                this.widgetStates.totalTable.loading = false;
            });
        },
        setupTable() {
            const pickupTableOutput = this.metricsStore.pickupTableOutput ?? {}; // Get the pickup table output
            const range = this.getDatesInRange(this.date[0], this.date[1]); // Get the date range in the format 'YYYY-MM-DD'
            const keySegments = Object.keys(pickupTableOutput); // get segment strings

            const createSegmentData = (key, date) => ({
                name: key,
                rn: { value: pickupTableOutput[key].RoomNights.current[date] ?? 0, variation: pickupTableOutput[key].RoomNights.variation[date] ?? 0 },
                occ: { value: pickupTableOutput[key].Occupancy.current[date] ?? 0, variation: pickupTableOutput[key].Occupancy.variation[date] ?? 0 },
                revenue: { value: pickupTableOutput[key].Revenue.current[date] ?? 0, variation: pickupTableOutput[key].Revenue.variation[date] ?? 0 },
                adr: { value: pickupTableOutput[key].ADR.current[date] ?? 0, variation: pickupTableOutput[key].ADR.variation[date] ?? 0 },
                revPar: { value: pickupTableOutput[key].RevPar.current[date] ?? 0, variation: pickupTableOutput[key].RevPar.variation[date] ?? 0 }
            });

            const calculateAverageVariation = (metric, date) => {
                const variations = keySegments.map(key => pickupTableOutput[key][metric].variation[date] ?? 0);
                const total = variations.reduce((acc, variation) => acc + variation, 0);
                return variations.length > 0 ? total / variations.length : 0;
            };

            const calculateAverageValue = (metric, date) => {
                const values = keySegments.map(key => pickupTableOutput[key][metric].current[date] ?? 0);
                const total = values.reduce((acc, value) => acc + value, 0);
                return values.length > 0 ? total / values.length : 0;
            };

            this.tableItemsFromApi = range.map(date => ({
                date: date,
                rn: { 
                    value: keySegments.reduce((acc, key) => acc + (pickupTableOutput[key].RoomNights.current[date] ?? 0), 0),
                    variation: calculateAverageVariation('RoomNights', date)
                },
                occ: { 
                    value: calculateAverageValue('Occupancy', date),
                    variation: calculateAverageVariation('Occupancy', date)
                },
                revenue: { 
                    value: keySegments.reduce((acc, key) => acc + (pickupTableOutput[key].Revenue.current[date] ?? 0), 0),
                    variation: calculateAverageVariation('Revenue', date)
                },
                adr: { 
                    value: keySegments.reduce((acc, key) => acc + (pickupTableOutput[key].ADR.current[date] ?? 0), 0),
                    variation: calculateAverageVariation('ADR', date)
                },
                revPar: { 
                    value: keySegments.reduce((acc, key) => acc + (pickupTableOutput[key].RevPar.current[date] ?? 0), 0),
                    variation: calculateAverageVariation('RevPar', date)
                },
                segments: keySegments.map(key => createSegmentData(key, date))
            }));
            
        },
        setDefaultFilterDate() {
            const currentDate = new Date();
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 7);

            // Function to format a date as YYYY-MM-DD
            const formatDate = (date) => {
                return date.toISOString().substring(0, 10);
            };

            // yyyy-MM-dd
            const dateFormat = {
                startsAt: formatDate(currentDate),
                endsAt: formatDate(futureDate)
            };

            this.date = [dateFormat.startsAt, dateFormat.endsAt]; // Set the default date range to the current date and 7 days ahead
        },
        // Function to fetch the segmented chart data
        async fetchPerformanceData(queryParams) {
            try {
                // Set loading flags to true
                this.widgetStates.doughnutTotalPerformance.loading = true;
                this.widgetStates.barTotalPerformance.loading = true;
                // Reset errors
                this.widgetStates.doughnutTotalPerformance.error = null;
                this.widgetStates.barTotalPerformance.error = null;

                // Fetch performance data and update chart when ready
                await this.metricsStore.fetchTotalPerformance(queryParams);
                const outputData = this.metricsStore.totalPerformanceOutput ?? {};
                // Initialize doughnutObject with empty arrays for labels and datasets.data
                // doughnut chart
                const doughnutObject = {
                    labels: [],
                    datasets: {
                        label: '',
                        data: []
                    }
                };

                // bar chart
                const barObject = {
                    labels: [],
                    datasets: [
                        {
                            label: this.$t("Current Period"),
                            data: []
                        },
                        {
                            label: this.$t("Same Period Last Year"),
                            data: []
                        }
                    ]
                };

                // Check if outputData has datasets and if the first dataset contains data
                if (outputData.datasets && outputData.datasets.length > 0) {
                    // donut chart
                    const dataValues = outputData.datasets[0].data;
                    // Set the label for the dataset
                    doughnutObject.datasets.label = outputData.datasets[0].label;
                    // Loop through dataValues and filter out zero values while adding corresponding labels
                    dataValues.forEach((value, index) => {
                        if (value !== 0) { // Retain both positive and negative values, filter out zeros
                            doughnutObject.labels.push(outputData.labels[index]); // Add the corresponding label
                            doughnutObject.datasets.data.push(value); // Add the non-zero value to datasets.data
                        }
                    });

                    // bar chart
                    outputData.labels.forEach((label, index) => {
                        const currentPeriodData = outputData.datasets[0].data[index];
                        const sameTimeLastYearData = outputData.datasets[1].data[index];
                        
                        if (currentPeriodData !== 0 || sameTimeLastYearData !== 0) {
                            barObject.labels.push(label);
                            barObject.datasets[0].data.push(currentPeriodData);
                            barObject.datasets[1].data.push(sameTimeLastYearData);
                        }
                    });
                }

                if (Object.keys(doughnutObject).length !== 0) {
                    // Setup total performance chart only when data is available
                    this.setupChart(
                        doughnutObject,
                        'totalPerformanceDoughnut',
                        ChartSetup.getSegmentsChartData,
                        {
                            // default background colors for the labels
                            // if there are more labels, it will generate random colors for the rest of the labels
                            datasetsOptions: [
                                { backgroundColor: '#A81800'}, // label 1
                                { backgroundColor: '#B74431'}, // label 2
                                { backgroundColor: '#C77062'}, // label 3
                                { backgroundColor: '#D69D93'}, // label 4
                                { backgroundColor: '#E6C9C4'}, // label 5
                                { backgroundColor: '#EEDFDD'} // label 6
                            ]
                        }
                    );
                }

                if(Object.keys(barObject).length !== 0) {
                    this.setupChart(
                        barObject,
                        'totalPerformanceBar',
                        ChartSetup.getTotalPerformanceChartData,
                        { 
                            indexAxis: 'x',
                            barThickness: 40,
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    border: {
                                        display: false,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        padding: 30,
                                        callback: function (value, index) {
                                            const label = this.getLabelForValue(index);
                                            return label.length > 10 ? label.substring(0, 10) + '...' : label;
                                        },
                                    },
                                },
                                y: {
                                    border: {
                                        dash: [5, 5],
                                        dashOffset: 5,
                                        display: false,
                                    },
                                    grid: {
                                        display: true,
                                    },
                                    ticks: {
                                        maxTicksLimit: 4,
                                        padding: 30,
                                        callback: (value) => {
                                            if (this.metric === 'Occupancy') {
                                                return this.$n(value, 'percent');
                                            } else if (this.metric === 'RoomNights') {
                                                return this.$n(value, 'integer');
                                            } else {
                                                return this.$n(value, 'currency');
                                            }
                                        },
                                    },
                                },
                            },

                            datasetsOptions: [
                                { backgroundColor: '#A81800' }, 
                                { backgroundColor: '#C77062' }
                            ] 
                        }
                    );
                }
            } catch (e) {
                this.widgetStates.doughnutTotalPerformance.error = e;
                this.widgetStates.barTotalPerformance.error = e;
                console.error('Unexpected error:', e);
            } finally {
                // Set loading flags to false
                this.widgetStates.doughnutTotalPerformance.loading = false;
                this.widgetStates.barTotalPerformance.loading = false;
            }
        },
        fetchBookingwidthAndLengthOfStay() {
            this.widgetStates.bookingWindow.loading = true;
            this.widgetStates.lengthOfStay.loading = true;

            Promise.all([
                // Fetch booking window data
                this.metricsStore.fetchBookingWindow(this.queryParams)
                .then(() => {
                    this.setupChart(
                        this.metricsStore.bookingWindowOutput,
                        'bookingWindow',
                        ChartSetup.getBookingWindowChartData,
                        { datasetsOptions: [{ pointBorderColor: "#A81800", pointBackgroundColor: "#A81800", borderColor: "#A81800" }, { borderDash: [5, 5], borderColor: "#E6C9C4" }] }
                    )
                })
                .catch( (error) => {
                    this.widgetStates.bookingWindow.error = error;
                    console.log(error);
                })
                .finally(() => {
                    this.widgetStates.bookingWindow.loading = false;
                }),
                // Fetch length of stay data
                this.metricsStore.fetchLengthOfStay(this.queryParams)
                .then(() => {
                    this.setupChart(
                        this.metricsStore.lengthOfStayOutput,
                        'lengthOfStay',
                        ChartSetup.getLengthOfStayChartData,
                        { datasetsOptions: [{ backgroundColor: '#A81800' }, { backgroundColor: '#C77062' }] }
                    )
                })
                .catch( (error) => {
                    this.widgetStates.lengthOfStay.error = error;
                })
                .finally(() => {
                    this.widgetStates.lengthOfStay.loading = false;
                })
            ])       
        },
        setupChart(outputData, keyName, setupFunction, customOptions = {}) {
            if (outputData.labels && outputData.datasets) {
                let { labels = [], datasets = [] } = outputData;

                customOptions.metric = this.metric;

                // Predefined colors for the labels
                const predefinedColors = customOptions.datasetsOptions || [];
                const datasetsOptions = [];

                labels.forEach((label, index) => {
                    // Check if there is a predefined color for the current label
                    if (predefinedColors[index]) {
                        datasetsOptions.push(predefinedColors[index]);
                    } else {
                        // Generate a random color if there is no predefined color
                        datasetsOptions.push({ backgroundColor: this.generateRandomColor() });
                    }
                });

                // Update customOptions with the generated/defined colors
                customOptions = {
                    ...customOptions,
                    datasetsOptions
                };

                // Generate the chart data
                this[`${keyName}Chart`] = setupFunction(labels, datasets, customOptions);

                const totalLabels = labels.length;
                const minimumLabelThreshold = 10;

                if (totalLabels > minimumLabelThreshold) {
                    const threshold = Math.floor(totalLabels * 0.5);

                    if (totalLabels > threshold) {
                        if (this[`${keyName}Chart`].chartOptions.indexAxis === 'y') {
                            const baseHeight = totalLabels * 33; // Adjust height
                            const dynamicHeight = baseHeight + ((totalLabels - threshold) * 50);
                            this[`${keyName}Chart`].chartOptions.height = `${dynamicHeight}px`;
                        } else {
                            const baseWidth = totalLabels * 50; // Adjust width
                            const dynamicWidth = baseWidth + ((totalLabels - threshold) * 50);
                            this[`${keyName}Chart`].chartOptions.width = `${dynamicWidth}px`;
                        }
                    }
                }

                // Set the variation value
                this[`${keyName}Chart`].variation = outputData.variation;

            } else {
                this[`${keyName}Chart`] = setupFunction([], [], customOptions);
            }
        },
        toggleDropdown(dropdownName) {
            this.dropdownStates[dropdownName] = !this.dropdownStates[dropdownName];
        },
        setDefaultDropdownSelections() {
            this.setDefaultFilterDate(); // Set the default date range
            // Set default selection for 'hotel' dropdown
            if (this.hotelOptions?.body.length > 0) {
                this.selectedItems.hotel = this.hotelOptions.body[0];
            }
        },
        getLabel(key) {
            const item = this.selectedItems[key];
            return item && item.label ? item.label : this.$t(key);
        },
        generateRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        resetFilter(selectedItem) {
            this.selectedItems[selectedItem] = {};
            if(this.customRange) this.customRange = null; // Reset custom range if it's set
            this.setDefaultFilterDate(); // Set the default date range
        }
    },
    watch: {
        async 'selectedItems.hotel'(newVal, oldVal) {
            if(Object.keys(oldVal).length === 0) return;
            if(newVal) {
                if(this.metric !== 'All') {
                    await this.fetchPerformanceData(this.queryParams); // Fetch the segmented charts data
                } else {
                    this.fetchBookingwidthAndLengthOfStay(); // Fetch the segmented charts data
                    this.fetchTotalTable();
                }
            }
        },
        async 'selectedItems.week'(newVal) {
            if (newVal) {
                if(Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty (when week filter is reset)
                // console.log("WEEK", newVal);
                // reset all other filters if we select a new week
                if(Object.keys(this.selectedItems.dayOfWeek).length > 0) {
                    this.selectedItems.dayOfWeek = {};
                }
                if(Object.keys(this.selectedItems.range).length > 0) {
                    this.selectedItems.range = {};
                }
                const weekNumber = newVal.id; // Get the week number from the selected item
                const dates = this.date; // Get the current dates

                // Use Day.js to parse the date directly
                const currentDate = dayjs(dates[0]); // Convert the date string to a Day.js object
                const currentYear = currentDate.year(); // Get the year directly from the Day.js object

                // Calculate the start and end dates of the week
                const startOfWeek = dayjs().year(currentYear).isoWeek(weekNumber).startOf('isoWeek');
                const endOfWeek = dayjs().year(currentYear).isoWeek(weekNumber).endOf('isoWeek');

                // Format the dates as YYYY-MM-DD
                this.date = [startOfWeek.format('YYYY-MM-DD'), endOfWeek.format('YYYY-MM-DD')];
                this.isDatePickerChanging = true; // Set the flag to prevent resetting filters
            }
        },
        async 'selectedItems.dayOfWeek'(newVal) {
            if (newVal) {
                if (Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty

                // console.log("DAY OF WEEK", newVal);

                // Reset range filter if a day of the week is selected
                if (Object.keys(this.selectedItems.range).length > 0) {
                    this.selectedItems.range = {};
                }

                this.queryParams.dayofweek = newVal.id; // Set the day of week in the query params
                if(this.metric !== 'All') {
                    await this.fetchPerformanceData(this.queryParams); // Fetch the segmented charts data
                } else {
                    this.fetchBookingwidthAndLengthOfStay();
                    this.fetchTotalTable();
                }
            }
        },
        async 'selectedItems.range'(newVal) {
            if (newVal) {
                if(Object.keys(newVal).length === 0) return; // Do nothing if the selected item is empty (when range filter is reset)
                // console.log("RANGE", newVal);
                // reset all ohter filters if range is selected
                if(Object.keys(this.selectedItems.dayOfWeek).length > 0) {
                    this.selectedItems.dayOfWeek = {};
                }
                if(Object.keys(this.selectedItems.week).length > 0) {
                    this.selectedItems.week = {};
                }
                // set the date range based on the selected range
                const today = new Date();
                const formatDate = (date) => date.toISOString().split('T')[0];
                switch (newVal.keyName) {
                    case 'yesterday':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 1))), formatDate(new Date())];
                        break;
                    case 'last2days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 2))), formatDate(new Date())];
                        break;
                    case 'last3days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 3))), formatDate(new Date())];
                        break;
                    case 'last4days':
                        this.date = [formatDate(new Date(today.setDate(today.getDate() - 4))), formatDate(new Date())];
                        break;
                }
                this.isDatePickerChanging = true; // Set the flag to prevent resetting filters
            }
        },
        async 'date'(newVal, oldVal) {
            if(!oldVal) return;
            if(newVal) {
                // Only proceed if the date change is not triggered by the date picker
                if (!this.isDatePickerChanging) {
                    // Reset filters if range or week is active
                    if (Object.keys(this.selectedItems.range).length > 0) {
                        this.selectedItems.range = {}; // Reset range filter
                    }
                    if (Object.keys(this.selectedItems.dayOfWeek).length > 0) {
                        this.selectedItems.dayOfWeek = {}; // Reset day of week filter
                    }
                    if (Object.keys(this.selectedItems.week).length > 0) {
                        this.selectedItems.week = {}; // Reset week filter
                    }
                } else {
                    // Reset the flag after processing the date change
                    this.isDatePickerChanging = false;
                }
                if(this.metric !== 'All') {
                    await this.fetchPerformanceData(this.queryParams); // Fetch the segmented charts data
                } else {
                    this.fetchBookingwidthAndLengthOfStay(); // Fetch the segmented charts data
                    this.fetchTotalTable();
                }
            }
        },
        async metric(newVal) {
            if(newVal !== 'All') {
                await this.fetchPerformanceData(this.queryParams); // Fetch the segmented charts data
            } else {
                this.fetchBookingwidthAndLengthOfStay(); // Fetch the segmented charts data
                this.fetchTotalTable();
            }
        },
    },
};
</script>

<style scoped>
.sidebar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transition: transform 0.3s ease;
}
.arrow-icon.open {
    transform: rotate(-180deg);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.page-title {
    color: var(--theme-secondary);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    word-break: break-all;
}
.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
.chart-wrapper {
    width: 100%;
    border-radius: 15px;
    height: calc(100vh - 20rem); 
    max-height: calc(100vh - 20rem);    
    background: #FFF;
    box-shadow: 0px 4px 30px 0px #00000014;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chart-wrapper::-webkit-scrollbar {
    display: none;
}
a {
    color: var(--theme-primary);
    font-size: .9rem;
}
@media (max-width: 991.98px){
    .dropdown,
    #date-button {
        width: 100%;
    }
}
@media (max-width: 767.98px){
    .chart-wrapper {
        max-height: 600px;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
}
</style>