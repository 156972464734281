<template>
    <section id="form">
        <template v-if="isMobile">
            <div @click="$router.push({name: 'CompanySettingsPage'})" class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/arrow-right-fill.svg')" alt="New hotel icon">
                <span class="page-title-mobile">{{ $t('New hotel') }} </span>
            </div>
        </template>
        <LayoutForm 
            class="mt-5 mt-lg-0"
            :pageLayout="{
                title: $t('New hotel'),
                icon: require('@/assets/Company/pencil.svg'),
                firstAction: { icon: require('@/assets/HotelGroup/x-circle.svg'), label: $t('cancel') },
                secondAction: { icon: require('@/assets/HotelGroup/saveBtn.svg'), label: $t('save'), loading: loadingForm },
            }"
            @firstActionClick="firstActionClick" 
            @secondActionClick="secondActionClick"
        >
            <template v-slot:dynamic-content>
                <div class="row p-0 p-md-3 border-bottom mb-3 mb-md-0">
                    <div class="file-picker">
                        <img 
                            v-if="Object.keys(formData.picture).length" 
                            class="cursor-pointer img-fluid rounded-circle" 
                            :src="formData.picture.url || parseImageData(formData?.picture)" 
                            @click="showimagePicker = true"
                        >                       
                        <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                            <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !formData.picture.url && imageError }">
                                {{ $t('Choose Image') }}
                                <pre class="m-0 text-muted">1:1</pre>
                            </span>   
                        </div>                      
                        <Form ref="myForm" class="form mb-3" :validation-schema="validationSchema" v-slot="{ errors }">
                            <div class="d-block d-md-flex justify-content-center w-100 gap-3 flex-wrap">
                                <div class="first-input">
                                    <label class="label" for="name">{{ $t('Name') }}</label>
                                    <Field
                                        v-model="formData.name"
                                        type="text"
                                        name="name"
                                        v-slot="{ field }"
                                        @input="validateField('name')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.name }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Name')"
                                        />
                                    </Field>  
                                    <ErrorMessage name="name" class="text-danger" />                            
                                </div>
                                <div class="second-input">
                                    <label class="label" for="location">{{ $t('Location') }}</label>
                                    <Field
                                        v-model="formData.location"
                                        name="location"
                                        type="text"
                                        v-slot="{ field }"
                                        @input="validateField('location')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.location }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Hotel Location')"
                                        />
                                    </Field>
                                    <ErrorMessage name="location" class="text-danger" />                            
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div class="row p-0 p-md-3 border-bottom">
                    <TableComponent 
                        v-model="addHotelGroups" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyHotelsRows" 
                        :clickableRows="false"
                    >
                        <template v-slot:title>
                            <div class="row">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img src="@/assets/Company/hotel-groups.svg" alt="">
                                        <span class="table-title">{{ $t('Add An Hotel Group') }}</span>
                                    </div>
                                </div>   
                                <div v-if="this.hotelGroupListFromApi && this.hotelGroupListFromApi.length && !isMobile" class="col d-flex justify-content-end">
                                    <CustomButton 
                                        :leftIcon="require('@/assets/HotelGroup/addIcon.svg')"
                                        :label="$t('Add')" 
                                        @clickBtn="openModal('hotelGroup')" 
                                        :buttonStyle="{
                                            borderRadius: '8px!important',
                                            gap: '8px',
                                            color: '#FFFFFF',
                                            padding: '6px 12px'
                                        }" 
                                    />
                                </div>
                            </div>
                            <div v-if="tableValidations.requiredHotelGroup === true" class="row p-3">
                                <div class="col">
                                    <span class="text-danger">{{ $t('You must add a hotel group') }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:actions="{ item, rowIndex, actionType }">
                            <div v-if="actionType === 'delete'">
                                <i @click.stop="handleDeleteHotelGroup(item, rowIndex)" class="bi bi-dash-circle-fill cursor-pointer" style="font-size: 20px; color:#6C757D"></i>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <CustomButton 
                            :leftIcon="require('@/assets/HotelGroup/addIcon.svg')"
                            :label="$t('Add')" 
                            @clickBtn="openModal('hotelGroup')" 
                            :buttonStyle="{
                                width: '100%',
                                borderRadius: '8px!important',
                                gap: '8px',
                                color: '#FFFFFF',
                                padding: '6px 12px'
                            }" 
                        />
                    </div>
                </div>
            </template>
        </LayoutForm>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false" 
            :titlePopup="$t('Change Company Picture')"
            :descriptionPopup="$t('Here you can change the photo of your company to make it more easily recognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="selectedPicture"
                        :placeholder="require('@/assets/Company/placeholder-2.png')" 
                        :ratioWidth="1"
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': !Object.keys(selectedPicture).length }"
                        :label="$t('Change Picture')" 
                        buttonType="theme-light"
                        :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
                        @clickBtn="onChooseImageClicked" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- cancel Hotel -->
        <CustomPopup 
          v-show="showCancelChangesPopup" 
          @popUpClosed="showCancelChangesPopup = false"
          :img="require('@/assets/Popup/cancelSave.svg')"
          :descriptionPopup="$t('Are you sure you want to cancel it? All changes will be deleted.')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('No')" 
                        @clickBtn="showCancelChangesPopup = false"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }"
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')" 
                        @clickBtn="cancelChanges"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                  />
                  
              </div>
          </template>
        </CustomPopup> 
        <!-- save Hotel -->
        <CustomPopup 
          v-show="showSaveChangesPopup" 
          @popUpClosed="showSaveChangesPopup = false"
          :img="require('@/assets/Popup/successSave.svg')"
          :descriptionPopup="'Your Hotel has been saved successfully!'"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="'Go back'" 
                      @clickBtn="successAddHotel"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
        <!-- ModalAddItems -->
        <ModalAddItems 
            v-show="modalVisible" 
            :itemsList="currentItemsList"
            :disabled="modalType === 'hotelGroup' && addHotelGroups.length > 0"
            @popUpClosed="closeModal"
        >
            <template v-slot:notice v-if="modalType === 'hotelGroup'">
                <div class="alert alert-warning alert-dismissible fade show mt-4" role="alert">
                    <div class="d-flex align-items-center">
                        <div style="font-size: .9rem">
                            <strong>{{ $t('Notice: ') + ' ' }}</strong>
                            <span>{{ $t('You can only associate one hotel group at this moment.') }}</span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actions="{item}">
                <img
                    class="add-icon cursor-pointer"
                    src="@/assets/ControlerAssets/addCompany.svg"
                    alt="Add"
                    @click="handleAddItem(item)"
                >
            </template>
        </ModalAddItems>
    </section>
</template>

<script>
import CustomPopup from '@/components/popups/CustomPopup.vue';
import ModalAddItems from '@/components/popups/ModalAddItems.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import LayoutForm from '@/components/LayoutForm.vue';
import TableComponent from '@/components/TableComponent.vue';
import { useHotelGroupStore, useAlertStore, useHotelStore } from "@/stores";
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useResponsive } from '@/composables/responsive.js';

    export default {
        components:{
            CustomPopup,
            ModalAddItems,
            CustomButton,
            ImagePicker,
            LayoutForm,
            TableComponent,
            Form,
            Field,
            ErrorMessage
        },
        setup() {
            const hotelGroupStore = useHotelGroupStore();
            const hotelStore = useHotelStore();
            const alertStore = useAlertStore();
            const { isMobile } = useResponsive();

            return {
                hotelGroupStore,
                alertStore,
                hotelStore,
                isMobile
            }
        },
        data() {
            return {
                showSaveChangesPopup: false,
                showCancelChangesPopup: false,
                showimagePicker: false,
                storeIndex: {
                    hotelGroup: null
                },
                modalVisible: false,
                modalType: '',
                hotelGroupListFromApi: [],
                addHotelGroups: [],
                bodyHotelsRows: [
                    { type: 'ImageWithText', field: 'name'},
                    { type: 'SimpleText', field: 'hotelNumbers' },
                    { type: 'Action', field: 'delete', hasHeader: false }
                ],
                formData: {
                    name: '',
                    location: '',
                    picture: {}
                },
                loadingForm: false,
                imageError: false,
                selectedPicture: {},
                tableValidations: {
                    requiredHotelGroup: null
                },
            }
        },
        async mounted() {
            try {
                // get hotel groups from the API
                await this.hotelGroupStore.getList();
                this.hotelGroupListFromApi = this.hotelGroupStore.getListOutput;
                // redefine the list to only have the id, name and image properties for the popup
                this.hotelGroupListFromApi = this.hotelGroupListFromApi.map((item) => {
                    return {
                        id: item.objectId,
                        name: item.name,
                        hotels: item.hotels.length === 0 ? this.$t('0 hotels') : `${item.hotels.length} hotel${item.hotels.length > 1 ? 's' : ''}`,
                        image: item.picture ?? require('@/assets/no-image.jpg')
                    }
                });
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        computed: {
            currentItemsList() {
                return this.modalType === 'hotelGroup' ? this.hotelGroupListFromApi : [];
            },
            validationSchema() {
                return {
                    name: 'required',
                    location: 'required'
                }
            }
        },
        methods: {
            firstActionClick() {
                this.showCancelChangesPopup = true;
            },
            openModal(type) {
                this.modalType = type;
                this.modalVisible = true;
            },
            closeModal() {
                this.modalVisible = false;
                this.modalType = '';
            },
            handleAddItem(item) {
                if (this.modalType === 'hotelGroup') {
                    this.addHotelGroup(item);
                }
            },
            addHotelGroup(item) {
                // Prevent adding more than one hotel group
                if(!this.addHotelGroups.length) {
                    // Store the index of the item to be removed from the hotelGroupListFromApi
                    this.storeIndex.hotelGroup = this.hotelGroupListFromApi.findIndex(hotelItem => hotelItem.id === item.id);
                    this.addHotelGroups.push({
                        id: item.id,
                        name: {
                            image: item.image,
                            firstText: item.name
                        },
                        hotelNumbers: item.hotels
                    });
                    this.tableValidations.requiredHotelGroup = null;
                    // Remove the item from hotelGroupListFromApi (the popup)
                    this.hotelGroupListFromApi = this.hotelGroupListFromApi.filter(hotelItem => hotelItem.id !== item.id);
                }
            },
            successAddHotel() {
                this.showSaveChangesPopup = false;
                this.$router.push({ name: 'CompanySettingsPage' });
            },
            handleDeleteHotelGroup(item, index) {
                // Remove the item from addHotelGroups
                this.addHotelGroups.splice(index, 1);

                // // Reinsert the item into hotelGroupListFromApi at the previously stored index
                this.hotelGroupListFromApi.splice(this.storeIndex.hotelGroup, 0, {
                    id: item.id,
                    name: item.name.firstText,
                    hotels: item.hotelNumbers,
                    image: item.name.image
                });

                // Reset the storeIndex
                this.storeIndex.hotelGroup = null;
            },
            cancelChanges() {
                // Reset form data to original data
                this.formData = {
                    name: '',
                    location: '',
                    picture: {}
                };
                this.selectedPicture = this.formData.picture;  
                this.showCancelChangesPopup = false;
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 100);
            },
            async secondActionClick() {
                // submit form and manually validate
                // const { valid, errors } = await this.$refs.myForm.validate();
                const { valid } = await this.$refs.myForm.validate();
                if (this.addHotelGroups.length === 0 || !Object.keys(this.formData.picture).length) {
                    this.tableValidations.requiredHotelGroup = !this.addHotelGroups.length;
                    this.imageError = !Object.keys(this.formData.picture).length;
                    return;
                }
                if (valid) {
                    // console.log('Form is valid');
                    this.imageError = false;
                    this.tableValidations.hotelGroup = null;
                    this.loadingForm = true;

                    const pictureObject = typeof this.formData.picture === 'object' && this.formData.picture !== null
                    ? { ...this.formData.picture }
                    : null;

                    const payload = {
                        name: this.formData.name,
                        location: this.formData.location,
                        groupId: this.addHotelGroups[0].id,
                        picture: pictureObject ?? this.formData.picture,
                        budget: null
                    };

                    if (pictureObject) {
                        if(pictureObject.url) delete pictureObject.url;
                        payload.picture = JSON.stringify(pictureObject);
                    }

                    try {
                        // console.log('Payload:', payload);
                        await this.hotelStore.createHotel(payload);
                        this.originalData = JSON.parse(JSON.stringify(this.formData)); // update original data
                        this.showSaveChangesPopup = true;
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 100);
                    } catch (error) {
                        alert(this.$t('Something went wrong! Please try again.'));
                        this.alertStore.error(error);
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 100);
                    } finally {
                        this.loadingForm = false;
                    }
                } else {
                    this.loadingForm = false;
                    // console.log('Form has errors:', errors);
                }
            },
            closeSavePopup() {
                this.showSaveChangesPopup = false;
            },
            rowClicked(obj, route) {
                if(route === 'CompanyUserEdit') {
                    this.$router.push({ name: route, params: {userId: obj.id} });
                }
            },
            onChooseImageClicked() {
                this.formData.picture = this.selectedPicture;
                this.showimagePicker = false;
            },
            validateField(fieldName) {
                this.$refs.myForm.validate(fieldName); // Validate field when user types
            },
            // parse the image data from JSON string
            parseImageData(imageDataString) {
                try {
                    if(!Object.keys(imageDataString).length) return null;
                    else {
                        const parsedData = JSON.parse(imageDataString);
                        return `data:${parsedData.mime};base64,${parsedData.data}`;
                    }
                } catch (e) {
                    console.error('Invalid JSON string:', e);
                    return null;
                }
            }
        },
    }
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transform: rotate(-180deg);
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.first-input .label,
.second-input .label {
    color: var(--theme-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
}
.table-title {
    color: var(--theme-dark);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}
.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.file-picker img,
.file-picker .placeholder-img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}
.text-danger,
input::placeholder,
.first-input .label,
.second-input .label {
    font-size: .8rem;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    color: var(--theme-secondary);
}
.first-input .form-control,
.second-input .form-control {
    width: 290px;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
@media (max-width: 767px) {
    .first-input .form-control,
    .second-input .form-control,
    .form {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
    .file-picker {
        margin-top: 1rem;
    }
}
</style>