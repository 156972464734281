<template>
    <section id="form">
        <template v-if="isMobile">
            <div @click="$router.push({name: 'CompanySettingsPage'})" class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/arrow-right-fill.svg')" alt="create hotel group icon">
                <span class="page-title-mobile">{{ $t('createHotelGroup') }} </span>
            </div>
        </template>
        <LayoutForm 
            class="mt-5 mt-lg-0"
            :pageLayout="{
                title: $t('createHotelGroup'),
                icon: require('@/assets/Company/pencil.svg'),
                firstAction: { icon: require('@/assets/HotelGroup/x-circle.svg'), label: $t('cancel') },
                secondAction: { icon: require('@/assets/HotelGroup/saveBtn.svg'), label: $t('save'), loading: loadingForm },

            }"
            @firstActionClick="firstActionClick" 
            @secondActionClick="secondActionClick"
        >
            <template v-slot:dynamic-content>
                <div class="row p-0 p-md-3">
                    <div class="file-picker">
                        <img 
                            v-if="Object.keys(formData.picture).length" 
                            class="cursor-pointer img-fluid rounded-circle" 
                            :src="formData.picture.url || parseImageData(formData?.picture)" 
                            @click="showimagePicker = true"
                        >                       
                        <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                            <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !formData.picture.url && imageError }">
                                {{ $t('Choose Image') }}
                                <pre class="m-0 text-muted">1:1</pre>
                            </span>   
                        </div>
                        <Form ref="myForm" class="form" :validation-schema="validationSchema" v-slot="{ errors }">
                            <div class="first-input">
                                <label class="label" for="name">{{ $t('hotelGroupName') }}</label>
                                <Field
                                    v-model="formData.name"
                                    type="text"
                                    name="name"
                                    v-slot="{ field }"
                                    @input="validateField('name')"
                                >
                                    <input
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.name }"
                                        v-bind="field"
                                        :placeholder="$t('insertHotelGroupName')"
                                    />
                                </Field>  
                                <ErrorMessage name="name" class="text-danger" />                            
                            </div>
                        </Form> 
                    </div>
                </div>
            </template>
        </LayoutForm>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false" 
            :titlePopup="$t('addPicture')"
            :descriptionPopup="$t('hereYouCanAddThePhotoOfYourHotelGroupToMakeItMoreEasilyRecognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="selectedPicture"
                        :placeholder="require('@/assets/Company/placeholder-2.png')" 
                        :ratioWidth="1" 
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': !Object.keys(selectedPicture).length }"
                        :label="$t('addPicture')" 
                        buttonType="theme-light"
                        :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')" 
                        @clickBtn="onChooseImageClicked" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
         <!-- cancel Hotel Group -->
        <CustomPopup 
            v-show="showCancelChangesPopup" 
            @popUpClosed="showCancelChangesPopup = false"
            :img="require('@/assets/Popup/cancelSave.svg')"
            :descriptionPopup="$t('Are you sure you want to cancel it? All changes will be deleted.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('No')" 
                        @clickBtn="showCancelChangesPopup = false"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }"
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')" 
                        @clickBtn="showCancelChangesPopup = false; goRouter('CompanySettingsPage')"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                </div>
            </template>
        </CustomPopup> 
        <!-- save Hotel Group -->
        <CustomPopup 
            v-show="showSaveChangesPopup" 
            @popUpClosed="showSaveChangesPopup = false"
            :img="require('@/assets/Popup/successSave.svg')"
            :descriptionPopup="$t('yourHotelGroupHasHeenSavedSuccessfully!')"
        >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('close')" 
                        @clickBtn="successAddHotelGroup"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                </div>
            </template>
        </CustomPopup>
    </section>
</template>

<script>
import LayoutForm from '@/components/LayoutForm.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import { useHotelGroupStore, useAlertStore } from "@/stores";
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useResponsive } from '@/composables/responsive.js';

export default {
    components: {
        LayoutForm,
        CustomPopup,
        CustomButton,
        ImagePicker,
        Form,
        Field,
        ErrorMessage
    },
    setup() {
        const hotelGroupStore = useHotelGroupStore();
        const alertStore = useAlertStore();
        const { isMobile, isTablet, isDesktop} = useResponsive();

        return {
            hotelGroupStore,
            alertStore,
            isMobile,
            isTablet,
            isDesktop
        }
    },
    data() {
        return {
            showSaveChangesPopup: false,
            showCancelChangesPopup: false,
            showimagePicker: false,
            selectedPicture: {},
            formData: {
                name: '',
                picture: {}
            },
            imageError: false,
            loadingForm: false
        }
    },
    computed: {
        validationSchema() {
            return {
                name: 'required'
            }
        }
    },
    methods: {
        goRouter(route) {
            this.$router.push({ name: route });
        },
        // cancel hotel group
        firstActionClick() {
            this.showCancelChangesPopup = true;
        },
        // create new hotel group
        async secondActionClick() {
            const { valid } = await this.$refs.myForm.validate();
            if (!Object.keys(this.formData.picture).length) {
                this.imageError = true;
                return;
            }
            if(valid) {
                this.imageError = false;
                this.loadingForm = true;

                const pictureObject = typeof this.formData.picture === 'object' && this.formData.picture !== null
                    ? { ...this.formData.picture }
                    : null;

                const payload = {
                    name: this.formData.name,
                    picture: pictureObject ?? this.formData.picture
                };
                
                if (pictureObject) {
                    if(pictureObject.url) delete pictureObject.url;
                    payload.picture = JSON.stringify(pictureObject);
                }

                try {
                    // console.log('payload', payload);
                    await this.hotelGroupStore.createHotelGroup(payload);
                    this.showSaveChangesPopup = true;
                } catch (error) {
                    alert(this.$t('Something went wrong! Please try again.'));
                    this.alertStore.error(error);
                } finally {
                    this.loadingForm = false;
                }
            } else {
                this.loadingForm = false;
                // console.log('Form has errors:', errors);
            }
        },
        onChooseImageClicked() {
            this.formData.picture = this.selectedPicture;
            this.showimagePicker = false;
        },
        successAddHotelGroup() {
            this.showSaveChangesPopup = false;
            this.$router.push({ name: 'CompanySettingsPage' });
        },
        validateField(fieldName) {
            this.$refs.myForm.validate(fieldName); // Validate field when user types
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    }
}
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transform: rotate(-180deg);
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.file-picker img,
.file-picker .placeholder-img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}
.text-danger,
input::placeholder,
.first-input .label {
    font-size: .8rem;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
    color: var(--theme-secondary);
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
@media (max-width: 767px) {
    .first-input .form-control,
    .form {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .file-picker {
        margin-top: 1rem;
    }
}
</style>