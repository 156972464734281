// validationRules.js
import { defineRule } from 'vee-validate';
import i18n from "./i18n";

defineRule('required', value => {
  if (!value || !value.length) {
    return i18n.global.t('This field is required');
  }

  return true;
});

defineRule('email', value => {
  if (!value || !value.length) {
      return true;
  }

  if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/i.test(value)) {
      return i18n.global.t('This field must be a valid email');
  }

  return true;
});
