import { HOTELGROUP_SERVICE } from "@/services/hotelGroup.service.js";
import { defineStore } from 'pinia'

export const useHotelGroupStore = defineStore('hotelGroup', {
    state: () => ({
        getListOutput: [],
        getHotelGroupOutput: [],
    }),
    actions: {
        // get list of hotel groups
        async getList() {
            this.getListOutput = await HOTELGROUP_SERVICE.getList();
        },
        // get hotel group data
        async getHotelGroup(slug) {
            this.getHotelGroupOutput = await HOTELGROUP_SERVICE.getHotelGroup(slug);
        },
        // create a new hotel group
        async createHotelGroup(payload) {
            await HOTELGROUP_SERVICE.createHotelGroup(payload);
        },
        // edit a hotel group
        async editHotelGroup(id, payload) {
            await HOTELGROUP_SERVICE.editHotelGroup(id, payload);
        },
        // delete a hotel group
        async deleteHotelGroup(id) {
            await HOTELGROUP_SERVICE.deleteHotelGroup(id);
        },
    }
});