<template>
    <section
        v-if="Object.keys(meteoStore.currentMeteoOutput).length != 0 && Object.keys(meteoStore.getAirPolutionOutput).length != 0 && this.selectedLocation != null && this.currentTime != null"
        id="meteo">
        <div class="row m-auto align-items-center p-3">
            <div class="col-12 col-lg-6">
                <div class="d-flex align-items-center gap-2 mb-3">
                    <img class="cursor-pointer"
                        @click="lastPath !== null ? $router.back() : $router.push({ name: 'DashboardPage' })"
                        src="@/assets/HotelGroup/backBtn.svg" alt="icon" />
                    <span class="page-title">{{ $t('meteorology') }}</span>
                </div>
                <span class="meteo-intro">{{ $t('meteoWelcome', { name: user }) }}</span>
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group mt-3 mb-lg-0">
                    <input type="text" class="form-control search-input ms-lg-auto" :placeholder="$t('searchLocation')"
                        v-model="searchQuery" @keyup.enter="handleSearch" />
                </div>
            </div>
        </div>
        <div class="row m-auto p-3">
            <div class="col-12 col-lg-6">
                <div class="current-meteo d-flex flex-column gap-3 h-100">
                    <span class="page-sub-title">{{ $t('currentMeteorology') }}</span>
                    <div class="current-meteo-box">
                        <div class="d-flex justify-content-between p-4">
                            <div class="select">
                                <div id="select-button" @click.stop="toggleOptions">
                                    <div id="selected-value">{{ selectedLocation ? selectedLocation :
                                        $t('selectDistrictOrIsland') }}</div>
                                    <input type="checkbox" id="options-view-button">
                                    <div id="arrows">
                                        <img v-if="!optionsViewChecked" id="down" src="@/assets/meteo/arrow.svg" alt="">
                                        <img v-else id="up" src="@/assets/meteo/arrow.svg" alt="">
                                    </div>
                                </div>
                                <ul ref="options" class="p-0" id="options" v-show="optionsViewChecked">
                                    <li v-for="(location, index) in locations" :key="index" class="option"
                                        @click="getWeatherLocation(location)">
                                        <input type="radio" name="location" :checked="selectedLocation === location">
                                        <span class="label">{{ location }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="d-flex align-items-center gap-2 time">
                                <img src="@/assets/meteo/clock.svg" alt="">
                                <span class="label">{{ currentTime }}</span>
                            </div>
                        </div>
                        <DividerComponent />
                        <div class="info">
                            <div class="d-flex justify-content-between align-items-center p-2 p-sm-4">
                                <img class="img-fluid"
                                    :src="currentWeatherIcon(meteoStore.currentMeteoOutput.current.weather[0].icon)">
                                <div class="d-flex flex-column">
                                    <span class="title"> {{
                                        getCurrentTemperature(meteoStore.currentMeteoOutput.current['temp'])
                                    }}</span>
                                    <span class="sub-title"> {{ getCurrentWather }}</span>
                                </div>
                            </div>
                            <div class="d-flex status flex-wrap p-2 p-sm-4">
                                <div class="col">
                                    <span class="label">{{ $t('airQuality') }}</span>
                                </div>
                                <div class="col text-center">
                                    <span class="label">{{ $t('wind') }}</span>
                                </div>
                                <div class="col text-end text-sm-center">
                                    <span class="label">{{ $t('humidity') }}</span>
                                </div>
                                <div class="d-none d-sm-flex col justify-content-end">
                                    <span class="label">{{ $t('t.sensation') }}</span>
                                </div>
                                <div class="col-12">
                                    <DividerComponent />
                                </div>
                                <div class="col">
                                    <span class="label fw-bold">
                                        {{ airQuality }}
                                    </span>
                                </div>
                                <div class="col text-center">
                                    <span class="label fw-bold">
                                        {{ formattedWindSpeed }}
                                    </span>
                                </div>
                                <div class="col text-end text-sm-center">
                                    <span class="label fw-bold">
                                        {{ formattedHumidity }}
                                    </span>
                                </div>
                                <div class="d-none d-sm-flex col justify-content-end">
                                    <span class="label fw-bold">
                                        {{ formattedFeelsLike }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex status flex-wrap p-2 p-sm-4">
                                <div class="col-4">
                                    <span class="label">{{ $t('visibility') }}</span>
                                </div>
                                <div class="col-4 text-center">
                                    <span class="label">{{ $t('uvi') }}</span>
                                </div>
                                <div class="col-4 text-end">
                                    <span class="label">{{ $t('pressure') }}</span>
                                </div>
                                <div class="col-12">
                                    <DividerComponent />
                                </div>
                                <div class="col-4">
                                    <span class="label fw-bold">
                                        {{ formattedVisibility }}
                                    </span>
                                </div>
                                <div class="col-4 text-center">
                                    <span class="label fw-bold">
                                        {{ meteoStore.currentMeteoOutput.current['uvi'] }}
                                    </span>
                                </div>
                                <div class="col-4 text-end">
                                    <span class="label fw-bold">
                                        {{ formattedPressure }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex d-sm-none status flex-wrap p-2 p-sm-4">
                                <div class="col text-start">
                                    <span class="label">{{ $t('t.sensation') }}</span>
                                </div>
                                <div class="col-12">
                                    <DividerComponent />
                                </div>
                                <div class="col text-start">
                                    <span class="label fw-bold">
                                        {{ formattedFeelsLike }}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="forecast d-flex flex-column gap-3 h-100 mt-3 mt-lg-0">
                    <span class="page-sub-title">{{ $t('forecastForTheNextDays:') }}</span>
                    <div class="row" style="gap: 1.5rem 0">
                        <div v-for="day in forecastDays" :key="day" class="col col-sm-4 col-lg-6 col-xxl-4">
                            <div class="box">
                                <div class="d-flex p-3 gap-2">
                                    <img class="img-fluid" src="@/assets/meteo/temp.svg" alt="">
                                    <span class="label">{{ day.dayOfWeek }}</span>
                                </div>
                                <DividerComponent />
                                <div class="d-flex justify-content-center gap-2 align-items-center">
                                    <img :src="currentWeatherIcon(day.weather[0].icon)" alt="">
                                    <div class="d-flex flex-column gap-2">
                                        <span class="temp-max">{{ getCurrentTemperature(day.temp['max']) }}</span>
                                        <span class="temp-min">{{ getCurrentTemperature(day.temp['min']) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import locations from '@/helpers/locations.json';
import { useMeteoStore } from '@/stores';
import moment from 'moment';
import DividerComponent from '@/components/DividerComponent.vue';

export default {
    components: {
        DividerComponent,
    },
    setup() {
        const meteoStore = useMeteoStore();

        return {
            meteoStore
        }
    },
    data() {
        return {
            searchQuery: '',
            lastPath: this.$router.options.history.state.back,
            user: 'User test',
            locations: locations,
            currentTime: null,
            selectedLocation: null,
            optionsViewChecked: false
        }
    },
    async mounted() {
        document.addEventListener('click', this.closeOptionsView);
        if (JSON.parse(localStorage.getItem('currentMeteo'))) {
            let meteoStorage = JSON.parse(localStorage.getItem('currentMeteo')) ?? {};
            await this.meteoStore.reverseGeocoding(meteoStorage.lat, meteoStorage.lon);
            this.selectedLocation = this.meteoStore.getLocationOutput[0].name;
            if (Object.keys(this.meteoStore.currentMeteoOutput).length) this.processForecastArray();
        }
        setInterval(() => {
            this.currentTime = moment().format('HH:mm');
        }, 1000);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.closeOptionsView);
    },
    computed: {
        getCurrentWather() {
            return this.meteoStore.currentMeteoOutput && this.meteoStore.currentMeteoOutput.current && this.meteoStore.currentMeteoOutput.current.weather ? this.meteoStore.currentMeteoOutput.current.weather[0].main : null;
        },
        formattedWindSpeed() {
            return this.convertMsToKm(this.meteoStore.currentMeteoOutput.current['wind_speed']);
        },
        formattedHumidity() {
            return `${this.meteoStore.currentMeteoOutput.current['humidity']}%`;
        },
        formattedFeelsLike() {
            return `${this.meteoStore.currentMeteoOutput.current['feels_like']}°C`;
        },
        formattedVisibility() {
            return this.convertMetersToKilometers(this.meteoStore.currentMeteoOutput.current['visibility']);
        },
        formattedPressure() {
            return `${this.meteoStore.currentMeteoOutput.current['pressure']} mb`;
        },
        airQuality() {
            return this.meteoStore.getAirPolutionOutput.list && this.meteoStore.getAirPolutionOutput.list.length > 0 ? this.mapAqiToLabel(this.meteoStore.getAirPolutionOutput.list[0].main['aqi']) : null;
        },
        forecastDays() {
            return this.meteoStore && this.meteoStore.currentMeteoOutput && this.meteoStore.currentMeteoOutput.daily && this.meteoStore.currentMeteoOutput.daily.length ? this.meteoStore.currentMeteoOutput.daily.slice(1) : [];
        }
    },
    methods: {
        toggleOptions() {
            this.optionsViewChecked = !this.optionsViewChecked;
        },
        closeOptionsView(event) {
            const optionsElement = this.$refs.options;
            if (optionsElement && !optionsElement.contains(event.target)) {
                this.optionsViewChecked = false;
            }
        },
        async getWeatherLocation(location) {
            this.selectedLocation = location;
            this.optionsViewChecked = false;
            await this.meteoStore.getCoordinates(location);
            let lat = this.meteoStore.getCoordinatesOutput[0].lat;
            let lon = this.meteoStore.getCoordinatesOutput[0].lon;
            await this.meteoStore.getList(lat, lon);
            await this.meteoStore.getAirPollution(lat, lon);
        },
        handleSearch() {
            const search = this.searchQuery.toLowerCase();
            if (search.length > 0) {
                const foundLocation = this.locations.find(location => location.toLowerCase() === search);

                if (foundLocation) {
                    this.selectedLocation = foundLocation;
                    this.getWeatherLocation(this.selectedLocation);
                } else {
                    alert(this.$t('weatherNotAvailable', { name: search }))
                }
            }
        },
        currentWeatherIcon(icon) {
            return "http://openweathermap.org/img/wn/" + icon + "@2x.png";
        },
        getCurrentTemperature(temp) {
            return `${Math.round(temp)}°C`;
        },
        convertMsToKm(windSpeedInMetersPerSecond) {
            const kmPerHour = parseFloat((windSpeedInMetersPerSecond * 3.6).toFixed(1));
            return `${kmPerHour} km/h`;
        },
        convertMetersToKilometers(meters) {
            return `${meters / 1000} km`;
        },
        mapAqiToLabel(aqiValue) {
            const airQualityMap = {
                1: this.$t('good'),
                2: this.$t('fair'),
                3: this.$t('Moderate'),
                4: this.$t('poor'),
                5: this.$t('Very Poor')
            };

            return airQualityMap[aqiValue];
        },
        processForecastArray() {
            const daysOfWeek = [this.$t('sunday'), this.$t('monday'), this.$t('tuesday'), this.$t('wednesday'), this.$t('thursday'), this.$t('friday'), this.$t('saturday')];
            const currentDate = new Date();
            this.forecastDays.forEach((forecast) => {
                if ('dt' in forecast) {
                    // Convert Unix timestamp to date
                    const forecastDate = new Date(forecast.dt * 1000);
                    const isTomorrow = forecastDate.getDate() === currentDate.getDate() + 1;
                    forecast.dayOfWeek = isTomorrow ? this.$t('tomorrow') : daysOfWeek[forecastDate.getDay()];
                }
            });
        },
    },
    watch: {
        'meteoStore.currentMeteoOutput': {
            async handler(val) {
                if (val) {
                    if (this.selectedLocation == null) {
                        await this.meteoStore.reverseGeocoding(val.lat, val.lon);
                        this.selectedLocation = this.meteoStore.getLocationOutput[0].name;
                    }
                    this.processForecastArray();
                }
            },
        }
    },
}
</script>

<style scoped>
.page-title {
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.page-sub-title {
    color: var(--theme-dark, #212529);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.form-control.search-input {
    padding-left: 40px;
    background-image: url('@/assets/HotelGroup/searchBtn.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 10px center;
}

.meteo-intro {
    color: var(--theme-secondary, #6C757D);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
}

.current-meteo-box {
    max-width: 500px;
    height: 100%;
    border-radius: 10px;
    background-color: #A81800;

}

.time .label {
    color: var(--gray-white, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

:deep(.divider) {
    color: var(--theme-border, #DEE2E6);
    opacity: 1;
}

.info .title {
    color: var(--gray-white, #FFF);
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.info .sub-title {
    color: var(--theme-light, #F8F9FA);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.status .label {
    color: var(--gray-white, #FFF);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.box {
    border-radius: 4px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.forecast .label {
    color: var(--theme-secondary, #6C757D);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.forecast .temp-max,
.forecast .temp-min {
    color: var(--theme-secondary, #6C757D);
    font-size: 20px;
    font-style: normal;
    line-height: 100%;
}

.forecast .temp-max {
    font-weight: 700;
}

.forecast .temp-min {
    font-weight: 400;
}

#up {
    transform: rotate(180deg);
}

.select {
    position: relative;
}

#select-button {
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: 1rem;
    position: relative;
}

#selected-value {
    color: var(--gray-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}


#options-view-button {
    all: unset;
    position: absolute;
    inset: 0;
    cursor: pointer;
    z-index: 3;
}

#options {
    margin-top: .25rem;
    border-radius: .375rem;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 470px;
    min-width: 200px;
}

#options {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

#options::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.option {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .75rem;
    border-bottom: 1px solid #252529;
    position: relative;
}

.option:last-child {
    border-bottom: none;
}

.option .label {
    color: var(--theme-secondary, #6C757D);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.option:hover {
    border-bottom: 1px solid #252529;
    background-color: #252529;
}

.option:hover .label {
    color: white;
}


.option input[type="radio"] {
    all: unset;
    position: absolute;
    inset: 0;
    cursor: pointer;
}




@media (max-width: 575.98px) {
    .status .label {
        font-size: 12px;
    }

    .info .title {
        font-size: 40px;
    }

    #select-button{
        padding: 0;
        gap: 0.5rem
    }

    #selected-value {
        font-size: 14px;
    }

}

@media (max-width: 767px) {
    .page-title {
        color: var(--theme-secondary, #6C757D);
        font-size: 21px;
    }

    .form-control.search-input {
        font-size: 13px;
        background-size: 15px 15px;
        padding-left: 35px;
    }
}


@media (min-width: 768px) {
    .search-input {
        width: 300px;
    }
}

@media (max-width: 991px) {
    .current-meteo-box {
        max-width: 100%;
    }
}
</style>