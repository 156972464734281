import i18n from '@/helpers/i18n';
import { useAlertStore } from '@/stores';

var moment = require('moment');
export {
    deepClone,
    convertToPascalCase,
    convertToCamelCase,
    readingTime,
    convertToBase64,
    getReducedFraction,
    formatDate,
    formatDateDotted,
    htmlToText,
    genericValidator,
    errorValidator
};

function deepClone(destination, source)
{
    return Object.assign(destination, JSON.parse(JSON.stringify(source)));
}


function convertToPascalCase(string)
{
    return string.replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
}

function convertToCamelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function readingTime (description)
{
    
    var wordCount = description.replace( /[^\w ]/g, "" ).split( /\s+/ ).length;
    var readingTimeInMinutes = Math.floor(wordCount / 228) + 1;
    var readingTimeAsString = readingTimeInMinutes + " min";
    return readingTimeAsString;
}

// function to encode file data to base64 encoded string
function convertToBase64(blob) {
    if(!blob)
    {
        return Promise.resolve(false);
    }
    else
    {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        }); 
    }   
}

function getReducedFraction(numerator, denominator)
{
    var gcd = getCommonDenominator(numerator, denominator);
    return `${numerator/gcd}:${denominator/gcd}`;
}
function getCommonDenominator(a,b){
    return b ? getCommonDenominator(b, a%b) : a;
}
function formatDate(date)
{
    if(!(date instanceof Date))
    {
        date = new Date(date);
    }

    return moment(date).format('DD/MM/YYYY');
}
function formatDateDotted(date)
{
    if(!(date instanceof Date))
    {
        date = new Date(date);
    }

    moment.locale(i18n.locale);

    return moment(date).format('DD.MMM.YYYY');
}
function htmlToText(html)
{
    var div = document.createElement('div');
    div.innerHTML = html.trim();
    return div.textContent || div.innerText || "";
}
function genericValidator(val, attemptedSubmit) {
    var result = undefined; // This is the default, in case the user hasn't attemptedSubmit yet

    if (attemptedSubmit) {
        if (val instanceof Array) { // Array
            result = Boolean(val.length);
        }
        else if (val instanceof Object) { // Multilanguage field 
            // Validate all fields
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(val)) {
                if (!value) {
                    result = false;
                    break;
                }
            }
        }
        else {
            result = Boolean(val);
        }    
    }

    return result;
}
function errorValidator(fieldName) {
    var result = undefined;
    const alertStore = useAlertStore();
    const alertOutput = alertStore.alert && alertStore.alert.output ? alertStore.alert.output : null;
    if (alertOutput && alertOutput.Errors) // Error alertOutput
    {
        if(Object.keys(alertOutput.Errors).find(key => {
            return key === fieldName;
        })) {
            result = false;
        }
    }

    return result;
}