import { defineStore } from 'pinia';

export const useMeteoStore = defineStore('meteo', {
    state: () => ({
        getMeteoOutput: {},
        getCoordinatesOutput: {},
        getAirPolutionOutput: {},
        getLocationOutput: {},
        isLoading: false
    }),
    getters: {
        currentMeteoOutput: (state) => {
            return state.getMeteoOutput ? state.getMeteoOutput : {};
        },
    },
    actions: {
        async getList(lat, lon) {
            try {
                this.isLoading = true;
                const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&appid=8be6651c8b970d9016d99e36b89dc90f&units=metric`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                this.getMeteoOutput = await response.json();
                this.isLoading = false;
                localStorage.setItem("currentMeteo", JSON.stringify(this.getMeteoOutput));
                
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching data:', error);
            }
        },
        async getCoordinates(location) {
            try {
                this.isLoading = true;
                const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/geo/1.0/direct?q=${location},PT&appid=8be6651c8b970d9016d99e36b89dc90f`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                this.getCoordinatesOutput = await response.json();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching data:', error);
            }
        },
        async getAirPollution(lat, lon) {
            try {
                this.isLoading = true;
                const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=8be6651c8b970d9016d99e36b89dc90f`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                this.getAirPolutionOutput = await response.json();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching data:', error);
            }
        },
        // get name of the location
        async reverseGeocoding(lat, lon) { 
            try {
                this.isLoading = true;
                const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&appid=8be6651c8b970d9016d99e36b89dc90f`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                this.getLocationOutput = await response.json();
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching data:', error);
            }
        }
    },
});
