import { HOTEL_SERVICE } from "@/services/hotel.service.js";
import { defineStore } from 'pinia'

export const useHotelStore = defineStore('hotel', {
    state: () => ({
        getHotelListOutput: [],
        getListBySlugOutput: [],
    }),
    actions: {
        async getList() {
            const response = await HOTEL_SERVICE.getList();
            this.getHotelListOutput = response;
        },
        async getListBySlug(slug) {
            this.getListBySlugOutput = await HOTEL_SERVICE.getListBySlug(slug);
        },
        async createHotel(payload) {
            await HOTEL_SERVICE.createHotel(payload);
        },
        async editHotel(id, payload) {
            await HOTEL_SERVICE.editHotel(id, payload);
        },
        async deleteHotel(id) {
            await HOTEL_SERVICE.deleteHotel(id);
        },
    }
});