/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const COMPANYUSERS_SERVICE = {
    getList,
    getUserDetailsById,
    createCompanyUsers,
    editCompanyUsers,
    deleteCompanyUsersBySlug,
    inviteUsers,
    acceptInvitation,
    rejectInvitation
};

// Root service to build API url
const API_ROOT_SERVICE = "company/users";

/* Services */
async function getList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getUserDetailsById(id) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, id]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function createCompanyUsers() 
{
    // this is not needed when its optional parameters, so i commented them temporarily ultil we know that they are really optional
    // if(!name || !integrationsIds)
    // {
        //     return Promise.reject("The parameters are not complete!");
        // }

    // var raw = JSON.stringify({name, integrationsIds});
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        // body: raw
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function editCompanyUsers() 
{
    // this is not needed when its optional parameters, so i commented them temporarily ultil we know that they are really optional
    // if(!name || !integrationsIds)
    // {
        //     return Promise.reject("The parameters are not complete!");
        // }
        
        // var raw = JSON.stringify({name, integrationsIds});
        const requestOptions = {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            // body: raw
        };
        let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
        
        return await API_HELPER.loggedApiCall(url, null, requestOptions);
    }
async function deleteCompanyUsersBySlug(slug) 
{
    const requestOptions = {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function inviteUsers(email) 
{
    const requestBody = JSON.stringify({ email });
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: requestBody
    };

    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "invite"]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function acceptInvitation(token) 
{
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token })
    };

    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "invite/accept"]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function rejectInvitation(token) 
{
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token })
    };

    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "invite/refuse"]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}