<template>
  <BaseSign>
    <template v-slot:content>
      <Form ref="myForm" :validation-schema="currentSchema" v-slot="{ errors }">
        <div class="col-12">
          <div class="d-none d-md-block col-12">
            <img src="@/assets/authentication/sign-up/logo.svg" alt="">
          </div>
          <div v-if="currentStep === 0" class="my-4">
            <span class="title-primary">{{ $t('createAnAccount') }}</span>
            <br>
            <span class="title-secondary">{{ $t('signUpAndStartUsingRevenueMasterToday!') }}</span>
          </div>
        </div>
        <template v-if="currentStep === 0">
          <div class="form-group">
            <label for="name">{{ $t('name') }}<span class="asterisk">*</span></label>
            <Field name="name" type="text" class="form-control input-form"
              :class="{ 'is-invalid': hasError(errors, 'name') }" :placeholder="$t('insertName')" />
          </div>
          <div class="form-group">
            <label for="email">{{ $t('email') }}<span class="asterisk">*</span></label>
            <Field name="email" type="email" class="form-control input-form"
              :class="{ 'is-invalid': hasError(errors, 'email') }" :placeholder="$t('insertEmail')" />
          </div>
          <div class="form-group">
            <label for="phoneNumber">{{ $t('phoneNumber') }}<span class="asterisk">*</span></label>
            <Field name="phoneNumber" type="text" class="form-control input-form"
              :class="{ 'is-invalid': hasError(errors, 'phoneNumber') }" :placeholder="$t('insertPhoneNumber')" />
          </div>
          <div class="form-group">
            <label for="password">{{ $t('password') }}<span class="asterisk">*</span></label>
            <Field name="password" type="password" v-slot="{ field }">
              <div id="input-password">
                <input id="password" v-bind="field" type="password" class="form-control input-form"
                  :class="{ 'is-invalid': hasError(errors, 'password') }" :placeholder="$t('insertPassword')"
                  v-model="password" @input="password_check" />
                <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                  @click="switchVisibility('password')" src="@/assets/MyProfile/visibilityPassword-icon.svg" alt="">
              </div>
            </Field>
            <div class="d-flex align-items-center gap-2 text-start" :class="{ 'mt-3': badgeColor }" ref="strengthBadge">
              <i class="frmIcon" :class="{ 'bi bi-check-circle-fill': badgeColor }" :style="{ color: badgeColor }"></i>
              <span :style="{ color: badgeColor }">{{ strengthText }}</span>
            </div>
          </div>
          <ValidationAlerts
            v-if="hasError(errors, 'name') || hasError(errors, 'email') || hasError(errors, 'password') || hasError(errors, 'phoneNumber')"
            class="my-2" :alertType="'error'"
            :title="getErrorMessages(errors, ['name', 'email', 'password', 'phoneNumber'])" 
          />
          <div class="text-start my-3">
            <p class="validation" :class="{ 'validation--passed': password.length >= 8 }">
              <i class="frmIcon" :class="password.length >= 8 ? 'bi bi-check' : 'bi bi-x-circle'"></i>
              {{ $t('mustBeAtLeast8Characters') }}
            </p>
            <p class="validation" :class="{ 'validation--passed': has_uppercase }">
              <i class="frmIcon" :class="has_uppercase ? 'bi bi-check' : 'bi bi-x-circle'"></i>
              {{ $t('hasACapitalLetter') }}
            </p>
            <p class="validation" :class="{ 'validation--passed': has_lowercase }">
              <i class="frmIcon" :class="has_lowercase ? 'bi bi-check' : 'bi bi-x-circle'"></i>
              {{ $t('hasALowercaseLetter') }}
            </p>
            <p class="validation" :class="{ 'validation--passed': has_number }">
              <i class="frmIcon" :class="has_number ? 'bi bi-check' : 'bi bi-x-circle'"></i>
              {{ $t('hasANumber') }}
            </p>
            <p class="validation" :class="{ 'validation--passed': has_special }">
              <i class="frmIcon" :class="has_special ? 'bi bi-check' : 'bi bi-x-circle'"></i>
              {{ $t('hasASpecialCharacter') }}
            </p>
          </div>
        </template>
        <div v-if="currentStep === 0" class="form-group">
          <CustomButton class="btn mt-5" :label="$t('saveAndContinue')" :loading="loading" @clickBtn="onSubmit"/>
        </div>
        <template v-if="currentStep === 1">
          <div class="col-12">
            <div class="my-4">
              <span class="title-primary">{{ $t('accountCreated') }}</span>
              <p class="title-secondary">
                {{ $t("Thank you for signing up! To complete the registration process, we need you to confirm your email address. We've sent a confirmation email to the address you provided.") }}
              </p>
            </div>
          </div>
        </template>
        <div class="col-12 my-4" v-if="currentStep === 0">
          <span>{{ $t('haveAnAccount?') }} <a class="login" @click="sendToSignIn">{{ $t('logIn') }}</a></span>
        </div>
        <div class="col-12 my-4" v-if="currentStep === 0">
          <div class="d-flex flex-column">
            <span class="text-start" :style="{ fontSize: '0.8rem' }">{{ $t('protectedByReCAPTCHAAndSubjectToThe') }}
              <a class="privacy-policy" href="https://revenuemasterio.pt/static/42yf2GbGgfrnnOrLAznF04" target="_blank">{{ $t('revenueMasterPrivacyPolicyAndTermsOfService.') }} </a>
            </span>
          </div>
        </div>
      </Form>
    </template>
  </BaseSign>
</template>


<script>
import BaseSign from "@/components/BaseSign.vue";
import CustomButton from '@/components/CustomButton.vue';
import countriesJson from '@/helpers/countries.json';
import { useUsersStore, useAlertStore } from '@/stores';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup'
import ValidationAlerts from '@/components/ValidationAlerts.vue'

export default {
  components: {
    BaseSign,
    CustomButton,
    ValidationAlerts,
    Form,
    Field,
  },
  setup() {
    const usersStore = useUsersStore();
    const alertStore = useAlertStore();

    return {
      usersStore,
      alertStore
    };
  },
  data() {
    return {
      currentStep: 0,
      countries: countriesJson,
      loading: false,
      defaultCountry: 'Portugal',
      password: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    }
  },
  computed: {
    schemas() {
      return [
        Yup.object({
          name: Yup.string().required(this.$t('nameRequired')),
          email: Yup.string().required(this.$t('emailRequired')).email(this.$t('emailMustBeAValidEmail')),
          password: Yup.string()
            .min(8, this.$t('passwordMustBeAtLeast8Characters'))
            .test('has_number', this.$t('passwordMustHaveANumber'), value => /\d/.test(value))
            .test('has_lowercase', this.$t('passwordMustHaveALowercaseLetter'), value => /[a-z]/.test(value))
            .test('has_uppercase', this.$t('passwordMustHaveAnUppercaseLetter'), value => /[A-Z]/.test(value))
            .test('has_special', this.$t('passwordMustHaveASpecialCharacter'), value => /[!@#$%^&*()_+=.-]/.test(value))
            .required(this.$t('passwordRequired')),
          phoneNumber: Yup.string().required(this.$t('phoneNumberRequired')),
        }),
      ];
    },
    currentSchema() {
      return this.schemas[this.currentStep];
    },
    apiErrors: function () {
      return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : this.alertStore.alert ? this.alertStore.alert : {};
    }
  },
  methods: {
    sendToSignIn() {
      this.alertStore.clear();
      this.$router.push({ name: 'SignIn' });
    },
    prevStep() {
      if (this.currentStep <= 0) {
        return;
      }

      this.currentStep--;
    },
    async onSubmit () {
      const { valid } = await this.$refs.myForm.validate();
      if (!valid) {
        return;
      }
      this.registerClicked(this.$refs.myForm.values);
    },
    async registerClicked(values) {
      this.alertStore.clear();
      this.loading = true;
      const { name, email, password, phoneNumber } = values;
      try {
        await this.usersStore.register({ name, email, password, phoneNumber });
        this.loading = false;
        this.currentStep = 1;
      }
      catch (error) {
        this.alertStore.error(error);
      } finally {
        this.loading = false;
      }
    },
    hasError(errors, label) {
      return errors[label] || this.hasApiError(label);
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    },
    getErrorMessages(errors, labels) {
      const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
      return errorMessages.length ? errorMessages.join('<br>') : errorMessages.concat(this.apiErrors.output);
    },
    StrengthChecker(password) {
      const requirements = [
        /\d/, // At least one digit
        /[a-z]/, // At least one lowercase letter
        /[A-Z]/, // At least one uppercase letter
        /[!@#$%^&*()_+=.-]/, // At least one special character
        /.{8,}/, // Must be at least 8 characters 
      ];
      const fulfilledRequirements = requirements.reduce(
        (count, requirement) => (requirement.test(password) ? count + 1 : count),
        0
      );

      if (fulfilledRequirements === 5) {
        this.badgeColor = "green";
        this.strengthText = this.$t('strongPassword');
      } else if (fulfilledRequirements >= 3) {
        this.badgeColor = "blue";
        this.strengthText = this.$t('mediumPassword');
      } else {
        this.badgeColor = "red";
        this.strengthText = this.$t('weakPassword');
      }
    },
    password_check: function () {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#$%^&*()_+=.-]/.test(this.password);
      this.$refs.strengthBadge.style.display = "block";
      this.StrengthChecker(this.password);
      if (this.password.length !== 0) {
        this.$refs.strengthBadge.style.display != "block";
      } else {
        this.$refs.strengthBadge.style.display = "none";
      }
    },
    switchVisibility(selector) {
      const passwordField = document.querySelector(`#${selector}`)
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    },
  },
}
</script>

<style scoped>
.title-primary {
  font-size: 20px;
  font-weight: 900;
}

.title-secondary {
  font-size: 15px;
  color: var(--theme-secondary);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  justify-content: flex-start;
}

.asterisk {
  color: var(--theme-primary);
  margin-left: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-form {
  max-width: 500px;
}

.form-group .remember label {
  margin-left: 5px;
}

.login {
  color: var(--theme-primary);
  text-decoration: underline;
  cursor: pointer;
}

.privacy-policy {
  color: var(--theme-primary);
}

.form-group button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--theme-primary);
  text-decoration: none;
  text-align: center;
}

.form-group a {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--theme-primary);
  text-decoration: underline;
  text-align: center;
}

#input-password {
  position: relative;
}

#visibility-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.validation {
  font-size: 13px;
}

.validation--passed {
  color: #717b85;
}

.frmIcon {
  color: #EB0029;
}

.validation--passed .frmIcon {
  color: #0fa140;
}

.frmIcon {
  color: #EB0029;
}

.validation--passed .frmIcon {
  color: #0fa140;
}
</style>