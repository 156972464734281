<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="popup">
                <div class="pop-up-close padding-style-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp">
                </div>
                <div class="title-div">
                    <span class="title-popup">New Event</span>
                </div>
                <hr>
                <div class="modal-container padding-style">
                    <div class="row">
                        <div class="col-12 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>Name</label><span class="asterisk"> *</span>
                            </div>
                            <input class="form-control w-100" type="text" placeholder="Insert Name">
                        </div>
                        <div class="col-12 col-lg-6 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>Start Date</label><span class="asterisk"> *</span>
                            </div>
                            <DateButton @update-date="updateDateProperty('evolutionChartDate', $event)" typeForm="1"
                                :label="$t('startDate')" />
                        </div>
                        <div class="col-12 col-lg-6 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>End Date</label><span class="asterisk"> *</span>
                            </div>
                            <DateButton @update-date="updateDateProperty('evolutionChartDate', $event)" typeForm="1"
                                :label="$t('endDate')" />
                        </div>
                        <div class="col-12 col-lg-6 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>Category</label><span class="asterisk"> *</span>
                            </div>
                            <SingleOptionDropdown :options="types" :title="$t('other')" :index="1"></SingleOptionDropdown>
                        </div>
                        <div class="col-12 col-lg-6 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>Location</label><span class="asterisk"> *</span>
                            </div>
                            <input class="form-control w-100" type="text" placeholder="Insert Location">
                        </div>
                        <div class="col-12 d-flex gap-2 flex-column mt-3">
                            <div class="d-flex flex-row">
                                <label>Description</label><span class="asterisk"> *</span>
                            </div>
                            <input class="form-control w-100" type="text" placeholder="Insert Description">
                        </div>
                    </div>
                    <slot name="body">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleOptionDropdown from '@/components/SingleOptionDropdown.vue'
import DateButton from '@/components/DateButton.vue';
export default {
    components: { SingleOptionDropdown, DateButton },
    data() {
        return {
            drag: false,
            types: [{label: this.$t("categoria1"), key: "categoria1"}, {label: this.$t("categoria2"), key: "categoria2"}],
        }
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        clickSmallIconText() {
            this.$emit("popUpDeleteOpen");

        },
        clickBtnCustom() {
            this.$emit("btnCustom");

        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        },
        updateDateProperty(propertyName, newValue) {
            this[propertyName] = newValue;
        },
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function (val) {
            if (val) {
                document.body.classList.add("modal-open");
            }
            else {
                document.body.classList.remove("modal-open");
            }
        }
    }

};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 1rem;
}

.popup{
    display: flex;
    flex-direction: column;
    width: 35vw;
    min-height: 65vh;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 40px;
    box-shadow: 0 0 8px 0 #303942;
}
.padding-style{
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
.title-popup{
    color: var(--Theme-Secondary, #6C757D);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.padding-style-close{
    padding-top: 2rem;
    padding-right: 2rem;
}

.pop-up-close {
    display: flex;
    justify-content: end;
}
.title-div{
    padding-left: 2rem;
}
.modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
#popup-title{
    color: var(--theme-dark, #212529);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
    word-break: break-word;
}
#popup-desc{
    color: var(--gray-800, #343A40);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: 1rem 0;
}
.asterisk{
  color: var(--theme-primary);
  margin-left: 5px;
}
@media only screen and (max-width: 1000px) {
    .popup{
        width: 80vw;
        min-height: 65vh;
    }
    .padding-style{
        padding: 1rem;
    }
}
@media only screen and (max-width: 450px) {
    .btns-div{
      flex-wrap: wrap;
    }
}
</style>