import { defineStore } from 'pinia';
// storage for mobile menu/submenu

export const useMenuStore = defineStore('menu', {
    state: () => ({
        isOpen: false,
        isSubMenuOpen: false
    }),
    actions: {
        toggleMenu() {
            this.isOpen = !this.isOpen;
        },
        toggleSubMenu() {
            this.isSubMenuOpen = !this.isSubMenuOpen;
        }
    }
});