import { defineStore } from 'pinia';
import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";

export const useAlertStore = defineStore('alert', {
    state: () => ({
        alert: null
    }),
    actions: {
        success(output) {
            this.alert = { output, type: 'alert-success' };
        },
        error(output, customError) {
            if (output instanceof TypeError && output.message.includes('NetworkError')) {
                this.alert = { output: output, type: 'alert-danger' };
            } else if (output && customError) {
                const errorMessage = customError; // add custom error when API doesn't return any specific error
                this.alert = { output: errorMessage, type: 'alert-danger' };
            } else {
                this.alert = { output: output, type: 'alert-danger' };
            }
            if (ENVIRONMENT_HELPER.isDev()) console.log(this.alert.output);
        },
        redirectError(output, context) {
            this.alert = { output, type: 'alert-danger' };
            context.$router.push(context.localePath({ name: "404" })); 
            if (ENVIRONMENT_HELPER.isDev()) console.log(output);
        },
        clear() {
            this.alert = null;
        }
    }
});