import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import router from "@/router";
import { useUsersStore } from "../stores/users.store";
import i18n from "./i18n";

export const API_HELPER = {
  abortController: new AbortController(),
  pathJoin,
  loggedApiCall,
  apiCall,
};

function pathJoin(parts) {
  return parts
    .map((part, i) => {
      if (i === 0) {
        return part.toString().trim().replace(/[/]*$/g, "");
      } else {
        return part
          .toString()
          .trim()
          .replace(/(^[/]*|[/]*$)/g, "");
      }
    })
    .filter((x) => x.length)
    .join("/");
}

async function apiCall(url, queryParams, requestOptions, canBeAborted) {
  if (!url || !requestOptions) {
    throw "The necessary info for the API call was not provided!";
  }

  if (ENVIRONMENT_HELPER.isDev()) {
    console.log(requestOptions);
  }

  canBeAborted = canBeAborted === undefined || canBeAborted === null ? true : canBeAborted;
  const fullUrl = getFullUrl(url, queryParams);

  if (canBeAborted) {
    requestOptions.signal = API_HELPER.abortController.signal;
  }

  if (!requestOptions.headers) {
    requestOptions.headers = {};
  }

  requestOptions.headers["Accept-Language"] = i18n.global.locale.value;

  if (ENVIRONMENT_HELPER.isDev()) {
    console.log(fullUrl);
  }

  try {
    const response = await fetch(fullUrl, requestOptions);
    const text = await response.text();
    if (!response.ok) {
      let errorMsg;
      switch (response.status) {
        case 500:
        case 501:
        case 502:
        case 503:
          router.push("/error");
          break;
        default:
          errorMsg = JSON.parse(text);
          break;
      }
      return Promise.reject(errorMsg);
    }
    return JSON.parse(text);
  } catch (err) {
    if (err.name === "AbortError") {
      console.log("The request was aborted!");
    } else {
      if (err || (err && err.errors)) {
        return Promise.reject(err);
      } else {
        return Promise.reject("Something went wrong, please try again. If the problem persists, contact support.");
      }
    }
  }
}

async function loggedApiCall(url, queryParams, requestOptions, canBeAborted) {
  if (!url || !requestOptions) {
    throw new Error("The necessary info for the API call was not provided!");
  }

  if (ENVIRONMENT_HELPER.isDev()) {
    console.log("Request options before authorization:", requestOptions);
  }

  // Allow aborting requests by default unless specified otherwise
  canBeAborted = canBeAborted === undefined || canBeAborted === null ? true : canBeAborted;

  const authStore = useUsersStore();
  let user = authStore.user;

  if (ENVIRONMENT_HELPER.isDev()) console.log("Current user from authStore:", user); // Log user for debugging

  // Check if the user is logged in and the session is valid
  if (!user || !user.access_token || !user.expires_in) {
    throw new Error("The user is not logged in or the current session is not valid!");
  }

  // Token expiration check with a buffer (5 minutes in dev, 10 minutes in prod)
  const limitTime = new Date();
  if (ENVIRONMENT_HELPER.isProd()) {
    limitTime.setMinutes(limitTime.getMinutes() + 10);
  } else {
    limitTime.setMinutes(limitTime.getMinutes() + 5);
  }

  // Convert expiration time from the token (assuming it's in milliseconds)
  const expirationTime = new Date(new Date().getTime() + user.expires_in * 1000);
  if (expirationTime < limitTime) {
    // Refresh token if it's close to expiring
    if (ENVIRONMENT_HELPER.isDev()) console.log("Token is about to expire, refreshing...");
    await authStore.refreshToken();
    user = authStore.user; // Refresh the user data
  }

  // Set the Authorization header with the refreshed token if available
  requestOptions.headers = requestOptions.headers || {};
  user = JSON.parse(localStorage.getItem("user")); // Ensure we fetch the updated user data from localStorage

  if (user && user.access_token) {
    requestOptions.headers.Authorization = "Bearer " + user.access_token;
  } else {
    throw new Error("Couldn't create the authorization header");
  }

  // Set the current language header
  requestOptions.headers["Accept-Language"] = i18n.global.locale.value;

  // Build the full URL with query parameters
  const fullUrl = getFullUrl(url, queryParams);

  // Add the abort controller signal if the request can be aborted
  if (canBeAborted) {
    requestOptions.signal = API_HELPER.abortController.signal;
  }

  if (ENVIRONMENT_HELPER.isDev()) {
    console.log("Full URL:", fullUrl);
    console.log("Request options with authorization:", requestOptions);
  }

  try {
    const response = await fetch(fullUrl, requestOptions);

    if (!response.ok) {
      let errorMsg;
      switch (response.status) {
        case 401:
          errorMsg = "The token is not valid.";
          authStore.logout();
          break;
        case 500:
        case 501:
        case 502:
        case 503:
          router.push({ name: "page500" });
          return; // Exit function after navigation
        default: {
          // Try to parse the error message if it's not a 204 status
          const text = await response.text();
          if (text) {
            try {
              const parsedError = JSON.parse(text);
              errorMsg = parsedError.message || parsedError.errors || parsedError.error || "An error occurred.";
            } catch (parseError) {
              errorMsg = text; // If not JSON, use raw text
            }
          } else {
            errorMsg = "An error occurred.";
          }
          break;
        }
      }
      throw new Error(JSON.stringify(errorMsg)); // Convert to string for consistent error handling
    }

    // For successful responses
    const text = await response.text();
    if (text) {
      // Check if there's content to parse
      if (ENVIRONMENT_HELPER.isDev()) console.log("Response data:", JSON.parse(text));
      return JSON.parse(text);
    }

    // Return an empty object or null for 204 No Content responses
    return null;
  } catch (err) {
    if (err.name === "AbortError") {
      if (ENVIRONMENT_HELPER.isDev()) {
        console.log("The request was aborted!");
      }
    } else {
      console.error("API call error:", err); // Log full error for debugging
      throw err;
    }
  }
}



function getFullUrl(url, queryParams) {
  var fullUrl = encodeURI(url);
  if (queryParams) {
    fullUrl += "?";
    for (var key in queryParams) {
      var value = queryParams[key];

      if (Array.isArray(value)) {
        value.forEach(function (item) {
          var encodedItem = encodeURIComponent(item);
          fullUrl += `&${key}=${encodedItem}`;
        });
      } else if (value || value == 0) {
        var encodedValue = encodeURIComponent(value);
        fullUrl += `&${key}=${encodedValue}`;
      }
    }
  }

  return fullUrl;
}
