<template>
    <section id="form">
        <LayoutForm 
            :pageLayout="{
                title: $t('newCompany'),
                icon: require('@/assets/new.svg'),
                firstAction: { icon: require('@/assets/HotelGroup/x-circle.svg'), label: $t('cancel') },
                secondAction: { icon: require('@/assets/HotelGroup/saveBtn.svg'), label: $t('save') }
            }"
            @firstActionClick="firstActionClick" 
            @secondActionClick="secondActionClick"
        >
            <template v-slot:dynamic-content>
                <div class="row justify-content-center p-3 border-bottom">
                    <div class="file-picker">
                        <img class="cursor-pointer img-fluid" :src="selectedImage" alt="icon-picker" @click="showimagePicker = true">
                        <div class="d-block d-md-flex gap-3">
                            <div class="first-input">
                                <label class="label" for="name">{{ $t('name') }}</label>
                                <input class="form-control" type="text" :placeholder="$t('insertName')" v-model="formData.name">
                            </div>
                            <div class="second-input">
                                <label class="label" for="location">{{ $t('phoneNumber') }}</label>
                                <input class="form-control" type="text" :placeholder="$t('insertNumber')" v-model="formData.phoneNumber">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row border-bottom p-3">
                    <TableComponent 
                        v-model="userItemsFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyUserRows" 
                        @rowClicked="rowClicked($event, 'CompanyUserEdit')"
                    >
                        <template v-slot:title>
                            <div class="d-flex p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="user-icon" src="@/assets/HotelGroup/person.svg" alt="">
                                        <span class="table-title">{{ $t('users') }}</span>
                                    </div>
                                </div>
                                <div class="col d-flex gap-3 justify-content-end">
                                    <button class="btn-custom">
                                        {{ $t('add') }}
                                        <img src="@/assets/HotelGroup/seeAll.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                </div> -->
            </template>
        </LayoutForm>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false" 
            :titlePopup="$t('changeCompanyPicture')"
            :descriptionPopup="$t('Here you can change the photo of your company to make it more easily recognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="formData.picture"
                        :placeholder="require('@/assets/HotelGroup/addImageCreateHotel.svg')" :ratioWidth="1"
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': !Object.keys(formData.picture).length }"
                        :label="$t('changePicture')" buttonType="theme-light"
                        :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
                        @clickBtn="onChooseImageClicked" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- cancel Company -->
        <CustomPopup 
          v-show="showCancelChangesPopup" 
          @popUpClosed="showCancelChangesPopup = false"
          :img="require('@/assets/Popup/cancelSave.svg')"
          :descriptionPopup="$t('Are you sure you want to cancel it? All changes will be deleted.')"
        >
          <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('No')" 
                        @clickBtn="showCancelChangesPopup = false"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }"
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')" 
                        @clickBtn="showCancelChangesPopup = false"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
              </div>
          </template>
        </CustomPopup> 
        <!-- save Company -->
        <CustomPopup 
          v-show="showSaveChangesPopup" 
          @popUpClosed="showSaveChangesPopup = false"
          :img="require('@/assets/Popup/successSave.svg')"
          :descriptionPopup="$t('Company has been successfully saved.')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="$t('Go back')" 
                      @clickBtn="showSaveChangesPopup = false, $router.push({ name: 'CompanySettingsPage' })"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
    </section>
</template>

<script>
import LayoutForm from '@/components/LayoutForm.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
// import TableComponent from '@/components/TableComponent.vue';
import { useCompanyStore, useAlertStore } from "@/stores";

export default {
    components: {
        LayoutForm,
        CustomPopup,
        CustomButton,
        ImagePicker,
        // TableComponent
    },
    setup() {
        const companyStore = useCompanyStore();
        const alertStore = useAlertStore();
        return { companyStore, alertStore };
    },
    data() {
        return {
            showSaveChangesPopup: false,
            showCancelChangesPopup: false,
            showimagePicker: false,
            selectedImage: require('@/assets/Company/uploadImageDefault.svg'),
            // bodyUserRows: [
            //     { type: 'ImageWithText', field: 'name' },
            //     { type: 'SimpleText', field: 'role' },
            //     { type: 'Status', field: 'status' },
            //     { type: 'SimpleText', field: 'tags' }
            // ],
            // userItemsFromApi: [
            //     { id: 0, name: { image: require('@/assets/HotelGroup/avatar.svg'), firstText: 'Paul Thomson 1', secondText: 'paul_thomson@RM.com' }, role: 'Admin', status: 'Active', tags: 'Revenue Controler' },
            //     { id: 1, name: { image: require('@/assets/HotelGroup/avatar.svg'), firstText: 'Paul Thomson 2', secondText: 'paul_thomson@RM.com' }, role: 'Admin', status: 'Active', tags: 'Revenue Analyst' },
            //     { id: 2, name: { image: require('@/assets/HotelGroup/avatar.svg'), firstText: 'Paul Thomson 3', secondText: 'paul_thomson@RM.com' }, role: 'Admin', status: 'Deactivated', tags: 'Revenue Manager' },
            // ],
            formData: {
                name: '',
                phoneNumber: '',
                picture: {}
            }
        }
    },
    methods: {
        firstActionClick() {
            this.showCancelChangesPopup = true;
        },
        async secondActionClick() {
            const payload = {
                name: this.formData.name,
                phoneNumber: this.formData.phoneNumber,
                // picture: this.formData.picture.url
            }
            try {
                console.log('payload', payload);
                await this.companyStore.createCompany(payload);
                this.showSaveChangesPopup = true;
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        rowClicked(obj, route) {
            if(route.name === 'CompanyUserEdit') {
                this.$router.push({ name: route, params: { userId: obj.id } });
                return;
            }
        },
        onChooseImageClicked() {
            this.selectedImage = this.formData.picture.url;
            this.showimagePicker = false;
        }
    },
}
</script>

<style scoped>
.first-input .label,
.second-input .label {
    color: var(--theme-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
}

.table-title {
    color: var(--theme-dark, #212529);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}

.btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-dark);
    border-radius: 8px;
    border: 1px solid var(--theme-border);
    background: var(--theme-light);
    font-size: .9rem;
    text-decoration: none;
}

.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.file-picker img{
    width: 200px;
    max-width: 200px;
}

@media (min-width: 992px) {
    .btn-custom{
        min-width: 200px
    }
}
@media (max-width: 767px) {
    .first-input .form-control,
    .second-input .form-control {
        width: 100%;
    }
    .table-title{
        font-size: 1rem;
    }
}
</style>