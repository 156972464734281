<template>
    <section id="notice">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center gap-3">
                    <img :src="image" alt="image" class="img-fluid">
                    <h2>{{ Title }}</h2>
                    <p class="text-center">{{ description }}</p>
                    <CustomButton 
                        v-if="buttonInfo"
                        class="btn" 
                        :label="buttonInfo" 
                        buttonType="bg-primary"  
                        @clickBtn="actionClicked" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';

export default {
    components: {
        CustomButton
    },
    name: 'NoticeComponent',
    data() {
        return {
            message: 'Hello Vue!'
        }
    },
    props: {
        Title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true
        },
        buttonInfo: {
            type: Boolean,
            required: true
        },
        buttonStyle: {
            type: String,
            required: false
        },
        actionClicked: {
            type: Function,
            required: false
        }
    }
}
</script>

<style scoped>
#notice{
    height: calc(100vh - 196px);
    min-height: calc(100vh - 196px);
}
</style>