<template>   
    <section id="list">
        <LayoutList
            v-model="itemsFromApi"
            :table="{
                layout: {
                    hasHeader: { desktop: false, mobile: false }
                },
                rows: tableRows,
                clickableRows: false
            }"
            :hasTitle="{
                title: $t('userActivity'),
            }"
            :hasDate="true"
            :hasAddButtonMobile="false"
        >
        </LayoutList>
    </section>
  </template>
  
  <script>
  import LayoutList from '@/components/LayoutList.vue';
  
  export default {
    components: {
        LayoutList,
    },
    data() {
        return {
          tableRows: [
              { type: 'ImageWithText', field: 'name' },
              { type: 'SimpleText', field: 'time' }
          ],
          itemsFromApi: [
              { id: 0, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
              { id: 1, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
          ]
        }
    }
  }
  </script>