<template>
    <section>
      <div id="div-global">
        <input 
          class="form-control" 
          :type="isPasswordVisible ? 'text' : 'password'" 
          v-model="password" 
          id="password"
        />
        <a id="visibility-icon" @click="toggleVisibility">
          <img src="@/assets/MyProfile/visibilityPassword-icon.svg" alt="">
        </a>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        isPasswordVisible: false,
      };
    },
    computed: {
      password: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        },
      },
    },
    methods: {
      toggleVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      },
    },
  };
  </script>
  
  <style scoped>
  #div-global {
    position: relative;
  }
  #visibility-icon {
    position: absolute;
    top: 7px;
    right: 7px;
  }
  </style>
  