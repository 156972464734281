import { SUBSCRIPTION_SERVICE } from "@/services/subscription.service.js";
import { defineStore } from 'pinia'

export const useSubscriptionStore = defineStore('subscription', {
        state: () => ({
            getListOutput: [],
            loading: false
        }),
        getters: {
            subscriptionOutput: (state) => {
                return state.getListOutput && state.getListOutput.data ? state.getListOutput.data : [];
            },
        },
        actions: {
            async getList(input) {
                this.loading = true;
                try{
                    this.getListOutput = await SUBSCRIPTION_SERVICE.getList(input);
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    throw error;
                }
            },
            async createSubscriptionPlanBySlug(slug) {
                await SUBSCRIPTION_SERVICE.createSubscriptionPlanBySlug(slug);
            },
            async purchasePlan(slug, frequency) {
                try {
                    // Make the API call via the service and return the response
                    return await SUBSCRIPTION_SERVICE.purchasePlan(slug, frequency);
                } catch (error) {
                    console.error('Error in purchasePlan:', error);
                    throw error; // Throw the error to be handled in the calling function
                }
            },            
            async managePayments() {
                await SUBSCRIPTION_SERVICE.managePayments();
            },
        }
    
});