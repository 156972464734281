<template>   
    <section v-if="$route.name === 'CompanyUsersList'" id="list">
        <template v-if="isMobile">
            <div @click="$router.push({name: 'CompanySettingsPage'})" class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/arrow-right-fill.svg')" alt="Users icon">
                <span class="page-title-mobile">{{ $t('Users') }} </span>
            </div>
        </template>
        <LayoutList
            class="mt-5 mt-lg-0"
            v-model="usersFromApi"
            :hasTitle="{
                title: $t('Users'),
                icon: require('@/assets/Company/userManageIcon.svg')
            }"
            :table="{
                layout: {
                    hasHeader: { desktop: true, mobile: false }
                },
                headers: tableHeaders,
                rows: tableRows,
                loading: isLoading
            }"
            :button="{
                label: $t('addMember'),
                icon: require('@/assets/Company/add-member.svg'),
                style: {
                    backgroundColor: 'var(--theme-light)!important',
                    color: 'var(--theme-dark)!important',
                    border: '1px solid var(--theme-border)!important',
                    borderRadius: '8px!important',
                    gap: '8px',
                    padding: '5px 10px',
                    minWidth: '200px'
                }
            }"
            :searchBar="false"
            @rowClicked="rowClicked"
            @addItem="addItem"
        >
        </LayoutList>
    </section>
    <InviteUsersPopUp :showModal="showModal" @popUpClosed="showModal = false" @inviteSent="invitesSent"/>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import { useCompanyUsersStore, useAlertStore } from "@/stores";
import LayoutList from '@/components/LayoutList.vue';
import InviteUsersPopUp from "@/components/popups/InviteUsersPopUp.vue";
import { useResponsive } from '@/composables/responsive.js';

export default {
    components: {
        LayoutList,
        InviteUsersPopUp
    },
    data() {
        return {
            tableHeaders: [
                {
                    icon: require('@/assets/Company/userManageIcon.svg'),
                    text: this.$t('name')
                },
                // {
                //     icon: require('@/assets/Company/lock-fill.svg'),
                //     text: this.$t('permissions')
                // },
                {
                    icon: require('@/assets/Company/tags.svg'),
                    text: this.$t('role')
                }
            ],
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
                // { type: 'SimpleText', field: 'permissions' },
                { type: 'SimpleText', field: 'role' }
            ],
            usersFromApi: [],
            isLoading: false,
            showModal: false
        }
    },
    setup() {
        const companyUsersStore = useCompanyUsersStore();
        const alertStore = useAlertStore();
        const { isMobile, isTablet, isDesktop} = useResponsive();

        return {
            companyUsersStore,
            alertStore,
            isMobile,
            isTablet,
            isDesktop
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'CompanyUsersList') {
            next(); 
            return; // Prevents the code below from running
        }
        console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        // When I load the child page, all the previous pages also load their content. 
        // It's essential to include a condition in the mounted hook to ensure it only mounts when the specific page is loaded.
        if(this.$route.name === 'CompanyUsersList') {
            await this.fetchData();
        }
    },
    methods: {
        async fetchData() {
            try {
                this.isLoading = true;
                await this.companyUsersStore.getList();
                const invitesSent = this.companyUsersStore.getListOutput.flatMap((user) => user.invites_sent);
                this.usersFromApi = this.companyUsersStore.getListOutput.map((user) => ({
                    id: user.objectId,
                    name: { 
                        image: user.picture && user.picture !== '{}' ? user.picture : require('@/assets/no-image.jpg'),
                        firstText: user?.name, 
                        secondText: user?.email 
                    },
                    // permissions: 'Member',
                    role: user?.pivot.role
                }));
                if(invitesSent && invitesSent.length) {
                    invitesSent.forEach(user => {
                        this.usersFromApi.push({
                            name: {
                                image: require('@/assets/no-image.jpg'),
                                secondText: user.email
                            },
                            role: this.$t('Pending invitation - awaiting acceptance'),
                            isNotRowClick: true
                        })
                    });
                }
            } catch (error) {
                this.alertStore.error(error);
                this.isLoading = false;
            }
            finally {
                this.isLoading = false;
            }
        },
        rowClicked(obj) {
            this.$router.push({ name: 'CompanyUserEdit', params: { userId: obj.id } });
        },
        addItem() {
            this.showModal = true;
        },
        async invitesSent() {
            await this.fetchData();
        }
    },
}
</script>
<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transform: rotate(-180deg);
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
</style>