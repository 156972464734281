<template>
    <div class="chart-container" :style="customStyle" ref="chartContainer">
        <div class="legend" v-if="chartTitle">
            <div class="title">
                <h4 class="fw-bold">{{ chartTitle }}</h4>
            </div>
            <div v-if="growth" class="growth" :style="{ backgroundColor: parseFloat(growth) > 0 ? '#D1E7DD' : (parseFloat(growth) < 0 ? '#F1D7D3' : '#E0E0E0') }">
                <img 
                    v-if="parseFloat(growth) !== 0" 
                    :src="parseFloat(growth) > 0 ? require('@/assets/Pickup/growth.svg') : require('@/assets/Pickup/negative-growth.svg')" 
                    alt="icon-growth"
                />
                <span 
                    class="fw-bold" 
                    :style="{ color: parseFloat(growth) > 0 ? '#006837' : (parseFloat(growth) < 0 ? '#DC3545' : '#6c757d')}"
                >
                    {{ formatGrowth(growth) }}
                </span>
            </div>
        </div>
        <template v-if="chartData && chartData.labels.length && chartData.datasets.length">
            <!-- Toggle Fullscreen and Close buttons -->
            <button 
                v-if="!isMobile"
                class="fullscreen-button" 
                @click="openFullScreen"
            >
                <img src="@/assets/DashboardPage/expandGraph.svg" alt="Fullscreen Icon" />
            </button>
            <Bar :data="chartData" :options="chartOptions" />
        </template>
        <template v-else>
            <div class="d-flex justify-content-center align-items-center h-100">
                <h6 class="text-center text-muted m-0 fs-12">{{ $t('No data to display') }}</h6>
            </div>
        </template>
    </div>
    <div v-if="isFullScreen" class="fullscreen-overlay">
        <div class="fullscreen-chart-container">
            <button class="close-fullscreen-button" @click="closeFullScreen">X</button>
            <div class="legend" v-if="chartTitle">
                <div class="title">
                    <h4 class="fw-bold">{{ chartTitle }}</h4>
                </div>
                <div v-if="growth" class="growth" :style="{ backgroundColor: parseFloat(growth) > 0 ? '#D1E7DD' : (parseFloat(growth) < 0 ? '#F1D7D3' : '#E0E0E0') }">
                    <img 
                        v-if="parseFloat(growth) !== 0" 
                        :src="parseFloat(growth) > 0 ? require('@/assets/Pickup/growth.svg') : require('@/assets/Pickup/negative-growth.svg')" 
                        alt="icon-growth"
                    />
                    <span 
                        class="fw-bold" 
                        :style="{ color: parseFloat(growth) > 0 ? '#006837' : (parseFloat(growth) < 0 ? '#DC3545' : '#6c757d')}"
                    >
                        {{ formatGrowth(growth) }}
                    </span>
                </div>
            </div>
            <Bar :data="chartData" :options="chartOptions" />
        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { useResponsive } from '@/composables/responsive';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default {
    name: 'BarChart',
    components: {
        Bar,
    },
    props: {
        chartTitle: String,
        growth: String,
        customStyle: Object,
        chartData: Object,
        chartOptions: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                    }
                },
                layout: {
                    backgroundColor: '#ffffff'
                },
                scales: {
                    x: {
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)', 
                        }
                    },
                    y: {
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)', 
                        }
                    }
                }
            })
        }
    },
    setup() {
        const { isMobile } = useResponsive();
        return {
            isMobile,
        }
    },
    data() {
        return {
            isFullScreen: false,
        };
    },
    methods: {
        openFullScreen() {
            this.isFullScreen = true;
        },
        closeFullScreen() {
            this.isFullScreen = false;
        },
        formatGrowth(value) {
            const numberValue = parseFloat(value);
            if (isNaN(numberValue)) {
                return '0,00%';
            }
            const formattedValue = this.$n(numberValue / 100, 'percent');
            return formattedValue;
        }
    }
}
</script>

<style scoped>
.chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999999;
}

.fullscreen-chart-container {
    width: 70%;
    height: 70%;
    background: white;
    padding: 40px;
    position: relative;
}

.close-fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.legend {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
    margin-bottom: 10px;
}

.growth {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
}

.growth img {
    margin-right: 5px;
}

.fs-12 {
    font-size: 12px;
}
@media (max-width: 575px) {
    .legend .title h4 {
        font-size: .8rem;
    }
    .growth img {
        width: 15px;
    }
    .growth span {
        font-size: .8rem;
    }
}
</style>