import { INTEGRATION_SERVICE } from "@/services/integration.service.js";
import { defineStore } from 'pinia'

export const useIntegrationStore = defineStore('integration', {
    state: () => ({
        integrationsOutput: [],
        companyIntegrationsOutput: [],
        companyIntegrationByIdOutput: [],
    }),
    actions: {
        async getIntegrations() {
            this.integrationsOutput = await INTEGRATION_SERVICE.getIntegrationsList();
        },
        async getCompanyIntegrations() {
            this.companyIntegrationsOutput = await INTEGRATION_SERVICE.getCompanyIntegrations();
        },
        async getCompanyIntegrationById(id) {
            this.companyIntegrationByIdOutput = await INTEGRATION_SERVICE.getCompanyIntegrationById(id);
        },
        async addCompanyIntegration(payload) {
            await INTEGRATION_SERVICE.addCompanyIntegration(payload);
        },
        async deleteCompanyIntegration(type) {
            await INTEGRATION_SERVICE.deleteCompanyIntegration(type);
        }
    }
});