<template>
    <section>
      <div class="container-fluid">
          <div class="row">
            <div class="col-12 d-flex align-items-center p-2 w-auto" @click="sendToMyProfileMobile" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false">
              <img src="@/assets/HotelGroup/backBtn.svg" alt="">
            </div>
            <div class="col-12 d-flex align-items-center gap-2 my-3 p-2 w-auto" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false">
              <BoldTitle :icon="require('@/assets/MyProfile/user-icon.svg')" :title="$t('Billing')"/>
            </div>
            <DividerComponent v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false"/>
            <div class="col-12 d-flex gap-2 mt-5" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false" @click="sendToBillingPage">
              <img src="@/assets/Billing/upgradePlan-mobile.svg" alt="">
              <span class="options-span">Upgrade Plan</span>
            </div>
            <div class="col-12 d-flex gap-2 mt-5" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false" @click="showPaymentMethod = true">
              <img src="@/assets/Billing/paymentMethod-mobile.svg" alt="">
              <span class="options-span">Payment Method</span>
            </div>
            <div class="col-12 d-flex gap-2 mt-5" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false" @click="showBillingHistory = true">
              <img src="@/assets/Billing/billingAddress-mobile.svg" alt="">
              <span class="options-span">Billing Address</span>
            </div>
            <div class="col-12 d-flex gap-2 mt-5" v-if="showUpgradePlan == false && showPaymentMethod == false && showBillingAddress == false && showBillingHistory == false" @click="showBillingAddress = true">
              <img src="@/assets/Billing/billingHistory-mobile.svg" alt="">
              <span class="options-span">Billing History</span>
            </div>
            <PersonalInformation v-if="showUpgradePlan == true"/>
            <SecuritySettings v-if="showPaymentMethod == true"/>
            <ActiveSessions v-if="showBillingAddress == true"/>
            <AccountActivity v-if="showBillingHistory == true"/>
          </div>
      </div>
    </section>
  </template>
  
  <script>
  import DividerComponent from '@/components/DividerComponent.vue';
  import BoldTitle from '@/components/BoldTitle.vue';
  import PersonalInformation from '@/components/MyProfile/PersonalInformation.vue';
  import SecuritySettings from '@/components/MyProfile/SecuritySettings.vue';
  import ActiveSessions from '@/components/MyProfile/ActiveSessions.vue';
  import AccountActivity from '@/components/MyProfile/AccountActivity.vue';
  export default {
      components: {
          DividerComponent,
          BoldTitle,
          PersonalInformation,
          SecuritySettings,
          ActiveSessions,
          AccountActivity
      },
      data() {
        return {
          showUpgradePlan: false,
          showPaymentMethod: false,
          showBillingAddress: false,
          showBillingHistory: false,
        }
      },
      methods: {
        sendToMyProfileMobile() {
            this.$router.go(0)
        },
        sendToBillingPage() {
            this.$router.push({ name: 'BillingPage' });
        }
      },
  }
  </script>
  
  <style scoped>
  #user-name{
    color: var(--theme-dark, #212529);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.options-span{
  color: var(--theme-secondary, #6C757D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
#export-data-span{
    color: var(--theme-danger, #DC3545);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
  </style>