<template>
    <section>
        <LayoutForm 
            :previousRoute="false"
            :pageLayout="{
                title: capitalizeFirstLetter(integrationParamType),
                icon: require('@/assets/HotelGroup/integrationsIcon.svg')
            }"
        >
            <template v-slot:dynamic-content>
                <div class="row border-bottom p-3">
                    <TableComponent 
                        v-model="hotels" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false },
                        }" 
                        :tableRows="bodyHotelsRows" 
                        :loading="loadingTable"
                        :clickableRows="false"
                    >
                        <template v-slot:title>
                            <div class="col d-flex align-items-center gap-2 py-1 px-3">
                                <img src="@/assets/HotelGroup/hotelsIconTitle.svg" alt="">
                                <span class="table-title">{{ $t('List Of Hotels') }}</span>
                            </div>
                            <div class="col px-3">
                                <span class="table-desc">{{ $t(`This is the application's list of hotels' section, showing each hotel created on our platform, with its connection status and an option to link it to a corresponding hotel in ${integrationParamType}.`) }}</span>
                            </div>
                        </template>
                        <template v-slot:actions="{ item, actionType }">
                            <div class="d-flex justify-content-end">
                                <div v-if="actionType === 'link'">
                                    <CustomButton 
                                        v-if="item.status.value === false"
                                        :label="$t(`Link to ${integrationParamType}`)" 
                                        @clickBtn="openModal(item)" 
                                        :buttonStyle="{
                                            backgroundColor: '#F8F9FA!important',
                                            borderRadius: '4px!important',
                                            border: '1px solid #DEE2E6!important',
                                            color: '#212529!important',
                                            padding: '6px 12px'
                                        }" 
                                    />
                                    <CustomButton 
                                        v-else
                                        :label="$t('Manage')"
                                        @clickBtn="openModal(item)" 
                                        :buttonStyle="{
                                            backgroundColor: '#F8F9FA!important',
                                            borderRadius: '4px!important',
                                            border: '1px solid #DEE2E6!important',
                                            color: '#212529!important',
                                            padding: '6px 12px'
                                        }" 
                                    />
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                </div>
            </template>
        </LayoutForm>
        <!-- ModalAddItems -->
        <ModalAddItems 
            :showModal="modalVisible" 
            :itemsList="availableHotels" 
            :loading="loading"
            @popUpClosed="closeModal"
        >
            <template v-slot:notice>
                <div class="text-start">
                    <span class="table-desc">{{ $t('Select a hotel from the list below to link it to the corresponding hotel in the integration.') }}</span>
                </div>
            </template>
            <template v-slot:actions="{item}">
                <CustomButton 
                    v-if="item.status === false"
                    :label="$t('Connect')" 
                    @clickBtn="connectHotel(item)" 
                    :buttonStyle="{
                        backgroundColor: '#F8F9FA!important',
                        borderRadius: '4px!important',
                        border: '1px solid #DEE2E6!important',
                        color: '#212529!important',
                        padding: '6px 12px'
                    }" 
                    :loading="item.loading"
                />
                <CustomButton 
                    v-else
                    :label="$t('Disconnect')"
                    @clickBtn="disconnectHotel(item)" 
                    :buttonStyle="{
                        backgroundColor: '#F8F9FA!important',
                        borderRadius: '4px!important',
                        border: '1px solid #DEE2E6!important',
                        color: '#212529!important',
                        padding: '6px 12px'
                    }"
                    :loading="item.loading"
                />
            </template>
                    
        </ModalAddItems>
    </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import ModalAddItems from '@/components/popups/ModalAddItems.vue';
import LayoutForm from '@/components/LayoutForm.vue';
import { useAlertStore, useIntegrationStore, useMetricsStore, useHotelStore } from "@/stores";

export default {
    name: 'IntegrationPage',
    components: {
        TableComponent,
        CustomButton,
        ModalAddItems,
        LayoutForm
    },
    setup() {
        const alertStore = useAlertStore();
        const integrationStore = useIntegrationStore();
        const metricsStore = useMetricsStore();
        const hotelStore = useHotelStore();

        return {
            alertStore,
            integrationStore,
            metricsStore,
            hotelStore
        };
    },
    data() {
        return {
            integrationParamType: this.$route.params.integrationType,
            itemsFromApi: [],
            availableHotels: [],
            modalVisible: false,
            bodyHotelsRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'Status', field: 'status'},
                { type: 'Action', field: 'link', hasHeader: false }
            ],
            loadingTable: false,
            loading: false
        };
    },
    computed: {
        hotels() {
            const integrationType = this.capitalizeFirstLetter(this.integrationParamType);
            const integration = this.itemsFromApi?.find(item => item.type === integrationType);

            if (integration) {
                const integrationType = this.integrationParamType + 'Id';

                return integration.company.hotels.map(hotel => ({
                    hotelId: hotel.objectId,
                    name: {
                        image: hotel.picture ?? require('@/assets/no-image.jpg'),
                        firstText: hotel.name
                    },
                    status: this.getHotelStatus(hotel[integrationType]),
                    integrationId: hotel[integrationType]
                }));
            }
            return [];
        }
    },
    async mounted() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loadingTable = true;
                await this.integrationStore.getCompanyIntegrations();
                this.itemsFromApi = this.integrationStore?.companyIntegrationsOutput?.integrations ?? [];
            } catch (error) {
                this.alertStore.error(error);
            } finally {
                this.loadingTable = false;
            }
        },
        async openModal(item) {
            this.modalVisible = true;
            this.loading = true;

            try {
                // Fetch integration hotels based on the integration type
                let integrationHotels = [];
                if (this.integrationParamType === 'mews') {
                    await this.metricsStore.fetchMewsHotels();
                    // get the hotels from mews api service
                    integrationHotels = this.metricsStore.mewsHotelsOutput.map(integrationHotelId => ({
                        id: integrationHotelId,
                        name: integrationHotelId
                    }));
                } else if (this.integrationParamType === 'lighthouse') {
                    await this.metricsStore.fetchLighthouseHotels();
                    // get the hotels from lighthouse api service
                    integrationHotels = Object.values(this.metricsStore.lighthouseHotelsOutput);
                }

                if (item.integrationId === null) {
                    let hotelsNotConnected = [];

                    // Conditional to check the integration type
                    const integrationFilter = this.integrationParamType === 'lighthouse'
                        ? (existingHotel, integrationHotel) => parseFloat(existingHotel.integrationId) === integrationHotel.id
                        : (existingHotel, integrationHotel) => existingHotel.integrationId === integrationHotel.id;

                    // Filter out hotels that are already connected
                    hotelsNotConnected = integrationHotels.filter(integrationHotel => 
                        !this.hotels.some(existingHotel => integrationFilter(existingHotel, integrationHotel))
                    );

                    // Map the filtered hotels to the desired format
                    this.availableHotels = hotelsNotConnected.map(hotel => ({
                        hotelId: item.hotelId,
                        integrationId: hotel.id,
                        name: hotel.name,
                        image: require('@/assets/HotelGroup/hotelsIconTitle.svg'),
                        status: false // Not connected
                    }));
                } else {
                    // Conditional to check the integration type
                    const integrationFilter = this.integrationParamType === 'lighthouse'
                        ? (existingHotel, integrationHotel) => parseFloat(existingHotel.integrationId) === integrationHotel.id
                        : (existingHotel, integrationHotel) => existingHotel.integrationId === integrationHotel.id;

                    // Find the hotel that is currently connected to allow disconnection
                    const connectedHotel = integrationHotels.find(integrationHotel => 
                        integrationFilter(item, integrationHotel)
                    );

                    this.availableHotels = connectedHotel ? [{
                        hotelId: item.hotelId,
                        integrationId: connectedHotel.id,
                        name: connectedHotel.name,
                        image: require('@/assets/HotelGroup/hotelsIconTitle.svg'),
                        status: true // Connected
                    }] : [];
                }
            } catch (error) {
                this.alertStore.error(error);

            } finally {
                this.loading = false;
            }
        },
        async connectHotel(item) {
            try {
                item.loading = true;
                const integrationType = this.integrationParamType + 'Id';
                await this.hotelStore.editHotel(item.hotelId, {
                    [integrationType]: item.integrationId
                });
                this.closeModal();
                await this.fetchData();
            } catch (error) {
                this.alertStore.error(error);
            } finally {
                item.loading = false;
            }
        },
        async disconnectHotel(item) {
            try {
                item.loading = true;
                const integrationType = this.integrationParamType + 'Id';
                await this.hotelStore.editHotel(item.hotelId, {
                    [integrationType]: null
                });
                this.closeModal();
                await this.fetchData();
            } catch (error) {
                this.alertStore.error(error);
            } finally {
                item.loading = false;
            }
        },
        closeModal() {
            this.modalVisible = false;
            this.availableHotels = [];
        },
        capitalizeFirstLetter(string) {
            if (!string) return string; // If string is empty, return it as is
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getHotelStatus(integrationType) {
            return integrationType !== null
                ? { label: this.$t('Connected'), class: 'bg-success text-white', value: true }
                : { label: this.$t('Not Connected'), class: 'bg-danger text-white', value: false };
        }
    },
};
</script>

<style scoped>
.table-title{
    color: var(--theme-dark, #212529);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}
.table-desc{
    color: #6C757D;
    font-weight: 400;
    font-size: .7rem;
}
@media (max-width: 767px) {
    .table-title{
        font-size: 1rem;
    }
}
</style>
