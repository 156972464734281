/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const HOTELGROUP_SERVICE = {
    getList,
    getHotelGroup,
    createHotelGroup,
    editHotelGroup,
    deleteHotelGroup
};

// Root service to build API url
const API_ROOT_SERVICE = "company/hotelGroups";

/* Services */
async function getList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getHotelGroup(slug) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function createHotelGroup(payload) 
{
    if(!payload) {
        return Promise.reject("The parameters are not complete!");
    }

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function editHotelGroup(id, payload) 
{
    if(!payload || !id) {
        return Promise.reject("The parameters are not complete!");
    }
    
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, id]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function deleteHotelGroup(id) 
{
    const requestOptions = {
        method: 'DELETE'
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, id]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}