<template>
    <section id="launching-soon">
        <NoticeComponent 
            :image="require('@/assets/noticeComponent/launchingIcon.svg')" 
            :Title="$t('LaunchingSoon')" 
            :description="$t('WeAreWorkingOnThisFeatureRightNow.JoinOurNewsletterAndGetNotified')" 
            :buttonInfo="$t('NotifyMe')"
            :actionClicked="actionClicked"
        >
        </NoticeComponent>
    </section>
</template>

<script>
    import NoticeComponent from '@/components/NoticeComponent.vue';

    export default {
        components: {
            NoticeComponent
        },
        name: 'LaunchingSoonPage',
        methods: {
            actionClicked() {
                console.log('Notify Me button clicked');
            }
        }
    }
</script>

<style scoped>
#launching-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: none;
}
</style>
