<template>
  <section class="d-none d-md-block">
    <nav class="navbar navbar-light bg-white fixed-top px-3">
      <!-- <router-link v-if="!menuStore.isOpen" class="meteo" :to="{ name: 'MeteorologyPage' }">
        <img class="img-fluid" :src="currentWeatherIcon">
        <div class="d-flex flex-column">
          <span class="title"> {{ getCurrentTemperature }}</span>
          <span class="sub-title"> {{ getCurrentWather }}</span>
        </div>
      </router-link> -->
      <div class="navbar-left-position">
        <router-link class="navbar-brand" :to="{ name: 'DashboardPage' }">
          <img src="@/assets/Header/logo.svg" alt="">
        </router-link>
      </div>
      <div class="navbar-right-position ms-auto">
        <!-- change currency -->
        <!-- <div class="nav-item dropdown">
          <a class="nav-link" href="#" id="currencyDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="@/assets/Header/currency.svg" alt="">
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="currencyDropdown">
            <li class="li-currency dropdown-item" v-for="(currency, index) in currencies" :key="index">
              <span class="notifications-person-name">{{ currency.name }}</span>
            </li>
          </ul>
        </div> -->
        <!-- Notifications Dropdown -->
        <div class="nav-item dropdown">
          <a class="nav-link" href="#" id="notificationsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="@/assets/Header/notificationBell.svg" alt="">
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notificationsDropdown">
            <li class="li-notifications dropdown-item" v-for="(notification, index) in (notificationsFromApi.data ? notificationsFromApi.data.slice(0, 3) : [])" :key="index">
              <img class="image-notification" :src="parseImageData(notification.user.picture) ?? require('@/assets/no-image.jpg')" alt="">
              <div class="d-flex flex-column">
              <a class="dropdown-item p-1" href="#">
                <span class="notifications-person-name">{{ notification.user.name }}</span>
                <span>{{$t("performed")}}</span>
                <span class="notifications-changed-file">{{ this.$t(translateAction(notification.action)) }}</span>
              </a>
              <span class="change-desc">{{ this.timeDifference(notification.createdAt) }}</span>
              </div>
            </li>
            <!-- <li class="li-notifications dropdown-item">
              <img src="../assets/Header/avatar2.svg" alt="">
              <div class="d-flex flex-column">
                <a class="dropdown-item p-1" href="#">
                  <span class="notifications-person-name">Marie Clarke</span>
                  <span>request acess to</span>
                  <span class="notifications-changed-file">Pickup</span>
                </a>
                <span class="change-desc">Yesterday - Hotel Example</span>
                <div class="d-flex flex-row gap-1 div-aprove-devy">
                  <button class="aprove-btn">
                    Aprove
                    <img src="../assets/Header/aprove.svg" alt="">
                  </button>
                  <button class="deny-btn">
                    Deny
                    <img src="../assets/Header/deny.svg" alt="">
                  </button>
                </div>
              </div>
            </li> -->
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item d-flex justify-content-center flex-row gap-1 cursor-pointer">
              <img src="../assets/Header/seeAll.svg" alt="">
              <a class="notifications_achor" href="/notifications"><span>See all</span></a>
            </li>
          </ul>
        </div>
        <!-- user Dropdown -->
        <div class="nav-item dropdown">
          <a class="nav-link" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img class="user-img" :src="headerUserImage" alt="">
            <div class="d-flex flex-column">
              <span class="nav-name">{{headerUserName}}</span>
              <div class="d-flex flex-row gap-1">
                <img class="img-fluid" src="@/assets/Header/email.svg" alt="">
                <span class="nav-email">{{userStorage.user.email}}</span>
              </div>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end p-2" aria-labelledby="userDropdown">
            <li class="li-avatar dropdown-item">
              <img src="../assets/Header/accountSettings.svg" alt="">
              <router-link class="dropdown-item p-1" :to="{ name: 'AccountSettingsPage' }">{{ $t('myAccount') }}</router-link>
            </li>
            <li class="li-avatar dropdown-item">
              <img src="@/assets/sideBar/budget.svg" alt="">
              <router-link class="dropdown-item p-1" :to="{ name: 'UpgradePlanPage' }">{{ $t('billing') }}</router-link>
            </li>
            <li class="li-avatar dropdown-item" @click="usersStore.logout()">
              <img src="../assets/Header/logout.svg" alt="">
              <span class="dropdown-item p-1 cursor-pointer">{{$t("Logout")}}</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
  <section class="d-block d-md-none">
    <MobileMenu :currentItem="currentItem" @activeItem="activeItem" @activeNestedItem="currentItem = 1" />
  </section>
</template>

<script>
import { useMeteoStore, useUsersStore, useMyStore } from '@/stores';
import MobileMenu from "@/layouts/MobileMenu.vue"
import { nextTick } from 'vue';
export default {
  components: {
    MobileMenu
  },
  setup() {
    const meteoStore = useMeteoStore();
    const usersStore = useUsersStore();
    const myStore = useMyStore();

    return {
      meteoStore,
      usersStore,
      myStore
    }
  },
  data() {
    return {
      currentItem: undefined,
      meteoInfo: {},
      currencies: [
        { name: 'EUR' },
        { name: 'USD' },
        { name: 'GBP' },
        { name: 'YEN' },
        { name: 'INR' },
      ],
      userStorage: JSON.parse(localStorage.getItem('user')) ?? {},
      notificationsFromApi: [],
      actionTranslations: {
        'user.login': 'user.login',
        'user.update': 'user.update',
        'user.update.password': 'user.update.password',
        'company.create': 'company.create',
        'company.active.read': 'company.active.read',
        'company.active.update': 'company.active.update',
        'company.active.delete': 'company.active.delete',
        'company.active.switch': 'company.active.switch',
        'company.read': 'company.read',
        'company.users.read': 'company.users.read',
        'company.user.read': 'company.user.read',
        'company.user.create': 'company.user.create',
        'company.user.update': 'company.user.update',
        'company.user.delete': 'company.user.delete',
        'hotels.read': 'hotels.read',
        'hotel.read': 'hotel.read',
        'hotel.create': 'hotel.create',
        'hotel.update': 'hotel.update',
        'hotel.delete': 'hotel.delete',
        'hotel.groups.read': 'hotel.groups.read',
        'hotel.group.read': 'hotel.group.read',
        'hotel.group.create': 'hotel.group.create',
        'hotel.group.update': 'hotel.group.update',
        'hotel.group.delete': 'hotel.group.delete',
        'hotel.group.hotel.delete': 'hotel.group.hotel.delete'
      }
    };
  },
  computed: {
    getCurrentWather() {
      return this.meteoInfo && this.meteoInfo.current && this.meteoInfo.current.weather ? this.meteoInfo.current.weather[0].main : null;
    },
    currentWeatherIcon() {
      return this.meteoInfo && this.meteoInfo.current && this.meteoInfo.current.weather ? "http://openweathermap.org/img/w/" + this.meteoInfo.current.weather[0].icon + ".png" : null;
    },
    getCurrentTemperature() {
      return this.meteoInfo && this.meteoInfo.current ? `${Math.round(this.meteoInfo.current && this.meteoInfo.current['temp'])}°C` : null;
    },
    headerUserName() {
      return this.usersStore.headerUserInformation?.name ?? '';
    },
    headerUserImage() {
      return this.parseImageData(this.usersStore.headerUserInformation?.picture) ?? require('@/assets/no-image.jpg');
    },
  },
  mounted() {
    // this.getMeteoInfo();
    this.usersStore.initializeHeaderUserInformation();
    this.fetchData();
  },
  methods: {
    activeItem(index) {
      this.currentItem = index;
    },
    clickBtn() {
      this.$router.push({ name: 'EditPlan' });
    },
    parseImageData(imageDataString) {
      try {
        if(!Object.keys(imageDataString).length) return null;
        else {
          // Parse the JSON string if it is a string
          const parsedData = typeof imageDataString === 'string' 
          ? JSON.parse(imageDataString) 
          : imageDataString;
          return `data:${parsedData.mime};base64,${parsedData.data}`;
        }
      } catch (e) {
        return imageDataString;
      }
    },
    async fetchData() {
        try {
          await this.myStore.getActivityList();
          nextTick(() => {
              this.notificationsFromApi = this.myStore.getMyActivityListOutput;
          });
        } catch (error) {
            this.alertStore.error(error);
        }
    },
    translateAction(action) {
      return this.actionTranslations[action] ?? action;
    },
    timeDifference(timestamp) {
      const now = new Date();
      const createdAt = new Date(timestamp);
      const diffInSeconds = Math.floor((now - createdAt) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays > 0) {
        return `${diffInDays} ${this.$t('daysAgo')}`;
      } else if (diffInHours > 0) {
        return `${diffInHours} ${this.$t('hoursAgo')}`;
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes} ${this.$t('minutesAgo')}`;
      } else {
        return `${diffInSeconds} ${this.$t('secondsAgo')}`;
      }
    }
    // async getMeteoInfo() {
    //   let meteoStorage = JSON.parse(localStorage.getItem('currentMeteo')) ?? {};
    //   let lat = meteoStorage.lat ?? 41.1496;
    //   let lon = meteoStorage.lon ?? -8.611;
    //   await this.meteoStore.getList(lat, lon);
    //   await this.meteoStore.getAirPollution(lat, lon);
    //   this.meteoInfo = this.meteoStore.currentMeteoOutput;
    // }
  },
  watch: {
    '$route'(val) {
      if (val.name === 'DashboardPage') {
        this.currentItem = undefined;
      }
    },
    'meteoStore.currentMeteoOutput': {
      handler(newValue) {
        if(newValue) {
          this.meteoInfo = newValue;
        }
      }
    }
      
  },
};
</script>

<style scoped>
/* navbar styles */
.navbar {
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #CED4DA;
}

.navbar .meteo {
  display: flex;
  margin-left: 110px;
  gap: 0.5rem;
  align-items: center;
  text-decoration: none;
}

.navbar .navbar-right-position {
  justify-content: flex-end;
  gap: 1rem;
  display: flex;
}

.navbar-right-position .nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: .5rem 1rem;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.nav.navbar .navbar-right-position .nav-item.dropdown .nav-link img {
  width: 18px;
  height: 18px;
}

.user-img {
  width: 30px;
  height: 30px;
}

.nav-name {
  font-size: .7rem;
  color: var(--theme-dark);
  font-weight: 600;
}

.nav-email {
  font-size: .6rem;
}

.dropdown-menu {
  border-radius: 10px;
  background: #FFF;
  border: none !important;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  z-index: 9999;
}

.li-currency {
  display: flex;
  width: 150px;
  padding: 8px 16px;
  border-radius: 10px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  margin: 10px 15px;
}

.li-notifications {
  display: flex;
  width: 600px;
  padding: 8px 16px;
  border-radius: 10px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  margin: 10px 15px;
}

.li-avatar {
  display: flex;
  padding: 0 2rem;
}

.notifications_achor {
  text-decoration: none;
  color: var(--theme-secondary);
}

.image-notification {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.notifications-person-name {
  font-size: 15px;
  font-weight: bold;
  padding-right: 5px;
  padding-left: 10px;
}

.notifications-changed-file {
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
}

.change-desc {
  font-size: 10px;
  color: var(--theme-secondary);
  padding-left: 14px;
}

.div-aprove-devy {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
}

.aprove-btn {
  background-color: var(--theme-primary);
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 8px;
  display: flex;
  width: 71px;
  height: 25px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.deny-btn {
  color: var(--theme-secondary);
  border-radius: 4px;
  border: 1px solid var(--theme-border, #DEE2E6);
  background: var(--theme-light, #F8F9FA);
  font-size: 8px;
  display: flex;
  width: 61px;
  height: 25px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--theme-primary);
}

.meteo .title {
  font-size: 12px;
  color: var(--theme-dark, #212529);
  font-weight: 500;
  line-height: 18px;
}

.meteo .sub-title {
  font-size: 12px;
  color: var(--gray-600, #6C757D);
  font-weight: 500;
  line-height: 18px;
}

.dropdown-item:active{
  background-color: var(--theme-primary);
  color: white!important;
}
.li-avatar:hover img{
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.li-avatar:active img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}
</style>