<template>
    <BasePopUp :showModal="showModal" @popUpClosed="$emit('popUpClosed')">
        <template v-slot:body>
            <section id="invite" class="p-4">
                <div v-show="!isSuccess">
                    <div class="modal-header d-flex flex-column align-items-center gap-3">
                        <img class="img-fluid" src="@/assets/Users/invite-user.svg" alt="invite-user-image" />
                        <h5 class="modal-title fw-bold">{{ $t('Add new member') }}</h5>
                        <p class="text-center">{{ $t('You need to send an invite to the new member.') }}</p>
                    </div>
                    <div class="modal-body mb-3">
                        <Form 
                            ref="form"
                            @submit="inviteUser" 
                            v-slot="{ errors }"
                        >
                            <div class="form-group mb-4">
                                <label class="mb-2" for="email">{{ $t('Member Email *') }}</label>
                                <Field 
                                    name="email" 
                                    class="form-control" 
                                    :class="{ 'is-invalid': errors.email }"
                                    v-model="email"
                                    :placeholder="$t('Insert member email')"
                                    rules="required|email"
                                />
                                <ErrorMessage name="email" class="text-danger" />                            
                            </div>
                            <div class="modal-footer">
                                <button type="submit" :class="{'disabled': loading}" class="btn">
                                    <span v-if="!loading">{{ $t('Send Invitation') }}</span>
                                    <span v-else>
                                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        <span>{{ $t('loading') }}</span>
                                    </span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div v-if="isSuccess" class="modal-header d-flex flex-column align-items-center gap-3">
                    <img class="img-fluid" src="@/assets/Users/invite-sent.svg" alt="invite-user-image" />
                    <h5 class="modal-title fw-bold">{{ $t('Invitation sent successfully!') }}</h5>
                    <p class="text-center">{{ $t('An invitation has been sent to the email of the new member with an access link. You will be notified once they accept.') }}</p>
                </div>
            </section>
        </template>
    </BasePopUp>
</template>

<script>
import BasePopUp from '@/components/popups/BasePopUp.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useAlertStore, useCompanyUsersStore } from '@/stores';

export default {
    components: {
        BasePopUp,
        Form,
        Field,
        ErrorMessage
    },
    setup() {
        const alertStore = useAlertStore();
        const companyUsersStore = useCompanyUsersStore();

        return {
            alertStore,
            companyUsersStore
        };
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            loading: false,
            isSuccess: false
        };
    },
    methods: {
        async inviteUser(values) {
            const { email } = values;
            this.loading = true;
            try {
                await this.companyUsersStore.inviteUsers(email);
                this.loading = false;
                this.isSuccess = true;
                this.$emit('inviteSent');
            }
            catch (error) {
                this.alertStore.error(error);
                this.loading = false;
                this.isSuccess = false;
            }
        }
    },
    watch: {
        showModal(val) {
            if(val) {
                this.email = '';
                this.$refs.form.resetForm();
                this.isSuccess = false;
            }
        }
    }
};
</script>

<style scoped>
.form-group,
input::placeholder {
    font-size: .8rem;
}
.modal-footer button {
    width: 100%;
    background-color: var(--theme-primary);
    border-radius: 4px;
    color: white;
}
.modal-footer button:active {
    background-color: var(--theme-primary);
    color: white;
}

</style>
