<template>
    <div class="container-fluid">
        <BoldTitle :title="$t('pricingDistribution')" />
        <div class="container-wrapper mt-5">
            <div class="row align-items-center p-4">
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <div class="dropdown no-select">
                            <IconTitleButton :firstIcon="require('@/assets/PricingDistribution/cash-stack.svg')"
                                :label="$t('pricingDistribution')"
                                @toggleDropdown="toggleDropdown('pricingDistribution')" />
                            <DropdownMenu :isOpen="isDropdownPricingDistributionOpen"
                                @close-dropdown="isDropdownPricingDistributionOpen = false"
                                :customClass="['pricing-distribution-dropdown-menu']"
                                :currentItem="currentPricingDistributionItem" :items="dropdownPricingDistributionItems"
                                @handle-body-click="((index) => console.log(index))">
                            </DropdownMenu>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="d-flex justify-content-end align-items-center gap-4">
                        <div class="metrics-dropdown dropdown no-select">
                            <CustomButton :leftIcon="require('@/assets/Pickup/graph-up-white.svg')"
                                :label="$t('changeMetrics')" @clickBtn="toggleDropdown('metrics')" :buttonStyle="{
                                    borderRadius: '8px!important',
                                    gap: '8px',
                                    color: '#FFFFFF',
                                    padding: '6px 12px'
                                }" />
                            <DropdownMenu :isOpen="isDropdownMetricsOpen" @close-dropdown="isDropdownMetricsOpen = false"
                                :hasSubMenu="true" :customClass="['metrics-dropdown-menu']"
                                :currentItem="currentMetricsItem" :items="dropdownMetricsItems"
                                @handle-previous-click="currentMetricsItem = 0" @handle-body-click="handleClick">
                                <template v-slot:dropdown-subItem-1>
                                    <CheckboxList :isOpen="isSubMenuOpen" :customClass="['check-list']"
                                        :items="websiteItems" :selectedButton="true" :selectedItems="selectedWebSiteItems"
                                        @item-click="handleCheckboxClick($event, 'websiteItems')"
                                        @confirm-selection="confirmSelectedItems($event, 'selectedWebSiteItems')">
                                    </CheckboxList>
                                </template>
                                <template v-slot:dropdown-subItem-2>
                                    <DateButton :date="date" @update-date="updateDateProperty('date', $event)" typeForm="2"
                                        :label="$t('selectDate')" :selected-button="true"
                                        @confirm-selection="confirmSelectedDate($event)" />
                                </template>
                            </DropdownMenu>
                        </div>
                        <div class="hotel-dropdown dropdown no-select">
                            <DropdownButton :label="$t('hotel')" :icon="require('@/assets/Pickup/house.svg')"
                                @clickBtn="toggleDropdown('hotel')" />
                            <CheckboxList :isOpen="isDropdownHotelOpen" @close-dropdown="isDropdownHotelOpen = false"
                                :customClass="['hotel-dropdown-menu']" :items="dropdownHotelItems" :avatar="true"
                                @item-click="handleCheckboxClick($event, 'dropdownHotelItems')">
                            </CheckboxList>
                        </div>
                    </div>
                </div>
            </div>
            <DividerComponent />
            <div class="d-flex flex-wrap my-5 p-4 gap-3">
                <StatisticsBlocks :statistics="statistics" />
            </div>
            <DividerComponent />
            <div class="mt-5 p-4">
                <TableComponent v-model="itemsFromApi" :layout="{
                    hasHeader: { desktop: true, mobile: false }
                }" :tableHeaders="tableHeaders" :tableRows="tableRows">
                    <template v-slot:title>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <BoldTitle :title="$t('metrics')" />
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="d-flex justify-content-end gap-4 align-items-center">
                                    <DateButton @update-date="updateDateProperty('metricsTableDate', $event)" typeForm="1"
                                        :label="$t('date')" />
                                    <ThreeVerticalDots :dynamicClass="'three-dots-menu'"
                                        :dropdownItems="threeDotsSettings" />
                                </div>
                            </div>
                        </div>
                    </template>
                </TableComponent>
            </div>
        </div>
    </div>
</template>
<script>
import BoldTitle from '@/components/BoldTitle.vue';
import IconTitleButton from '@/components/IconTitleButton.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import CustomButton from '@/components/CustomButton.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import CheckboxList from '@/components/CheckboxList.vue';
import DateButton from '@/components/DateButton.vue';
import StatisticsBlocks from '@/components/StatisticsBlocks.vue';
import TableComponent from '@/components/TableComponent.vue';

export default {
    components: {
        BoldTitle,
        IconTitleButton,
        DividerComponent,
        DropdownMenu,
        CustomButton,
        DropdownButton,
        CheckboxList,
        DateButton,
        StatisticsBlocks,
        TableComponent
    },
    setup() {
        return {}
    },
    data() {
        return {
            //table
            tableHeaders: [
                {
                    icon: require('@/assets/Pickup/calendar2-fill.svg'),
                    text: 'Date'
                },
                {
                    icon: require('@/assets/Pickup/bar-chart.svg'),
                    text: 'RN'
                },
                {
                    icon: require('@/assets/Pickup/graph-up.svg'),
                    text: 'OCC'
                },
                {
                    icon: require('@/assets/Pickup/cash-stack.svg'),
                    text: 'Revenue'
                },
                {
                    icon: require('@/assets/Pickup/list-stars.svg'),
                    text: 'ADR'
                },
                {
                    icon: require('@/assets/Pickup/activity.svg'),
                    text: 'RevPAR'
                },
                {
                    icon: require('@/assets/MarketAnalysis/pin-map.svg'),
                    text: 'Demand Location'
                },
                {
                    icon: require('@/assets/MarketAnalysis/cash-coin.svg'),
                    text: 'Recomend Price'
                },
                {
                    icon: require('@/assets/MarketAnalysis/arrow-bar-right.svg'),
                    text: 'Recomed Action'
                },
            ],
            tableRows: [
                { type: 'BoldText', field: 'date' },
                { type: 'SimpleText', field: 'rn' },
                { type: 'SimpleText', field: 'occ' },
                { type: 'SimpleText', field: 'revenue' },
                { type: 'SimpleText', field: 'adr' },
                { type: 'SimpleText', field: 'revPar' },
                { type: 'SimpleText', field: 'demandLocation' },
                { type: 'SimpleText', field: 'recomendPrice' },
                { type: 'SimpleText', field: 'recomendAction' },
            ],
            itemsFromApi: [
                { date: 'Mon 10', rn: '100', occ: '80%', revenue: '$1000', adr: '$100', revPar: '$80', demandLocation: 'Porto', recomendPrice: '13€', recomendAction: 'Push Price' },
                { date: 'Mon 10', rn: '100', occ: '80%', revenue: '$1000', adr: '$100', revPar: '$80', demandLocation: 'Porto', recomendPrice: '13€', recomendAction: 'Push Price' },
                { date: 'Mon 10', rn: '100', occ: '80%', revenue: '$1000', adr: '$100', revPar: '$80', demandLocation: 'Porto', recomendPrice: '13€', recomendAction: 'Push Price' },
                { date: 'Mon 10', rn: '100', occ: '80%', revenue: '$1000', adr: '$100', revPar: '$80', demandLocation: 'Porto', recomendPrice: '13€', recomendAction: 'Push Price' }
            ],
            // dates
            date: null,
            metricsTableDate: null,
            // pricing distribution dropdown
            isDropdownPricingDistributionOpen: false,
            currentPricingDistributionItem: 0,
            dropdownPricingDistributionItems: {
                header: [{ id: 0, icon: require('@/assets/sideBar/hamburger.svg'), label: this.$t('pickUp'), alt: 'menu-hamburger' }],
                body: [
                    {
                        id: 0,
                        icon: require('@/assets/Pickup/calendar-check.svg'),
                        label: this.$t('general'),
                        alt: 'calendar'
                    },
                    {
                        id: 1,
                        icon: require('@/assets/Pickup/list-ul.svg'),
                        label: this.$t('segmented'),
                        alt: 'lock-fill'
                    },
                ]
            },
            // metrics dropdown
            isDropdownMetricsOpen: false,
            currentMetricsItem: 0,
            dropdownMetricsItems: {
                header: [{ id: 0, icon: require('@/assets/Pickup/graph-up.svg'), label: this.$t('metrics'), alt: 'graph-up' }],
                body: [
                    {
                        id: 0,
                        icon: require('@/assets/Competitors/cursor.svg'),
                        label: this.$t('website'),
                        alt: 'cursor'
                    },
                    {
                        id: 1,
                        icon: require('@/assets/Pickup/calendar-check.svg'),
                        label: this.$t('date'),
                        alt: 'calendar-check'
                    }
                ]
            },
            // sub-menu configuration in the metrics dropdown
            isSubMenuOpen: false,
            selectedWebSiteItems: [],
            websiteItems: [{ id: 0, label: 'Booking' }, { id: 1, label: 'Expedia' }
            ],
            // hotel dropdown
            isDropdownHotelOpen: false,
            dropdownHotelItems: [
                {
                    id: 0,
                    label: "Cystal Breeze Resort California",
                    avatar: require('@/assets/avatar.png')
                },
                {
                    id: 1,
                    label: "Cystal Breeze Resort California",
                    avatar: require('@/assets/avatar.png')
                }
            ],
            selectedHotelItems: [],
            // statistics blocks
            statistics: [
                {
                    id: 0,
                    title: 'Room Nights',
                    value: '920',
                    comparison: '+102 than June',
                },
                {
                    id: 1,
                    title: 'Occupancy',
                    value: '70%',
                    comparison: '30% than June',
                },
                {
                    id: 2,
                    title: 'Revenue',
                    value: '200.000',
                    comparison: '30% than June',
                },
                {
                    id: 3,
                    title: 'ADR',
                    value: '800',
                    comparison: '+20 than June',
                },
                {
                    id: 4,
                    title: 'RevPAR',
                    value: '20.000',
                    comparison: '30% than June',
                },
            ],
        }
    },
    methods: {
        toggleDropdown(dropdownName) {
            this.isDropdownPricingDistributionOpen = dropdownName === "pricingDistribution" && !this.isDropdownPricingDistributionOpen;
            this.isDropdownMetricsOpen = dropdownName === "metrics" && !this.isDropdownMetricsOpen;
            this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
        },
        handleCheckboxClick(index, propertyName) {
            const selectedItem = this[propertyName][index];
            selectedItem.checked = !selectedItem.checked;
            // Only for hotel dropdown (confirmation button not required)
            if (this.isDropdownHotelOpen) {
                // Check if the item is already in the selectedHotelItems array
                const selectedIndex = this.selectedHotelItems.findIndex(item => item.id === selectedItem.id);
                if (selectedItem.checked && selectedIndex === -1) {
                    // If the item is selected and not in the array, add it
                    this.selectedHotelItems.push(selectedItem);
                } else if (!selectedItem.checked && selectedIndex !== -1) {
                    // If the item is deselected and in the array, remove it
                    this.selectedHotelItems.splice(selectedIndex, 1);
                }
            }
        },
        updateDateProperty(propertyName, newValue) {
            this[propertyName] = newValue;
        },
        handleClick(index) {
            this.currentMetricsItem = index;
            this.isSubMenuOpen = true;
        },
        confirmSelectedItems(items, propertyName) {
            this[propertyName] = items.filter((item) => item.checked);
        },
        confirmSelectedDate(date) {
            console.log(date)
        },
    },
    watch: {
        selectedWebSiteItems(newValue, oldValue) {
            console.log(oldValue)
            console.log(newValue)
        },
        selectedHotelItems: {
            handler(newValue) {
                console.log(newValue)
            },
            deep: true
        }
    },
}
</script>
<style scoped>
.container-wrapper {
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.pricing-distribution-dropdown-menu {
    min-width: 240px;
    left: 210px;
    top: -30px;
}

.metrics-dropdown-menu {
    min-width: 300px;
    left: -320px;
    top: 0;
}

.hotel-dropdown-menu {
    min-width: 450px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 0px #00000013;
    right: 0;
    top: 50px;
}

.check-list {
    border: none;
    width: 100%;
}
</style>