<template>
  <BaseSign>
    <template v-slot:content>
      <Form class="form" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
        <template v-if="showRecover == false">
          <LoginForm 
            @loginClicked="onSubmit" 
            :errors="errors" 
            :isSubmitting="isSubmitting" 
            :loading="loading"
            :hasError="hasError" 
            :getErrorMessages="getErrorMessages" 
            :apiErrors="apiErrors"
            @goToShowRecover="goToShowRecover" 
          />
        </template>
        <template v-else>
          <RecoverPasswordForm 
            @recoverClicked="onSubmit" 
            :errors="errors" 
            :isSubmitting="isSubmitting" 
            :loading="loading"
            :hasError="hasError" 
            :getErrorMessages="getErrorMessages" 
            :recoverSuccess="recoverSuccess" 
          />
        </template>
        <div v-if="showRecover == false" class="col-12 mt-5">
          <span>{{ $t('dontHaveAnAccount?') }} <a class="new-route" @click="sendToSignUp">{{ $t('signUp') }}</a></span>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center gap-2 mt-5 cursor-pointer"
          @click="goToSignIn">
          <img src="@/assets/authentication/create-new-Password/arrow-left.svg" alt="icon">
          <span class="back-login-btn">{{ $t('backToLogin') }}</span>
        </div>
      </Form>
      <div class="col-12 mt-5">
        <div class="d-flex flex-column">
          <span class="text-start" :style="{ fontSize: '0.8rem' }">{{ $t('protectedByReCAPTCHAAndSubjectToThe') }}
            <a class="privacy-policy" href="https://revenuemasterio.pt/static/42yf2GbGgfrnnOrLAznF04" target="_blank">{{ $t('revenueMasterPrivacyPolicyAndTermsOfService.') }} </a>
          </span>
        </div>
      </div>
    </template>
  </BaseSign>
</template>

<script>
import BaseSign from "@/components/BaseSign.vue";
import { useUsersStore, useAlertStore } from '@/stores';
import LoginForm from '@/components/Authentication/LoginForm.vue';
import RecoverPasswordForm from '@/components/Authentication/RecoverPasswordForm.vue';
import { Form } from 'vee-validate';
import * as Yup from 'yup';

export default {
  components: {
    Form,
    BaseSign,
    LoginForm,
    RecoverPasswordForm
  },
  setup() {
    const usersStore = useUsersStore();
    const alertStore = useAlertStore();

    return {
      usersStore,
      alertStore
    };
  },
  data() {
    return {
      showRecover: false,
      loading: false,
      password: '',
      recoverSuccess: false,
      apiError: '' // Add apiError to handle API error messages
    }
  },
  computed: {
    schema() {
      if (this.showRecover == false) {
        return Yup.object({
          email: Yup.string().required(this.$t('emailRequired')),
          password: Yup.string().required(this.$t('passwordRequired')),
        })
      } else {
        return Yup.object({
          email: Yup.string().required(this.$t('emailRequired')),
        })
      }
    },
    apiErrors: function () {
      return this.apiError ? { output: this.apiError } : {};
    },
  },
  methods: {
    onSubmit(values) {
      if (!this.showRecover) {
        this.loginClicked(values);
      } else {
        this.recoverClicked(values)
      }
    },
    async loginClicked(values) {
      this.alertStore.clear();
      this.loading = true;
      this.apiError = ''; // Clear previous API error
      const { email, password } = values;
      try {
        const response = await this.usersStore.login(email, password);
        
        if (response && response.status === 205) {
          const data = {
            email: email,
            password: password,
          };
          const item = {
            value: data,
            expiry: new Date().getTime() + 1 * 60 * 5000 // Expiration time in milliseconds
          };
    
          localStorage.setItem('2FAUser', JSON.stringify(item)); // Store data in localStorage
          this.$router.push({ name: 'SecretCode' });
          return;
        }else{
          const userData = response.data.user;
          if(userData.invites_received && userData.invites_received.length) {
            // Redirect to the first invitation
            const firstInvitation = userData.invites_received[0];
            this.$router.push({ 
              name: 'InvitationsPage',
              params: { 
                invitationToken: firstInvitation.token 
              }
            });
            return;
          }
          let userStorage = JSON.parse(localStorage.getItem('user')) || {};
           // Dynamically update the language in the application
           this.$i18n.locale = userStorage.user.language.toLowerCase(); // Set the i18n locale

          if ((userData.isFirstLogin === 1 || !userData.companies.length) && !userStorage.user?.companies[0]?.plan) {
            this.$router.push({ name: 'CompleteProfile' });
          } else {
            // The ideal solution would be to use router.push.
            // However, with router.push, the currency defined in vue-i18n wasn't updating 
            // because vue-i18n had already been initialized.
            // So we can restart the entire app, including vue-i18n, and the currency will load its value from local storage.
            window.location.href = '/'; 
            // this.$router.push({ name: 'DashboardPage' });
          }
        }

        this.loading = false;
      }
      catch (error) {
        this.apiError = this.$t('wrongAccountDetailsOrPassword.');
        this.loading = false;
      }
    },
    async recoverClicked(values) {
      console.log(values)
      this.alertStore.clear();
      // this.loading = true;
      // const { email } = values;
      this.recoverSuccess = true;
      // try {
      //   await this.usersStore.login(email);
      //   this.loading = false;
      //   this.recoverSuccess = true;
      //   this.$router.push({ name: 'DashboardPage' });
      // }
      // catch (error) {
      //   this.alertStore.error(error);
      //   this.loading = false;
      // }
    },
    hasError(errors, label) {
      return errors[label] || this.hasApiError(label);
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    },
    getErrorMessages(errors, labels) {
      const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
      return errorMessages.length ? errorMessages.join('<br>') : errorMessages.concat(this.apiErrors.output);
    },
    sendToSignUp() {
      this.alertStore.clear();
      this.$router.push({ name: 'SignUp' });
    },
    goToShowRecover() {
      this.alertStore.clear();
      this.showRecover = true;
    },
    goToSignIn() {
      this.alertStore.clear();
      this.showRecover = false;
      this.recoverSuccess = false;
      this.$router.push({ name: 'SignIn' });
    },
  }
}
</script>

<style scoped>
.new-route {
  color: var(--theme-primary);
  text-decoration: underline;
  cursor: pointer;
}

.back-login-btn {
  cursor: pointer;
  color: var(--theme-secondary);
}

.privacy-policy {
  color: var(--theme-primary);
}
</style>