<template>
    <button id="dropdown-button" class="d-flex nav-link" @click="handleButtonClick">
      <div class="wrapper w-100">
        <img v-if="icon" :src="icon" alt="icon" width="16" height="16" />
        <span class="title">{{ truncatedLabel }}</span>
      </div>
      <div v-if="hasValue && ((typeof hasValue === 'object' && Object.keys(hasValue).length && hasValue.keyValue !== null) || (Array.isArray(hasValue) && hasValue.length))" class="icon" @click.stop="resetFilter">        
        <img src="@/assets/Popup/x.svg" alt="close" />
      </div>
      <div v-else class="icon">
        <img src="@/assets/Pickup/chevron-down.svg" alt="chevron-down" />
      </div>
    </button>
  </template>
  
  <script>
  export default {
    props: {
      icon: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      maxLength: {
        type: Number,
        default: 32, // Maximum character length before truncating
      },
      hasValue: Object || Array,
    },
    computed: {
      truncatedLabel() {
        // Truncate the label if it exceeds maxLength
        return this.label.length > this.maxLength
          ? this.label.substring(0, this.maxLength) + "..."
          : this.label;
      },
    },
    methods: {
      handleButtonClick(event) {
        // Prevent click event from propagating to the document
        event.stopPropagation();
        this.$emit("clickBtn");
      },
      resetFilter() {
        this.$emit("resetFilter");
      },
    },
  };
  </script>
  
<style scoped>
  #dropdown-button {
    width: 100%;
    min-width: 200px;
  }
  button .wrapper {
    display: flex;
    gap: 8px;
    padding: 6px 12px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #ced4da;
    background-color: transparent;
    align-items: center;
    overflow: hidden; /* Make sure content inside stays within boundaries */
  }
  
  button .wrapper .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #6c757d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Ensure the title takes full width of container */
    display: block; /* Ensures ellipsis applies correctly */
  }
  
  button .icon {
    padding: 6px 12px 6px 12px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid #ced4da;
    border-left: none;
    background-color: #e9ecef;
  }
  @media (max-width: 768px) {
    #dropdown-button {
      width: 100%;
      min-width: unset!important;
    } 
  }
</style>
  