/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import { useUsersStore } from '@/stores';

/* Exports */
// API services that can be invoked on the modules
export const USER_SERVICE = {
    login,
    loginSecret,
    processLogin,
    signup,
    getUserData,
    authenticatorQrCode,
    authenticatorVerify,
    emailCodeSecret,
    changeMe,
    refreshToken,
    resetPassword,
    passwordChange,
    // confirmAccount,
    // requestConfirmToken
};

// Root service to build API url
const API_ROOT_SERVICE = "auth";
const API_ROOT_SERVICE_USER = "user";


/* Services */
async function login(email, password) {
    // Mandatory parameters
    if(!email || !password) {
        return Promise.reject("The parameters are not complete!");
    }

    var raw = JSON.stringify({ email, password });

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw
    };

    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "login"]);
    return await processLogin(url, requestOptions);
}

async function loginSecret(email, password, secret) {
    // Mandatory parameters
    if(!email || !password || !secret)
    {
        return Promise.reject("The parameters are not complete!");
    }

    var raw = JSON.stringify({email, password, secret});

    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw
    };

    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "login"]);
    return await processLogin(url, requestOptions);
}

async function processLogin(url, requestOptions) {        
    const response = await fetch(encodeURI(url), requestOptions);
    const authStore = useUsersStore();
    
    if (ENVIRONMENT_HELPER.isDev()) console.log(response);
    const text = await response.text();
    const data = text && JSON.parse(text);
        
    if (!response.ok) {
        const errorData = data;
        return Promise.reject(errorData);
    }

    // Update pinia state
    authStore.user = data;
    // stored some details in local storage when the user logged in with 2fa enabled
    const storedItem = localStorage.getItem('2FAUser');
    // remove the stored item if it exists
    if(storedItem) localStorage.removeItem('2FAUser');

    // Login successful if there's a jwt token in the response
    if (authStore.user.access_token) {
        // Store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(authStore.user));
    }

    return { status: response.status, data: authStore.user };
}



async function signup(input) {  
    // Mandatory parameters
    if (!input || !Object.values(input).some(v => v)) {
      return Promise.reject("The parameters are not complete!");
    }
  
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input)
    };
  
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "register"]);
    // Process API call
    return await API_HELPER.apiCall(url, null, requestOptions);
}

async function getUserData() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "me"]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function changeMe(payload) {  

    var raw = JSON.stringify(payload);
    const requestOptions = {
      method: 'PUT',
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
      body: raw,
    };
    
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "me"]);
    // Process API call
    return await API_HELPER.apiCall(url, null, requestOptions);
}
async function authenticatorQrCode() {  

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
    };
  
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "authenticator-qr-code"]);
    // Process API call
    return await API_HELPER.apiCall(url, null, requestOptions);
}
async function authenticatorVerify(payload) {  

    var raw = JSON.stringify(payload);

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
      body: raw
    };
  
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "authenticator-verify"]);
    // Process API call
    return await API_HELPER.apiCall(url, null, requestOptions);
}
async function emailCodeSecret(code, email) {  
    const requestBody = JSON.stringify({ email });

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json"},
      body: requestBody
    };
  
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL_HOOKS, API_ROOT_SERVICE_USER, code, "/activate"]);
    // Process API call
    return await API_HELPER.apiCall(url, null, requestOptions);
}

async function refreshToken() {
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token },
    };
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "refresh"]);
  
    const response = await API_HELPER.apiCall(url, null, requestOptions);
    return response;
}

async function resetPassword(username, password) 
{
    // Mandatory parameters
    if(!username)
    {
        return Promise.reject("The parameters are not complete!");
    }
        
    var raw = JSON.stringify({username, password});
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw
    };
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "reset-password"]);

    return await API_HELPER.apiCall(url, null, requestOptions);    
}
async function passwordChange(current_password, new_password, new_password_confirmation) 
{
    // Mandatory parameters
    if(!current_password || !new_password || !new_password_confirmation)
    {
        return Promise.reject("The parameters are not complete!");
    }
        
    var raw = JSON.stringify({current_password, new_password, new_password_confirmation});
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw
    };
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "password-change"]);

    return await API_HELPER.apiCall(url, null, requestOptions);    
}

// async function confirmAccount(username, token) 
// {
//     // Mandatory parameters
//     if(!username || !token)
//     {
//         return Promise.reject("The parameters are not complete!");
//     }

//     var raw = JSON.stringify({username, token});
//     const requestOptions = {
//         method: 'PUT',
//         headers: { "Content-Type":"application/json" },
//         body: raw
//     };
//     var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "ConfirmAccount"]);

//     return await API_HELPER.apiCall(url, null, requestOptions);    
// }

// async function requestConfirmToken(email) 
// {
//     // Mandatory parameters
//     if(!email)
//     {
//         return Promise.reject("The parameters are not complete!");
//     }

//     const requestOptions = {
//         method: 'GET',
//     };
//     var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "ConfirmAccount", email]);

//     return await API_HELPER.apiCall(url, null, requestOptions);
// }