<!-- WidgetWrapper.vue -->
<template>
    <div class="position-relative" :class="wrapperClass">
        <div v-if="loading" class="widget-loader">
            <slot name="loader">
                <div class="spinner-border color-primary" role="status">
                    <span class="visually-hidden">{{ $t('Loading...') }}</span>
                </div>
            </slot>
        </div>

        <div v-else-if="errorData" class="widget-error">
            <slot name="error" :error="errorData">
                <div class="d-flex flex-column justify-content-center align-items-center h-100 gap-2">
                    <!-- Integration Error -->
                    <template v-if="errorData.type === 'INTEGRATION'">
                        <h6 class="text-center text-muted m-0 fs-12">
                            {{ errorData.message }}
                        </h6>
                        <router-link :to="{ name: 'IntegrationsPage' }" class="btn btn-outline-primary btn-sm">
                            {{ $t('Add Integration') }}
                        </router-link>
                    </template>

                    <!-- Hotel Error -->
                    <template v-else-if="errorData.type === 'HOTEL'">
                        <h6 class="text-center text-muted m-0 fs-12">
                            {{ errorData.message }}
                        </h6>
                        <router-link :to="{ name: 'CompanySettingsPage' }" class="btn btn-outline-primary btn-sm">
                            {{ $t('Add Hotels') }}
                        </router-link>
                    </template>

                    <!-- Generic Error -->
                    <template v-else>
                        <h6 class="text-center text-muted m-0 fs-12">
                            {{ errorData.message }}
                        </h6>
                        <button v-if="retryable" @click="handleRetry" class="btn btn-outline-primary btn-sm">
                            {{ $t('Retry') }}
                        </button>
                    </template>
                </div>
            </slot>
        </div>

        <slot v-else></slot>
    </div>
</template>

<script>
export default {
    name: 'WidgetWrapper',

    props: {
        widgetId: {
            type: String,
            required: true
        },
        error: {
            type: [Object, String],
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        retryable: {
            type: Boolean,
            default: true
        },
        wrapperClass: {
            type: String,
            default: 'widget-wrapper p-3 p-lg-5'
        },
        integration: {
            type: String,
            default: 'Mews'
        }
    },

    emits: ['retry'],

    data() {
        return {
            ERROR_TYPES: {
                INTEGRATION: 'INTEGRATION',
                HOTEL: 'HOTEL',
                GENERIC: 'GENERIC'
            }
        }
    },

    computed: {
        errorData() {
            if (!this.error) return null;
            return this.parseError(this.error)
        }
    },

    methods: {
        parseError(error) {
            // Handle string errors (possibly JSON)
            if (typeof error === 'string') {
                try {
                    const errorObject = JSON.parse(error)

                    if (errorObject?.integration) {
                        return {
                            type: this.ERROR_TYPES.INTEGRATION,
                            message: this.$t(`The data will only be displayed if you add the ${this.integration} integration to this hotel`)
                        }
                    }

                    if (errorObject?.hotelId) {
                        return {
                            type: this.ERROR_TYPES.HOTEL,
                            message: this.$t('Please add at least one hotel group with hotels.')
                        }
                    }

                    return {
                        type: this.ERROR_TYPES.GENERIC,
                        message: errorObject.message || error
                    }
                } catch {
                    // If JSON parsing fails, treat as generic error
                    return {
                        type: this.ERROR_TYPES.GENERIC,
                        message: error
                    }
                }
            }

            // Handle Error objects or unknown error types
            return {
                type: this.ERROR_TYPES.GENERIC,
                message: error?.message || this.$t('An unexpected error occurred')
            }
        },

        handleRetry() {
            this.$emit('retry')
        }
    }
}
</script>

<style scoped>
.widget-wrapper {
    width: 100%;
    border-radius: 15px;
    height: calc(100vh - 20rem);
    max-height: calc(100vh - 20rem);
    background: #FFF;
    box-shadow: 0px 4px 30px 0px #00000014;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.table-widget-wrapper {
    border-radius: 15px 15px 0 0;
    background: var(--gray-white,#fff);
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    min-height: calc(100vh - 20rem);
}
/* Hide scrollbar for Chrome, Safari and Opera */
.widget-wrapper::-webkit-scrollbar,
.table-widget-wrapper::-webkit-scrollbar {
    display: none;
}

.widget-loader,
.widget-error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fs-12 {
    font-size: 12px;
}

.btn-outline-primary {
    color: var(--theme-primary)!important;
    border-color: var(--theme-primary)!important;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    color: #fff!important;
    background-color: var(--theme-primary)!important;
    border-color: var(--theme-primary)!important;
}
@media (max-width: 767.98px) {
    .widget-wrapper {
        max-height: 600px;
    }
}
</style>