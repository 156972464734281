import { USER_SERVICE } from "@/services/users.service.js";
import { defineStore } from 'pinia'
// import router from '@/router';
// import { useAlertStore } from '@/stores';

export const useUsersStore = defineStore('users', {
        state: () => ({
            // initialize state from local storage to enable user to stay logged in
            user: JSON.parse(localStorage.getItem('user')),
            headerUserInformation: {
                name: '',
                picture: {},
            },
            getListOutput: null,
            loading: false,
            // requestResetTokenProcessTask: null,
            resetPasswordProcessTask: null,
            // confirmAccountProcessTask: null,
            // requestConfirmTokenProcessTask: null
        }),
        getters: {
            isLoggedIn(state) {
                return state.user !== null;
            },
        },
        actions: {
            initializeHeaderUserInformation() {
                // Safely retrieve and parse the user from localStorage
                const userStorage = JSON.parse(localStorage.getItem('user')) || {};
                this.headerUserInformation = {
                    name: userStorage?.user?.name || '',
                    picture: userStorage?.user?.picture || {},
                };
            },
            async login(email, password) {
                return await USER_SERVICE.login(email, password);
            },
            async loginSecret(email, password, secret) {
                await USER_SERVICE.loginSecret(email, password, secret);
            },
            async register(user) {
                await USER_SERVICE.signup(user);
            },
            async getUserData(input) {
                this.getListOutput = await USER_SERVICE.getUserData(input);
            },
            async changeMe(payload) {
                this.loading = true;
                try{
                    await USER_SERVICE.changeMe(payload);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    throw e;
                }
            },
            async authenticatorQrCode() {
                return await USER_SERVICE.authenticatorQrCode();
            },
            async authenticatorVerify(payload) {
                this.loading = true;
                try{
                    await USER_SERVICE.authenticatorVerify(payload);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    throw e;
                }
            },
            async emailCodeSecret(codeSecret, email) {
                return await USER_SERVICE.emailCodeSecret(codeSecret, email);
            },
            async refreshToken() {
                const response = await USER_SERVICE.refreshToken();
                this.user = response.data;
            },                           
            async resetPassword(username, password) {
                await USER_SERVICE.resetPassword(username, password);
            },
            async passwordChange(current_password, new_password, new_password_confirmation) {
                await USER_SERVICE.passwordChange(current_password, new_password, new_password_confirmation);
            },
            // async confirmAccount(username, token) {
            //     try{
            //         await USER_SERVICE.confirmAccount(username, token);
            //         this.confirmAccountProcessTask = true;
            //     } catch(error) {
            //         const alertStore = useAlertStore();
            //         alertStore.error(error);   
            //         this.confirmAccountProcessTask = false;
            //     }
            // },
            // async requestConfirmToken(email) {        
            //     await USER_SERVICE.requestConfirmToken(email);
            // },
            updateHeaderUserInformation(headerUserInfo) {
                // Update the company name and picture
                this.headerUserInformation = {
                    name: headerUserInfo.name || '',
                    picture: headerUserInfo.picture || require('@/assets/no-image.jpg'),
                };
    
                // Retrieve the user from localStorage
                const userStorage = JSON.parse(localStorage.getItem('user')) || {};
    
                // Ensure properties exist before setting values
                if (userStorage.user) {
                    userStorage.user.name = headerUserInfo.name;
                    userStorage.user.picture = headerUserInfo.picture;
                    userStorage.user.phoneNumber = headerUserInfo.phoneNumber;
                    userStorage.user.currency = headerUserInfo.currency;
                    userStorage.user.language = headerUserInfo.language;
                    userStorage.user.country = headerUserInfo.country;
                }
    
                localStorage.setItem('user', JSON.stringify(userStorage));
            },
            logout() {
                this.user = null;
                localStorage.removeItem('user');
                window.location.href = '/sign-in'; // redirect to login page
            }
        }
    
});