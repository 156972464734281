import { PICKUP_SERVICE } from "@/services/pickup.service.js";
import { defineStore } from 'pinia'

export const usePickupStore = defineStore('pickup', {
    state: () => ({
        getListOutput: [],
        getListBySlugOutput: [],
    }),
    getters: {
        pickupOutput: (state) => {
            return state.getListOutput && state.getListOutput.data ? state.getListOutput.data : [];
        },
        pickupOutputBySlug: (state) => {
            return state.getListBySlugOutput && state.getListBySlugOutput.data ? state.getListBySlugOutput.data : [];
        },
    },
    actions: {
        async getList(input) {
            this.getListOutput = await PICKUP_SERVICE.getList(input);
        },
        async getListBySlug(slug) {
            this.getListBySlugOutput = await PICKUP_SERVICE.getListBySlug(slug);
        },
        async createPickup() {
            await PICKUP_SERVICE.createPickup();
        },
        async editPickup() {
            await PICKUP_SERVICE.editPickup();
        },
        async deletePickupBySlug(slug) {
            await PICKUP_SERVICE.deletePickupBySlug(slug);
        },
    }
});