<template>
  <ul v-show="configuration.isDropdownOpen" class="dropdown-menu" :class="dynamicClass" ref="dropdownMenu">
    <!-- header -->
    <template v-if="configuration.hasHeader">
      <li class="li-header p-2" v-for="(item, index) in configuration.items.header" :key="index">
        <div class="d-flex gap-4">
          <div class="col d-flex align-items-center">
            <img class="cursor-pointer" :src="item.icon" :alt="item.alt" width="14" height="14">
            <span class="label mx-2"> {{ item.label }} </span>
          </div>
          <div class="col-auto d-flex align-items-center">
            <img src="@/assets/Pickup/gear-wide-connected.svg" width="12" height="12" alt="gear-wide-connected">
          </div>
        </div>
      </li>
    </template>
    <hr v-if="configuration.hasHeader" class="divider">
    <!-- body -->
    <div class="li-body p-2">
      <template v-if="configuration.items.body && configuration.items.body.length">
        <li 
          v-for="(item, index) in configuration.items.body" 
          :key="index" 
          @click.stop="handleItemClick(item)" 
          class="dropdown-item cursor-pointer p-2" 
          :class="{ 
            'active': isSelected(item.id) && item.keyName !== 'customize',
            'border-bottom': index !== configuration.items.body.length - 1
          }"
        >
          <div v-if="item.keyName !== 'customize'" class="d-flex align-items-center">
            <img v-if="item.icon" class="icon me-2" :src="item.icon" width="12" height="12" alt="icon">
            <img v-if="item.image" class="img-fluid me-2" :src="parseImageData(item.image)" width="30" height="30">
            <span v-if="item.label" class="label">{{ item.label }}</span>
          </div>
          <div v-else>
            <slot name="customize" :item="item" :active="isSelected(item.id)"></slot>
          </div>
        </li>
      </template>
      <template v-else>
        <li class="p-2">
          <div class="d-flex justify-content-center align-items-center h-100">
            <span>{{ $t('No data available') }}</span>
          </div>  
        </li>
      </template>
    </div>
  </ul>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object,
      required: true,
      default: () => {
        return {
          items: Array, // items
          isDropdownOpen: Boolean, // dropdown open
          hasHeader: Boolean, // header
          hasBody: Boolean, // body
          openDropdownPosition: String, // dropdown position left or right
          selectionMode: String, // single or multi
        }
      }
    },
    customClass: Array
  },
  data() {
    return {
      selectedItems: this.modelValue || [] // Use value to track selected items
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
  computed: {
    dynamicClass() {
      let classes = this.customClass ? [this.customClass] : [];

      if (this.configuration.isDropdownOpen) {
        classes.push('show');
      }
      if (this.configuration.openDropdownPosition === 'right') {
        classes.push('drop-right');
      }

      return classes;
    }
  },
  methods: {
    closeDropdownOnClickOutside(event) {
      if (this.configuration.isDropdownOpen && this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
        this.$emit('close-dropdown');
      }
    },
    handleItemClick(item) {
      const { selectionMode } = this.configuration;

      // Determine if `this.selectedItems` is an array
      const isArray = Array.isArray(this.selectedItems);

      // Check if item is already selected
      const isSelected = isArray
        ? this.selectedItems.some(selectedItem => selectedItem.id === item.id)
        : this.selectedItems && this.selectedItems.id === item.id;

      if (selectionMode === 'multi') { // multi-selection
        if (!isSelected) { // add item to selected items
          this.selectedItems.push(item);
        } else { // remove item from selected items
          const itemIndex = this.selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
          this.selectedItems.splice(itemIndex, 1);
        }
      } else if (selectionMode === 'single') { // single-selection
        if (isSelected && !this.configuration.hasHeader) { // only deselect if item is not header
          if (this.selectedItems && this.selectedItems.id === item.id) {
            // Prevent deselection if this is the only selected item
            return;
          }
          this.selectedItems = [];
        } else {
          this.selectedItems = item;
          if (this.configuration.hasHeader) {
            this.$emit('close-dropdown');
          }
        }
        // Emit close dropdown event for single selection if key is not 'customize'
        if(item.keyName !== 'customize') {
          this.$emit('close-dropdown', item);
        }
      }
    },
    isSelected(id) {
      if (Array.isArray(this.selectedItems)) {
        // Handle multi-selection
        return this.selectedItems.some(item => item.id === id);
      } else if (this.selectedItems && typeof this.selectedItems === 'object') {
        // Handle single selection
        return this.selectedItems.id === id;
      }
      // Default case: selectedItems is neither an array nor an object
      return false;
    },
    parseImageData(imageDataString) {
      try {
        if(!Object.keys(imageDataString).length) return null;
        else {
          const parsedData = JSON.parse(imageDataString);
          return `data:${parsedData.mime};base64,${parsedData.data}`;
        }
      } catch (error) {
        // If JSON parsing fails, return the original string
        return imageDataString;
      }
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.selectedItems = newValue;
      },
      deep: true
    },
    selectedItems: {
      handler(newValue) {
        this.$emit('update:modelValue', newValue);
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.dropdown-menu {
  border-radius: 10px;
  padding: 0;
  min-width: 200px;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 4px 0px #00000013;
  margin-top: 1rem;
  user-select: none;
  max-height: 345px;
  overflow: auto;
}

.dropdown-menu.drop-right {
  left: auto;
  right: 0;
}

.dropdown-menu .li-header:hover {
  background-color: unset;
}

.dropdown-menu .li-header .label {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #6C757D;
}

.dropdown-menu .divider {
  stroke-width: 1px;
  stroke: var(--theme-border);
  padding: 0;
  margin: 0;
}

.li-body span {
  font-size: .8rem;
  font-weight: 400;
  line-height: 24px;
  color: #6C757D;
}

.dropdown-item:hover {
  background-color: rgba(108, 117, 125, .05);
}

.dropdown-item:hover .label {
  color: #A81800;
}

.dropdown-item:hover .icon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}

.dropdown-item.active {
  background-color: #A81800;
}

.dropdown-item.active .label {
  color: #fff;
}

.dropdown-item.active .icon {
  filter: brightness(0) invert(1);
}
</style>