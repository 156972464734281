<template>
  <div class="container-fluid">
    <div class="row">
      <span>Insights</span>
    </div>
  </div>
</template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  
  </style>