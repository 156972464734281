import { defineStore } from 'pinia';

export const useSideMenuStore = defineStore('sideMenu', {
    state: () => ({
        isOpen: true, // Defaults to true if not defined
        openWidth: '250px',
        closeWidth: '100px',
        isTransitionEnabled: false,
        company: {
            name: '',  // Default to empty string
            picture: {}, // Default to empty object
            phoneNumber: ''
        }
    }),
    actions: {
        initializeCompany() {
            // Safely retrieve and parse the user from localStorage
            const userStorage = JSON.parse(localStorage.getItem('user')) || {};
            this.company = {
                name: userStorage?.user?.companies?.[0]?.name || '',
                picture: userStorage?.user?.companies?.[0]?.picture || {},
                phoneNumber: userStorage?.user?.phoneNumber || ''
            };
            this.isOpen = userStorage?.sideMenu ?? true;
        },
        toggleMenu() {
            // Toggle the side menu state
            this.isOpen = !this.isOpen;
            this.isTransitionEnabled = true;

            // Retrieve the user from localStorage
            const user = JSON.parse(localStorage.getItem('user')) || {};

            // Update the sideMenu property and save back to localStorage
            user.sideMenu = this.isOpen;
            localStorage.setItem('user', JSON.stringify(user));
        },
        updateCompany(company) {
            // Update the company name and picture
            this.company = {
                name: company.name || '',
                picture: company.picture || require('@/assets/no-image.jpg'),
                phoneNumber: company.phoneNumber || ''
            };

            // Retrieve the user from localStorage
            const userStorage = JSON.parse(localStorage.getItem('user')) || {};

            // Ensure properties exist before setting values
            if (userStorage.user && userStorage.user.companies?.[0]) {
                userStorage.user.companies[0].name = company.name;
                userStorage.user.companies[0].picture = company.picture;
                userStorage.user.companies[0].phoneNumber = company.phoneNumber;
            }

            userStorage.phoneNumber = company.phoneNumber;
            userStorage.user.phoneNumber = company.phoneNumber;

            localStorage.setItem('user', JSON.stringify(userStorage));
        }
    }
});
