<template>
  <PageSubMenu class="d-none d-md-block" :sections="menuSections" />
  <section v-if="$route.name.includes('BillingPage')" >
    <BillingDesktop class="d-none d-md-block"></BillingDesktop>
    <BillingMobile class="d-md-none d-block"></BillingMobile>
  </section>
  <router-view></router-view>
</template>

<script>
import BillingDesktop from "@/components/BillingPage/BillingDesktop.vue";
import BillingMobile from "@/components/BillingPage/BillingMobile.vue";
import PageSubMenu from "@/components/PageSubMenu.vue";

export default {
  components: {
    BillingDesktop,
    BillingMobile,
    PageSubMenu,
  },
  data() {
    return {
      menuSections: [
        {
            title: this.$t('My Profile'),
            items: [
                { label: 'Account Settings', icon: require('@/assets/MyProfile/acc-settings.svg'), pathName: 'AccountSettingsPage' },
                { label: 'Billing', icon: require('@/assets/MyProfile/billing-icon.svg'), pathName: 'BillingPage' }
            ]
        }
      ]
    };
  },
}
</script>

<style></style>