<template>
    <BaseSign>
      <template v-slot:content>
        <div class="container-fluid" id="container-style">
          <div class="row w-100">
            <Form ref="myForm" class="form" :validation-schema="schema" v-slot="{ errors }">
              <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <img class="icon" src="@/assets/authentication/sign-up/twoFactor.svg" alt="">
                <div class="my-2">
                  <span class="title-primary">{{ $t('insertToken') }}</span>
                </div>
              </div>
              <div class="form-group d-flex flex-column justify-content-center align-items-center">
                <Field 
                  v-model="queryToken"
                  name="queryToken" 
                  type="text" 
                  class="form-control input-form"
                  :class="{ 'is-invalid': hasError(errors, 'queryToken') }" 
                  :placeholder="$t('token')"
                />
                <ValidationAlerts
                  v-if="hasError(errors, 'queryToken')"
                  class="my-2 w-100" :alertType="'error'"
                  :title="getErrorMessages(errors, ['queryToken'])" 
                />       
              </div>
                <div class="form-group d-flex flex-column justify-content-center align-items-center" v-bind:class="{ disabled: loading }">
                  <CustomButton class="btn" :label="$t('Verify')" :loading="loading"  @clickBtn="onSubmit" />
                </div>
            </Form>
          </div>
        </div>
      </template>
    </BaseSign>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup'
import ValidationAlerts from '@/components/ValidationAlerts.vue'
import { useAlertStore, useUsersStore } from '@/stores';
import CustomButton from '@/components/CustomButton.vue';
import BaseSign from "@/components/BaseSign.vue";

export default {
  components: {
    Form,
    Field,
    CustomButton,
    ValidationAlerts,
    BaseSign
  },
  setup() {
    const alertStore = useAlertStore();
    const usersStore = useUsersStore();

    return {
      alertStore,
      usersStore
    };
  },
  data() {
    return {
      completed: false,
      loading: false,
      queryEmail: this.$route.query.email,
      queryToken: this.$route.query.token ?? '',
    }
  },
  methods: {
    async onSubmit() {
      const { valid } = await this.$refs.myForm.validate();
      if (!valid) {
        return;
      }
      const value = this.$refs.myForm.values;
      this.alertStore.clear();
      this.loading = true;
      try {
        await this.usersStore.emailCodeSecret(value.queryToken, this.queryEmail);
        this.loading = false;
        this.$router.push({ name: 'SignIn' })
      }
      catch (error) {
        this.alertStore.error(error, this.$t('yourTokenIsNotValid.'));
        this.loading = false;
      }
    },
    hasError(errors, label) {
      return errors[label] || this.hasApiError(label);
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    },
    getErrorMessages(errors, labels) {
      const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
      return errorMessages.length ? errorMessages.join('<br>') : errorMessages.concat(this.apiErrors.output);
    },
  },
  computed: {
    schema() {
      return Yup.object({
        queryToken: Yup.string().required(this.$t('tokenRequired')),
      })
    },
    apiErrors: function () {
      return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : this.alertStore.alert ? this.alertStore.alert : {};
    }
  },
}
</script>

<style scoped>
#container-style{
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-primary {
font-size: 20px;
font-weight: 900;
word-break: break-word;
}

.title-secondary {
font-size: 15px;
color: var(--theme-secondary);
word-break: break-word;
}

.form-group {
margin-bottom: 15px;
}

.form-group label {
display: flex;
justify-content: flex-start;
}

.asterisk {
color: var(--theme-primary);
margin-left: 5px;
}

.form-group input {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
}

.input-form {
max-width: 500px;
}

.privacy-policy {
color: var(--theme-primary);
}

.space-top {
margin-top: 5rem;
}

.form-group button {
width: 100%;
padding: 10px;
border: none;
border-radius: 4px;
color: white;
background-color: var(--theme-primary);
text-decoration: none;
text-align: center;
}

.form-group a {
width: 100%;
padding: 10px;
border: none;
border-radius: 4px;
color: var(--theme-primary) !important;
text-decoration: underline !important;
cursor: pointer;
}

.icon {
width: 130px;
}

#input-code {
position: relative;
}

#visibility-icon {
position: absolute;
top: 15px;
right: 15px;
}
</style>