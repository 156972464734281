<template>
    <section id="picker">
        <div class="file-picker" @click="onChooseImageClicked">
            <img v-if="Object.keys(image).length" class="img-fluid w-100 rounded-circle" :src="image.url ? image.url : parseImageData(image)" alt="">
            <img v-else class="img-fluid w-100" :src="placeholder" alt="icon-picker">
        </div>
        <!-- Picker -->
        <input ref="fileInput" type="file" accept=".jpg,.jpeg,.png" class="d-none" @change="onFileSelect" />
        <!-- Cropper -->
        <BasePopUp 
            v-show="showModal" 
            v-model="showModal" 
            @popUpClosed="showModal = false"
        >
            <template v-slot:body>
                <VueCropper 
                    ref="cropper" 
                    v-show="selectedFile" 
                    :src="selectedFile" 
                    alt="Source Image" 
                    @ready="onCropStart"
                    :aspectRatio="applyRatio" 
                    :initialAspectRatio="applyRatio"
                />
                <div class="d-flex justify-content-between m-0 p-1">
                    <CustomButton 
                        @clickBtn="showModal = false" 
                        :type="2" :label="$t('cancel')" 
                        buttonType="theme-light"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            padding: '5px 15px',
                            minWidth: '85px'
                        }" 
                    />
                    <CustomButton 
                        @clickBtn="confirmClicked" 
                        :type="2" 
                        :label="$t('crop')" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            padding: '5px 15px',
                            minWidth: '85px'
                        }" 
                    />
                </div>
            </template>
        </BasePopUp>
    </section>
</template>

<script>
import BasePopUp from "@/components/popups/BasePopUp.vue";
import { getReducedFraction, convertToBase64 } from "@/helpers/utils.js";
import CustomButton from '@/components/CustomButton.vue';
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

const MAX_SIZE = 1; // 1 MB
//const MAX_SIZE = 500 / 1024; // 500 KB em MB
export default {
    components: { VueCropper, BasePopUp, CustomButton },
    props: {
        modelValue: {
            type: Object
        },
        ratioWidth: {
            type: Number,
            default: 16
        },
        ratioHeight: {
            type: Number,
            default: 9
        },
        placeholder: String
    },
    data() {
        return {
            image: this.modelValue ? this.modelValue : {},
            mime_type: '',
            fileName: '',
            selectedFile: '',
            showModal: false,
            currentSlide: 0
        }
    },
    computed:
    {
        applyRatio: function () {
            return this.ratioWidth / this.ratioHeight;
        },
        readableRatio: function () {
            return getReducedFraction(this.ratioWidth, this.ratioHeight);
        },
        heightPercentage: function () {
            return this.ratioHeight / this.ratioWidth * 100;
        },
        halfHeightPercentage: function () {
            return this.heightPercentage / 2;
        }
    },
    created() {
        window.addEventListener("resize", this.onWindowResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.onWindowResize);
    },
    methods: {
        onChooseImageClicked() {
            this.$refs.fileInput.click();
        },
        onFileSelect(e) {
            try {
                const file = e.target.files[0];
                const maxSize = 1024 * 1024 * MAX_SIZE;
                this.mime_type = file.type;
                this.fileName = file.name;

                // Validations
                if (typeof FileReader != 'function') {
                    throw 'Sorry, FileReader API not supported';
                }

                if (this.mime_type != 'image/jpg' && this.mime_type != 'image/jpeg' && this.mime_type != 'image/png') {
                    throw this.$t('File type should be jpg, jpeg or png');
                }

                if (file.size > maxSize) {
                    throw this.$t('Size of the image should be less than 500 KB');
                }

                // Process
                this.showModal = true

                const reader = new FileReader()
                reader.onload = (event) => {
                    this.showModal = true;
                    this.selectedFile = event.target.result;
                    this.$refs.cropper.replace(this.selectedFile);
                }
                reader.readAsDataURL(file);
            }
            catch (err) {
                alert(err);
            }
        },
        confirmClicked() {
            this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
                blob.lastModifiedDate = new Date();
                blob.name = this.fileName;

                // Create a URL from the Blob to use for displaying the image
                const objectURL = URL.createObjectURL(blob);

                // Update the image with the Blob converted to Base64 and the generated URL
                convertToBase64(blob)
                    .then(base64Image => {
                        var newImage = { 
                            mime: this.mime_type, 
                            data: base64Image.split(',')[1], 
                            url: objectURL // Use the generated URL
                        };
                        this.image = newImage;
                    });
            }, this.mime_type);

            this.showModal = false;
        },
        setupCanvasAndCrop() {
            const cropper = this.$refs.cropper;

            if (cropper) {
                const image = cropper.getImageData();
                const imageHeight = image.height;
                const imageWidth = image.width;

                const container = document.querySelector(".modal-body > div");
                const containerHeight = container.clientHeight;
                const containerWidth = container.clientWidth;

                if (imageHeight > imageWidth) {
                    if (imageHeight > containerHeight) {
                        const height = containerHeight;
                        const width = imageWidth * height / imageHeight;
                        const left = containerWidth / 2 - width / 2;
                        const top = 0;

                        cropper.setCanvasData({ left: left, top: top, width: width, height: height });
                        cropper.setCropBoxData({ left: left, top: top, height: height });
                    }
                }
            }
        },
        onWindowResize() {
            this.setupCanvasAndCrop();
        },
        onCropStart() {
            this.setupCanvasAndCrop();
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    },
    watch:
    {
        modelValue: function (val) {
            this.image = val ? val : null;
        },
        showModal: function (val) {
            if (!val) {
                this.$refs.fileInput.value = null;
                this.$refs.cropper.reset();
                this.setupCropBox = false;
            }
        },
        image: function (val) {
            var image = val ? val : null;
            this.$emit('update:modelValue', image);
        }
    }
}
</script>

<style scoped>
#picker {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.file-picker {
    cursor: pointer;
    width: 200px;
    min-width: 200px;
}

:deep(.cropper-container) {
    max-height: 80vh;
}

:deep(.cropper-bg) {
    width: 100% !important;
}
</style>