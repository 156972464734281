<template>
    <div class="container-fluid py-3 py-md-0">
        <LayoutWrapper>
            <TableComponent 
                v-model="items" 
                :layout="table.layout" 
                :tableHeaders="table.headers" 
                :tableRows="table.rows"
                :loading="table.loading"
                :clickableRows="table.clickableRows"
                @rowClicked="rowClicked" 
            >
                <template v-slot:title>
                    <!-- desktop -->
                    <div v-if="!isMobile" class="row py-3 px-4 border-bottom align-items-center">
                        <div v-if="hasTitle.title || hasTitle.icon" class="col d-none d-md-flex gap-2">
                            <img v-if="hasTitle.icon" class="img-fluid" :src="hasTitle.icon" alt="" width="18">
                            <span class="page-title">{{ hasTitle.title }}</span>
                        </div>
                        <div v-if="hasDate" class="col d-block d-md-none mb-3">
                            <DateButton 
                                :date="date" 
                                @update-date="updateDateProperty('date', $event)" 
                                typeForm="2"
                                :label="$t('selectDate')" 
                                @confirm-selection="confirmSelectedDate($event)" 
                            />
                        </div>
                        <div v-if="searchBar" class="col d-flex justify-content-start align-items-center">
                            <div class="form-group">
                                <input type="text" class="form-control search-input" placeholder="Search...">
                            </div>
                        </div>
                        <div v-if="(button.label && !hasDate) || date" class="col d-none d-md-flex align-items-center justify-content-end gap-3">
                            <CustomButton 
                                v-if="!hasDate && button.label" 
                                :leftIcon="button.icon" 
                                :label="button.label" 
                                @clickBtn="addItem"
                                :buttonStyle="button.style" 
                            />
                            <DateButton 
                                v-else-if="date" 
                                :date="date" 
                                @update-date="updateDateProperty('date', $event)" 
                                typeForm="2"
                                :label="$t('selectDate')" 
                                @confirm-selection="confirmSelectedDate($event)" 
                            />
                        </div>
                    </div>
                </template>
                <template v-slot:actions="{ item, rowIndex, actionType }">
                    <slot name="actions" :item="item" :rowIndex="rowIndex" :actionType="actionType" ></slot>
                </template>
            </TableComponent>
        </LayoutWrapper>
        <div v-if="hasAddButtonMobile" class="col d-flex d-md-none">
            <div class="w-100 p-3">
                <CustomButton 
                    class="w-100" 
                    :rightIcon="button.icon" 
                    :label="button.label" 
                    @clickBtn="addItem"
                    :buttonStyle="button.style" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import CustomButton from '@/components/CustomButton.vue';
import DateButton from '@/components/DateButton.vue';
import { useResponsive } from '@/composables/responsive.js';

export default {
    components: {
        TableComponent,
        LayoutWrapper,
        CustomButton,
        DateButton
    },
    props: {
        modelValue: Array,
        table: {
            type: Object,
            default: () => ({ layout: {}, headers: [], rows: [], loading: false, clickableRows: true })
        },
        hasTitle: {
            type: Object,
            default: () => ({ title: '', icon: '' })
        },
        button: {
            type: Object,
            default: () => ({ label: '', icon: '', style: {} })
        },
        hasDate: {
            type: Boolean,
            default: false
        },
        hasAddButtonMobile: {
            type: Boolean,
            default: true
        },
        searchBar: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const { isMobile } = useResponsive();

        return {
            isMobile
        }
    },
    data() {
        return {
            items: this.modelValue,
            date: null,
        }
    },
    methods: {
        rowClicked(obj) {
            this.$emit('rowClicked', obj);
        },
        addItem() {
            this.$emit('addItem');
        },
        updateDateProperty(propertyName, newValue) {
            this[propertyName] = newValue;
        },
    },
    watch: {
        modelValue: {
            handler(newValue) {
                this.items = newValue;
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.page-title {
    color: var(--theme-secondary);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}

.form-control.search-input {
    padding-left: 40px;
    background-image: url('@/assets/HotelGroup/searchBtn.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 10px center;
}

@media (min-width: 768px) {
    .search-input {
        width: 300px;
    }
}

@media (max-width: 767px) {
    .form-control.search-input {
        font-size: 13px;
        background-size: 15px 15px;
        padding-left: 35px;
    }
}
</style>