<template>
    <section id="footer-mobile">
        <div class="container-fluid container-style">
            <div class="row row-style">
                <router-link class="col d-flex justify-content-center" :to="{ name: 'DashboardPage' }"
                    @click="menuStore.isOpen = false">
                    <div class="item">
                        <img class="icon w-50" src="@/assets/MobileNav/dashboard.svg" alt="">
                    </div>
                </router-link>
                <div class="col d-flex justify-content-center" @click="sendToMobileSearch">
                    <div class="item">
                        <img class="icon w-50" src="@/assets/SearchMobile/search-icon.svg" alt="">
                    </div>
                </div>
                <div class="col d-flex justify-content-center" @click="sendToMobileProfile">
                    <img class="image-style" :src="headerUserImage" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useMenuStore, useUsersStore } from '@/stores';

export default {
    setup() {
        const menuStore = useMenuStore();
        const usersStore = useUsersStore();

        return {
            menuStore,
            usersStore
        }
    },
    methods: {
        sendToMobileProfile() {
            this.$router.push({ name: 'MyAccountMobile' });
        },
        sendToMobileSearch() {
            this.$router.push({ name: 'SearchMobile' });
        },
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                // Parse the JSON string if it is a string
                const parsedData = typeof imageDataString === 'string' 
                ? JSON.parse(imageDataString) 
                : imageDataString;
                return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                return imageDataString;
            }
        },
    },
    computed: {
        headerUserImage() {
            return this.parseImageData(this.usersStore.headerUserInformation?.picture) ?? require('@/assets/no-image.jpg');
        },
    },


}
</script>

<style scoped>
#footer-mobile {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 60px;
  min-height: 60px;
  background-color: rgb(247, 248, 249);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.container-style {
    padding: 8px 16px;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}
.image-style{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.router-link-active .item {
    background-color: rgba(108, 117, 125, .05);
    border-radius: 8px;
}

.router-link-active .icon {
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}</style>