<template>
  <section id="complete_profile">
    <div class="container-fluid">
      <div ref="steps" class="row">
        <div class="col d-flex overflow-auto">
          <div class="step-indicator my-4 my-lg-5 mx-auto">
            <div 
              v-for="step in 4" 
              :key="step" 
              class="step" 
              @click="goToStep($event)"
              :data-step="step" 
              :class="{'disabled': !currentSteps.includes(step)}" 
            >
            <template v-if="isDesktop">
              <div :class="{ 'circle': true, 'current': currentStepComponent === step }">
                <span :class="{ 'number': true, 'current': currentStepComponent === step }" :style="{ color: currentStepComponent === step ? 'white' : 'black' }">{{ step }}</span>
              </div>
              <div class="label" :class="{ 'current-label': currentStepComponent === step }">{{ getStepLabel(step) }}</div>
            </template>
            <template v-if="isMobile || isTablet">
              <div class="d-flex flex-column align-items-center">
                <div :class="{ 'circle': true, 'current': currentStepComponent === step }">
                  <span :class="{ 'number': true, 'current': currentStepComponent === step }" :style="{ color: currentStepComponent === step ? 'white' : 'black' }">{{ step }}</span>
                </div>
                <div class="label" :class="{ 'current-label': currentStepComponent === step }">{{ getStepLabel(step) }}</div>
              </div>
            </template>
            <hr v-if="step !== 4" class="line" :class="{ 'current-line': currentStepComponent === step }"/>
            </div>
          </div>
        </div>
      </div>
      <LayoutCompleteRegister 
        :title="title" 
        :desc="$t('enterYourPersonalInformation')" 
        @stepClicked="stepClicked" 
        :currentStepComponentChild="currentStepComponent"
        :style="{ height: 'calc(100vh - ' + stepsHeight + 'px)' }"
      />
    </div>
  </section>
</template>

<script>
import LayoutCompleteRegister from '@/components/Authentication/LayoutCompleteRegister.vue';
import { useResponsive } from '@/composables/responsive';
export default {
  components: {
    LayoutCompleteRegister
  },
  setup() {
    const { isMobile, isTablet, isDesktop } = useResponsive();
    return {
      isMobile,
      isTablet,
      isDesktop
    }
  },
  data() {
    return {
      currentStepComponent: 1,
      currentSteps: [1],
      stepLabels: {
        1: 'Profile',
        2: 'Security',
        3: 'Company',
        4: 'Choose Plan'
      },
      stepsHeight: null,
    }
  },
  computed: {
    title() {
      let result = '';
      switch (this.currentStepComponent) {
        case 1:
          result = this.$t('completeProfile');
          break;
        case 2:
          result = this.$t('completeSecurity');
          break;
        case 3:
          result = this.$t('completeCompany');
          break;
        case 4:
          result = this.$t('completePlan');
          break;
      }
      return result;
    }
  },
  mounted () {
    this.calculateStepsHeight();
    window.addEventListener('resize', this.calculateStepsHeight)
  },
  unmounted () {
    window.removeEventListener('resize', this.calculateStepsHeight)
  },
  methods: {
    goToStep(event) {
      if(!event.target.closest('.step')) return; // check if the clicked element is a step
      const step = parseInt(event.target.closest('.step').dataset.step);
      if (!isNaN(step) && this.currentSteps.includes(step)) { // check if step is a number and is in the currentSteps array
        this.currentStepComponent = step;
      }
    },
    getStepLabel(step) {
      return this.stepLabels[step];
    },
    stepClicked(step, errorOutput) {
      if(errorOutput) { 
        // this is important when in step 2 we get the message that the user has already activated 2fa
        // We just push the step into the array and don't allow it to advance to the next step
        this.currentSteps.push(step);
      } else {
        this.currentSteps.push(step);
        this.currentStepComponent = step;
      }
    },
    calculateStepsHeight() {
      const div = this.$refs.steps;
      if (div) {
        this.stepsHeight = div.clientHeight;
      } else {
        this.stepsHeight = null;
      }
    }
  }
}
</script>

<style scoped>
.step-indicator {
  display: flex;
  align-items: center;
  user-select: none;
}

.step {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle .number {
  color: gray;
}

.label {
  margin: 0 5px;
  color: gray;
  text-align: center;
}
.current-label {
  font-weight: 900;
  text-align: center;
}

.line {
  width: 100px;
  height: 2px;
  background-color: gray;
  margin: 0 10px;
}

.current {
  background-color: #A81800;
}

.current .number {
  color: white;
}

.current-line {
  background-color: #A81800;
  border-color: #A81800;
}
@media (max-width: 767.98px){
  .line{
    width: 50px;
    margin: unset;
  }
}
@media (max-width: 575.98px){
  .line{
    width: 30px;
    margin: unset;
  }
  .current-label,
  .label{
    font-size: .7rem;
    margin: 0;
  }
}
</style>
