<template>
  <section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 d-flex align-items-center p-2 mt-4" @click="sendToMyProfile">
                <img src="../../assets/HotelGroup/backBtn.svg" alt="">
            </div>
            <div class="col-12 d-flex align-items-center gap-2 my-3 p-2">
                <BoldTitle :icon="require('@/assets/Billing/billing-mobile-icon.svg')" :title="$t('Billing')"/>
            </div>
            <DividerComponent/>
            <div class="col-12 d-flex gap-2 my-3" @click="sendToUpgradePlan">
                <img src="@/assets/Billing/upgradePlan-mobile.svg" alt="">
                <span>{{$t("UpgradePlan")}}</span>
            </div>
            <div class="col-12 d-flex gap-2 my-3" @click="sendToPaymentMethod">
                <img src="@/assets/Billing/paymentMethod-mobile.svg" alt="">
                <span>{{$t("PaymentMethod")}}</span>
            </div>
            <div class="col-12 d-flex gap-2 my-3" @click="sendToBillingAddress">
                <img src="@/assets/Billing/billingAddress-mobile.svg" alt="">
                <span>{{$t("BillingAddress")}}</span>
            </div>
            <div class="col-12 d-flex gap-2 my-3" @click="sendToBillingHistory">
                <img src="@/assets/Billing/billingHistory-mobile.svg" alt="">
                <span>{{$t("BillingHistory")}}</span>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
export default {
    components: {
        BoldTitle,
        DividerComponent
    },
    methods: {
        sendToMyProfile() {
            this.$router.push({ name: 'MyAccountMobile' });
        },
        sendToUpgradePlan() {
            this.$router.push({ name: 'UpgradePlanPage' });
        },
        sendToPaymentMethod() {
            this.$router.push({ name: 'PaymentMethodPage' });
        },
        sendToBillingAddress() {
            this.$router.push({ name: 'BillingAddressPage' });
        },
        sendToBillingHistory() {
            this.$router.push({ name: 'BillingHistoryPage' });
        }
    },
}
</script>

<style scoped>
#col-top-style{
    background: #F8F9FA;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
}
</style>