<template>
    <nav class="sub-menu" :class="{'open': !isMobile && sideMenuStore.isOpen}">
        <div 
            v-for="(section, index) in sections"
            :key="index" 
            class="section"
            :style="{ borderBottom: index !== sections.length - 1 ? '1px solid rgb(206, 212, 218)' : 'none' }"
        >
            <h3 class="section-title">{{ section.title }}</h3>
            <ul class="section-items">
                <li 
                    v-for="item in section.items" 
                    :key="item.label" 
                    :class="{ active: isActive(item.pathName) }" 
                    class="menu-item" 
                    @click.prevent="navigateTo(item.pathName)"
                >
                    <router-link :to="{ name: item.pathName }" class="router-link">
                        <img v-if="item.icon" :src="item.icon" class="svg-icon" alt="icon" />
                        <span class="item-label">{{ item.label }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { useSideMenuStore } from '@/stores';
import { useResponsive } from '@/composables/responsive.js';

export default {
    name: "PageSubMenu",
    setup() {
        const sideMenuStore = useSideMenuStore();
        const { isMobile, isTablet, isDesktop } = useResponsive();

        return {
            sideMenuStore,
            isMobile,
            isTablet,
            isDesktop
        }
    },
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    methods: {
        // Check if the current route is active or not (if it's a child route, it will also be considered active)
        isActive(pathName) {
            return this.$route.matched.some(route => route.name === pathName);
        },
        navigateTo(pathName) {
            this.$router.push({ name: pathName });
        },
    }
};
</script>

<style scoped>
.sub-menu {
    position: fixed;
    top: 120px;
    left: 60px;
    flex-direction: column;
    width: 210px;
    height: calc(100vh - 120px);
    min-height: calc(100vh - 120px);
    overflow-y: auto;
    border-right: 1px solid rgb(206, 212, 218);
    z-index: 9998;
    background-color: white;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
    z-index: 9998;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sub-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sub-menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.sub-menu.open {
    transform: translateX(190px);
}

.section {
    padding: 1rem;
}

.section-title {
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-items {
    list-style: none;
    padding: 0;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: .5rem;
    margin-bottom: .5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
}

.menu-item.active {
    background-color: var(--theme-primary);
    color: white;
}

.menu-item.active .svg-icon{
    filter: brightness(0) invert(1); /* invert color for white */
}

.router-link {
    width: 100%;
    text-decoration: none;
    color: inherit;
}

.menu-item .svg-icon {
    margin-right: 10px;
    transition: all 0.4s ease-in-out;
}

.menu-item .item-label {
    font-size: .8rem;
    transition: all 0.4s ease-in-out;
}
/* @supports code is important for solving the height issue on iOS Safari and Chrome */
@supports (height: 100dvh) {
  .sub-menu{
    height: calc(100dvh - 120px);
    min-height: calc(100dvh - 120px);
    overflow-y: auto;
  }
}
@media screen and (min-width: 992px) {
    .menu-item:hover .svg-icon {
        filter: brightness(0) invert(1); /* invert color for white */
    }
    .menu-item:hover {
        background-color: var(--theme-primary);
        color: white;
    }
}
</style>