<template>
    <section>
      <div class="container-fluid">

        <!-- Mobile Layout -->
        <div class="row">
          <!-- Mobile Layout Content -->
          <div class="header-mobile border-bottom">
              <img class="img-fluid arrow-icon" :src="require('@/assets/MyProfile/acc-settings.svg')" alt="competitors icon">
              <span class="page-title-mobile">{{ $t('My Profile') }} </span>
          </div>
          <div class="col-12 d-flex flex-row gap-3 mt-6 mb-5 align-items-center px-3">
            <img class="image-style" :src="headerUserImage" alt="">
            <div class="d-flex flex-column gap-2">
              <span id="user-name">{{ this.itemsFromApi.name }}</span>
              <div class="d-flex d-row gap-2 align-items-center">
                <img src="@/assets/MyProfile/email-icon.svg" alt="" height="15" width="15">
                <span>{{ this.itemsFromApi.email }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center px-4" @click="handleAccountSettingsClick">
            <div class="col d-flex gap-2">
              <img src="@/assets/MyProfile/acc-settings.svg" alt="">
              <span class="options-span">{{$t("AccountSettings")}}</span>
            </div>
            <div class="col d-flex justify-content-end">
              <img src="@/assets/MyProfile/arrow-collapse.svg" alt="">
            </div>
          </div>
          <div class="col-12 d-flex align-items-center px-4 gap-2 my-4" @click="sendToReportsPage">
            <img src="@/assets/MyProfile/acc-settings.svg" alt="">
            <span class="options-span">{{$t("Folders")}}</span>
          </div>
          <div class="col-12 d-flex align-items-center px-4" @click="sendToBillingPage">
            <div class="col d-flex gap-2">
              <img src="@/assets/MyProfile/folders-icon.svg" alt="">
              <span class="options-span">{{$t("Billing")}}</span>
            </div>
            <div class="col d-flex justify-content-end">
              <img src="@/assets/MyProfile/arrow-collapse.svg" alt="">
            </div>
          </div>
          <!-- <DividerComponent id="divider-style" />
          <div class="col-12 d-flex gap-2 px-4">
            <img src="@/assets/MyProfile/delete-acc-icon.svg" alt="">
            <span id="delete-acc-span" @click="showPopup">Delete this account</span>
          </div> -->
          <CustomPopup 
            v-show="isPopupVisible" 
            @popUpClosed="isPopupVisible = false"
            :img="require('@/assets/MyProfile/delete-account.svg')"
            :titlePopup="'To delete your account, you will need to delete the company or transfer it to another administrator.'">
            <template v-slot:body>
              <CustomButton 
                class="btn mt-2 d-flex gap-2" 
                :label="'Go back'"
                :buttonType="'transparent-dark-no-border'" 
                @clickBtn="sendToHome" 
                :leftIcon="require('@/assets/MyProfile/goBack-arrow.svg')" 
                :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
                }"
              />
            </template>
          </CustomPopup> 
          <BillingMobileSubMenu v-if="showBilling == true" />
        </div>
        <!-- End Mobile -->
      </div>
    </section>
</template>

<script>
  import { nextTick } from 'vue';
  import { useUsersStore, useAlertStore } from "@/stores";
  import BillingMobileSubMenu from '@/components/MyProfile/BillingMobileSubMenu.vue';
  import CustomPopup from '@/components/popups/CustomPopup.vue';
  import CustomButton from '@/components/CustomButton.vue';
  
  export default {
    data() {
      return {
        showBilling: false,
        showFolders: false,
        isPopupVisible: false,
        isPopupVisibleDelete: false,
        isPopupVisibleExport: false,
        isPopupVisibleSave: false,
        itemsFromApi: [],
        menuSections: [
          {
            title: this.$t('My Profile'),
            items: [
              { label: 'Account Settings', icon: require('@/assets/MyProfile/acc-settings.svg'), pathName: 'AccountSettingsPage' },
              { label: 'Billing', icon: require('@/assets/MyProfile/billing-icon.svg'), pathName: 'BillingPage' }
            ]
          }
        ]
      }
    },
    components: {
      BillingMobileSubMenu,
      CustomPopup,
      CustomButton,
    },
    setup() {
      const usersStore = useUsersStore();
      const alertStore = useAlertStore();
      return {
        usersStore,
        alertStore
      }
    },
    async mounted() {
      await this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          await this.usersStore.getUserData();
          nextTick(() => {
            this.itemsFromApi = this.usersStore.getListOutput;
          });
        } catch (error) {
          this.alertStore.error(error);
        }
      },
      sendToHome() {
        this.$router.push({ name: 'DashboardPage' });
      },
      sendToReportsPage() {
        this.$router.push({ name: 'ReportsPage' });
      },
      sendToBillingPage() {
        this.$router.push({ name: 'UpgradePlanPage' });
      },
      handleAccountSettingsClick() {
        this.$router.push({ name: 'AccountSettingsPage' });
      },
      showPopup() {
        this.isPopupVisible = true;
      },
      showPopupDelete() {
        this.isPopupVisibleDelete = true;
      },
      showPopupExport() {
        this.isPopupVisibleExport = true;
      },
      parseImageData(imageDataString) {
          try {
              if(!Object.keys(imageDataString).length) return null;
              else {
              // Parse the JSON string if it is a string
              const parsedData = typeof imageDataString === 'string' 
              ? JSON.parse(imageDataString) 
              : imageDataString;
              return `data:${parsedData.mime};base64,${parsedData.data}`;
              }
          } catch (e) {
              return imageDataString;
          }
      },
    },
    computed: {
        headerUserImage() {
            return this.parseImageData(this.usersStore.headerUserInformation?.picture) ?? require('@/assets/no-image.jpg');
        },
    },
  }
  </script>
  
  <style scoped>
  .header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.mt-6{
  margin-top: 5rem;
}
  #user-name {
    color: var(--theme-dark, #212529);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .options-span {
    color: var(--theme-secondary, #6C757D);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  #divider-style {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
  #delete-acc-span {
    color: var(--theme-danger, #DC3545);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .image-style {
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }
  </style>