<template>
    <section v-if="$route.name === 'CompanySettingsPage'" id="form">
        <template v-if="isMobile">
            <div class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/sideBar/company-settings.svg')" alt="company settings icon">
                <span class="page-title-mobile">{{ $t('Company Settings') }} </span>
            </div>
        </template>
        <LayoutForm 
            class="mt-5 mt-lg-0"
            :hasPageActions="false"
            :pageLayout="{
                title: $t('Company Settings'),
                icon: require('@/assets/sideBar/company-settings.svg')
            }"
            :threeDotsSettings="threeDotsSettings"
            @pageActionClick="pageActionClick($event)"
        >
            <template v-slot:dynamic-content>
                <div class="row p-0 p-md-3 border-bottom">
                    <div class="file-picker">
                        <img 
                            v-if="Object.keys(formData.picture).length" 
                            class="cursor-pointer img-fluid rounded-circle" 
                            :src="formData.picture.url || parseImageData(formData?.picture)" 
                            @click="showimagePicker = true"
                        >                       
                        <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                            <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !formData.picture.url && imageError }">
                                {{ $t('Choose Image') }}
                                <pre class="m-0 text-muted">1:1</pre>
                            </span>   
                        </div>                      
                        <Form ref="myForm" class="form" :validation-schema="validationSchema" v-slot="{ errors }">
                            <div class="d-block d-md-flex justify-content-center w-100 gap-3 flex-wrap">
                                <div class="first-input">
                                    <label class="label" for="name">{{ $t('Name') }}</label>
                                    <Field
                                        v-model="formData.name"
                                        type="text"
                                        name="name"
                                        v-slot="{ field }"
                                        @input="validateField('name')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': isSubmitting && errors.name }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Name')"
                                        />
                                    </Field>  
                                    <ErrorMessage v-if="isSubmitting" name="name" class="text-danger" />                            
                                </div>
                                <div class="second-input">
                                    <label class="label" for="location">{{ $t('Phone Number') }}</label>
                                    <Field
                                        v-model="formData.phoneNumber"
                                        name="phoneNumber"
                                        type="text"
                                        v-slot="{ field }"
                                        @input="validateField('phoneNumber')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': isSubmitting && errors.phoneNumber }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Number')"
                                        />
                                    </Field>
                                    <ErrorMessage v-if="isSubmitting" name="phoneNumber" class="text-danger" />                            
                                </div>
                            </div>
                            <div class="d-flex justify-content-end gap-2 my-4">
                                <CustomButton 
                                    :rightIcon="require('@/assets/HotelGroup/x-circle.svg')" 
                                    :label="$t('Cancel')" 
                                    @clickBtn="firstActionClick"
                                    buttonType="theme-light" 
                                    :buttonStyle= "{
                                        borderRadius: '8px!important',
                                        gap: '8px',
                                        color: 'var(--theme-secondary, #6C757D)',
                                        padding: '6px 12px',
                                        width: '230px'
                                    }" 
                                />
                                <CustomButton 
                                    :rightIcon="require('@/assets/HotelGroup/saveBtn.svg')" 
                                    :label="$t('Save')" 
                                    @clickBtn="secondActionClick"
                                    :buttonStyle="{
                                        borderRadius: '8px!important',
                                        gap: '4px',
                                        color: '#FFFFFF',
                                        padding: '6px 12px',
                                        width: '230px'
                                    }" 
                                    :loading="loadingForm"
                                />
                            </div>
                        </Form>
                    </div>
                </div>
                <div class="row p-0 p-md-3 border-bottom">
                    <TableComponent 
                        v-model="hotelGroupsFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyHotelGroupsRows" 
                        @rowClicked="rowClicked($event, 'HotelGroupEditPage')"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row align-items-center p-3">
                                <div class="col-12 col-md-6">
                                    <div class="d-flex align-items-center gap-2">
                                        <img id="integration-icon" src="@/assets/Company/hotel-groups.svg" alt="icon">
                                        <span class="table-title">{{ $t('hotelGroups') }}</span>
                                    </div>
                                </div>
                                <div v-if="!isMobile" class="col-6">
                                    <div class="d-flex gap-3 justify-content-end">
                                        <router-link class="btn-custom" :to="{ name: 'HotelGroupAddPage' }">
                                            <img src="@/assets/Company/plus.svg" />
                                            {{ $t('Create Hotel Group') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <router-link class="btn-custom" :to="{ name: 'HotelGroupAddPage' }">
                            <img src="@/assets/Company/plus.svg" />
                            {{ $t('Create Hotel Group') }}
                        </router-link>
                    </div>
                </div>
                <div class="row p-0 p-md-3 border-bottom">
                    <TableComponent 
                        v-model="hotelsFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyHotelsRows" 
                        @rowClicked="rowClicked($event, 'HotelEditPage')"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row align-items-center p-3">
                                <div class="col-12 col-md-6">
                                    <div class="d-flex align-items-center gap-2">
                                        <img id="integration-icon" src="@/assets/HotelGroup/hotelsIconTitle.svg" alt="icon">
                                        <span class="table-title">{{ $t('hotels') }}</span>
                                    </div>
                                </div>
                                <div v-if="!isMobile" class="col-6">
                                    <div class="d-flex gap-3 justify-content-end">
                                        <router-link class="btn-custom" :to="{ name: 'HotelAddPage' }">
                                            <img src="@/assets/Company/plus.svg" />
                                            {{ $t('Create Hotel') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <router-link class="btn-custom" :to="{ name: 'HotelAddPage' }">
                            <img src="@/assets/Company/plus.svg" />
                            {{ $t('Create Hotel') }}
                        </router-link>
                    </div>
                </div>
                <div class="row p-0 p-md-3">
                    <TableComponent 
                        v-model="usersFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyUserRows" 
                        :clickableRows="false"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row align-items-center p-3">
                                <div class="col-12 col-md-6">
                                    <div class="d-flex align-items-center gap-2">
                                        <img id="user-icon" src="@/assets/HotelGroup/person.svg" alt="icon">
                                        <span class="table-title">{{ $t('users') }}</span>
                                    </div>
                                </div>
                                <div v-if="!isMobile" class="col-6">
                                    <div class="d-flex gap-3 justify-content-end">
                                        <router-link class="btn-custom" :to="{ name: 'CompanyUsersList' }">
                                            <img src="@/assets/Company/arrow-up-right.svg"/>
                                            {{ $t('manageUsers') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <router-link class="btn-custom" :to="{ name: 'CompanyUsersList' }">
                            <img src="@/assets/Company/arrow-up-right.svg"/>
                            {{ $t('manageUsers') }}
                        </router-link>
                    </div>
                </div>
            </template>
        </LayoutForm>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false" 
            :titlePopup="$t('Change Company Picture')"
            :descriptionPopup="$t('Here you can change the photo of your company to make it more easily recognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="selectedPicture"
                        :placeholder="require('@/assets/Company/placeholder-2.png')" 
                        :ratioWidth="1"
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': selectedPicture?.url === formData?.picture?.url }"
                        :label="$t('Change Picture')" 
                        buttonType="theme-light"
                        :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
                        @clickBtn="onChooseImageClicked" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- Delete Company -->
        <CustomPopup 
          v-show="showDeleteCompanyPopup" 
          @popUpClosed="showDeleteCompanyPopup = false"
          :img="require('@/assets/Popup/deleteHotelGroup.svg')"
          :descriptionPopup="$t('Are you sure you want to delete this Company? <br> All associated data will be permanently deleted.')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'transparent-dark'" 
                      :label="$t('Cancel')" 
                      @clickBtn="showDeleteCompanyPopup = false"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px',
                          border: '1px solid var(--theme-secondary) !important',
                      }"
                  />
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="$t('Yes')" 
                      @clickBtn="showDeleteCompanyPopup = false"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
        <!-- cancel Company -->
        <CustomPopup 
          v-show="showCancelChangesPopup" 
          @popUpClosed="showCancelChangesPopup = false"
          :img="require('@/assets/Popup/cancelSave.svg')"
          :descriptionPopup="$t('Are you sure you want to discard the changes? All unsaved changes will be lost.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                        <CustomButton 
                            class="w-100" 
                            :buttonType="'transparent-dark'" 
                            :label="$t('No')" 
                            @clickBtn="showCancelChangesPopup = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px',
                                border: '1px solid var(--theme-secondary) !important',
                            }"
                        />
                        <CustomButton 
                            class="w-100" 
                            :buttonType="'primary'" 
                            :label="$t('Yes')" 
                            @clickBtn="cancelChanges"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                </div>
            </template>
        </CustomPopup> 
        <!-- save Company -->
        <CustomPopup 
          v-show="showSaveChangesPopup" 
          @popUpClosed="showSaveChangesPopup = false"
          :img="require('@/assets/Popup/successSave.svg')"
          :descriptionPopup="$t('Your Company Has Been Saved Successfully!')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="$t('Close')" 
                      @clickBtn="closeSavePopup"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
    </section>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import { useCompanyUsersStore, useAlertStore, useCompanyStore, useSideMenuStore } from "@/stores";
import LayoutForm from '@/components/LayoutForm.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import TableComponent from '@/components/TableComponent.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useResponsive } from "@/composables/responsive";

export default {
    components: {
        LayoutForm,
        CustomPopup,
        CustomButton,
        ImagePicker,
        TableComponent,
        Form,
        Field,
        ErrorMessage
    },
    setup() {
        const companyUsersStore = useCompanyUsersStore();
        const alertStore = useAlertStore();
        const companyStore = useCompanyStore();
        const sideMenuStore = useSideMenuStore();
        const { isMobile } = useResponsive();

        return {
            companyUsersStore,
            alertStore,
            companyStore,
            sideMenuStore,
            isMobile
        }
    },
    data() {
        return {
            formData: {
                name: '',
                phoneNumber: '',
                picture: {}
            },
            originalData: {},
            selectedPicture: {},
            threeDotsSettings: [
                {
                    id: 0,
                    icon: require('@/assets/Popup/smallDeleteBtn.svg'),
                    alt: 'Delete Company',
                    label: this.$t('Delete Company'),
                }
            ],
            loading: false,
            showSaveChangesPopup: false,
            showCancelChangesPopup: false,
            showDeleteCompanyPopup: false,
            showimagePicker: false,
            // Tables
            bodyHotelGroupsRows: [
                { type: 'ImageWithText', field: 'hotel' }
            ],
            hotelGroupsFromApi: [],
            bodyHotelsRows: [
                { type: 'ImageWithText', field: 'hotel' }
            ],
            hotelsFromApi: [],
            bodyUserRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'ImageWithText', field: 'role' },
            ],
            usersFromApi: [],
            loadingForm: false,
            imageError: false,
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'CompanySettingsPage') {
            next(); 
            return; // Prevents the code below from running
        }
        // console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        // It's essential to include a condition in the mounted hook to ensure it only mounts when the specific page is loaded.
        if(this.$route.name === 'CompanySettingsPage') {
            await this.fetchData();
        }
    },
    computed: {
        validationSchema() {
            return {
                name: 'required',
                phoneNumber: 'required'
            }
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const response = await this.companyStore.getCompanyDetails();
                const picture = JSON.parse(response.picture);
                this.formData = {
                    ...response,
                    name: response.name,
                    phoneNumber: response.phoneNumber,
                    picture: picture && Object.keys(picture).length ? JSON.stringify(picture) : {url: require('@/assets/no-image.jpg')}
                };
                // Save original data
                this.selectedPicture = this.formData.picture;
                this.originalData = JSON.parse(JSON.stringify(this.formData));

                // hotel groups
                this.hotelGroupsFromApi = response.hotel_groups.map((hotelGroup) => ({
                    id: hotelGroup.objectId,
                    hotel: { 
                        image: hotelGroup.picture ?? require('@/assets/no-image.jpg'), 
                        firstText: hotelGroup.name
                    }
                }));

                // hotels
                this.hotelsFromApi = response.hotels.map((hotel) => ({
                    id: hotel.objectId,
                    hotel: { 
                        image: hotel.picture ?? require('@/assets/no-image.jpg'), 
                        firstText: hotel.name
                    }
                }));

                await this.companyUsersStore.getList();
                const invitesSent = this.companyUsersStore.getListOutput.flatMap((user) => user.invites_sent);
                this.usersFromApi = this.companyUsersStore.getListOutput.map((user) => ({
                    name: { 
                        image: user.picture && user.picture !== '{}' ? user.picture : require('@/assets/no-image.jpg'), 
                        firstText: user?.name, 
                        secondText: user?.pivot?.jobTitle 
                    },
                    role: { 
                        image: require('@/assets/Company/lock.svg'), 
                        firstText: user?.pivot?.role 
                    }
                }));
                if(invitesSent && invitesSent.length) {
                    invitesSent.forEach(user => {
                        this.usersFromApi.push({
                            name: {
                                image: require('@/assets/no-image.jpg'),
                                secondText: user.email
                            },
                            role: {
                                firstText: this.$t('Pending invitation - awaiting acceptance')
                            },
                            isNotRowClick: true
                        })
                    });
                }
            } catch (error) {
                this.alertStore.error(error);
            }
            finally {
                this.loading = false;
            }
        },
        pageActionClick(index) {
            if(index == 0){
                this.showDeleteCompanyPopup = true;
            }
        },
        firstActionClick() {
            this.showCancelChangesPopup = true;
        },
        cancelChanges() {
            // Reset form data to original data
            this.formData = JSON.parse(JSON.stringify(this.originalData)); 
            this.selectedPicture = this.formData.picture;  
            this.showCancelChangesPopup = false;
        },
        async secondActionClick() {
            // submit form and manually validate
            // const { valid, errors } = await this.$refs.myForm.validate();
            this.isSubmitting = true;
            const { valid } = await this.$refs.myForm.validate();
            if (!Object.keys(this.formData.picture).length) {
                this.imageError = true;
                this.isSubmitting = false;
                return;
            }
            if (valid) {
                // console.log('Form is valid');
                this.imageError = false;
                this.loadingForm = true;
                
                const pictureObject = typeof this.formData.picture === 'object' && this.formData.picture !== null
                    ? { ...this.formData.picture }
                    : null;

                const payload = {
                    name: this.formData.name,
                    phoneNumber: this.formData.phoneNumber,
                    picture: pictureObject ?? this.formData.picture,
                };

                if (pictureObject) {
                    if(pictureObject.url) delete pictureObject.url;
                    payload.picture = JSON.stringify(pictureObject);
                }

                try {
                    // console.log('Payload:', payload);
                    await this.companyStore.editCompany(payload);
                    this.originalData = JSON.parse(JSON.stringify(this.formData)); // update original data
                    this.sideMenuStore.updateCompany(this.formData);
                    this.showSaveChangesPopup = true;
                } catch (error) {
                    this.alertStore.error(error);
                } finally {
                    this.loadingForm = false;
                    this.isSubmitting = false;
                }
            } else {
                this.loadingForm = false;
                this.isSubmitting = false;
                // console.log('Form has errors:', errors);
            }
        },
        closeSavePopup() {
            this.showSaveChangesPopup = false;
        },
        rowClicked(obj, route) {
            if(route === 'HotelEditPage') {
                this.$router.push({ name: route, params: { hotelId: obj.id } });
                return;
            }
            if(route === 'HotelGroupEditPage') {
                this.$router.push({ name: route, params: { hotelGroupId: obj.id } });
                return;
            }
        },
        onChooseImageClicked() {
            this.formData.picture = this.selectedPicture;
            this.showimagePicker = false;
        },
        validateField(fieldName) {
            this.$refs.myForm.validate(fieldName); // Validate field when user types
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    },
}
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.text-danger,
input::placeholder,
.first-input .label,
.second-input .label {
    color: var(--theme-secondary);
    font-size: .8rem;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
}
.table-title {
    color: var(--theme-dark);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}

.btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-dark);
    border-radius: 8px;
    border: 1px solid var(--theme-border);
    background: var(--theme-light);
    font-size: .9rem;
    text-decoration: none;
}

.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.file-picker img,
.file-picker .placeholder-img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}
.first-input .form-control,
.second-input .form-control {
    width: 290px;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
@media (min-width: 992px) {
    .btn-custom{
        min-width: 200px
    }
}
@media (max-width: 767px) {
    .first-input .form-control,
    .second-input .form-control,
    .form {
        width: 100%;
    }
    .btn-custom{
        width: 100%;
        font-size: .8rem
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
    .file-picker {
        margin-top: 1rem;
    }
}
</style>