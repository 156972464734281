<template>
    <section v-if="$route.name === 'HotelEditPage'" id="form">
        <template v-if="isMobile">
            <div @click="$router.push({name: 'CompanySettingsPage'})" class="header-mobile border-bottom">
                <img class="img-fluid arrow-icon" :src="require('@/assets/arrow-right-fill.svg')" alt="Edit hotel icon">
                <span class="page-title-mobile">{{ $t('Edit hotel') }} </span>
            </div>
        </template>
        <LayoutForm 
            class="mt-5 mt-lg-0"
            :hasPageActions="true"
            :pageLayout="{
                title: $t('Edit hotel'),
                icon: require('@/assets/Company/pencil.svg'),
                firstAction: { icon: require('@/assets/HotelGroup/x-circle.svg'), label: $t('cancel') },
                secondAction: { icon: require('@/assets/HotelGroup/saveBtn.svg'), label: $t('save'), loading: loadingForm },
            }"
            :threeDotsSettings="threeDotsSettingsHeader"
            @pageActionClick="pageActionClick($event)" 
            @firstActionClick="firstActionClick" 
            @secondActionClick="secondActionClick"
        >
            <template v-slot:dynamic-content>
                <div class="row p-0 p-md-3 border-bottom">
                    <div class="file-picker">
                        <img 
                            v-if="Object.keys(formData.picture).length" 
                            class="cursor-pointer img-fluid rounded-circle" 
                            :src="formData.picture.url || parseImageData(formData?.picture)" 
                            @click="showimagePicker = true"
                        >                       
                        <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                            <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !formData.picture.url && imageError }">
                                {{ $t('Choose Image') }}
                                <pre class="m-0 text-muted">1:1</pre>
                            </span>   
                        </div>                      
                        <Form ref="myForm" class="form mb-3" :validation-schema="validationSchema" v-slot="{ errors }">
                            <div class="d-block d-md-flex justify-content-center w-100 gap-3 flex-wrap">
                                <div class="first-input">
                                    <label class="label" for="name">{{ $t('Name') }}</label>
                                    <Field
                                        v-model="formData.name"
                                        type="text"
                                        name="name"
                                        v-slot="{ field }"
                                        @input="validateField('name')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.name }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Name')"
                                        />
                                    </Field>  
                                    <ErrorMessage name="name" class="text-danger" />                            
                                </div>
                                <div class="second-input">
                                    <label class="label" for="location">{{ $t('Location') }}</label>
                                    <Field
                                        v-model="formData.location"
                                        name="location"
                                        type="text"
                                        v-slot="{ field }"
                                        @input="validateField('location')"
                                    >
                                        <input
                                            class="form-control"
                                            :class="{ 'is-invalid': errors.location }"
                                            v-bind="field"
                                            :placeholder="$t('Insert Hotel Location')"
                                        />
                                    </Field>
                                    <ErrorMessage name="location" class="text-danger" />                            
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div class="row">
                    <TableComponent 
                        v-model="hotelGroupFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyHotelGroupRows" 
                        :clickableRows="false"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="hotel-icon" src="@/assets/Company/hotel-groups.svg" alt="">
                                        <span class="table-title">{{ $t('Hotel Group') }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                </div>
                <!-- <div class="row border-bottom p-3">
                    <TableComponent 
                        v-model="usersFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyUserRows" 
                        :clickableRows="false"
                        :loading="loading"
                    >
                        <template v-slot:title>
                            <div class="row p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="hotel-icon" src="@/assets/Users/usersIcon.svg" alt="">
                                        <span class="table-title">{{ $t('Users') }}</span>
                                    </div>
                                </div>
                                <div v-if="usersFromApi && usersFromApi.length && !isMobile" class="col d-flex gap-3 justify-content-end">
                                    <router-link class="btn-custom" :to="{ name: 'HotelUsersList'}">
                                        <img src="@/assets/Company/arrow-up-right.svg" alt="icon">
                                        {{ $t('manageUsers')}}
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                    <div v-if="isMobile" class="p-3">
                        <router-link class="btn-custom" :to="{ name: 'HotelUsersList'}">
                            <img src="@/assets/Company/arrow-up-right.svg" alt="icon">
                            {{ $t('manageUsers')}}
                        </router-link>
                    </div>
                </div> -->
            </template>
        </LayoutForm>
        <!-- CustomPopup for image picker -->
        <CustomPopup 
            v-show="showimagePicker" 
            @popUpClosed="showimagePicker = false"
            :titlePopup="$t('changeHotelPicture')"
            :descriptionPopup="$t('Here you can change the photo of your hotel to make it more easily recognizable.')"
        >
            <template v-slot:body>
                <div class="d-flex flex-column align-items-center gap-3">
                    <ImagePicker 
                        v-model="selectedPicture"
                        :placeholder="require('@/assets/Company/placeholder-2.png')" 
                        :ratioWidth="1" 
                        :ratioHeight="1"
                    />
                    <CustomButton 
                        :class="{ 'disabled': selectedPicture?.url === formData?.picture?.url }"
                        :label="$t('changePicture')"
                            buttonType="theme-light" 
                            :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
                            @clickBtn="onChooseImageClicked" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }" 
                    />
                </div>
            </template>
        </CustomPopup>
        <!-- Delete Hotel -->
        <CustomPopup 
          v-show="showDeleteHotelPopup" 
          @popUpClosed="showDeleteHotelPopup = false"
          :img="require('@/assets/Popup/deleteHotel.svg')"
          :descriptionPopup="$t('Are you sure you want to delete this Hotel? <br> All associated data will be permanently deleted.')"
        >
          <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('Cancel')" 
                        @clickBtn="showDeleteHotelPopup = false"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }"
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')" 
                        @clickBtn="removeHotel"
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                </div>
          </template>
        </CustomPopup> 
        <!-- Leave Hotel -->
        <!-- <CustomPopup 
          v-show="showLeaveHotelPopup" 
          @popUpClosed="showLeaveHotelPopup = false"
          :img="require('@/assets/Popup/leaveHotelBigIcon.svg')"
          :descriptionPopup="'To leave this Hotel, you will need to transfer the administrator role to another member'"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="'Got it'" 
                      @clickBtn="showLeaveHotelPopup = false"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup>  -->
        <!-- cancel Hotel -->
        <CustomPopup 
          v-show="showCancelChangesPopup" 
          @popUpClosed="showCancelChangesPopup = false"
          :img="require('@/assets/Popup/cancelSave.svg')"
          :descriptionPopup="$t('Are you sure you want to cancel the changes?')"
        >
          <template v-slot:body>
                <div class="d-flex flex-row gap-4 mt-3">
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'transparent-dark'" 
                        :label="$t('No')"
                        @clickBtn="showCancelChangesPopup = false" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px',
                            border: '1px solid var(--theme-secondary) !important',
                        }" 
                    />
                    <CustomButton 
                        class="w-100" 
                        :buttonType="'primary'" 
                        :label="$t('Yes')"
                        @clickBtn="cancelChanges" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }" 
                    />
                </div>
          </template>
        </CustomPopup> 
        <!-- save Hotel -->
        <CustomPopup 
          v-show="showSaveChangesPopup" 
          @popUpClosed="showSaveChangesPopup = false"
          :img="require('@/assets/Popup/successSave.svg')"
          :descriptionPopup="$t('Your Hotel has been saved successfully!')"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-4 mt-3">
                  <CustomButton 
                      class="w-100" 
                      :buttonType="'primary'" 
                      :label="$t('Got back')" 
                      @clickBtn="showSaveChangesPopup = false; $router.push({ name: 'CompanySettingsPage' })"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CustomPopup> 
    </section>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import { useHotelStore, useAlertStore, useHotelGroupStore } from "@/stores";
import LayoutForm from '@/components/LayoutForm.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import TableComponent from '@/components/TableComponent.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useResponsive } from '@/composables/responsive.js';

export default {
    components: {
        LayoutForm,
        CustomPopup,
        CustomButton,
        ImagePicker,
        TableComponent,
        Form,
        Field,
        ErrorMessage
    },
    setup() {
        const hotelStore = useHotelStore();
        const alertStore = useAlertStore();
        const hotelGroupStore = useHotelGroupStore();
        const { isMobile } = useResponsive();

        return {
            hotelStore,
            alertStore,
            hotelGroupStore,
            isMobile
        }
    },
    data() {
        return {
            hotelId: this.$route.params.hotelId,
            formData: {
                name: '',
                location: '',
                picture: {}
            },
            originalData: {},
            selectedPicture: {},
            loading: false,
            loadingForm: false,
            imageError: false,
            threeDotsSettingsHeader: [
                {
                    id: 0,
                    icon: require('@/assets/Popup/smallDeleteBtn.svg'),
                    alt: 'Delete Hotel',
                    label: this.$t('Delete Hotel'),
                }
                // {
                //     id: 1,
                //     icon: require('@/assets/Popup/leaveHotel.svg'),
                //     alt: 'Leave Hotel',
                //     label: this.$t('Leave Hotel'),
                // },
            ],
            showSaveChangesPopup: false,
            showCancelChangesPopup: false,
            showDeleteHotelPopup: false,
            showLeaveHotelPopup: false,
            showimagePicker: false,
            usersFromApi: [],
            bodyUserRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'ImageWithText', field: 'role' },
            ],
            bodyHotelGroupRows: [
                { type: 'ImageWithText', field: 'name'},
                { type: 'SimpleText', field: 'hotelNumbers' }
            ],
            hotelGroupFromApi: []
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'HotelEditPage') {
            next(); 
            return; // Prevents the code below from running
        }
        console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        if(this.$route.name === 'HotelEditPage') {
            await this.fetchData();
        }
    },
    computed: {
        validationSchema() {
            return {
                name: 'required',
                location: 'required'
            }
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                await this.hotelStore.getListBySlug(this.hotelId); // Fetch the hotel data
                this.formData = {
                    name: this.hotelStore.getListBySlugOutput.name,
                    location: this.hotelStore.getListBySlugOutput.location,
                    picture: this.hotelStore.getListBySlugOutput.picture && Object.keys(this.hotelStore.getListBySlugOutput.picture).length ? this.hotelStore.getListBySlugOutput.picture : {}
                }
                // Save original data
                this.selectedPicture = this.formData.picture;
                this.originalData = JSON.parse(JSON.stringify(this.formData));

                // users of the hotel
                this.usersFromApi = this.hotelStore.getListBySlugOutput.users.map(user => ({
                    name: { 
                        image: user?.picture ?? require('@/assets/no-image.jpg'), 
                        firstText: user?.name, 
                        secondText: user?.pivot?.jobTitle ?? this.$t('jobTitleMissing')
                    },
                    role: { 
                        image: require('@/assets/Company/lock.svg'), 
                        firstText: user?.pivot?.role ?? this.$t('roleMissing')
                    }
                }));

                // console.log('usersFromApi', this.usersFromApi);

                await this.hotelGroupStore.getHotelGroup(this.hotelStore.getListBySlugOutput.hotelGroupObjectId	); // Fetch the hotel group data
                const hotelCount = this.hotelGroupStore.getHotelGroupOutput?.hotels?.length ?? 0;
                const hotelLabel = hotelCount === 1 ? this.$t('hotel') : this.$t('hotels');

                this.hotelGroupFromApi = [
                    {
                        name: { 
                            image: this.hotelGroupStore.getHotelGroupOutput?.picture ?? require('@/assets/no-image.jpg'), 
                            firstText: this.hotelGroupStore.getHotelGroupOutput?.name
                        },
                        hotelNumbers: `${hotelCount} ${hotelLabel}`
                    }
                ];

            } catch (error) {
                this.alertStore.error(error);
            } finally {
                this.loading = false;
            }
        },
        pageActionClick(index) {
            if(index == 0){
                this.showDeleteHotelPopup = true;
            }
            // else if (index == 1){
            //     this.showLeaveHotelPopup = true;
            // }
        },
        firstActionClick() {
            this.showCancelChangesPopup = true;
        },
        cancelChanges() {
            // Reset form data to original data
            this.formData = JSON.parse(JSON.stringify(this.originalData));
            this.selectedPicture = this.formData.picture;
            this.showCancelChangesPopup = false;
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        },
        async secondActionClick() {
            // submit form and manually validate
            // const { valid, errors } = await this.$refs.myForm.validate();
            const { valid } = await this.$refs.myForm.validate();
            if (!Object.keys(this.formData.picture).length) {
                this.imageError = !Object.keys(this.formData.picture).length;
                return;
            }
            if(valid) {
                this.imageError = false;
                this.loadingForm = true;

                const pictureObject = typeof this.formData.picture === 'object' && this.formData.picture !== null
                    ? { ...this.formData.picture }
                    : null;

                const payload = {
                    name: this.formData.name,
                    location: this.formData.location,
                    picture: pictureObject ?? this.formData.picture,
                    groupId: this.hotelGroupStore.getHotelGroupOutput.objectId
                };

                if (pictureObject) {
                    if(pictureObject.url) delete pictureObject.url;
                    payload.picture = JSON.stringify(pictureObject);
                }
                
                try {
                    // console.log('payload', payload);
                    await this.hotelStore.editHotel(this.hotelId, payload);
                    this.showSaveChangesPopup = true;
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                } catch (error) {
                    alert(this.$t('Something went wrong! Please try again.'));
                    this.alertStore.error(error);
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 100);
                } finally {
                    this.loadingForm = false;
                }
            } else {
                this.loadingForm = false;
                // console.log('Form has errors:', errors);
            }
        },
        onChooseImageClicked() {
            this.formData.picture = this.selectedPicture;
            this.showimagePicker = false;
        },
        async removeHotel() {
            try{
                await this.hotelStore.deleteHotel(this.hotelId);
                this.showDeleteHotelPopup = false;
                this.$router.push({ name: 'CompanySettingsPage' });
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        validateField(fieldName) {
            this.$refs.myForm.validate(fieldName); // Validate field when user types
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    },
}
</script>

<style scoped>
.header-mobile {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transform: rotate(-180deg);
    filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.text-danger,
input::placeholder,
.first-input .label,
.second-input .label {
    color: var(--theme-secondary);
    font-size: .8rem;
    font-style: normal;
    font-weight: 400;
    word-break: break-word;
}

.table-title {
    color: var(--theme-dark, #212529);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}

.btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-dark);
    border-radius: 8px;
    border: 1px solid var(--theme-border);
    background: var(--theme-light);
    font-size: .9rem;
    text-decoration: none;
}
.file-picker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.file-picker img,
.file-picker .placeholder-img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}
.first-input .form-control,
.second-input .form-control {
    width: 290px;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
@media (min-width: 992px) {
    .btn-custom{
        min-width: 200px
    }
}
@media (max-width: 767px) {
    .first-input .form-control,
    .second-input .form-control,
    .form {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
    .file-picker {
        margin-top: 1rem;
    }
}
</style>