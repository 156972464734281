<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="popup">
                <div class="pop-up-close padding-style-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp">
                </div>
                <div class="title-div">
                    <span class="title-popup">Auto Classic</span>
                </div>
                <hr>
                <div class="modal-container padding-style">
                    <div class="row">
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/date-popup-red.svg">
                            <div class="d-flex flex-column gap-2">
                                <span>Thu 5 October 2023</span>
                                <span>Sun 8 October 2023</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/people-icon-red.svg">
                            <span>5,000</span>
                        </div>
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/conference-popup-red.svg">
                            <span>Conference</span>
                        </div>
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/info-popup-red.svg">
                            <span>Expo</span>
                        </div>
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/url-popup-red.svg">
                            <span>URL</span>
                        </div>
                        <div class="col-12 d-flex gap-2 flex-row mt-3">
                            <img class="icon-size" src="@/assets/Market/location-popup-red.svg">
                            <span>Exponor - Feira Internacional do Porto ( 8.9km )</span>
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-3">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.424126538477!2d-8.69253170806356!3d41.19859716479325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246887efcbf357%3A0x476e753e16918d0d!2sExponor-%20Feira%20Internacional%20do%20Porto!5e0!3m2!1spt-PT!2spt!4v1706630858462!5m2!1spt-PT!2spt" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <slot name="body">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drag: false,        }
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        clickSmallIconText() {
            this.$emit("popUpDeleteOpen");

        },
        clickBtnCustom() {
            this.$emit("btnCustom");

        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        },
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function (val) {
            if (val) {
                document.body.classList.add("modal-open");
            }
            else {
                document.body.classList.remove("modal-open");
            }
        }
    }

};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 1rem;
}

.popup{
    display: flex;
    flex-direction: column;
    width: 40vw;
    min-height: 65vh;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 40px;
    box-shadow: 0 0 8px 0 #303942;
}
.padding-style{
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}
.title-popup{
    color: var(--Theme-Secondary, #6C757D);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.padding-style-close{
    padding-top: 2rem;
    padding-right: 2rem;
}

.pop-up-close {
    display: flex;
    justify-content: end;
}
.title-div{
    padding-left: 2rem;
}
.modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.icon-size{
    width: 20px;
}
#popup-title{
    color: var(--theme-dark, #212529);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
    word-break: break-word;
}
#popup-desc{
    color: var(--gray-800, #343A40);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: 1rem 0;
}
.asterisk{
  color: var(--theme-primary);
  margin-left: 5px;
}
@media only screen and (max-width: 1000px) {
    .popup{
        width: 80vw;
        min-height: 65vh;
    }
    .padding-style{
        padding: 1rem;
    }
}
</style>