<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="d-flex flex-column justify-content-center align-items-center gap-1 gap-lg-3 h-100">
                <ImagePicker 
                    v-if="currentStepComponentChild === 1"
                    v-model="imagePickerProfile" 
                    :placeholder="require('@/assets/authentication/completeRegister/profile-icon.svg')"
                    :ratioWidth="1" :ratioHeight="1">
                </ImagePicker>
                <div class="icon">
                    <img v-if="currentStepComponentChild === 2" 
                        src="@/assets/authentication/completeRegister/security-icon.svg" 
                        alt="icon" 
                        class="img-fluid w-100"
                    >
                </div>
                <ImagePicker 
                    v-if="currentStepComponentChild === 3"
                    v-model="imagePickerCompany" 
                    :placeholder="require('@/assets/authentication/completeRegister/company-icon.svg')"
                    :ratioWidth="1" :ratioHeight="1"
                >
                </ImagePicker>
                <div class="icon">
                    <img v-if="currentStepComponentChild === 4" 
                        src="@/assets/authentication/completeRegister/plan-icon.svg" 
                        alt="icon" 
                        class="img-fluid w-100"
                    >
                </div>
                <span class="title">{{ title }}</span>
                <span class="desc">{{ desc }}</span>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="d-flex justify-content-center align-items-start align-items-lg-center h-100">
                <div id="step_form">
                    <template v-if="currentStepComponentChild === 1">
                        <div class="d-flex flex-column gap-3">
                            <div class="d-flex flex-column gap-2">
                                <label for="language">{{ $t('language') }}<span class="asterisk">*</span></label>
                                <SingleOptionDropdown 
                                    v-model="language" 
                                    :options="languages" 
                                    :title="$t('insertLanguage')" 
                                    :index="1"
                                    :is-valid="genericValidator(language, attemptSubmit) && errorValidator('language')"
                                />
                            </div>
                            <div class="d-flex flex-column gap-2">
                                <label for="currency">{{ $t('currency') }}<span class="asterisk">*</span></label>
                                <SingleOptionDropdown 
                                    v-model="currency" 
                                    :options="currencies" 
                                    :title="$t('insertCurrency')" 
                                    :index="2"
                                    :is-valid="genericValidator(currency, attemptSubmit) && errorValidator('currency')"
                                />
                            </div>
                            <CustomButton 
                                class="btn" 
                                buttonType="theme-light" 
                                :buttonStyle="{ borderRadius: '0 !important',}" 
                                :label="$t('saveAndContinue')" 
                                @clickBtn="onSubmitMe"
                                :loading="usersStore.loading"
                            />
                        </div>
                    </template>
                    <template v-if="currentStepComponentChild === 2">
                        <div class="d-flex flex-column gap-3">
                            <span class="title">{{ $t('Two-Factor Authentication') }}</span>
                            <!-- Instructions for 2FA setup -->
                            <div class="instructions">
                                <p>{{ $t('1. Scan the QR code: Open the Google Authenticator app on your mobile device. Tap the "+" button to add a new account, then choose "Scan a QR code" and scan the QR code displayed on your screen.') }} </p>
                                <p>{{ $t('2. Enter the code: After scanning the QR code, a 6-digit verification code will appear in the Google Authenticator app. Enter this code into the provided field on your screen to complete the setup.') }} </p>
                                <p>{{ $t('3. Verify: Click the "verify" button to confirm the code and enable 2FA for your account.') }}</p>
                            </div>
                            <template v-if="qrFromApi !== null">
                                <qrcode-vue class="m-auto" :value="qrFromApi" :size="size" level="H" />
                                <Form class="d-flex flex-column gap-3" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                                    <div class="form-group">
                                        <Field 
                                            class="form-control" 
                                            name="secret" 
                                            type="text"
                                            :class="{ 'is-invalid': hasError(errors, 'secret', ['message'])}"
                                            :placeholder="$t('Two-factor authentication code')" 
                                        />
                                        <ErrorMessage class="invalid-feedback" name="secret" as="div" />
                                        <span v-if="apiErrors.message" class="invalid-feedback"> {{ apiErrors.message }} </span>
                                    </div>
                                    <CustomButton 
                                        class="btn" 
                                        :buttonStyle="{ borderRadius: '0 !important'}" 
                                        :label="$t('Verify and continue')" 
                                        :loading="usersStore.loading"
                                    />
                                    <CustomButton 
                                        class="btn" 
                                        buttonType="theme-light" 
                                        :buttonStyle="{ borderRadius: '0 !important'}" 
                                        :label="$t('Advance without 2FA code')"
                                        @clickBtn="this.$emit('stepClicked', 3)"
                                    />
                                    <CustomButton 
                                        class="btn" 
                                        buttonType="theme-light" 
                                        :buttonStyle="{ borderRadius: '0 !important'}" 
                                        :label="$t('I have already set up the 2FA code')"
                                        @clickBtn="this.$emit('stepClicked', 3)"
                                    />
                                </Form>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center justify-content-center" style="min-height: 300px;">
                                    <div class="spinner-border" style="color: #A81800" role="status"></div>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-if="currentStepComponentChild === 3">
                        <Form ref="myForm" :validation-schema="schema" v-slot="{ errors }">
                            <div class="row">
                                <div class="col-12 my-2">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="language">{{ $t('companyName') }}<span class="asterisk">*</span></label>
                                        <Field 
                                            v-model="company.companyName"
                                            name="companyName" 
                                            type="text" 
                                            class="form-control"
                                            :class="{ 'is-invalid': hasError(errors, 'companyName') }" 
                                            :placeholder="$t('insertCompanyName')" 
                                        />
                                        <ErrorMessage class="invalid-feedback" name="companyName" as="div" />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 my-2">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="language">{{ $t('numberOfHotels') }}<span class="asterisk">*</span></label>
                                        <Field 
                                            v-model="company.numberOfHotels"
                                            name="numberOfHotels" 
                                            type="number" 
                                            class="form-control"
                                            :class="{ 'is-invalid': hasError(errors, 'numberOfHotels') }" 
                                            :placeholder="$t('numberOfHotels')" 
                                        />
                                        <ErrorMessage class="invalid-feedback" name="numberOfHotels" as="div" />
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 my-2">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="language">{{ $t('numberOfRooms') }}<span class="asterisk">*</span></label>
                                        <Field 
                                            v-model="company.numberOfRooms"
                                            name="numberOfRooms" 
                                            type="number" 
                                            class="form-control"
                                            :class="{ 'is-invalid': hasError(errors, 'numberOfRooms') }" 
                                            :placeholder="$t('numberOfRooms')" 
                                        />       
                                        <ErrorMessage class="invalid-feedback" name="numberOfRooms" as="div" />                         
                                    </div>
                                </div>
                                <div class="col-12 my-2">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="language">{{ $t('propertyManagmentSystem') }}<span class="asterisk">*</span></label>
                                        <Field 
                                            v-model="company.propertyManagmentSystem"
                                            class="form-control"
                                            :class="{ 'is-invalid': hasError(errors, 'propertyManagmentSystem') }" 
                                            name="propertyManagmentSystem" 
                                            as="select" 
                                            v-slot="{ value }"
                                        >
                                            <option value="" hidden>{{ $t('selectYourOption') }}</option>
                                            <option 
                                                v-for="propertyManagmentSystem in propertyManagmentSystems" 
                                                :key="propertyManagmentSystem.key" 
                                                :value="propertyManagmentSystem.key" 
                                                :selected="value"
                                            >
                                                {{ propertyManagmentSystem.label }}
                                            </option>
                                        </Field>
                                        <ErrorMessage class="invalid-feedback" name="propertyManagmentSystem" as="div" />
                                    </div>
                                </div>
                                <div class="col-12 my-2">
                                    <div class="d-flex flex-column gap-2">
                                        <label for="language">{{ $t('channelManager') }}<span class="asterisk">*</span></label>
                                        <Field 
                                            v-model="company.channelManager"
                                            class="form-control"
                                            :class="{ 'is-invalid': hasError(errors, 'channelManager') }" 
                                            name="channelManager" 
                                            as="select" 
                                            v-slot="{ value }"
                                        >
                                            <option value="" hidden>{{ $t('selectYourOption') }}</option>
                                            <option 
                                                v-for="channelManager in channelManagers" 
                                                :key="channelManager.key" 
                                                :value="channelManager.key" 
                                                :selected="value"
                                            >
                                                {{ channelManager.label }}
                                            </option>
                                        </Field>
                                        <ErrorMessage class="invalid-feedback" name="channelManager" as="div" />
                                    </div>
                                </div>
                                <div class="col-12 my-2">
                                    <CustomButton 
                                        class="btn w-100" 
                                        :label="$t('saveAndContinue')" 
                                        buttonType="theme-light" 
                                        :buttonStyle="{ borderRadius: '0 !important',}"     
                                        :loading="companyStore.loading"    
                                        @clickBtn="onSubmit"                  
                                    />
                                </div>
                            </div>
                        </Form>
                        <!-- <div class="col-12 my-2">
                            <CustomButton 
                                class="btn w-100" 
                                @clickBtn="showAddUsersPopup = true"
                                :label="$t('addUsersToMyCompany')" 
                                buttonType="secondary" 
                                :buttonStyle="{ borderRadius: '0 !important',}"
                            />
                        </div>
                        <AddUsersPopup v-if="showAddUsersPopup" @popUpClosed="showAddUsersPopup = false"></AddUsersPopup> -->
                    </template>
                    <template v-if="currentStepComponentChild === 4">
                        <div class="row g-3">
                            <template v-if="!subscriptionStore.loading">
                                <div class="row">
                                    <div class="col-12 my-2">
                                        <div class="switch-container" @click="toggleSwitch">
                                            <div :class="{ 'switch-active': isMonthActive, 'switch-inactive': !isMonthActive }">{{$t("Montly")}}</div>
                                            <div :class="{ 'switch-inactive': isMonthActive, 'switch-active': !isMonthActive }">{{ $t("Yearly") }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6" v-for="(plan, index) in subscriptionStore.getListOutput" :key="index">
                                    <div class="col-individual-style">
                                        <span id="subscription-type">{{ plan.name }}</span>
                                        <div class="d-flex flex-column">
                                            <span v-if="isMonthActive === false" id="price-span">$<span id="number-price">{{ plan.prices.yearly }}</span></span>
                                            <span v-if="isMonthActive === true" id="price-span">$<span id="number-price">{{ plan.prices.monthly }}</span></span>
                                            <span id="payment-period">{{$t("perUser/MonthBilledAnnually")}}</span>
                                            <CustomButton 
                                                class="btn mt-2 gap-2" 
                                                :label="$t('choosePlan')" 
                                                @clickBtn="sendToPayment(plan.objectId, isMonthActive ? 'monthly' : 'yearly')" 
                                                buttonType="theme-light" 
                                                :buttonStyle="{ borderRadius: '0 !important',}"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-12">
                                    <div class="d-flex align-items-center justify-content-center" style="min-height: 300px;">
                                        <div class="spinner-border" style="color: #A81800" role="status"></div>
                                    </div>
                                </div>
                            </template>
                            <div class="col-12">
                                <CustomButton 
                                    class="btn w-100" 
                                    buttonType="theme-light"
                                    :buttonStyle="{ borderRadius: '0 !important', margin: '1rem auto'}" 
                                    :label="$t('saveAndContinue')"
                                    @clickBtn="onSubmit"
                                />
                            </div>
                        </div>
                        <EditPlanForm v-show="isPopupCurrentPlanVisible" @popUpClosed="isPopupCurrentPlanVisible = false"></EditPlanForm>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleOptionDropdown from '@/components/SingleOptionDropdown.vue'
import ImagePicker from '@/components/ImagePicker.vue';
import CustomButton from '@/components/CustomButton.vue';
import { useUsersStore, useAlertStore, useSubscriptionStore, useCompanyStore } from '@/stores';
import QrcodeVue from 'qrcode.vue'
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup'
import { genericValidator, errorValidator } from "@/helpers/utils.js";
// import AddUsersPopup from '@/components/Authentication/AddUsersPopup.vue';
import EditPlanForm from '@/components/popups/EditPlanForm.vue';
import { loadStripe } from '@stripe/stripe-js';
import { nextTick } from 'vue';

export default {
    components: { 
        SingleOptionDropdown, 
        ImagePicker, 
        CustomButton, 
        QrcodeVue,
        Form,
        Field,
        ErrorMessage,
        // AddUsersPopup,
        EditPlanForm
    },
    props: {
        title: {
            type: String,
        },
        desc: {
            type: String,
        },
        image: {
            type: String,
        },
        currentStepComponentChild: {
            type: Number,
            default: 0,
        }
    },
    setup() {
        const usersStore = useUsersStore();
        const alertStore = useAlertStore();
        const subscriptionStore = useSubscriptionStore();
        const companyStore = useCompanyStore();

        return {
            usersStore,
            alertStore,
            subscriptionStore,
            companyStore,
        };
    },
    data() {
        return {
            languages: [{label: this.$t("english"), key: "EN"}, {label: this.$t("portuguese"), key: "PT"}],
            // languages: [{label: this.$t("english"), key: "EN"}, {label: this.$t("portuguese"), key: "PT"}, {label: this.$t("french"), key: "FR"}, {label: this.$t("italian"), key: "IT"}, {label: this.$t("dutch"), key: "NL"}],
            // currencies: [ {label: this.$t("usd"), key: 'USD' }, {label: this.$t("eur"), key: 'EUR' }, {label: this.$t("gbp"), key: 'GBP' }, {label: this.$t("yen"), key: 'YEN' }, {label: this.$t("inr"), key: 'INR' } ],
            currencies: [ {label: this.$t("USD"), key: 'usd' } ],
            channelManagers: [ {label: this.$t("channelManager1"), key: 'channelManager1' }, {label: this.$t("channelManager2"), key: 'channelManager2' }, {label: this.$t("channelManager3"), key: 'channelManager3' }],
            propertyManagmentSystems: [ {label: this.$t("propertyManagmentSystem1"), key: 'propertyManagmentSystem1' }, {label: this.$t("propertyManagmentSystem2"), key: 'propertyManagmentSystem2' }, {label: this.$t("propertyManagmentSystem3"), key: 'propertyManagmentSystem3' }], 
            userInfo: [],
            companyInfo: null,
            language: null,
            currency: '',
            imagePickerProfile: {},
            imagePickerCompany: {},
            qrCode: null,
            qrFromApi: null,
            size: 300,
            attemptSubmit: false,
            showAddUsersPopup: false,
            // company form
            company: {
                companyName: null,
                numberOfHotels: null,
                numberOfRooms: null,
                propertyManagmentSystem: null,
                channelManager: null,
            },
            isPopupCurrentPlanVisible: false,
            isMonthActive: true,
            stripe: null,
            subscriptionsFromApi: [],
        }
    },
    computed: {
        schema() {
            switch (this.currentStepComponentChild) {
                case 1:
                    return Yup.object({
                        language: Yup.string().required(this.$t('languageRequired')),
                        currency: Yup.string().required(this.$t('currencyRequired')),
                    });
                case 2:
                    return Yup.object({
                        secret: Yup.string().required(this.$t('secretRequired'))
                    });
                case 3:
                    return Yup.object({
                        companyName: Yup.string().required(this.$t('companyNameRequired')),
                        numberOfHotels: Yup.string().required(this.$t('numberOfHotelsRequired')),
                        numberOfRooms: Yup.string().required(this.$t('numberOfRoomsRequired')),
                        propertyManagmentSystem: Yup.string().required(this.$t('propertyManagmentSystemRequired')),
                        channelManager: Yup.string().required(this.$t('chanelManagerRequired')),
                    });
                default:
                    return {};
            }
        },
        apiErrors: function () {
            return this.alertStore.alert && this.alertStore.alert.output ? this.alertStore.alert.output : {};
        }
    },
    async mounted() {
        await this.fetchData();
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    },
    methods: {
        genericValidator,
        errorValidator,
        async fetchData() {
            try {
                if(this.currentStepComponentChild === 1) { // step 1
                    await this.usersStore.getUserData();
                    this.userInfo = this.usersStore.getListOutput;
                    if(this.userInfo.language || this.userInfo.currency) {
                        this.language = this.userInfo.language;
                        this.currency = this.userInfo.currency;
                    }
                } 
                else if( this.currentStepComponentChild === 2) { // step 2
                    if(this.qrFromApi !== null) return;
                    try{
                        const qrData = await this.usersStore.authenticatorQrCode();
                        this.qrFromApi = qrData;
                    } catch (error) {
                        this.alertStore.error(error);
                    }
                }
                else if(this.currentStepComponentChild === 3) { // step 3
                    // get user info to check if company exists
                    await this.usersStore.getUserData();
                    this.userInfo = this.usersStore.getListOutput;
                    
                    if(this.userInfo.active_company !== null || this.userInfo.companies.length) {
                        await this.companyStore.getList();
                    }
                    this.companyInfo = this.companyStore.companyOutput;
                    if(this.companyInfo.length !== 0) {
                        const company = {
                            companyName: this.companyInfo.name,
                            numberOfHotels: this.companyInfo.formHotelsNumber,
                            numberOfRooms: this.companyInfo.formRoomsNumber,
                            propertyManagmentSystem: this.companyInfo.formPMS,
                            channelManager: this.companyInfo.formCM,
                        }
                        this.company = company;
                    }
                }
                else{ // step 4
                    try{
                        // get user info to check if company exists (temporary code)
                        await this.usersStore.getUserData();
                        this.userInfo = this.usersStore.getListOutput;
                        // Last step is not yet implemented (Will only proceed if the user purchases a plan)
                        // When the user completes the last step, redirect to the control panel and update the user's companies in the local storage
                        // if the user has companies, they will not be able to access the complete profile page.
                        const loggedIn = JSON.parse(localStorage.getItem('user'));
                        if (loggedIn && loggedIn.user) {
                            loggedIn.user.companies = this.userInfo.companies;
                            localStorage.setItem('user', JSON.stringify(loggedIn));
                        }

                        // get subscription plans
                        await this.subscriptionStore.getList();
                        nextTick(() => {
                            this.subscriptionsFromApi = this.subscriptionStore.getListOutput;
                        });
                    } catch (error) {
                        this.alertStore.error(error);
                    }
                }
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        async onSubmitMe() {
            const name = this.userInfo.name;
            const { language, currency } = this;
            
            const profilePictureObject = typeof this.imagePickerProfile === 'object' && this.imagePickerProfile !== null
                    ? { ...this.imagePickerProfile }
                    : null;
            
            const payload = { 
                name, 
                language, 
                currency, 
                picture:  profilePictureObject ?? this.imagePickerProfile 
            };
            const userStorage = JSON.parse(localStorage.getItem('user')) || {};
            if(userStorage.user) userStorage.user.picture = profilePictureObject ?? this.imagePickerProfile;
            localStorage.setItem('user', JSON.stringify(userStorage));

            if (profilePictureObject) {
                    if(profilePictureObject.url) delete profilePictureObject.url;
                    payload.picture = JSON.stringify(profilePictureObject);
                }

            try {
                this.attemptSubmit = true;
                if (language && currency) {
                    await this.usersStore.changeMe(payload);  // Send the payload with picture as URL string
                    this.attemptSubmit = false;
                    this.$emit('stepClicked', 2);
                }
            } catch (error) {
                this.attemptSubmit = false;
                this.alertStore.error(error);
            }
        },
        hasError(errors, label, keys) {
            let hasLocalError = errors[label];
            let hasApiError = keys ? Object.keys(this.apiErrors).some(key => keys.includes(key)) : false;
            return hasLocalError || hasApiError;
        },
        resetComponentStates() {
            this.qrFromApi = null;
            this.attemptSubmit = false;
            this.alertStore.clear();
        },
        async onSubmit() {
            const { valid } = await this.$refs.myForm.validate();
            if (!valid) {
                return;
            }
            if(this.currentStepComponentChild === 2) { // 2FA form submit
                const { secret } = this.$refs.myForm.values;               
                const payload = {
                    secret: secret
                }
                try {
                    await this.usersStore.authenticatorVerify(payload);
                    this.$emit('stepClicked', 3);
                } catch (error) {
                    if(error.message === '2FA not started.') {
                        error.message = this.$t('youHaveAlreadyActivated2FA');
                        this.alertStore.error(error);
                        this.$emit('stepClicked', 3, error);
                    } else {
                        this.alertStore.error(error);
                    }
                }
            }
            else if(this.currentStepComponentChild === 3) { // company form submit
                const { companyName, numberOfHotels, numberOfRooms, propertyManagmentSystem, channelManager } = this.$refs.myForm.values;
                
                const companyPictureObject = typeof this.imagePickerCompany === 'object' && this.imagePickerCompany !== null
                    ? { ...this.imagePickerCompany }
                    : null;

                const payload = {
                    name: companyName,
                    formHotelsNumber: numberOfHotels,
                    formRoomsNumber: numberOfRooms,
                    formPMS: propertyManagmentSystem,
                    formCM: channelManager,
                    picture: companyPictureObject ?? this.imagePickerCompany
                }

                if (companyPictureObject) {
                    if(companyPictureObject.url) delete companyPictureObject.url;
                    payload.picture = JSON.stringify(companyPictureObject);
                }
                try{
    
                    if(this.companyInfo.length === 0) await this.companyStore.createCompany(payload);
                    else await this.companyStore.editCompany(payload);
                    this.$emit('stepClicked', 4);
                } catch (error) {
                    this.alertStore.error(error);
                }
            }
            // Temporary code to test the last step of the registration process (Missing payments logic)
            else if(this.currentStepComponentChild === 4) { // plan form submit
                this.$router.push({ name: 'CompleteProfile' });
            }
        },
        toggleSwitch() {
            this.isMonthActive = !this.isMonthActive;
        },
        async sendToPayment(planId, isMonthActive) {
            try {
                const frequency = isMonthActive 

                // Execute the purchasePlan action in your subscription store with the selected priceId
                const response = await this.subscriptionStore.purchasePlan(planId, frequency );

                // Check the response from the action
                if (response.OK && response.RedirectUrl) {
                    // Redirect to the Stripe Checkout URL
                    window.location.href = response.RedirectUrl;
                } else {
                    throw new Error('Unable to process payment. Please try again later.');
                }
            } catch (error) {
                console.error('Error in sendToPayment:', error.message);
                // Display error message to the user
                alert(`Error: ${error.message}`);
                // Alternatively, you can use a better UI element for user notification, e.g., a toast notification
            }
        },
    // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    },
    watch: {
        currentStepComponentChild(newValue) {
            if(newValue) {
                this.resetComponentStates();
                this.fetchData();
            }
        }
    },
};
</script>

<style scoped>
.title {
    color: black;
    font-size: 20px;
    font-weight: bold;
}
.desc {
    color: black;
    font-size: 15px;
}
:deep(.file-picker),
.icon{
    width: 250px;
    max-width: 250px;
}
#step_form{
    width: 70%;
}
.form-control::placeholder {
    color: black;
    opacity: 1; /* Firefox */
}
.form-control::-ms-input-placeholder { /* Edge 12 -18 */
    color: black;
}
.col-individual-style{
    padding: 30px;
    border-radius: 5px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
#price-span{
    color: var(--Theme-Dark, #212529);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
#number-price{
    color: var(--Theme-Dark, #212529);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}
#subscription-type{
    color: var(--Theme-Dark, #212529);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}
#payment-period{
    color: var(--gray-600, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.instructions p {
    font-size: 15px;
    color: var(--theme-secondary);
    word-break: break-word;
}
.switch-container {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
  
.switch-active,
.switch-inactive {
    width: 70px;
    height: 30px;
    transition: background-color 0.3s ease;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--Theme-Secondary, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 0 !important;
}

.switch-active {
    background-color: #d3d3d3;
}

.switch-inactive {
    background-color: rgb(245, 245, 245);
}
@media (max-width: 991.98px){
    :deep(.file-picker),
    .icon{
        width: 150px;
        max-width: 150px;
    }
    #step_form{
        margin-top: 1rem;
        width: 100%;
    }
}
@media (max-width: 575.98px){
    #verify_button,
    #save_button{
        width: 100%!important;
    }
}
</style>