<template>
  <section id="dashboard">
    <div class="container-fluid py-3 py-md-0">
      <!-- <NoticeComponent
        v-if="hotelStore && !hotelStore.getHotelListOutput.length" 
        :image="require('@/assets/noticeComponent/noDataYet.svg')" 
        :Title="$t('ThereIsNoDataYet')" 
        :description="$t('ThereIsNoDataForThisCompanyYetPleaseEnterYourInfo')" 
        :buttonInfo="$t('startNow')"
        :actionClicked="actionClicked"
       /> -->
      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <div class="d-flex align-items-center gap-2">
            <img class="icon-inspirationalPhrase" src="@/assets/DashboardPage/inspirationalPhrase.svg" alt="">
            <span class="inspirationalPhrase">{{ inspirationalPhrase }}</span>
          </div>
          <span class="last-updated">Last updated on Nov 8, 2024 at 16:15</span>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="d-flex justify-content-start justify-content-lg-end align-items-center h-100">
            <div class="dropdown">
              <DropdownButton
                :label="getLabel('hotel')" 
                :icon="require('@/assets/Pickup/house.svg')"
                @clickBtn="toggleDropdown('hotel')" 
              />
              <DropdownMenu 
                v-model="selectedItems.hotel" 
                :configuration="{
                  items: hotelOptions,
                  isDropdownOpen: dropdownStates.hotel,
                  hasHeader: false,
                  hasBody: true,
                  openDropdownPosition: 'right',
                  selectionMode: 'single'
                }" 
                @close-dropdown="dropdownStates.hotel = false" 
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="d-flex align-items-center">
            <DateButton v-model="date" typeForm="1" range />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-xxl mb-3" v-for="(statisticInfo, index) in hotelSummary" :key="index">
          <WidgetWrapper 
            wrapperClass="statistic-wrapper"
            :widgetId="statisticInfo.type"
            :error="widgetStates.hotelSummary.error"
            :loading="widgetStates.hotelSummary.loading"
            @retry="fetchStartupData"
          >
            <StatisticsWidget :statisticsInfo="statisticInfo" />
          </WidgetWrapper>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6 mb-3">
          <WidgetWrapper
            wrapperClass="dash-chart-wrapper p-3 p-lg-4"
            widgetId="bookingWindow"
            :error="widgetStates.bookingWindow.error"
            :loading="widgetStates.bookingWindow.loading"
            @retry="fetchStartupData"
          >
            <LineChart 
              :chartTitle="$t('bookingWindow')" 
              :chartData="getChartData('bookingWindow')"
              :chartOptions="getChartOptions('bookingWindow')" 
              :growth="bookingWindowChart?.variation" 
              :customStyle="{
                width: getChartOptions('bookingWindow')?.width,
                height: getChartOptions('bookingWindow')?.height
              }"
            />
            </WidgetWrapper>
        </div>
        <div class="col-12 col-xl-6 mb-3">
          <WidgetWrapper
            wrapperClass="dash-chart-wrapper p-3 p-lg-4"
            widget-id="lengthOfStay"
            :error="widgetStates.lengthOfStay.error"
            :loading="widgetStates.lengthOfStay.loading"
            @retry="fetchStartupData"
          >
            <BarChart 
              :chartTitle="$t('lengthOfStay')" 
              :chartData="getChartData('lengthOfStay')" 
              :chartOptions="getChartOptions('lengthOfStay')" 
              :growth="lengthOfStayChart?.variation" 
              :customStyle="{
                width: getChartOptions('lengthOfStay')?.width,
              }" 
            />
          </WidgetWrapper>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <div class="d-flex flex-wrap gap-3">
            <div class="d-flex gap-2 align-items-center">
              <img class="icon-inspirationalPhrase" src="@/assets/DashboardPage/chooseAMetric.svg" alt="">
              <span class="chooseAMetric">{{ $t("chooseAMetric") }}</span>
            </div>
            <div class="dropdown">
              <DropdownButton 
                :label="getLabel('metric')" 
                @clickBtn="toggleDropdown('metric')"
              />
              <DropdownMenu v-model="selectedItems.metric" 
                :configuration="{
                  items: metricOptions,
                  isDropdownOpen: dropdownStates.metric,
                  hasHeader: false,
                  hasBody: true,
                  openDropdownPosition: 'right',
                  selectionMode: 'single'
                }" 
                @close-dropdown="dropdownStates.metric = false" 
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6 mb-3">
          <WidgetWrapper
            wrapperClass="dash-chart-wrapper p-3 p-lg-4"
            widgetId="totalPerformance"
            :error="widgetStates.totalPerformance.error"
            :loading="widgetStates.totalPerformance.loading"
            @retry="fetchDataByDateHotelAndMetric"
          >
            <BarChart 
              :chartTitle="$t('totalPerformance')" 
              :chartData="getChartData('totalPerformance')" 
              :chartOptions="getChartOptions('totalPerformance')" 
              :growth="totalPerformanceChart?.variation" 
              :customStyle="{
                height: getChartOptions('totalPerformance')?.height
              }" 
            />
          </WidgetWrapper>
        </div>
        <div class="col-12 col-xl-6">
          <WidgetWrapper
            wrapperClass="dash-chart-wrapper p-3 p-lg-4"
            widgetId="totalEvolution"
            :error="widgetStates.totalEvolution.error"
            :loading="widgetStates.totalEvolution.loading"
            @retry="fetchDataByDateHotelAndMetric"
          >
            <LineChart 
              :chartTitle="$t('totalEvolution')" 
              :chartData="getChartData('totalEvolution')" 
              :chartOptions="getChartOptions('totalEvolution')" 
              :growth="totalEvolutionChart?.variation" 
              :customStyle="{
                height: getChartOptions('totalEvolution')?.height
              }" 
            />
          </WidgetWrapper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BarChart from '@/components/Charts/BarChart.vue';
import LineChart from '@/components/Charts/LineChart.vue';
import StatisticsWidget from '@/components/StatisticsWidget.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import DateButton from '@/components/DateButton.vue';
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useMetricsStore, useHotelStore } from "@/stores";
import ChartSetup from '@/helpers/chart-setup.js';
// import NoticeComponent from '@/components/NoticeComponent.vue';
import WidgetWrapper from '@/components/WidgetWrapper.vue';

export default {
  components: {
    BarChart,
    LineChart,
    StatisticsWidget,
    DropdownButton,
    DateButton,
    DropdownMenu,
    // NoticeComponent,
    WidgetWrapper
  },
  name: 'DashboardPage',
  setup() {
    const { isMobile, isTablet, isDesktop } = useResponsive();
    const metricsStore = useMetricsStore();
    const hotelStore = useHotelStore();

    return {
      isMobile,
      isTablet,
      isDesktop,
      metricsStore,
      hotelStore
    }
  },
  data() {
    return {
      // Inspirational phrase
      inspirationalPhrase: 'Stay positive, work hard, make it happen.',
      // selected items for dropdowns
      selectedItems: {
        hotel: null,
        metric: null,
      },
      // dropdown states
      dropdownStates: {
        hotel: false,
        metric: false,
      },
      // date
      date: null,
      // widget states
      widgetStates: {
        totalPerformance: {
          loading: true,
          error: null
        },
        totalEvolution: {
          loading: true,
          error: null
        },
        bookingWindow: {
          loading: true,
          error: null
        },
        lengthOfStay: {
          loading: true,
          error: null
        },
        hotelSummary: {
          loading: true,
          error: null
        }
      },
      hotelSummary: Array.from({ length: 5 }, (_, index) => ({
        id: index + 1
      })),
      // charts
      totalPerformanceChart: null,
      totalEvolutionChart: null,
      bookingWindowChart: null,
      lengthOfStayChart: null
    };
  },
  async mounted() {
    this.setDefaultFilterDate(); // Set default date range
    await this.hotelStore.getList(); // Fetch hotels from API
    this.setDefaultDropdownSelections(); // Set default selections
    await this.fetchStartupData(); // Fetch data from API
  },
  computed: {
    hotelOptions() {
      return {
        header: [],
        body: this.hotelStore?.getHotelListOutput?.filter(hotel => hotel.mewsId !== null).map(hotel => ({
          id: hotel.mewsId,
          label: hotel.name,
          image: hotel.picture ?? require('@/assets/no-image.jpg')
        }))
      }
    },
    metricOptions() {
      return {
        header: [],
        body: [
          {
            id: 0,
            label: this.$t("Room Nights"),
            keyName: 'RoomNights'
          },
          {
            id: 1,
            label: this.$t("Occupancy"),
            keyName: 'Occupancy'
          },
          {
            id: 2,
            label: this.$t("Revenue"),
            keyName: 'Revenue'
          },
          {
            id: 3,
            label: this.$t("ADR"),
            keyName: 'Adr'
          },
          {
            id: 4,
            label: this.$t("RevPAR"),
            keyName: 'RevPar'
          },
        ]
      }
    }
  },
  methods: {
    // Method for fetching data with Date, Hotel and metric (only for fetchTotalPerformance and fetchTotalEvolution) filters
    async fetchStartupData() {
      const queryParams = {
        hotelId: this.selectedItems?.hotel?.id ?? null,
        startsAt: this.date[0],
        endsAt: this.date[1]
      };

      const queryParamsTPAndEvolution = {
        ...queryParams,
        metric: this.selectedItems.metric.keyName
      };

      try {        
        Object.keys(this.widgetStates).forEach(key => {
          this.widgetStates[key].loading = true;
          this.widgetStates[key].error = null;
        });

        // Fetch all data in parallel
        await Promise.all([
          this.metricsStore.fetchHotelSummary(queryParams)
            .then(() => {
              // Update the hotel summary with new data
              this.hotelSummary = this.metricsStore.hotelSummaryOutput ?? [];
            })
            .catch(error => this.widgetStates.hotelSummary.error = error)
            .finally(() => {
              this.widgetStates.hotelSummary.loading = false;
            }),

          this.metricsStore.fetchBookingWindow(queryParams)
            .then(() => {
              this.setupChart(
                this.metricsStore.bookingWindowOutput,
                'bookingWindow',
                this.$t('bookingWindow'),
                ChartSetup.getBookingWindowChartData,
                { datasetsOptions: [{ pointBorderColor: "#A81800", pointBackgroundColor: "#A81800", borderColor: "#A81800" }, { borderDash: [5, 5], borderColor: "#E6C9C4" }] }
              );
            })
            .catch(error => {
              // console.log("booking window error", error);
              this.widgetStates.bookingWindow.error = error;
            })
            .finally(() => {
              this.widgetStates.bookingWindow.loading = false;
            }),

          this.metricsStore.fetchLengthOfStay(queryParams)
            .then(() => {
              this.setupChart(
                this.metricsStore.lengthOfStayOutput,
                'lengthOfStay',
                this.$t('lengthOfStay'),
                ChartSetup.getLengthOfStayChartData,
                { datasetsOptions: [{ backgroundColor: '#A81800' }, { backgroundColor: '#C77062' }] }
              );
            })
            .catch(error => {
              this.widgetStates.lengthOfStay.error = error;
            })
            .finally(() => {
              this.widgetStates.lengthOfStay.loading = false;
            }),

          this.metricsStore.fetchTotalPerformance(queryParamsTPAndEvolution)
            .then(() => {
              const outputData = this.metricsStore.totalPerformanceOutput ?? {};

              // bar chart
              const barObject = {
                labels: [],
                datasets: [
                  {
                    label: this.$t("Current Period"),
                    data: []
                  },
                  {
                    label: this.$t("Same Period Last Year"),
                    data: []
                  }
                ],
                variation: outputData.variation ?? 0
              };

              if (outputData.datasets && outputData.datasets.length > 0) {
                // bar chart
                outputData.labels.forEach((label, index) => {
                  const currentPeriodData = outputData.datasets[0].data[index];
                  const sameTimeLastYearData = outputData.datasets[1].data[index];
                        
                  if (currentPeriodData !== 0 || sameTimeLastYearData !== 0) {
                      barObject.labels.push(label);
                      barObject.datasets[0].data.push(currentPeriodData);
                      barObject.datasets[1].data.push(sameTimeLastYearData);
                  }
                });
              }

              this.setupChart(
                barObject,
                'totalPerformance',
                this.$t('totalPerformance'),
                ChartSetup.getTotalPerformanceChartData,
                { datasetsOptions: [{ backgroundColor: '#A81800' }, { backgroundColor: '#C77062' }] }
              );
            })
            .catch(error => {
              // console.log("total performance error", error);
              this.widgetStates.totalPerformance.error = error;
            })
            .finally(() => {
              this.widgetStates.totalPerformance.loading = false;
            }),

          this.metricsStore.fetchTotalEvolution(queryParamsTPAndEvolution)
            .then(() => {
              this.setupChart(
                this.metricsStore.totalEvolutionOutput,
                'totalEvolution',
                this.$t('totalEvolution'),
                ChartSetup.getTotalEvolutionChartData,
                { datasetsOptions: [{ pointBorderColor: "#A81800", pointBackgroundColor: "#A81800", borderColor: "#A81800" }, { pointBorderColor: '#C77062', pointBackgroundColor: '#C77062', borderDash: [5, 5], borderColor: "#C77062" }] }
              );
            })
            .catch(error => {
              // console.log("total evolution error", error);
              this.widgetStates.totalEvolution.error = error;
            })
            .finally(() => {
              this.widgetStates.totalEvolution.loading = false;
            })
        ]);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    },
    // Method for fetching data with Date, Hotel, and Metric filters (only for fetchTotalPerformance and fetchTotalEvolution)
    async fetchDataByDateHotelAndMetric() {
      const queryParamsTPAndEvolution = {
        hotelId: this.selectedItems?.hotel?.id ?? null,
        startsAt: this.date[0],
        endsAt: this.date[1],
        metric: this.selectedItems.metric.keyName
      };

      try {
        // Reset errors
        this.widgetStates.totalPerformance.error = null;
        this.widgetStates.totalEvolution.error = null;
        // Set loading states to true
        this.widgetStates.totalPerformance.loading = true;
        this.widgetStates.totalEvolution.loading = true;
        
        await Promise.all([
          this.metricsStore.fetchTotalPerformance(queryParamsTPAndEvolution).then(() => {
            const outputData = this.metricsStore.totalPerformanceOutput ?? {};
            // bar chart
            const barObject = {
              labels: [],
              datasets: [
                {
                  label: this.$t("Current Period"),
                  data: []
                },
                {
                  label: this.$t("Same Period Last Year"),
                  data: []
                }
              ],
              variation: outputData.variation ?? 0
            };
            if (outputData.datasets && outputData.datasets.length > 0) {
              // bar chart
              outputData.labels.forEach((label, index) => {
                const currentPeriodData = outputData.datasets[0].data[index];
                const sameTimeLastYearData = outputData.datasets[1].data[index];
                      
                if (currentPeriodData !== 0 || sameTimeLastYearData !== 0) {
                    barObject.labels.push(label);
                    barObject.datasets[0].data.push(currentPeriodData);
                    barObject.datasets[1].data.push(sameTimeLastYearData);
                }
              });
            }
            // Add updated Total Performance chart data
            this.setupChart(
              barObject,
              'totalPerformance', // keyName for the chart
              this.$t('totalPerformance'),
              ChartSetup.getTotalPerformanceChartData,
              { datasetsOptions: [{ backgroundColor: '#A81800' }, { backgroundColor: '#C77062' }] }
            );
          }).catch(error => {
            // console.log("total performance error", error);
            this.widgetStates.totalPerformance.error = error;
          })
            .finally(() => {
              this.widgetStates.totalPerformance.loading = false;
            }),

          this.metricsStore.fetchTotalEvolution(queryParamsTPAndEvolution).then(() => {
            // Add updated Total Evolution chart data
            this.setupChart(
              this.metricsStore.totalEvolutionOutput,
              'totalEvolution', // keyName for the chart
              this.$t('totalEvolution'),
              ChartSetup.getTotalEvolutionChartData,
              { datasetsOptions: [{ pointBorderColor: "#A81800", pointBackgroundColor: "#A81800", borderColor: "#A81800" }, { borderDash: [5, 5], borderColor: "#E6C9C4" }] }
            );
          }).catch(error => {
            // console.log("total evolution error", error);
            this.widgetStates.totalEvolution.error = error;
          })
            .finally(() => {
              this.widgetStates.totalEvolution.loading = false;
            })
        ]);
      } catch (error) {
        console.error('Error fetching data:', error); // Catch any other errors
      }
    },
    setupChart(outputData, keyName, chartTitle, setupFunction, customOptions = {}) {
      const chartData = {
        ...outputData,
        keyName: keyName,
        chartTitle: chartTitle
      };

      if (chartData && outputData.labels && outputData.datasets) {
        let { labels = [], datasets = [] } = outputData;

        // Conditionally add metric to customOptions for specific charts
        if (keyName === 'totalPerformance' || keyName === 'totalEvolution') {
          customOptions.metric = this.selectedItems.metric.keyName;
        }

        this[`${keyName}Chart`] = setupFunction(labels, datasets, customOptions);

        // Adjust chart size dynamically
        const totalLabels = labels.length;
        const minimumLabelThreshold = 10;

        if (totalLabels > minimumLabelThreshold) {
          const threshold = Math.floor(totalLabels * 0.5);
          if (totalLabels > threshold) {
            if (this[`${keyName}Chart`].chartOptions.indexAxis === 'y') {
              const baseHeight = totalLabels * 33;
              const dynamicHeight = baseHeight + ((totalLabels - threshold) * 50);
              this[`${keyName}Chart`].chartOptions.height = `${dynamicHeight}px`;
            } else {
              const baseWidth = totalLabels * 50;
              const dynamicWidth = baseWidth + ((totalLabels - threshold) * 50);
              this[`${keyName}Chart`].chartOptions.width = `${dynamicWidth}px`;
            }
          }
        }
          // Set the variation value
          this[`${keyName}Chart`].variation = outputData.variation;
      } else {
        this[`${keyName}Chart`] = setupFunction([], [], customOptions);
      }

      return chartData;
    },
    toggleDropdown(dropdownName) {
      this.dropdownStates[dropdownName] = !this.dropdownStates[dropdownName];
    },
    // Set default selection for dropdowns
    setDefaultDropdownSelections() {
      // Set default selection for 'hotel' dropdown
      if (this.hotelOptions?.body.length > 0) {
        this.selectedItems.hotel = this.hotelOptions.body[0];
      }

      // Set default selection for 'metric' dropdown
      if (this.metricOptions.body.length > 0) {
        this.selectedItems.metric = this.metricOptions.body[0];
      }
    },
    // Set default date range
    setDefaultFilterDate() {
      const currentDate = new Date();
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + 7);

      const formatDate = (date) => {
        return date.toISOString().substring(0, 10);
      };

      this.date = [formatDate(currentDate), formatDate(futureDate)]; // Set the default date range to the current date and 7 days ahead
    },
    getLabel(key) {
      const item = this.selectedItems[key];
      return item && item.label ? item.label : this.$t(key);
    },
    getChartData(keyName) {
      switch (keyName) {
        case 'bookingWindow':
          return this.bookingWindowChart.chartData;
        case 'lengthOfStay':
          return this.lengthOfStayChart.chartData;
        case 'totalPerformance':
          return this.totalPerformanceChart.chartData;
        case 'totalEvolution':
          return this.totalEvolutionChart.chartData;
        default:
          return null;
      }
    },
    getChartOptions(keyName) {
      switch (keyName) {
        case 'bookingWindow':
          return this.bookingWindowChart.chartOptions;
        case 'lengthOfStay':
          return this.lengthOfStayChart.chartOptions;
        case 'totalPerformance':
          return this.totalPerformanceChart.chartOptions;
        case 'totalEvolution':
          return this.totalEvolutionChart.chartOptions;
        default:
          return null;
      }
    },
    actionClicked() {
      this.$router.push({ name: 'CompanyList' });
    }
  },
  watch: {
    async date(newVal, oldVal) {
      if (!oldVal) return;
      if (newVal) {
        // Fetch all data when the date changes
        await this.fetchStartupData();
      }
    },
    async 'selectedItems.hotel'(newVal, oldVal) {
      if (!oldVal) return;
      if (newVal) {
        // Fetch all data when the hotel changes
        await this.fetchStartupData();
      }
    },
    async 'selectedItems.metric'(newVal, oldVal) {
      if (!oldVal) return;
      if (newVal) {
        // Fetch only data dependent on the metric when it changes
        await this.fetchDataByDateHotelAndMetric();
      }
    }
  }
}
</script>

<style scoped>
#dashboard {
  min-height: calc(100vh - 196px);;
}
.inspirationalPhrase {
  font-size: 24px;
  font-weight: 700;
  color: #212529;
}

.chooseAMetric {
  font-size: 15px;
  font-weight: 700;
  color: #212529;
}

.icon-inspirationalPhrase {
  width: 20px;
  height: 20px;
}

#colstatistics {
  min-width: 300px;
}

.metric-dropdown-menu {
  min-width: 450px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 4px 0px #00000013;
  left: 110px;
  top: 480px;
}

.last-updated {
  font-size: 12px;
  color: #6C757D;
}

.chart-container {
  min-width: unset;
}

a {
  color: var(--theme-primary);
  font-size: .9rem;
}

.dash-chart-wrapper {
  border-radius: 15px;
  height: 400px;
  max-height: 400px;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px #00000014;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dash-chart-wrapper::-webkit-scrollbar {
  display: none;
}
.statistic-wrapper{
  height: 160px;
  min-height: 160px;
  box-shadow: 0px 4px 30px 0px #00000014;
}
@media (max-width: 1460px) {
  .statisticWidget-row {
    flex-direction: column;
  }
}
@media (max-width: 991.98px){
  .dropdown {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .inspirationalPhrase {
    font-size: 16px;
  }
}
</style>
