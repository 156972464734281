<template>
    <div class="container-fluid">
        <LayoutWrapper>
            <div class="d-none d-md-flex row py-3 px-4 border-bottom">
                <div class="col d-flex gap-2">
                    <img class="img-fluid" :src="pageLayout?.icon" alt="" width="18">
                    <span class="page-title">{{ pageLayout?.title }}</span>
                </div>
                <div v-if="hasPageActions" class="col d-flex gap-3 justify-content-end">
                    <ThreeVerticalDots :dropdownItems="threeDotsSettings" @itemClick="pageActionClick($event)" />
                </div>
                <div v-if="hasButtonActions" class="col d-flex gap-3 justify-content-end">
                    <CustomButton
                        :label="buttonActionLabel" 
                        buttonType="custom"
                        :rightIcon="buttonActionIcon"
                        @clickBtn="pageButtonActionClick()"
                    >
                    </CustomButton>
                </div>
            </div>
            <slot name="dynamic-content"></slot>
        </LayoutWrapper>
        <div v-if="pageLayout.firstAction && pageLayout.secondAction" class="btns-div gap-2 my-4">
            <CustomButton 
                :rightIcon="pageLayout?.firstAction?.icon" 
                :label="pageLayout?.firstAction?.label" 
                @clickBtn="firstActionClick"
                buttonType="theme-light" 
                :buttonStyle= "{
                    borderRadius: '8px!important',
                    gap: '8px',
                    color: 'var(--theme-secondary, #6C757D)',
                    padding: '6px 12px',
                    width: '230px'
                }" 
            />
            <CustomButton 
                :rightIcon="pageLayout?.secondAction?.icon" 
                :label="pageLayout?.secondAction?.label" 
                @clickBtn="secondActionClick"
                :buttonStyle="{
                    borderRadius: '8px!important',
                    gap: '4px',
                    color: '#FFFFFF',
                    padding: '6px 12px',
                    width: '230px'
                }" 
                :loading="pageLayout?.secondAction?.loading"
            />
        </div>
    </div>
</template>

<script>
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import CustomButton from '@/components/CustomButton.vue';
import ThreeVerticalDots from '@/components/ThreeVerticalDots.vue';

export default {
    props: {
        hasPageActions: {
            type: Boolean,
            default: false
        },
        hasButtonActions: {
            type: Boolean,
            default: false
        },
        buttonActionLabel: String,
        buttonActionIcon: String,
        threeDotsSettings: Array,
        pageLayout: Object,
    },
    components: {
        LayoutWrapper,
        CustomButton,
        ThreeVerticalDots
    },
    methods: {
        pageActionClick(index) {
            this.$emit('pageActionClick', index);
        },
        pageButtonActionClick() {
            this.$emit('pageButtonActionClick');
        },
        firstActionClick() {
            this.$emit('firstActionClick');
        },
        secondActionClick() {
            this.$emit('secondActionClick');
        }
    },
}
</script>

<style scoped>
.editDots {
    width: 20px;
    cursor: pointer;
}

.page-title {
    color: var(--theme-secondary, #6C757D);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    word-break: break-all;
}

.btns-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

@media only screen and (max-width: 767px) {
    .btns-div {
        align-items: normal;
        flex-direction: column;
    }
    .btns-div button {
        width: 100%!important;
    }
    .page-title{
        font-size: 15px;
    }
}
</style>