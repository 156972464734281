<template>
  <section>
    <div class="container-fluid">
        <div class="col-12 d-flex align-items-center gap-2 mx-2 my-4 cursor-pointer" @click="sendToBillingPage">
            <img src="@/assets/HotelGroup/backBtn.svg" alt="">
            <span class="page-title">Payment Method</span>
        </div>
        <div class="container-list">
            <div class="row">
                <div class="col-6 d-flex gap-2 p-5" v-if="typeForm == 'create'">
                    <img src="@/assets/Billing/create-form-icon.svg" alt="">
                    <span class="sub-title">Add Card Details</span>
                </div>
                <div class="col-6 d-flex gap-2 p-5" v-if="typeForm == 'edit'">
                    <img src="@/assets/Billing/edit-form-icon.svg" alt="">
                    <span class="sub-title">Edit Card Details</span>
                </div>
                <!-- <div class="col-6 d-flex justify-content-end p-5">
                    <img class="editDots" src="@/assets/HotelGroup/editDots.svg" alt="">
                </div> -->
            </div>
            <Form @submit="onSubmit" :validation-schema="schemaBillingForm"
            v-slot="{ errors, isSubmitting }">
                  <div class="row px-5">
                      <div class="col-12 col-md-4 form-group my-3">
                          <label class="mb-2" for="cardNumber">Card Number</label>
                          <Field name="cardNumber" type="text" class="form-control input-form"
                              :class="{ 'is-invalid': hasError(errors, 'cardNumber') }" 
                          />
                      </div>
                      <div class="col-12 col-md-4 form-group my-3">
                          <label class="mb-2" for="month">Card Exp Date</label>
                          <div class="d-flex gap-3">
                              <Field name="month" type="number" class="form-control input-form"
                                  :class="{ 'is-invalid': hasError(errors, 'month') }" 
                              />
                              <Field name="year" type="number" class="form-control input-form"
                                  :class="{ 'is-invalid': hasError(errors, 'year') }" 
                              />
                          </div>
                      </div>
                  </div>
                  <div class="row px-5">
                      <div class="col-12 col-md-4 form-group my-3">
                          <label class="mb-2" for="cardOwner">Card Owner</label>
                          <Field name="cardOwner" type="text" class="form-control input-form"
                              :class="{ 'is-invalid': hasError(errors, 'cardOwner') }" 
                          />
                      </div>
                      <div class="col-12 col-md-4 form-group my-3">
                          <label class="mb-2" for="cvvNumber">Cvv Number</label>
                          <Field name="cvvNumber" type="text" class="form-control input-form"
                              :class="{ 'is-invalid': hasError(errors, 'cvvNumber') }" 
                          />
                      </div>
                      <div class="d-flex gap-3 mt-5 flex-wrap">
                          <div class="d-flex gap-2 align-items-center">
                              <input type="checkbox" checked/>
                              <span>Same as billing Address</span>
                          </div>
                          <div class="d-flex gap-2 align-items-center">
                              <input type="checkbox"/>
                              <span>Enter a different Address</span>
                          </div>
                      </div>
                      <div class="d-flex gap-2 mt-3 align-items-center">
                          <input type="checkbox"/>
                          <span>Agree to the <a href="/" id="terms-conditions-span">Automatic Payment Terms & Conditions</a></span>
                      </div>
                  </div>
                  <ValidationAlerts 
                      v-if="hasError(errors,'cardNumber') || hasError(errors,'month') || hasError(errors,'year') || hasError(errors,'cardOwner') || hasError(errors,'cvvNumber')"
                      class="my-2" 
                      :alertType="'error'" 
                      :title="getErrorMessages(errors, ['cardNumber', 'month', 'year', 'cardOwner', 'cvvNumber'])"
                  />
                  <div class="d-flex justify-content-end px-5 py-5">
                      <div class="d-flex gap-3 btn-div">
                          <button type="submit" class="w-100 mt-5 btn-delete" v-if="typeForm == 'edit'">
                              <span>Delete Card</span><img src="@/assets/HotelGroup/deleteBtn.svg" alt="">
                          </button>
                          <button type="submit" class="w-100 mt-5" id="save-btn" v-bind:class="{ disabled: loading }" :disabled="isSubmitting" v-if="typeForm == 'edit'">
                              <span v-show="!loading">save</span><img src="@/assets/HotelGroup/saveBtn.svg" alt="">
                              <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                          <button type="submit" class="w-100 mt-5 btn-delete" v-if="typeForm == 'create'">
                              <span>Cancel</span><img src="@/assets/HotelGroup/deleteBtn.svg" alt="">
                          </button>
                          <button type="submit" class="w-100 mt-5" id="save-btn" v-bind:class="{ disabled: loading }" :disabled="isSubmitting" v-if="typeForm == 'create'">
                              <span v-show="!loading">create</span><img src="@/assets/HotelGroup/saveBtn.svg" alt="">
                              <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                      </div>
                  </div>
              </Form>
        </div>
    </div>
  </section>
</template>

<script>
import ValidationAlerts from '@/components/ValidationAlerts.vue'
import { Form, Field } from 'vee-validate';
import { useUsersStore, useAlertStore } from '@/stores';
import * as Yup from 'yup'
export default {
    components: {
        ValidationAlerts,
        Form,
        Field
    },
    setup() {
        const usersStore = useUsersStore();
        const alertStore = useAlertStore();

        return {
            usersStore,
            alertStore
        };
    },
    data() {
        return {
            loading: false,
            typeForm: this.$route.query.type
        }
    },
    computed: {
        schemaBillingForm() {
            return Yup.object({
                cardNumber: Yup.string().required(this.$t('cardNumberRequired')),
                month: Yup.string().required(this.$t('monthRequired')),
                year: Yup.string().required(this.$t('yearRequired')),
                cardOwner: Yup.string().required(this.$t('cardOwnerRequired')),
                cvvNumber: Yup.string().required(this.$t('cvvNumberRequired')),
            })
        },
        apiErrors: function () {
            return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : {};
        },
    },
    methods: {
        onSubmit: function (values) {
            this.billingFormClicked(values);
        },
        async billingFormClicked(values) {
            console.log(values)
            // this.alertStore.clear();
            // this.loading = true;
            // const { email, password } = values;
            // try {
            //     await this.usersStore.login(email, password);
            //     this.loading = false;
            //     this.$router.push({name : 'DashboardPage'});
            // }
            // catch (error) {
            //     this.alertStore.error(error);
            //     alert(error.message);
            //     this.loading = false;
            // }
        },
        hasError(errors, label) {
            return errors[label] || this.hasApiError(label);
        },
        hasApiError(keys) {
            return Object.keys(this.apiErrors).some(key => keys.includes(key));
        },
        getErrorMessages(errors, labels) {
            const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
            return errorMessages.join('<br>');
        },
        sendToBillingPage() {
          this.$router.push({ name: 'BillingPage' });
        }
    },
}
</script>

<style scoped>
  .container-list{
      border-radius: 15px 15px 0px 0px;
      background: var(--gray-white, #FFF);
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .page-title{
      color: var(--theme-dark, #212529);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
  }
  .sub-title{
      color: var(--theme-secondary, #6C757D);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
  }
  .btn-div{
      width: fit-content;
  }
  .editDots{
      width: 20px;
  }
  #save-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 8px;
    background: var(--theme-primary, #A81800);
    color: var(--gray-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    text-decoration: none;
    justify-content: center;
  }
  .btn-delete{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-secondary, #6C757D);
    border-radius: 8px;
    border: 1px solid var(--theme-border, #DEE2E6);
    background: var(--theme-light, #F8F9FA);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    text-decoration: none;
    min-width: fit-content;
  }
  #terms-conditions-span{
      color: var(--theme-primary);
      text-decoration: none !important;
      word-break: break-word;
  }
  input[type="radio"],
  input[type="checkbox"]{
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;
      width: 15px;
      height: 15px;
      padding: 1px;
      /* background-color only for content */
      background-clip: content-box;
      border: 1px solid gray;
      background-color: unset;
      border-radius: 50%;
  }
  /* appearance for checked radiobutton */
  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
      background-color: white;
      border: 4px solid #962422;
  }
  @media only screen and (max-width: 400px) {
      .btn-div{
          flex-wrap: wrap;
      }
      .px-5{
          padding-right: 1rem !important;
          padding-left: 1rem !important;
      }
  }
</style>