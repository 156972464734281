<template>
  <section id="side-menu" class="d-none d-md-block" :class="{'close': !sideMenuStore.isOpen}" :style="menuContainerStyle">
    <div class="side-menu-content">
      <div id="company-section" class="d-flex justify-content-between align-items-center p-2">
        <div class="navbar-nav dropdown">
          <div class="nav-link d-flex align-items-center gap-2 p-2">
            <img class="company-img" :src="companyImage" alt="image">
            <span class="color-secondary fw-bold">{{ truncatedLabel(companyName) }}</span>
          </div>
          <!-- <a class="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center gap-1 cursor-pointer">
              <img class="company-img" :src="userStorage?.user.companies[0].picture ?? require('@/assets/no-image.jpg')" alt="image">
              <span v-if="sideMenuStore.isOpen" class="color-secondary">{{userStorage?.user.companies[0].name}}</span>
              <img src="@/assets/sideBar/arrow.svg" alt="icon">
            </div>
          </a>
          <ul class="dropdown-menu" aria-labelledby="companyDropdown">
            <div id="header" class="d-flex align-items-center gap-2 border-bottom p-2" @click.stop>
              <img class="company-img" :src="userStorage?.user.companies[0].picture ?? require('@/assets/no-image.jpg')" alt="image">
              <span class="color-secondary">{{userStorage?.user.companies[0].name}}</span>
            </div>
            <div class="p-2">
              <li class="dropdown-item p-2 cursor-pointer" v-for="(company,index) in userStorage?.user.companies" :key="index">
                <img class="company-img" :src="company.picture ?? require('@/assets/no-image.jpg')" alt="image">
                <span>{{ company.name }}</span>
              </li>
            </div>
            <div class="border-bottom"/>
            <div id="footer" class="d-flex justify-content-center align-items-center p-2 gap-2 cursor-pointer">
              <img class="icon" src="@/assets/new.svg" alt="icon">
              <router-link class="text-decoration-none" :to="{ name: 'NewCompanyPage' }" active-class>
                <span class="color-primary">{{$t('createCompany')}}</span>
              </router-link>
            </div>
          </ul> -->
        </div>
        <img id="collapse-menu" @click="sideMenuStore.toggleMenu" class="cursor-pointer" src="@/assets/sideBar/collapse.svg" alt="icon">
      </div>
      <ul id="items" class="navbar-nav p-2">
        <li v-for="item in menuItems" :key="item.index">
          <router-link :to="{ name: item.routeName }" class="nav-link">
            <div class="icon">
              <img class="img-fluid" :src="item.icon" alt="icon">
            </div>
            <span class="label color-secondary">{{ item.label }}</span>
            <span v-show="!sideMenuStore.isOpen" class="tooltip-text">{{ item.label }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { useSideMenuStore } from '@/stores';

export default {
  name: 'SideMenu',
  setup() {
    const sideMenuStore = useSideMenuStore();

    return {
      sideMenuStore
    }
  },
  data() {
    return {
      userStorage: JSON.parse(localStorage.getItem('user')) ?? null,
    }
  },
  computed: {
    menuItems() {
      return [
        { index: 0, icon: require("@/assets/sideBar/dashboard.svg"), routeName: 'DashboardPage', label: "Dashboard" },
        { index: 1, icon: require("@/assets/sideBar/chain-view.svg"), routeName: 'ChainViewPage', label: "Chain View" },
        { index: 2, icon: require("@/assets/sideBar/pickup.svg"), routeName: 'PickUpPage', label: "Pickup" },
        { index: 3, icon: require("@/assets/sideBar/competitors.svg"), routeName: 'CompetitorsAnalysis', label: "Competitors" },
        { index: 4, icon: require("@/assets/sideBar/forecast.svg"), routeName: 'ForecastPage', label: "Forecast" },
        { index: 5, icon: require("@/assets/sideBar/budget.svg"), routeName: 'BudgetAnalysis', label: "Budget" },
        // { index: 6, icon: require("@/assets/sideBar/smartPricing.svg"), routeName: 'SmartPricing', label: "Smart Pricing" },
        { index: 7, icon: require("@/assets/sideBar/groupDisplacement.svg"), routeName: 'GroupDisplacement', label: "Group Displacement" },
        { index: 8, icon: require("@/assets/sideBar/company-settings.svg"), routeName: 'CompanySettingsPage', label: "Settings" },
        { index: 9, icon: require("@/assets/sideBar/reports.svg"), routeName: 'ReportsPage', label: "Reports" },
        { index: 10, icon: require("@/assets/sideBar/integrations.svg"), routeName: 'IntegrationsPage', label: "Integrations" },
      ];
    },
    companyName() {
      return this.sideMenuStore.company?.name ?? '';
    },
    companyImage() {
      return this.parseImageData(this.sideMenuStore.company?.picture) ?? require('@/assets/no-image.jpg');
    },
  },
  mounted() {
    this.sideMenuStore.initializeCompany();
  },
  methods: {
    truncatedLabel(label) {
      if (typeof label !== 'string') {
        return '';
      }
      
      return label.length > 12 ? label.substring(0, 12) + "..." : label;
    },
    // parse the image data from JSON string
    parseImageData(imageDataString) {
      try {
        if(!Object.keys(imageDataString).length) return null;
        else {
          // Parse the JSON string if it is a string
          const parsedData = typeof imageDataString === 'string' 
          ? JSON.parse(imageDataString) 
          : imageDataString;
          return `data:${parsedData.mime};base64,${parsedData.data}`;
        }
      } catch (e) {
        console.error('Invalid JSON string:', e);
        return null;
      }
    }
  }
}

</script>

<style scoped>
#side-menu {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  width: 250px;
  background-color: #F7F8F9;
  z-index: 9999;
  border-right: 1px solid #CED4DA;
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
#side-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#side-menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 
/* side menu styles - company dropdown */
#side-menu.close #company-section .nav-link span,
#side-menu.close #company-section #collapse-menu{
  opacity: 0;
}
#side-menu #company-section #collapse-menu{
  transition: all 0.4s ease-in-out;
  opacity: 1;
}
#side-menu #company-section {
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #CED4DA;
}
.company-img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
#side-menu #company-section .dropdown-menu {
  width: 200px;
  min-width: 200px;
  border-radius: 8px;
  padding: 0;
  font-size: .8rem;
}
#side-menu #company-section .dropdown-menu #header,
#side-menu #company-section .dropdown-menu #footer {
  min-height: 60px;
}
#side-menu #company-section .dropdown-menu #footer .icon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
#side-menu #company-section .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: #6c756c
}
#side-menu #company-section .dropdown-menu .dropdown-item:hover,
#side-menu #company-section .dropdown-menu .dropdown-item:focus,
#side-menu #company-section .dropdown-menu .dropdown-item:active {
  background-color: var(--theme-primary);
  color: white;
  border-radius: 4px;
}
/* side menu styles - menu items */
#side-menu #items.navbar-nav {
  gap: .5rem;
  width: 100%;
}
#side-menu #items.navbar-nav li {
  height: 43px;
}
#side-menu #items.navbar-nav li .nav-link{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
}
#side-menu #items.navbar-nav li .nav-link:hover{
  background-color: var(--theme-primary);
}
#side-menu #company-section .nav-link span,
#side-menu #items.navbar-nav li .nav-link .label{
  font-size: .9rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap; /* Prevents line breaks */
  opacity: 1;
}
#side-menu #items.navbar-nav li .nav-link .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
}
#side-menu #items.navbar-nav li .nav-link .icon,
#side-menu #items.navbar-nav li .nav-link .label{
  transition: all .4s ease-in-out;
}
#side-menu #items.navbar-nav li .nav-link:hover .label{
  color: #FFFFFF;
}
#side-menu #items.navbar-nav li .nav-link:hover .icon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(323deg) brightness(102%) contrast(101%);
}
#side-menu.close{
  width: 60px;
}
#side-menu.close #items.navbar-nav li .nav-link .label{
  opacity: 0;
}
#side-menu #items.navbar-nav li .router-link-active{
  background-color: var(--theme-primary);
}
#side-menu #items.navbar-nav li .router-link-active .label{
  color: #FFFFFF;
}
#side-menu #items.navbar-nav .router-link-active .icon{
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(323deg) brightness(102%) contrast(101%);
}
/* Tooltip for icons when the side menu is collapsed */
#side-menu #items.navbar-nav li .nav-link .tooltip-text {
  visibility: hidden;
  background-color: #6c757d;
  color: #fff!important;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  z-index: 9999;
  left: 60px;
  transform: translateY(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: .7rem;
}
#side-menu #items.navbar-nav li .nav-link:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
/* @supports code is important for solving the height issue on iOS Safari and Chrome */
@supports (height: 100dvh) {
  #side-menu {
    height: calc(100dvh - 60px);
    min-height: calc(100dvh - 60px);
  }
}
@media only screen and (max-height: 630px) {
  #side-menu {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>