<template>
    <section id="statistic-wrapper">
        <div class="p-3 mb-1 bg-body rounded h-100">
            <template v-if="loading">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <div class="spinner-border color-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading...') }}</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <!-- Title Section -->
                <div class="d-flex align-items-center gap-2 mb-2">
                    <img src="@/assets/DashboardPage/roomNights.svg" alt="room-nights">
                    <span class="title">{{ statisticsInfo.type }}</span>
                </div>
        
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <span class="value">{{ formattedValue(statisticsInfo.type, statisticsInfo.value) }}</span>
                    <div class="d-flex align-items-center gap-1 px-2 py-1 rounded bg-opacity-10" 
                         :class="{
                            'positive-graph': statisticsInfo.variationStly > 0, 
                            'negative-graph': statisticsInfo.variationStly < 0, 
                            'neutral-graph': statisticsInfo.variationStly === 0
                         }">
                        <img v-if="statisticsInfo.variationStly > 0" src="@/assets/DashboardPage/upTrend.svg" alt="up-trend">
                        <img v-else-if="statisticsInfo.variationStly < 0" src="@/assets/DashboardPage/downTrend.svg" alt="down-trend">
                        <span :class="{
                                'positive-value': statisticsInfo.variationStly > 0, 
                                'negative-value': statisticsInfo.variationStly < 0, 
                                'neutral-value': statisticsInfo.variationStly === 0
                            }">
                            {{ this.$n(statisticsInfo.variationStly / 100, 'percent') }}
                        </span>
                    </div>
                </div>
        
                <!-- Two Columns for STLY and LY -->
                <div class="row border-top pt-2">
                    <!-- Left Column (STLY) -->
                    <div class="col-6 d-flex flex-column align-items-start px-3 border-end">
                        <span class="text-muted small fw-bold mb-1">STLY:</span>
                        <span class="bottom-value">{{ formattedValue(statisticsInfo.type, statisticsInfo.stly) }}</span>
                    </div>
        
                    <!-- Right Column (LY) -->
                    <div class="col-6 d-flex flex-column align-items-start px-3">
                        <span class="text-muted small fw-bold mb-1">LY:</span>
                        <span class="bottom-value">{{ formattedValue(statisticsInfo.type, statisticsInfo.ly) }}</span>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        statisticsInfo: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        formattedValue(type, value) {
            if (type === 'RoomNights') {
                return this.$n(value, 'integer'); // Integer for RoomNights
            } else if (type === 'Occupancy') {
                return this.$n(value / 100, 'percent'); // Percentage for Occupancy
            } else {
                return this.$n(value, 'currency');
            }
        }
    }
};
</script>

<style scoped>
#statistic-wrapper{
  height: 160px;
  min-height: 160px;
  box-shadow: 0px 4px 30px 0px #00000014;
}
/* Title Styling */
.title {
    color: var(--theme-secondary, #6C757D);
    font-size: 1rem;
    font-weight: 700;
}

/* Main Percentage Styling */
.value {
    color: var(--theme-dark, #212529);
    font-size: 1rem;
    font-weight: 700;
}

/* Positive & Negative Graph Styles */
.positive-graph {
    background-color: #D1E7DD;
}

.negative-graph {
    background-color: #F8D7DA;
}

.positive-value {
    color: #006837;
    font-size: .7rem;
    font-weight: 600;
}

.negative-value {
    color: #E53935;
    font-size: .7rem;
    font-weight: 600;
}

.neutral-graph {
    background-color: #E9ECEF;
    font-size: .7rem;
    font-weight: 600;
}
/* Bottom Percentage Styling */
.bottom-value {
    color: var(--theme-secondary, #6C757D);
    font-size: .8rem;
    font-weight: 500;
}
</style>