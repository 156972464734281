<template>
    <section v-if="$route.name === 'HotelGroupUserEdit'" id="form">
        <LayoutForm 
            :hasPageActions="false"
            :pageLayout="{
                title: $t('editUser'),
                icon: require('@/assets/Company/pencil.svg')
            }"
        >
            <template v-slot:dynamic-content>
                <div class="row justify-content-center p-3 border-bottom">
                    <div class="col-12 col-xl-6">
                        <div class="row">
                            <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                                <img class="avatar-img" :src="parseImageData(formData?.picture) ?? require('@/assets/no-image.jpg')">
                            </div>
                            <div class="col-12 col-lg-6 role-current-user my-4 my-lg-0">
                                <div class="d-flex flex-column justify-content-center gap-2">
                                    <div class="d-flex gap-2 flex-wrap align-items-center">
                                        <span class="user-name">{{formData.name}}</span>
                                    </div>
                                    <div class="d-flex gap-2">
                                        <img src="@/assets/Users/email-icon.svg" alt="">
                                        <span class="user-email">{{formData.email}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-6 role-current-user my-4 my-lg-0">
                        <div class="d-flex flex-column gap-3">
                            <div class="d-flex gap-2">
                                <img src="@/assets/Company/lock-black-fill.svg" alt="icon" width="20" height="20">
                                <span class="role-current-user-span">{{$t('permission')}}:</span>
                            </div>
                            <div class="d-flex gap-2">
                                <img src="@/assets/Company/hotels.svg" alt="icon" width="20" height="20">
                                <span class="role-current-user-span">{{$t('hotels')}}:</span>
                            </div>
                            <div class="d-flex gap-2">
                                <img src="@/assets/Users/tags.svg" alt="icon" width="20" height="20">
                                <span class="role-current-user-span">{{$t('role')}}:</span>
                            </div>
                        </div>
                        <div class="d-flex flex-column gap-3">
                            <span class="role-current-value">{{formData?.pivot?.permission ?? $t('permissionMissing')}}</span>
                            <span class="role-current-value">{{getHotels.length}} hotels</span>
                            <span class="role-current-value">{{formData?.pivot?.role}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row border-bottom p-3">
                    <TableComponent 
                        v-model="userActivityItemsFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyUserActivityRows" 
                        @rowClicked="rowUserClicked"
                        :clickableRows="false"
                    >
                        <template v-slot:title>
                            <div class="d-flex p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="user-icon" src="@/assets/Company/calendar.svg" alt="icon">
                                        <span class="table-title">{{ $t('userActivity') }}</span>
                                    </div>
                                </div>
                                <div class="col d-flex gap-3 justify-content-end">
                                    <router-link class="btn-custom" :to="{ name: 'CompanyUserActivity' }">
                                        <img src="@/assets/Company/arrow-up-right.svg" alt="icon">
                                        {{$t('seeAll')}}
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                </div> -->
                <!-- <div class="row border-bottom p-3">
                    <TableComponent 
                        v-model="permissionsItemsFromApi" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="bodyPermissionsRows" 
                        :clickableRows="false"
                    >
                        <template v-slot:title>
                            <div class="d-flex p-3">
                                <div class="col">
                                    <div class="col-12 d-flex align-items-center gap-2">
                                        <img id="permission-icon" src="@/assets/HotelGroup/editIcon.svg" alt="">
                                        <span class="table-title">{{$t('permissions')}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </TableComponent>
                </div> -->
            </template>
        </LayoutForm>
    </section>
    <!-- children -->
    <router-view></router-view>
</template>

<script>
import LayoutForm from '@/components/LayoutForm.vue';
// import TableComponent from '@/components/TableComponent.vue';
import { useCompanyUsersStore, useAlertStore } from "@/stores";

export default {
    components: {
        LayoutForm,
        // TableComponent,
    },
    setup() {
        const companyUsersStore = useCompanyUsersStore();
        const alertStore = useAlertStore();

        return {
            companyUsersStore,
            alertStore
        }
    },
    data() {
        return {
            userId: this.$route.params.userId,
            formData: {
                name: '',
                email: '',
                picture: {}
            },
            loading: false,
            // bodyUserActivityRows: [
            //     { type: 'ImageWithText', field: 'name' },
            //     { type: 'SimpleText', field: 'time' }
            // ],
            // userActivityItemsFromApi: [
            //     { id: 0, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
            //     { id: 1, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
            // ],
            // bodyPermissionsRows: [
            //     { type: 'Icon', field: 'icon' },
            //     { type: 'SimpleText', field: 'tool' },
            //     { type: 'ImageWithText', field: 'permissionType' }
            // ],
            // permissionsItemsFromApi: [
            //     { id: 0, icon: require('@/assets/Users/pickup.svg'), tool: 'Pickup', permissionType: {image: require('@/assets/Users/view-icon.svg') , firstText:'View'} },
            //     { id: 1, icon: require('@/assets/Users/competitors.svg'), tool: 'Competitors', permissionType: {image: require('@/assets/Users/update-icon.svg') , firstText:'Update'} },
            // ],
            // threeDotsSettingsHeader: [
            //     {
            //         id: 0,
            //         icon: require('@/assets/Popup/smallDeleteBtn.svg'),
            //         alt: 'Delete User',
            //         label: this.$t('Delete User'),
            //     },
            // ],
        }
    },
    async beforeRouteUpdate(to, from, next) {
        if(to.name !== 'HotelGroupUserEdit') {
            next(); 
            return; // Prevents the code below from running
        }
        console.log('Route updated');
        // If there are updates on the child pages, this code is crucial for reflecting those changes.
        try {
            await this.fetchData();
            next();
        } catch (error) {
            // If there's an error while fetching data, cancel the navigation
            next(false);
        }
    },
    async mounted() {
        // When child page load, all the previous pages also load their content. 
        // It's essential to include a condition in the mounted hook to ensure it only mounts when the specific page is loaded.
        if(this.$route.name === 'HotelGroupUserEdit') {
            console.log(this.userId)
            if(!this.userId) {
                this.$router.push({ name: 'CompanyUsersList' });
            }
            await this.fetchData();
        }
    },
    computed: {
        // Retrieve all hotels from all companies
        getHotels() {
            const userDetails = this.companyUsersStore.getUserDetailsOutput;
            if (!userDetails || !userDetails.companies) {
                return [];
            }
            return userDetails.companies.flatMap(company => company.hotels || []);
        }
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                await this.companyUsersStore.getUserDetailsById(this.userId);
                this.formData = {
                    ...this.companyUsersStore.getUserDetailsOutput,
                    name: this.companyUsersStore.getUserDetailsOutput.name,
                    email: this.companyUsersStore.getUserDetailsOutput.email,
                    picture: this.companyUsersStore.getUserDetailsOutput.picture && Object.keys(this.companyUsersStore.getUserDetailsOutput.picture).length ? this.companyUsersStore.getUserDetailsOutput.picture : {}
                }
                console.log('formData:', this.formData);
            } catch (error) {
                this.alertStore.error(error);
            } finally {
                this.loading = false;
            }
        },
        pageActionClick() {
            console.log("pageActionClick")
        },
        firstActionClick() {
            console.log("firstActionClick")
        },
        secondActionClick() {
            console.log("secondActionClick")
        },
        // parse the image data from JSON string
        parseImageData(imageDataString) {
            try {
                if(!Object.keys(imageDataString).length) return null;
                else {
                    const parsedData = JSON.parse(imageDataString);
                    return `data:${parsedData.mime};base64,${parsedData.data}`;
                }
            } catch (e) {
                console.error('Invalid JSON string:', e);
                return null;
            }
        }
    },
}
</script>

<style scoped>
.image-current-user,
.role-current-user{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.user-name{
    color: var(--theme-dark);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.user-email{
    color: var(--gray-800);
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
}

.table-title{
    color: var(--theme-dark);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
}
.btn-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-dark);
    border-radius: 8px;
    border: 1px solid var(--theme-border);
    background: var(--theme-light);
    font-size: .9rem;
    text-decoration: none;
}

.role-current-user-span{
    color: var(--theme-dark);
    font-size: .9rem;
    font-style: normal;
    font-weight: 500;
}

.role-current-value{
    font-size: .9rem;
}

.avatar-img{
    width: 150px;
    height: auto;
    border-radius: 50%;
    border: 1px solid var(--theme-border);
}
@media (min-width: 992px) {
    .btn-custom{
        min-width: 200px
    }
}
@media (max-width: 767px) {
    .first-input .form-control,
    .second-input .form-control {
        width: 100%;
    }
    .table-title{
        font-size: 1rem;
    }
}
</style>