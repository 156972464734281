<template>
    <section>
        <NotificationsDesktop :itemsFromApi="itemsFromApi" class="d-none d-md-block"/>
        <NotificationsMobile :itemsFromApi="itemsFromApi" class="d-md-none d-block"/>
    </section>
</template>
  
<script>
import NotificationsDesktop from '@/components/NotificationsPage/NotificationsDesktop.vue';
import NotificationsMobile from '@/components/NotificationsPage/NotificationsMobile.vue';
import { useMyStore, useAlertStore } from "@/stores";
import { nextTick } from 'vue';
    export default {
        components: {
            NotificationsDesktop,
            NotificationsMobile
        },
        data() {
            return {
                itemsFromApi: [],
            }
        },
        setup() {
            const myStore = useMyStore();
            const alertStore = useAlertStore();
            return {
                myStore,
                alertStore
            }
        },
        async mounted() {
            await this.fetchData();
        },
        methods: {
            async fetchData() {
                try {
                await this.myStore.getActivityList();
                nextTick(() => {
                    this.itemsFromApi = this.myStore.getMyActivityListOutput;
                });
                } catch (error) {
                    this.alertStore.error(error);
                }
            },
        },
    }
</script>
  
  <style scoped>
  </style>