<template>
    <section id="sales_forecast">
        <template v-if="isMobile">
            <div class="sidebar-toggle border-bottom" @click="menuStore.toggleSubMenu">
                <img src="@/assets/arrow-right-fill.svg" class="arrow-icon" :class="{ open: menuStore.isSubMenuOpen }" alt="">
                <span class="page-title-mobile">{{ $t('Segmented Forecast') }} - {{ metricTitle }}</span>
            </div>
        </template>
        <div class="container-fluid py-3 py-md-0 mt-5">
            <div class="row gy-2 gy-sm-0">
                <div class="col-12 col-sm-6">
                    <BoldTitle :title="$t('forecast')"/>
                </div>
                <!-- <div class="col-12 col-sm-6">
                    <div class="d-flex justify-content-start justify-content-sm-end">
                        <CustomButton :type="1" :label="$t('createPageReport')" buttonType="theme-light"
                            :rightIcon="require('@/assets/BudgetAnalysis/createPageReport.svg')" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                padding: '5px 15px',
                                minWidth: '85px',
                                gap: '10px'
                            }" 
                        />
                    </div>
                </div> -->
            </div>
            <div class="mt-3 mt-sm-5">
                <div class="row gy-3 gy-lg-0">
                    <div class="col-12 col-lg-3">
                        <div class="d-flex align-items-center justify-content-start justify-content-lg-end h-100">
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="$t('hotel')" 
                                    :icon="require('@/assets/Pickup/house.svg')"
                                    @clickBtn="toggleDropdown('hotel')" 
                                />
                                <DropdownMenu
                                    v-model="selectedItems['selectedHotel']"
                                    :configuration=" {
                                        items: hotelOptions,
                                        isDropdownOpen: isDropdownHotelOpen,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="isDropdownHotelOpen = false"
                                />
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="container-wrapper mt-3 mt-lg-5">
                <div class="p-4">
                    <div class="row align-items-center gy-3 gy-lg-0">
                        <div class="col-12 col-lg-4">
                            <!-- forecast dropdown -->
                            <div class="dropdown">
                                <IconTitleButton 
                                    :firstIcon="require('@/assets/Pickup/graph-up.svg')"
                                    :label="currentSelectedForecastLabel" 
                                    :secondIcon="require('@/assets/Pickup/chevron-right.svg')"
                                    @toggleDropdown="toggleDropdown('forecast')" 
                                />
                                <DropdownMenu
                                    v-model="selectedItems['selectedForecast']"
                                    :configuration=" {
                                        items: forecastOptions,
                                        isDropdownOpen: isDropdownForecastOpen,
                                        hasHeader: true,
                                        hasBody: true,
                                        openDropdownPosition: 'left',
                                        selectionMode: 'single'
                                    }"
                                    :customClass="['forecast-menu']" 
                                    @close-dropdown="isDropdownForecastOpen = false"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-lg-8">
                            <!-- user forecast dropdown -->
                            <div class="dropdown">
                                <DropdownButton 
                                    :label="$t('userForecast')" 
                                    @clickBtn="toggleDropdown('userForecast')" 
                                />
                                <DropdownMenu
                                    v-model="selectedItems['selectedUserForecast']"
                                    :configuration=" {
                                        items: userForecastOptions,
                                        isDropdownOpen: isDropdownUserForecastOpen,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'left',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="isDropdownUserForecastOpen = false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <DividerComponent />
                <div class="mt-2 p-4">
                    <!-- general forecast -->
                    <div v-if="currentSelectedForecastId === 0" class="row gy-3 gy-lg-0">
                        <div class="col-12 col-lg-6">
                            <DateButton v-model="date" typeForm="1" range />
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="d-flex flex-wrap justify-content-start justify-content-lg-end gap-3">
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="$t('range')" 
                                        @clickBtn="toggleDropdown('range')" 
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['selectedRange']"
                                        :configuration=" {
                                            items: rangeOptions,
                                            isDropdownOpen: isDropdownRangeOpen,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'left',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="isDropdownRangeOpen = false"
                                    />
                                </div>
                                <div class="dropdown">
                                    <DropdownButton 
                                        :label="$t('dayOfWeek')" 
                                        @clickBtn="toggleDropdown('dayOfWeek')" 
                                    />
                                    <DropdownMenu
                                        v-model="selectedItems['selectedDayOfWeek']"
                                        :configuration=" {
                                            items: dayOfWeekOptions,
                                            isDropdownOpen: isDropdownDayOfWeekOpen,
                                            hasHeader: false,
                                            hasBody: true,
                                            openDropdownPosition: 'right',
                                            selectionMode: 'single'
                                        }"
                                        @close-dropdown="isDropdownDayOfWeekOpen = false"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row flex-nowrap overflow-auto">
                                <div class="col my-3 flex-wrap" id="colstatistics" v-for="(statisticInfo, index) in statisticsInfo" :key="index">
                                    <StatisticsWidget :statisticsInfo="statisticInfo"></StatisticsWidget>
                                </div>
                            </div>
                        </div>
                        <div class="container-wrapper mt-3 mt-lg-5 overflow-auto">
                            <TableComponent 
                                v-model="itemsFromApi" 
                                :layout="{
                                    hasHeader: { desktop: true, mobile: false }
                                }" 
                                :tableHeaders="tableHeaders" 
                                :tableRows="tableRows" 
                                @item-selected="handleItemSelected"
                                @all-items-selected="handleAllItemsSelected" 
                                :extendItems="true" 
                                extendItemsWithProp="details"
                            >
                                <template v-slot:title>
                                    <div class="row py-3 px-4 border-bottom align-items-center">
                                        <div class="col-6">
                                            <h5 class="table-title fw-bold m-0">{{ $t('totalTable') }}</h5>
                                        </div>
                                        <!-- <div class="col-6">
                                            <CustomButton 
                                                class="ms-auto"
                                                :type="1" 
                                                buttonType="theme-light"
                                                :rightIcon="require('@/assets/BudgetAnalysis/createPageReport.svg')" 
                                                :buttonStyle="{
                                                    borderRadius: '8px!important',
                                                    padding: '5px'
                                                }" 
                                            />
                                        </div> -->
                                    </div>
                                </template>
                                <template v-slot:details="detailsProp">
                                    <TableComponent 
                                        v-model="detailsProp.details" 
                                        :layout="{
                                            hasHeader: { desktop: false, mobile: false }
                                        }" 
                                        :tableRows="tableRows"
                                    >
                                    </TableComponent>
                                </template>
                            </TableComponent>
                        </div>
                    </div>
                    <!-- segmented forecast -->
                    <div v-if="currentSelectedForecastId == 1" class="row">
                        <div class="col-12 col-md-6">
                            <DateButton v-model="date" typeForm="1" range />
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-end gap-3">
                            <div class="dropdown">
                                <DropdownButton
                                    :label="$t('range')"
                                    @clickBtn="toggleDropdown('range')"
                                />
                                <DropdownMenu
                                    v-model="selectedItems['selectedRange']"
                                    :configuration=" {
                                        items: rangeOptions,
                                        isDropdownOpen: isDropdownRangeOpen,
                                        hasHeader: false,
                                        hasBody: true,
                                        openDropdownPosition: 'right',
                                        selectionMode: 'single'
                                    }"
                                    @close-dropdown="isDropdownRangeOpen = false"
                                />
                            </div>
                        </div>
                        <div v-if="segmentChart" class="col-12 mt-5">
                            <div class="chart-wrapper p-3 p-lg-5">
                                <DoughnutChart 
                                    :chartTitle="$t('segments')" 
                                    :chartData="segmentChart.chartData" 
                                    :chartOptions="segmentChart.chartOptions" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Popup for Save Your File -->
            <CustomPopup 
                v-if="openDownloadPopup == true" 
                @popUpClosed="openDownloadPopup = false"  
            >
                <template v-slot:body>
                    <div class="container-fluid" id="container-list-folders-popup">
                        <div class="row" id="row-list-folders-popup">
                            <BoldTitle :title="$t('saveYourFile')" :icon="require('@/assets/Pickup/saveFile.svg')"/>
                            <hr>
                            <span>Choose a folder to save your file:</span>
                            <div class="col-12 d-flex gap-3 align-items-center justify-content-center my-2" id="col-folder-style" v-for="(item,index) in foldersPickupList" :key="index">
                                <input type="checkbox">
                                <img src="@/assets/Pickup/listFoldersPickup.svg" alt="">
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center">
                        <CustomButton class="btn mt-5 d-flex gap-2" :label="$t('createNewFolder')" 
                            :buttonType="'theme-light'" 
                            :rightIcon="require('@/assets/Pickup/createFolder.svg')" 
                            @click="createFolderPopupOpen = true; openDownloadPopup = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }" 
                        />
                        <CustomButton class="btn mt-5 d-flex gap-2" :label="$t('save')" 
                            :buttonType="'primary'" 
                            :rightIcon="require('@/assets/NotificationsMobile/aprove-btn.svg')" 
                            @click="openDownloadPopup = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }" 
                        />
                    </div>
                </template>
            </CustomPopup>
            <!-- Popup to create a new folder -->
            <CustomPopup 
                v-show="createFolderPopupOpen" 
                @popUpClosed="createFolderPopupOpen = false"
                :img="require('@/assets/Pickup/createFolderPopup.svg')"
                :titlePopup="'Choose a name to identify your Folder:'"
                :inputType="'text'"
                :inputRequired="true"
                :labelInput="$t('name')"
                :placeholderText="$t('nameOfYourFolder')"
              >
                <template v-slot:body>
                    <div class="d-flex gap-2">
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('cancel')"
                            :buttonType="'theme-light'" 
                            :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                            @click="createFolderPopupOpen = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('save')"
                            :buttonType="'primary'" 
                            :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                            @click="createFilePopupOpen = true; createFolderPopupOpen = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                    </div>
                </template>
            </CustomPopup> 
            <!-- Popup to create a new file -->
            <CustomPopup 
                v-show="createFilePopupOpen" 
                @popUpClosed="createFilePopupOpen = false"
                :img="require('@/assets/Pickup/createFilePopup.svg')"
                :titlePopup="'Choose a name to identify your File:'"
                :inputType="'text'"
                :inputRequired="true"
                :labelInput="$t('name')"
                :placeholderText="$t('nameOfYourFile')"
              >
                <template v-slot:body>
                    <div class="d-flex gap-2">
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('cancel')"
                            :buttonType="'theme-light'" 
                            :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                            @click="createFilePopupOpen = false; createFolderPopupOpen = false"
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('save')"
                            :buttonType="'primary'" 
                            :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                    </div>
                </template>
            </CustomPopup> 
        </div>
    </section>
</template>
<script>
import BoldTitle from '@/components/BoldTitle.vue';
import IconTitleButton from '@/components/IconTitleButton.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';
import DateButton from '@/components/DateButton.vue';
import StatisticsWidget from '@/components/StatisticsWidget.vue';
import TableComponent from '@/components/TableComponent.vue';
import DoughnutChart from '@/components/Charts/DoughnutChart.vue';
import ChartSetup from '@/helpers/chart-setup.js';
import { useResponsive } from '@/composables/responsive.js';
import { useMenuStore } from '@/stores';

export default {
    components: {
        BoldTitle,
        IconTitleButton,
        DividerComponent,
        CustomButton,
        CustomPopup,
        DropdownButton,
        DropdownMenu,
        DateButton,
        StatisticsWidget,
        TableComponent,
        DoughnutChart
    },
    props: {
        metric: {
            type: String,
            required: true
        }
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const menuStore = useMenuStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            menuStore
        }
    },
    data() {
        return {
            // Popups
            createFilePopupOpen: false,
            createFolderPopupOpen: false,
            openDownloadPopup: false,
            // Table
            tableHeaders: [
                {
                    icon: require('@/assets/FoldersPage/file-name-type.svg'),
                    text: 'Date'
                },
                {
                    icon: require('@/assets/FoldersPage/file-name-type.svg'),
                    text: 'User Forecast'
                },
                {
                    icon: require('@/assets/FoldersPage/date-icon.svg'),
                    text: 'ADR'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'RevPar'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'Budget'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'LY'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'STLY'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'OTB'
                },
            ],
            tableRows: [
                { type: 'SimpleText', field: 'date' },
                { type: 'SimpleText', field: 'userForecast' },
                { type: 'SimpleText', field: 'adr' },
                { type: 'SimpleText', field: 'revPar' },
                { type: 'SimpleText', field: 'budget' },
                { type: 'SimpleText', field: 'ly' },
                { type: 'SimpleText', field: 'stly' },
                { type: 'SimpleText', field: 'otb' },
            ],
            itemsFromApi: [
                { id: 0, date: 'Jan 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%', details:[
                    { id: 0, metric: 'BAR', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                    { id: 1, metric: 'NFR', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                    { id: 2, metric: 'Early booking', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                ]},
                { id: 1, date: 'Feb 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 2, date: 'Mar 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 3, date: 'Apr 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 4, date: 'May 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 5, date: 'Jun 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 6, date: 'Jul 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 7, date: 'Aug 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 7, date: 'Sep 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 7, date: 'Out 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 7, date: 'Nov 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },
                { id: 7, date: 'Dec 2023', userForecast: '61', adr: '61', revPar: '80%', budget: '14,214€', ly: '14,214€', stly: '14,214€',otb: '4%' },

            ],
            foldersPickupList: [
                { title: "Revenue Crystal Breeze Resort  Oct 2023" },
                { title: "Revenue Crystal Breeze Resort  Oct 2023" },
                { title: "Revenue Crystal Breeze Resort  Oct 2023" },
                { title: "Revenue Crystal Breeze Resort  Oct 2023" },
                { title: "Revenue Crystal Breeze Resort  Oct 2023" },
            ],
            statisticsInfo:[
                {id: 0, iconTitle: require('@/assets/DashboardPage/roomNights.svg'), title: 'Room Nights', mainPercentage: '53,4', iconMainMiniGraph: require('@/assets/DashboardPage/upTrend.svg'), percMainMiniGraph: '53,4', iconSTLY: require('@/assets/DashboardPage/upTrend.svg'), percSTLY: '9,4', iconLY: require('@/assets/DashboardPage/downTrend.svg'), percLY: '9,4', bottomPercLeft: '44,0', bottomPercRight: '82,6' },
                {id: 1, iconTitle: require('@/assets/DashboardPage/roomNights.svg'), title: 'Occupancy', mainPercentage: '53,4', iconMainMiniGraph: require('@/assets/DashboardPage/upTrend.svg'), percMainMiniGraph: '53,4', iconSTLY: require('@/assets/DashboardPage/upTrend.svg'), percSTLY: '9,4', iconLY: require('@/assets/DashboardPage/downTrend.svg'), percLY: '9,4', bottomPercLeft: '44,0', bottomPercRight: '82,6' },
                {id: 2, iconTitle: require('@/assets/DashboardPage/roomNights.svg'), title: 'Revenue', mainPercentage: '53,4', iconMainMiniGraph: require('@/assets/DashboardPage/upTrend.svg'), percMainMiniGraph: '53,4', iconSTLY: require('@/assets/DashboardPage/upTrend.svg'), percSTLY: '9,4', iconLY: require('@/assets/DashboardPage/downTrend.svg'), percLY: '9,4', bottomPercLeft: '44,0', bottomPercRight: '82,6' },
                {id: 3, iconTitle: require('@/assets/DashboardPage/roomNights.svg'), title: 'ADR', mainPercentage: '53,4', iconMainMiniGraph: require('@/assets/DashboardPage/upTrend.svg'), percMainMiniGraph: '53,4', iconSTLY: require('@/assets/DashboardPage/upTrend.svg'), percSTLY: '9,4', iconLY: require('@/assets/DashboardPage/downTrend.svg'), percLY: '9,4', bottomPercLeft: '44,0', bottomPercRight: '82,6' },
                {id: 4, iconTitle: require('@/assets/DashboardPage/roomNights.svg'), title: 'RevPAR', mainPercentage: '53,4', iconMainMiniGraph: require('@/assets/DashboardPage/upTrend.svg'), percMainMiniGraph: '53,4', iconSTLY: require('@/assets/DashboardPage/upTrend.svg'), percSTLY: '9,4', iconLY: require('@/assets/DashboardPage/downTrend.svg'), percLY: '9,4', bottomPercLeft: '44,0', bottomPercRight: '82,6' },
            ],
            //forecast dropdown
            isDropdownForecastOpen: false,
            // hotel dropdown
            isDropdownHotelOpen: false,
            // user forecast dropdown
            isDropdownUserForecastOpen: false,
            // range dropdown
            isDropdownRangeOpen: false,
            // day of week dropdown
            isDropdownDayOfWeekOpen: false,
            selectedItems: {},
            // date
            date: null,
            // charts
            segmentChart: {}
        }
    },
    mounted () {
        this.setDefaultSelectedForecast(); // Set the default selected forecast
        this.setDefaultFilterDate(); // Set the default date range
        this.fetchData(); // Fetch data
    },
    computed: {
        hotelOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: "Cystal Breeze Resort California",
                        image: require('@/assets/avatar.png')
                    },
                    {
                        id: 1,
                        label: "Cystal Breeze Resort California",
                        image: require('@/assets/avatar.png')
                    }
                ]
            }
        },
        forecastOptions() {
            return {
                header: [{ id: 0, icon: require('@/assets/Pickup/graph-up.svg'), label: this.$t('forecast'), alt: 'menu-hamburger' }],
                body: [
                    {
                        id: 0,
                        icon: require('@/assets/Pickup/calendar-check.svg'),
                        label: this.$t('general'),
                        alt: 'calendar'
                    },
                    {
                        id: 1,
                        icon: require('@/assets/Pickup/list-ul.svg'),
                        label: this.$t('segmented'),
                        alt: 'lock-fill'
                    },
                ]
            }
        },
        userForecastOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: "User Forecast",
                        image: require('@/assets/avatar.png')
                    },
                    {
                        id: 1,
                        label: "System Forecast",
                        image: require('@/assets/avatar.png')
                    },
                    {
                        id: 2,
                        label: "User vs System",
                        image: require('@/assets/avatar.png')
                    }
                ]
            }
        },
        rangeOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: "none",
                    },
                    {
                        id: 1,
                        label: "1",
                    },
                    {
                        id: 2,
                        label: "2",
                    },
                    {
                        id: 3,
                        label: "3",
                    },
                    {
                        id: 4,
                        label: "4",
                    },
                    {
                        id: 5,
                        label: "5",
                    },
                ]
            }
        },
        dayOfWeekOptions() {
            return {
                header: [],
                body: [
                    {
                        id: 0,
                        label: "Monday",
                    },
                    {
                        id: 1,
                        label: "Tuesday",
                    },
                    {
                        id: 2,
                        label: "Wednesday",
                    },
                    {
                        id: 3,
                        label: "Thursday",
                    },
                    {
                        id: 4,
                        label: "Friday",
                    },
                    {
                        id: 5,
                        label: "Saturday",
                    },
                    {
                        id: 6,
                        label: "Sunday",
                    },
                ]
            }
        },
        currentSelectedForecastLabel() {
            if (this.selectedItems['selectedForecast'] && this.selectedItems['selectedForecast'][0] && this.selectedItems['selectedForecast'][0].id === 0) {
                return this.$t('generalForecast');
            } else {
                return this.$t('segmentedForecast');
            }
        },
        currentSelectedForecastId() {
            return this.selectedItems['selectedForecast'] && this.selectedItems['selectedForecast'][0] && this.selectedItems['selectedForecast'][0].id;
        },
        metricTitle() {
            switch(this.metric) {
                case 'SegmentedRoomNights':
                    return this.$t('Room Nights');
                case 'SegmentedOccupancy':
                    return this.$t('Occupancy');
                case 'SegmentedRevenue':
                    return this.$t('Revenue');
                case 'SegmentedADR':
                    return this.$t('ADR');
                case 'SegmentedRevPAR':
                    return this.$t('RevPar');
                case 'SegmentedAll':
                    return this.$t('All');
                default:
                    return '';
            }
        },
    },
    methods: {
        setDefaultSelectedForecast() {
            this.selectedItems['selectedForecast'] = [this.forecastOptions.body[0]];
        },
        fetchData() {
            this.fetchSegmentChart();
        },
        setDefaultFilterDate() {
            const currentDate = new Date();
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 7);
            this.date = [currentDate.toISOString(), futureDate.toISOString()]; // Set the default date range to the current date and 7 days ahead
        },
        toggleDropdown(dropdownName) {
            this.isDropdownUserForecastOpen = dropdownName === "userForecast" && !this.isDropdownUserForecastOpen;
            this.isDropdownForecastOpen = dropdownName === "forecast" && !this.isDropdownForecastOpen;
            this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
            this.isDropdownRangeOpen = dropdownName === "range" && !this.isDropdownRangeOpen;
            this.isDropdownDayOfWeekOpen = dropdownName === "dayOfWeek" && !this.isDropdownDayOfWeekOpen;
        },
        fetchSegmentChart() {
            // Dummy API data
            const labels = ["Segment 1", "Segment 2", "Segment 3", "Segment 4", "Segment 5", "Segment 6"];
            const datasets = [
                { 
                    data: labels.map(() => Math.floor(Math.random() * (100 - 10 + 1)) + 10), // Generate random values between 10 and 100
                    backgroundColor: ['#A81800', '#B74431', '#C77062', '#D69D93', '#E6C9C4', '#EEDFDD'],
                }
            ];

            // Fetch chart data using ChartsSetup helper
            this.segmentChart = ChartSetup.getSegmentsChartData(labels, datasets);
        },
    },
    watch: {
        selectedItems: {
          handler: function (newVal) {
            console.log(newVal);
          },
          deep: true
        },
        currentSelectedForecastId: {
            handler(newValue, oldValue) {
                if(newValue !== 0 || oldValue !== undefined) {       
                    for (let key in this.selectedItems) {
                        if (Object.prototype.hasOwnProperty.call(this.selectedItems, key)) {
                            if(key !== 'selectedForecast') { // reset all keys except the selected forecast
                                this.selectedItems[key] = []; // reset all selected items when the current forecast item changes
                            }
                        }
                    }             
                }
            }
        }
    },
}
</script>
<style scoped>
.sidebar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #f5f6f7;
    transition: all 0.3s ease;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 1001;
    right: 0;
    overflow: auto;
    white-space: nowrap;
}
.arrow-icon {
    width: 23px;
    height: auto;
    margin-right: 10px;
    transition: transform 0.3s ease;
}
.arrow-icon.open {
    transform: rotate(-180deg);
}
.page-title-mobile {
  font-size: 1rem;
  font-weight: 700;
  color: var(--theme-dark);
}
.table-title{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
}
.container-wrapper {
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.container-list {
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.forecast-menu {
    min-width: 240px;
    position: absolute;
    left: 250px;
    top: -60px;
}
#colstatistics{
    min-width: 300px;
}
.chart-wrapper {
    width: 100%;
    border-radius: 15px;
    height: calc(100vh - 100px); 
    max-height: calc(100vh - 100px);    
    background: #FFF;
    box-shadow: 0px 4px 30px 0px #00000014;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chart-wrapper::-webkit-scrollbar {
    display: none;
}
@media (max-width: 767.98px){
    .chart-wrapper {
        max-height: 600px;
    }
}
@media (max-width: 575px) {
    .table-title{
        font-size: .8rem;
    }
}
</style>