<template>
    <div class="col-12">
        <div class="col-12">
            <img src="@/assets/authentication/sign-up/logo.svg" alt="">
        </div>
        <div class="d-flex flex-column gap-3 my-4">
            <span class="title-primary">{{ $t('logInToYourAccount') }}</span>
            <span class="title-secondary color-secondary">{{ $t('welcomeBack!SelectMethodToLogin:') }}</span>
        </div>
    </div>
    <ValidationAlerts v-if="hasError(errors, 'email') || hasError(errors, 'password') || apiErrors.output" :alertType="'error'" class="my-2"
        :title="combinedErrors" :errors="apiErrors" />
    <div class="form-group">
        <label for="email">{{ $t('email') }}<span class="asterisk">*</span></label>
        <Field class="form-control input-form" name="email" type="email"
            :class="{ 'is-invalid': hasError(errors, 'email') }" :placeholder="$t('enterYourEmail')" />
    </div>
    <div class="form-group">
        <label for="password">{{ $t('password') }}<span class="asterisk">*</span></label>
        <Field name="password" type="password" v-slot="{ field }">
            <div id="input-password">
                <input id="password" v-bind="field" class="form-control input-form"
                    :class="{ 'is-invalid': hasError(errors, 'password') }" type="password" :placeholder="$t('password')" />
                <img class="cursor-pointer" v-show="!hasError(errors, 'password')" id="visibility-icon"
                    @click="switchVisibility()" src="@/assets/MyProfile/visibilityPassword-icon.svg" alt="">
            </div>
        </Field>
    </div>
    <div class="row">
        <div class="form-group remember col-6">
            <!-- <input class="form-check-input" type="checkbox" id="remember-me">
            <label class="mx-2" for="remember-me">{{ $t('rememberMe') }}</label> -->
        </div>
        <div class="form-group col-6 forgot-password">
            <a @click="goToShowRecoverForm">{{ $t('forgotPassword') }}</a>
        </div>
    </div>
    <div class="form-group">
        <button type="submit" class="w-100" v-bind:class="{ disabled: loading }" :disabled="isSubmitting">
            <span v-show="!loading">{{ $t('login') }}</span>
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
    <!-- <div class="form-group">
        <CustomButton class="d-none d-sm-block mt-5" buttonType="transparent" :label="$t('signUpWithGoogle')"
            @clickBtn="clickBtn"></CustomButton>
    </div> -->
</template>
  
<script>
// import CustomButton from '@/components/CustomButton.vue';
import ValidationAlerts from '@/components/ValidationAlerts.vue';
import { Field } from 'vee-validate';

export default {
    components: {
        // CustomButton,
        ValidationAlerts,
        Field
    },
    props: {
        errors: Object,
        isSubmitting: Boolean,
        loading: Boolean,
        hasError: Object,
        getErrorMessages: Object,
        apiErrors: Object
    },
    methods: {
        onSubmit(values) {
            this.$emit('loginClicked', values)
        },
        clickBtn() {
            this.$emit('signUpGoogle')
        },
        switchVisibility() {
            const passwordField = document.querySelector('#password')
            if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
            else passwordField.setAttribute('type', 'password')
        },
        goToShowRecoverForm() {
            this.$emit('goToShowRecover')
        },
    },
    computed: {
        combinedErrors() {
            const validationErrors = this.getErrorMessages(this.errors, ['email', 'password']);
            const apiErrorOutput = this.apiErrors.output;
            return apiErrorOutput ? apiErrorOutput : validationErrors;
        }
    }
}
</script>
  
<style scoped>
.title-primary {
    font-size: 20px;
    font-weight: 900;
    word-break: break-word;
}

.title-secondary {
    font-size: 15px;
    word-break: break-word;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: flex;
    justify-content: flex-start;
}

.asterisk {
    color: var(--theme-primary);
    margin-left: 5px;
}

.form-group input,
:deep(.form-group #div-global input) {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.remember {
    display: flex;
    flex-direction: row;
}


.input-form {
    max-width: 500px;
}

.form-group .remember label {
    margin-left: 5px;
}

.form-group button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--theme-primary);
    text-decoration: none;
    text-align: center;
}

.form-group a {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: var(--theme-primary) !important;
    text-decoration: underline !important;
    cursor: pointer;
}

/* appearance for checked checkbox */
.form-check-input:checked {
    background-color: var(--theme-primary) !important;
}

.form-check-input {
    margin-top: 0 !important;
}

.forgot-password {
    text-align: end;
}

#input-password {
    position: relative;
}

#visibility-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}
</style>