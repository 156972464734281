<template>
  <section id="table">
    <!-- title -->
    <slot name="title" />

    <!-- Spinner Loader -->
    <div v-if="loading" id="spinner-wrapper">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="spinner-border color-primary" role="status">
          <span class="visually-hidden">{{ $t('Loading...') }}</span>
        </div>
      </div>
    </div>

    <div v-else-if="paginatedItems && paginatedItems.length" id="table-wrapper">
      <!-- header -->
      <div id="table-header" v-if="shouldShowHeader && tableHeaders.length" class="row p-4 border-bottom">
        <div class="row align-items-center mx-auto">
          <div 
            v-for="(headerColumn, index) in tableHeaders" 
            :key="index" 
            class="col text-center"
          >
            <div 
              class="d-flex align-items-center gap-2" 
              :class="{'justify-content-center': tableRows.length > 2, 'justify-content-end': tableRows.length === 2 && index === tableRows.length - 1}"
            >
              <img v-if="headerColumn.icon" class="img-fluid" :src="headerColumn.icon" alt="icon">
              <span 
                v-if="headerColumn.text" 
                :title="headerColumn.text" 
              >
                {{ truncateText(headerColumn.text, 15) }}
              </span>
            </div>
          </div>
          <!-- actions -->
          <div class="col text-center" v-for="n in actionsCount" :key="n"/>
        </div>
      </div>
      <!-- body -->
      <div id="table-body" class="row gap-3 p-1 p-md-4">
        <template v-for="(item, index) in paginatedItems" :key="index">
          <!-- Main Row -->
          <div id="table-row" class="row mx-auto p-4" @click="handleRowClick(index, item)" :class="{'cursor-pointer': clickableRows && !item.isNotRowClick, 'opacity-50': item.isNotRowClick}">
            <div 
              v-for="(bodyRow, rowIndex) in tableRows" 
              :key="rowIndex"
              class="col align-self-center" 
              :class="{
                'text-center': bodyRow.type !== 'Action' && tableRows.length > 2,
                'text-end': bodyRow.type === 'Action' || tableRows.length === 2 && rowIndex === tableRows.length - 1,
                'd-flex flex-column align-items-center gap-2': bodyRow.type === 'Variation'
              }" 
            >
              <template v-if="bodyRow.type === 'SimpleText' || bodyRow.type === 'BoldText'">
                <span 
                  :class="{ 
                    'label': bodyRow.type === 'SimpleText', 
                    'bold-label': bodyRow.type === 'BoldText' 
                    }"
                  >
                    {{ bodyRow.typeField === 'percentage' ? $n(item[bodyRow.field], 'percent') : bodyRow.typeField === 'currency' ? $n(item[bodyRow.field], 'currency') : item[bodyRow.field] }}
                </span>
              </template>
              <template v-if="bodyRow.type === 'Variation' && item[bodyRow.field]">
                <span class="label">{{ item[bodyRow.field].value }}</span>
                <div class="d-flex justify-content-center align-items-center gap-1 px-2 py-1 rounded bg-opacity-10 w-50 mx-auto" 
                  :class="{
                    'positive-graph': removeSymbols(item[bodyRow.field].variation) > 0,
                    'negative-graph': removeSymbols(item[bodyRow.field].variation) < 0,
                    'neutral-graph': removeSymbols(item[bodyRow.field].variation) === 0
                  }"
                >
                  <img v-if="removeSymbols(item[bodyRow.field].variation) > 0" class="img-fluid" src="@/assets/DashboardPage/upTrend.svg" alt="up-trend">
                  <img v-else-if="removeSymbols(item[bodyRow.field].variation) < 0" class="img-fluid" src="@/assets/DashboardPage/downTrend.svg" alt="down-trend">
                  <span :class="{
                      'positive-value': removeSymbols(item[bodyRow.field].variation) > 0,
                      'negative-value': removeSymbols(item[bodyRow.field].variation) < 0,
                      'neutral-value': removeSymbols(item[bodyRow.field].variation) === 0
                    }"
                  >
                    {{ (item[bodyRow.field].variation) }}
                  </span>
                </div>
              </template>
              <template v-if="bodyRow.type === 'Date'">
                <div class="d-flex align-items-center icon gap-2">
                  <img class="img-fluid" :src="isRowExpanded(index) ? require('@/assets/Pickup/arrow-down.svg') : require('@/assets/Pickup/arrow-right.svg')" >
                  <span class="label">{{ item[bodyRow.field] }}</span>
                </div>
              </template>
              <template v-if="bodyRow.type === 'ImageWithText'">
                <div 
                  class="d-flex image-text align-items-center" 
                  :class="{'justify-content-center': tableRows.length > 2, 'justify-content-end': tableRows.length === 2 && rowIndex === tableRows.length - 1}"
                >
                  <img v-if="item[bodyRow.field].image" class="img-fluid" :src="formatImageData(item[bodyRow.field].image)">
                  <div class="d-flex flex-column">
                    <span v-if="item[bodyRow.field].firstText" class="first-label">{{ item[bodyRow.field].firstText }}</span>
                    <span v-if="item[bodyRow.field].secondText" class="second-label">{{ item[bodyRow.field].secondText }}</span>
                  </div>
                </div>
              </template>
              <template v-if="bodyRow.type === 'Icon'">
                <div class="icon">
                  <img class="img-fluid" :src="item[bodyRow.field]" alt="icon">
                </div>
              </template>
              <template v-if="bodyRow.type === 'Status'">
                <div class="d-flex justify-content-center">
                  <span class="p-1 fw-bold" :class="item[bodyRow.field].class" style="border-radius: 4px;font-size: 12px;">
                    {{ item[bodyRow.field].label }}
                  </span>                  
                </div>
              </template>
              <template v-if="bodyRow.type === 'Input'">
                <div class="d-flex justify-content-center gap-2 icon-text">
                  <img :src="item[bodyRow.field].icon" alt="icon">
                  <input type="text" :value="inputValue" class="form-control"
                    @input="$emit('update:inputValue', $event.target.value)" />
                </div>
              </template>
              <template v-if="bodyRow.type === 'Action'">
                <slot name="actions" :item="item" :rowIndex="index" :actionType="bodyRow.field"></slot>
              </template>
            </div>
          </div>

          <!-- Expanded Rows Section -->
          <template v-if="tableExpandedRows && tableExpandedRows.length">
            <template v-if="isRowExpanded(index)">
              <!-- Render Existing Expanded Rows -->
              <div id="expanded-row" class="row mx-auto p-4" v-for="(expandedRow, expandedIndex) in item.expandedRows" :key="expandedIndex">
                <div v-for="(bodyExpandedRow, nestedIndex) in tableExpandedRows" :key="nestedIndex"
                  class="col align-self-center" :class="{
                    'text-center': expandedRow.type !== 'Action',
                    'text-end': expandedRow.type === 'Action',
                    'd-flex flex-column align-items-center gap-2': expandedRow.type === 'Variation'
                  }">
                  <!-- Display existing data for each expanded row field -->
                  <template v-if="bodyExpandedRow.type === 'SimpleText' || bodyExpandedRow.type === 'BoldText'">
                    <span :class="{
                      'label': bodyExpandedRow.type === 'SimpleText',
                      'bold-label': bodyExpandedRow.type === 'BoldText'
                    }">
                      {{ bodyExpandedRow.typeField === 'percentage' ? $n(expandedRow[bodyExpandedRow.field], 'percent') : bodyExpandedRow.typeField === 'currency' ? $n(expandedRow[bodyExpandedRow.field], 'currency') : expandedRow[bodyExpandedRow.field] }}
                    </span>
                  </template>
                  <template v-if="bodyExpandedRow.type === 'Variation'">
                    <span class="label">{{ expandedRow[bodyExpandedRow.field].value }}</span>
                    <div class="d-flex justify-content-center align-items-center gap-1 px-2 py-1 rounded bg-opacity-10 w-50 mx-auto" 
                      :class="{
                        'positive-graph': removeSymbols(expandedRow[bodyExpandedRow.field].variation) > 0,
                        'negative-graph': removeSymbols(expandedRow[bodyExpandedRow.field].variation) < 0,
                        'neutral-graph': removeSymbols(expandedRow[bodyExpandedRow.field].variation) === 0
                      }"
                    >
                      <img v-if="removeSymbols(expandedRow[bodyExpandedRow.field].variation) > 0" class="img-fluid" src="@/assets/DashboardPage/upTrend.svg" alt="up-trend">
                      <img v-else-if="removeSymbols(expandedRow[bodyExpandedRow.field].variation) < 0" class="img-fluid" src="@/assets/DashboardPage/downTrend.svg" alt="down-trend">
                      <span :class="{
                          'positive-value': removeSymbols(expandedRow[bodyExpandedRow.field].variation) > 0,
                          'negative-value': removeSymbols(expandedRow[bodyExpandedRow.field].variation) < 0,
                          'neutral-value': removeSymbols(expandedRow[bodyExpandedRow.field].variation) === 0
                        }"
                      >
                        {{ (expandedRow[bodyExpandedRow.field].variation) }}
                      </span>
                    </div>
                  </template>
                  <!-- Add a button to remove the expanded row next to the last item -->
                  <button v-if="nestedIndex === tableExpandedRows.length - 1 && allowDeleteLine" @click="removeExpandedRow((currentPage - 1) * pageSize + index, expandedIndex)" id="remove-icon" class="btn">
                    <img src="@/assets/remove-icon.svg" alt="Remove" class="remove-icon">
                  </button>
                </div>
              </div>

              <!-- Add Line Input Section for each row -->
              <div v-if="allowNewLine" class="row mx-auto py-2 px-4">
                <!-- Dynamically Render Input Fields Based on tableExpandedRows -->
                <div v-for="(field, fieldIndex) in tableExpandedRows" :key="fieldIndex" class="col">
                  <input 
                    v-if="field.allowAdd"
                    :type="field.inputType === 'number' ? 'number' : 'text'"
                    v-model="newRowData[(currentPage - 1) * pageSize + index][field.field]"
                    :placeholder="`Insert ${field.field}`" 
                    class="form-control" 
                  />
                </div>
              </div>
              <div v-if="allowNewLine" id="new-line" class="row mx-auto py-2 px-4">
                <!-- Add New Line Button -->
                <div class="col">
                  <button @click="addNewExpandedRow((currentPage - 1) * pageSize + index)" class="btn w-100 text-white">
                    + {{$t('Add new line')}}
                  </button>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>

      <!-- Pagination Controls -->
      <div id="pagination-controls" class="row p-4">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Items per page selection -->
          <div id="items-per-page" class="d-flex align-items-center">
            <label for="rowsPerPage" class="me-2">{{ $t('Rows per page') }}</label>
            <select v-model="pageSize" @change="changeRowsPerPage" class="form-select form-select-sm">
              <option v-for="option in rowsPerPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>

          <!-- Current page range and total items -->
          <div id="range-items">
            {{ (currentPage - 1) * pageSize + 1 }} - {{ Math.min(currentPage * pageSize, items.length) }} {{
              $t('of') }}
            {{ items.length }}
          </div>

          <!-- Navigation Buttons -->
          <div class="d-flex justify-content-end">
            <button @click="goToFirstPage" :disabled="currentPage === 1" class="btn btn-light btn-sm">
              <i class="bi bi-chevron-double-left" :class="{'disabled': currentPage === 1}"></i>
            </button>
            <button @click="goToPreviousPage" :disabled="currentPage === 1" class="btn btn-light btn-sm ms-2">
              <i class="i bi-chevron-left" :class="{'disabled': currentPage === 1}"></i>
            </button>
            <button @click="goToNextPage" :disabled="currentPage === totalPages" class="btn btn-light btn-sm ms-2">
              <i class="bi bi-chevron-right" :class="{'disabled': currentPage === totalPages}"></i>
            </button>
            <button @click="goToLastPage" :disabled="currentPage === totalPages" class="btn btn-light btn-sm ms-2">
              <i class="bi bi-chevron-double-right" :class="{'disabled': currentPage === totalPages}"></i>
            </button>
          </div>
        </div>
      </div>

    </div>
    <!-- No data available message -->
    <div v-else id="message" class="row p-3">
      <div class="d-flex justify-content-center align-items-center">
        <h6 class="text-center text-muted m-0 fs-12">{{ $t('No data available') }}</h6>
      </div>
    </div>
  </section>
</template>

<script>
const IS_DESKTOP = 768;
const IS_MOBILE = 767;

export default {
  props: {
    loading: Boolean,
    modelValue: Array,
    layout: Object,
    tableHeaders: Array,
    tableRows: Array,
    tableExpandedRows: Array,
    inputValue: String,
    clickableRows: {
      type: Boolean,
      default: true
    },
    allowNewLine: {
      type: Boolean,
      default: false
    },
    allowDeleteLine: {
      type: Boolean,
      default: false
    },
    // Important to close the expanded rows when we change or use the filters
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      items: this.modelValue,
      currentPage: 1, // Start at page 1
      pageSize: 5, // Default rows per page
      rowsPerPageOptions: [5, 10, 15, 20], // Rows per page options
      expandedRows: [], // Track expanded rows
      newRowData: {}, // Stores dynamic form input values
      currentWidthScreen: window.innerWidth
    }
  },
  mounted() {
    // Add an event listener to track screen width changes
    window.addEventListener('resize', this.updateScreenWidth);
  },
  beforeUnmount() {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  computed: {
    // Check if the header should be shown
    shouldShowHeader() {
      return this.shouldShowProperty('hasHeader');
    },
    // Get expanded field
    expandField() {
      return this.tableExpandedRows && this.tableExpandedRows.length;
    },
    // Get the number of actions in the body
    actionsCount() {
      return this.tableRows.filter(column => column.hasHeader === false && column.type === 'Action').length;
    },
    // Get paginated items based on current page and rows per page
    paginatedItems() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.items.slice(start, end);
    },
    // Total number of pages
    totalPages() {
      return Math.ceil(this.items.length / this.pageSize);
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
    removeSymbols(value) {
      // Removes any character that is not a number, decimal point, or minus sign ($ € % for example) 
      return parseFloat(value?.replace(/[^0-9.-]/g, ''));
    },
    // Update the current screen width
    updateScreenWidth() {
      this.currentWidthScreen = window.innerWidth;
    },
    // Check if the current layout is desktop
    isDesktopLayout() {
      const windowWidth = this.currentWidthScreen;
      return windowWidth >= IS_DESKTOP;
    },
    // Check if the current layout is mobile
    isMobileLayout() {
      const windowWidth = this.currentWidthScreen;
      return windowWidth <= IS_MOBILE;
    },
    // Check if the property should be shown based on the layout
    shouldShowProperty(propertyName) {
      return (this.isDesktopLayout() && this.layout[propertyName]?.desktop) ||
        (this.isMobileLayout() && this.layout[propertyName]?.mobile);
    },
    // Handle row click (expand or emit rowClicked event)
    handleRowClick(index, item) {
      if (this.clickableRows && !item.isNotRowClick) {
        // console.log('clickableRows', this.clickableRows);
        // Check if the row is expandable
        if (this.expandField) {
          // If it is, toggle the row expansion
          this.toggleRowExpansion(index);
        } else { // If it's not, emit the rowClicked event
          // Calculate the actual index based on the current page and rows per page
          const actualIndex = (this.currentPage - 1) * this.pageSize + index;
          this.rowClicked(actualIndex);
        }
      }
    },
    // Toggle row expansion
    toggleRowExpansion(index) {
      // Check if the row is already expanded
      if (this.expandedRows.includes(index)) {
        // If it is, remove it from the expanded rows
        this.expandedRows = this.expandedRows.filter(i => i !== index);
      } else { // If it's not, add it to the expanded rows
        this.expandedRows.push(index);
      }
    },
    // Emit the rowClicked event
    rowClicked(index) {
      this.$emit('rowClicked', this.items[index]);
    },
    // Check if the row is expanded
    isRowExpanded(index) {
      return this.expandedRows.includes(index);
    },
    goToFirstPage() {
      this.currentPage = 1;
    },
    goToLastPage() {
      this.currentPage = this.totalPages;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    changeRowsPerPage() {
      this.currentPage = 1; // Reset to page 1 when rows per page changes
    },
    // Add a new expanded row based on dynamic user inputs
    addNewExpandedRow(index) {
      // Validate that all fields with allowAdd === true have been filled
      const isValid = this.validateNewRowData(index);

      if (!isValid) {
        return;
      }

      // Create a new row object based on the input data
      const newExpandedRow = { ...this.newRowData[index] };
      this.$emit('addExpandedRow', newExpandedRow, index); // Emit the addExpandedRow event with the new row data
      // Clear the input fields after adding the row for that specific index
      this.resetNewRowData(index);
    },
    // Validate the new row data for the specific index
    validateNewRowData(index) {
      const item = this.newRowData[index];
      
      // Filter only the fields with 'allowAdd' set to true
      const fieldsToValidate = this.tableExpandedRows.filter(field => field.allowAdd);
      
      // Check if any required field is empty
      for (const field of fieldsToValidate) {
        const fieldValue = item[field.field];
        
        // Check if the field is empty or contains only whitespace
        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === "")) {
          alert(this.$t('Please validate the input fields')); // Alert the user if any required fields are empty
          return false; // Return false if any required field is empty
        }
      }

      // Additional check for room nights vs inventory
      const roomNights = item.rn;
      const inventory = item.inventory;
      
      // Ensure that roomNights do not exceed inventory
      if (roomNights > inventory) {
        alert("Room nights sold cannot exceed the available inventory!");
        return false; // Return false if room nights exceed inventory
      }
      
      return true; // Return true if all required fields are filled and validation passes
    },
    // Reset the newRowData object for the specific index after adding a new row
    resetNewRowData(index) {
      // Reset all the dynamic fields back to empty strings for the specific row
      Object.keys(this.newRowData[index]).forEach(key => {
        this.newRowData[index][key] = '';
      });
    },
    // Method to remove an expanded row by index
    removeExpandedRow(rowIndex, expandedIndex) {
      if (this.items[rowIndex].expandedRows) {
        this.$emit('removeExpandedRow', rowIndex, expandedIndex); 
      }
    },
    formatImageData(imageDataString) {
      try {
        // Attempt to parse imageDataString as JSON
        const parsedData = JSON.parse(imageDataString);

        // Check if parsedData is a valid object, indicating successful JSON parsing
        if (typeof parsedData === 'object' && parsedData !== null) {
          // Construct a data URL from the mime type and base64 data
          return `data:${parsedData.mime};base64,${parsedData.data}`;
        }
      } catch (error) {
        // If JSON parsing fails, return the original string
        return imageDataString;
      }
    }
  },
  watch: {
    // Watch for changes in the modelValue prop
    modelValue(newValue) {
      // Emit the update:modelValue event with the new value
      this.$emit("update:modelValue", newValue);
      this.items = newValue;
    },
    // Watch for changes in tableExpandedRows and create dynamic fields accordingly
    tableExpandedRows: {
      handler(newVal) {
        if (!newVal) return; // Return if tableExpandedRows is not defined
        // Initialize newRowData with empty values for each index based on tableExpandedRows structure
        this.items.forEach((item, index) => {
          if (!this.newRowData[index]) {
            this.newRowData[index] = {};
          }

          newVal.forEach(field => {
            this.newRowData[index][field.field] = ''; // Initialize dynamic inputs for each row
          });
        });
      },
      immediate: true
    },
    currentPage() {
      this.expandedRows = []; // Clear all expanded rows when the page changes
    },
    filters: {
      handler(newVal) {
        if(Object.keys(newVal).length) {
          this.expandedRows = []; // close all expanded rows when filters are used
        }
      }, 
      deep: true
    }
  }
}
</script>

<style scoped>
#spinner-wrapper {
  height: calc(100vh - 18rem);
  min-height: calc(100vh - 18rem);
}

#message {
  min-height: calc(100vh - 35rem);
}

#table-header span {
  font-size: .9rem;
  font-weight: 700;
  color: #6C757D;
}

#table-body #table-row,
#table-body #expanded-row {
  box-shadow: 0px 4px 20px 0px #0000000D;
  position: relative;
}

#table-body #table-row .label,
#table-body #expanded-row .label {
  font-size: .9rem;
  font-weight: 500;
  color: #6C757D;
}

#table-body #table-row .bold-label,
#table-body #expanded-row .bold-label {
  font-size: .9rem;
  font-weight: 700;
  color: #212529;
}

#table-body #table-row:hover,
#table-body #expanded-row:hover {
  background-color: #0000000D;
}

.image-text img {
  width: 20px;
  object-fit: cover;
}

.icon img {
  width: 15px;
  object-fit: cover;
}

.image-text .first-label {
  color: #6C757D;
  font-size: .9rem;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  margin-left: 1rem;
}

.image-text .second-label {
  color: var(--gray-600);
  font-size: .8rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-left: 1rem;
}

/* Positive & Negative Graph Styles */
.positive-graph {
  background-color: #D1E7DD;
}

.negative-graph {
  background-color: #F8D7DA;
}

.neutral-graph {
  background-color: #E9ECEF;
}

.positive-value {
  color: #006837;
  font-size: .8rem;
  font-weight: 600;
}

.negative-value {
  color: #E53935;
  font-size: .8rem;
  font-weight: 600;
}

.neutral-value {
  color: #6C757D;
  font-size: .8rem;
  font-weight: 600;
}

/* Pagination Controls */
#pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
}

#items-per-page {
  white-space: nowrap;
  /* Prevents line breaks */
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

#range-items,
#items-per-page label {
  font-size: .9rem;
  font-weight: 500;
  color: #6C757D;
}

input,
input::placeholder {
  font-size: .7rem;
}

#new-line{
  background-color: var(--theme-secondary);
}

#remove-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.fs-12 {
  font-size: 12px;
}
@media (max-width: 1320px) {
  .positive-value,
  .negative-value,
  .neutral-value {
    font-size: .6rem;
  }
  #table-header,
  #pagination-controls,
  #table-body {
    min-width: 900px;
  }
  #table-wrapper {
    overflow-x: auto;
  }
}
@media (max-width: 575px) {
  #table-header span,
  #table-body #table-row .label,
  #table-body #expanded-row .label,
  #table-body #table-row .bold-label,
  #table-body #expanded-row .bold-label,
  input,
  input::placeholder,
  #range-items,
  #items-per-page label,
  .image-text .first-label,
  .image-text .second-label,
  .form-select-sm {
    font-size: .5rem;
  }
  .positive-value,
  .negative-value,
  .neutral-value {
    font-size: .3rem;
  }
}
</style>