<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex align-items-center gap-3 flex-wrap">
          <BoldTitle :title="$t('groupDisplacement')"/>
          <div>
              <DropdownButton :label="$t('Crystal Breeze Resort')" @clickBtn="toggleDropdown('hotel')" :icon="require('@/assets/Market/hotel-icon.svg')"/>    
              <CheckboxList :isOpen="isDropdownHotelOpen" @close-dropdown="isDropdownHotelOpen = false"
                  :customClass="['hotel-dropdown-menu']" :items="dropdownHotelItems"
                  @item-click="handleCheckboxClick($event, 'dropdownHotelItems')" :avatar="true">
              </CheckboxList>
          </div>
        </div>
        <div class="container-wrapper p-0 mt-5">
          <div class="row p-4">
            <div class="col-12 d-flex align-items-center gap-2">
              <img src="@/assets/GroupDisplacement/icon-title.svg" alt="">
              <span class="title-container-wrapper">{{ $t('groupDisplacement') }}</span>
            </div>
          </div>
          <DividerComponent />
          <div class="row p-4" v-if="showSummary == false">
            <div class="col-12">
              <BoldTitle :icon="require('@/assets/GroupDisplacement/group-info-icon.svg')" :title="$t('groupInfo')"/>
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('checkInDate') }}</label>
              </div>
              <DateButton class="w-100 d-flex" @update-date="updateDateProperty('evolutionChartDate', $event)" typeForm="1"
                :label="$t('checkInDate')" 
              />
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('checkOutDate') }}</label>
              </div>
              <DateButton class="w-100 d-flex" @update-date="updateDateProperty('evolutionChartDate', $event)" typeForm="1"
                :label="$t('checkOutDate')" 
              />
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('groupRooms') }}</label>
              </div>
              <SingleOptionDropdown :options="types" :title="$t('groupRooms')" :index="1"></SingleOptionDropdown>
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('roomType') }}</label>
              </div>
              <SingleOptionDropdown :options="types" :title="$t('roomType')" :index="1"></SingleOptionDropdown>
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('mealCost') }}</label>
              </div>
              <input class="form-control" type="text" placeholder="0,00€">
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('comission') }}</label>
              </div>
              <input class="form-control" type="text" placeholder="0%">
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('groupAncillary') }}</label>
              </div>
              <input class="form-control" type="text" placeholder="0,00€">
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="d-flex flex-row">
                <label>{{ $t('groupRate') }}</label>
              </div>
              <input class="form-control" type="text" placeholder="0,00€">
            </div>
            <div class="col-12 mt-5" id="btn-div">
              <CustomButton 
                buttonType="primary" 
                :label="$t('submit')" @clickBtn="showSummary = true" 
                :leftIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                :buttonStyle="{
                    borderRadius: '8px!important',
                    gap: '8px',
                    color: '#FFFFFF',
                    padding: '6px 35px'
                }"
              />
            </div>
          </div>
          <div v-else>
            <div class="container-wrapper p-1 m-4">
              <div class="row">
                <div class="col-12 p-4">
                  <BoldTitle :icon="require('@/assets/GroupDisplacement/group-info-icon.svg')" :title="$t('summary')"/>
                </div>
              </div>
              <DividerComponent />
              <div class="row py-4">
                <div class="col-12 col-md-4 text-center"><span class="sub-title-table">{{ $t('category') }}</span></div>
                <div class="col-12 col-md-4 text-center"><span class="sub-title-table">{{ $t('generatedByRequest') }}</span></div>
                <div class="col-12 col-md-4 text-center"><span class="sub-title-table">{{ $t('displacedByRequest') }}</span></div>
              </div>
              <DividerComponent />
              <div class="row-wrapper" v-for="(tableInfo, index) in tableItems" :key="index">
                <div class="col-12 col-md-4 p-4 text-center">
                  <span class="category-style">{{ tableInfo.category }}</span>
                </div>
                <div class="col-12 col-md-4 p-4 text-center">
                  <span class="numbers-style">{{ tableInfo.generatedBy }}</span>
                </div>
                <div class="col-12 col-md-4 p-4 text-center">
                  <span class="numbers-style">{{ tableInfo.displacedBy }}</span>
                </div>
              </div>
              <div class="row" id="row-final-math">
                <div class="col-12 my-2" id="incrementals-div" v-for="(IncrementalValue, index) in IncrementalValues" :key="index">
                  <span class="category-style">{{IncrementalValue.category}}</span>
                  <span class="category-style">{{IncrementalValue.value}}</span>
                </div>
              </div>
              <div class="row" id="row-feedback">
                <div class="col-12 gap-2" id="col-feedback">
                  <img src="@/assets/GroupDisplacement/accept-icon.svg" alt="">
                  <span id="feedback-span">Accept: Recomendation based on displaced Revenue</span>
                </div>
              </div>
              <div id="container-btn-style">
                <CustomButton 
                  buttonType="primary"
                  :label="$t('save')" @clickBtn="showSummary = true" 
                  :leftIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                  :buttonStyle="{
                      borderRadius: '8px!important',
                      gap: '8px',
                      color: '#FFFFFF',
                      padding: '6px 35px'
                  }"
                />
                <CustomButton 
                  buttonType="theme-light" 
                  :label="$t('changeInfo')" @clickBtn="showSummary = false" 
                  :leftIcon="require('@/assets/GroupDisplacement/changeInfo.svg')" 
                  :buttonStyle="{
                      borderRadius: '8px!important',
                      gap: '8px',
                      padding: '6px 35px'
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoldTitle from "@/components/BoldTitle";
import DividerComponent from "@/components/DividerComponent";
import DropdownButton from "@/components/Dropdown/DropdownButton";
import CheckboxList from "@/components/CheckboxList";
import DateButton from "@/components/DateButton";
import SingleOptionDropdown from "@/components/SingleOptionDropdown";
import CustomButton from "@/components/CustomButton";
export default {
  name: "GroupDisplacement",
  components: {
    BoldTitle,
    DividerComponent,
    DropdownButton,
    CheckboxList,
    DateButton,
    SingleOptionDropdown,
    CustomButton
  },
  data() {
    return {
      showSummary: false,
      isDropdownHotelOpen: false,
      tableItems: [
        {
            id: 0,
            category: "Rooms",
            generatedBy: '150',
            displacedBy: '100'
        },
        {
            id: 1,
            category: "ADR",
            generatedBy: '245,00',
            displacedBy: '245,00'
        },
        {
            id: 2,
            category: "Room Revenue",
            generatedBy: '37500',
            displacedBy: '26950'
        },
        {
            id: 3,
            category: "Other Revenue",
            generatedBy: '0',
            displacedBy: '11000'
        },
      ],
      IncrementalValues: [
        {
            id: 0,
            category: "Incremental Room Revenue",
            value: '10550,00',
        },
        {
            id: 1,
            category: "Incremental Total Revenue",
            value: '-450,00',
        },
        {
            id: 2,
            category: "Break Even Rate",
            value: '253,00',
        },
      ],
      dropdownHotelItems: [
          {
              id: 0,
              label: "Cystal Breeze Resort California 1 ",
              avatar: require('@/assets/avatar.png')
          },
          {
              id: 1,
              label: "Cystal Breeze Resort California 2",
              avatar: require('@/assets/avatar.png')
          }
      ],
      types: [{label: this.$t("categoria1"), key: "categoria1"}, {label: this.$t("categoria2"), key: "categoria2"}],
    }
  },
  methods: {
    toggleDropdown(dropdownName) {
      this.isDropdownPickupOpen = dropdownName === "pickup" && !this.isDropdownPickupOpen;
      this.isDropdownMetricsOpen = dropdownName === "metrics" && !this.isDropdownMetricsOpen;
      this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
      this.isDropdownWebsiteOpen = dropdownName === "website" && !this.isDropdownWebsiteOpen;
      this.isDropdownLocationOpen = dropdownName === "location" && !this.isDropdownLocationOpen;
    },
    handleCheckboxClick(index, propertyName) {
      const selectedItem = this[propertyName][index];
      selectedItem.checked = !selectedItem.checked;

      // Check if the item is already in the selectedHotelItems array
      const selectedIndex = this.selectedHotelItems.findIndex(item => item.id === selectedItem.id);

      if (selectedItem.checked && selectedIndex === -1) {
          // If the item is selected and not in the array, add it
          this.selectedHotelItems.push(selectedItem);
      } else if (!selectedItem.checked && selectedIndex !== -1) {
          // If the item is deselected and in the array, remove it
          this.selectedHotelItems.splice(selectedIndex, 1);
      }
    },
    updateDateProperty(propertyName, newValue) {
      this[propertyName] = newValue;
    },
  },

}
</script>

<style scoped>
.container-wrapper {
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.row-wrapper {
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    margin: 1rem;
}
.hotel-dropdown-menu {
    min-width: 450px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 4px 0px #00000013;
    left: 200px;
    top: 100px;
}
#btn-div{
    display: flex;
    justify-content: flex-end;
}
.sub-title-table {
    font-size: 14px;
    font-weight: 700;
    color: #6C757D;
}
.category-style {
    font-size: 16px;
    font-weight: 700;
    color: #6C757D;
}
.numbers-style {
    font-size: 14px;
    font-weight: 700;
    color: #6C757D;
}
.title-container-wrapper {
  color: var(--Theme-Secondary, #6C757D);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
#feedback-span {
    font-size: 14px;
    font-weight: 700;
    color: white;
}
#row-final-math {
    background-color: #F8F9FA;
    padding: 1rem;
    margin: 1rem;
}
#incrementals-div {
    display: flex;
    justify-content: space-between;
}
#row-feedback {
  background-color: #198754;
  display: flex;
  margin: 1rem;
  padding: 2rem 0;
}
#col-feedback {
  display: flex;
  justify-content: center;
}
#container-btn-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem;
}
@media screen and (max-width: 991px){
  #btn-div{
    justify-content: center;
  }
  #container-btn-style {
    display: flex;
    justify-content: center;
  }
}
</style>