<template>
  <section>
    <div class="col-12 col-md-6 p-4">
      <BoldTitle class="cursor-pointer" :icon="require('@/assets/HotelGroup/backBtn.svg')" :title="$t('allNotifications')" @click="sendToHome"/>
    </div>
    <LayoutWrapper>
      <TableComponent 
        v-model="formatedItems" 
        :layout="{
          hasHeader: { desktop: false, mobile: false }
        }" 
        :tableHeaders="tableHeaders" 
        :tableRows="tableRows"
      >
        <template v-slot:title>
          <div class="row d-flex flex-row pt-5">
            <div class="col-12 d-flex px-5 flex-row gap-2 align-items-center">
              <img id="notificationBell" src="@/assets/Header/notificationBell.svg" alt="">
              <span>{{$t("notifications")}}</span>
            </div>
            <!-- <div class="col-12 col-md-6 d-flex flex-row gap-3">
              <div class="d-flex align-items-center gap-2 cursor-pointer" @click="showRead = false">
                <img v-if="showRead == false" src="@/assets/NotificationsMobile/readedMessages.svg" alt="">
                <img v-if="showRead == true" src="@/assets/NotificationsMobile/unreadedMessages.svg" alt="">
                <span>Unread</span>
              </div>
              <div class="d-flex align-items-center gap-2 cursor-pointer" @click="showRead = true">
                <img v-if="showRead == true" src="@/assets/NotificationsMobile/readedMessages.svg" alt="">
                <img v-if="showRead == false" src="@/assets/NotificationsMobile/unreadedMessages.svg" alt="">
                <span>Read</span>
              </div>
            </div> -->
          </div>
        </template>
        <template v-slot:actions="{ item }">
          <div class="d-flex justify-content-end d-none d-md-flex px-5">
            <router-link :to="{ name: 'DashboardPage', query: { id: item.id } }">
              <img class="editDots" src="@/assets/HotelGroup/editDots.svg" alt="">
            </router-link>
          </div>
        </template>
      </TableComponent>
    </LayoutWrapper>
  </section>
</template>

<script>
import LayoutWrapper from '@/components/LayoutWrapper.vue';
import TableComponent from '@/components/TableComponent.vue';
import BoldTitle from '@/components/BoldTitle.vue';

export default {
  components: {
    LayoutWrapper,
    TableComponent,
    BoldTitle
  },
  data() {
    return {
      showRead: false,
      formatedItems: [],
      tableHeaders: [
        {
          icon: require('@/assets/HotelGroup/userManageIcon.svg'),
          text: 'Notifications'
        },
      ],
      tableRows: [
        { type: 'ImageWithText', field: 'name' },
      ],
      actionTranslations: {
        'user.login': 'user.login',
        'user.update': 'user.update',
        'user.update.password': 'user.update.password',
        'company.create': 'company.create',
        'company.active.read': 'company.active.read',
        'company.active.update': 'company.active.update',
        'company.active.delete': 'company.active.delete',
        'company.active.switch': 'company.active.switch',
        'company.read': 'company.read',
        'company.users.read': 'company.users.read',
        'company.user.read': 'company.user.read',
        'company.user.create': 'company.user.create',
        'company.user.update': 'company.user.update',
        'company.user.delete': 'company.user.delete',
        'hotels.read': 'hotels.read',
        'hotel.read': 'hotel.read',
        'hotel.create': 'hotel.create',
        'hotel.update': 'hotel.update',
        'hotel.delete': 'hotel.delete',
        'hotel.groups.read': 'hotel.groups.read',
        'hotel.group.read': 'hotel.group.read',
        'hotel.group.create': 'hotel.group.create',
        'hotel.group.update': 'hotel.group.update',
        'hotel.group.delete': 'hotel.group.delete',
        'hotel.group.hotel.delete': 'hotel.group.hotel.delete'
      }
    }
  },
  props: {
    itemsFromApi: {
      type: Object,
      required: true
    }
  },
  watch: {
    itemsFromApi: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.data) {
          this.formatedItems = newVal.data.map(item => {
            const actionTranslationKey = this.actionTranslations[item.action] || item.action;
            return {
              id: item.userId,
              name: {
                image: item.user && item.user.picture ? item.user.picture : require('@/assets/HotelGroup/avatar.svg'),
                firstText: item.user ? `${item.user.name} ${this.$t('performed')} ${this.$t(actionTranslationKey)}` : 'Unknown user',
                secondText: this.timeDifference(item.createdAt)
              }
            };
          });
        }
      }
    }
  },
  methods: {
    sendToHome() {
      this.$router.push({ name: 'DashboardPage' });
    },
    timeDifference(timestamp) {
      const now = new Date();
      const createdAt = new Date(timestamp);
      const diffInSeconds = Math.floor((now - createdAt) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays > 0) {
        return `${diffInDays} ${this.$t('daysAgo')}`;
      } else if (diffInHours > 0) {
        return `${diffInHours} ${this.$t('hoursAgo')}`;
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes} ${this.$t('minutesAgo')}`;
      } else {
        return `${diffInSeconds} ${this.$t('secondsAgo')}`;
      }
    }
  }
}
</script>

<style scoped>
#notificationBell {
  width: 15px;
  height: 15px;
}
</style>