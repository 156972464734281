<template>
    <a class="nav-link" role="button" id="icon-title-button" @click.stop="$emit('toggleDropdown')">
        <img :src="firstIcon" width="20" height="20" alt="menu-hamburger">
        <span class="label">{{label}}</span>
        <img v-if="secondIcon" :src="secondIcon" alt="chevron-right">
    </a>
</template>

<script>
    export default {
        props: {
            firstIcon: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            secondIcon: String // optional
        },
        
    }
</script>

<style scoped>
#icon-title-button {
    display: flex;
    gap: .5rem;
    align-items: center;
}
#icon-title-button .label {
    font-size: 18px;
    font-weight: 700;
    color: var(--theme-secondary);
    line-height: 18px;
}
</style>