
import { createRouter, createWebHistory } from 'vue-router'

import AppHeader from "@/layouts/AppHeader.vue";
import AppFooter from "@/layouts/AppFooter.vue";

import MarketAnalysis from "@/views/MarketAnalysis.vue";
import DemandPage from "@/views/Market/DemandPage.vue";
import EventsPage from "@/views/Market/EventsPage.vue";
import InsightsPage from "@/views/Market/InsightsPage.vue";
import OnlineDistribution from "@/views/Market/OnlineDistribution.vue";
import OnlineReputation from "@/views/Market/OnlineReputation.vue";
import SmartPricing from "@/views/SmartPricing.vue";
import SearchMobile from "@/views/SearchMobile.vue";
import NotificationsPage from "@/views/NotificationsPage.vue";
import MeteorologyPage from "@/views/MeteorologyPage.vue";

// authentication pages
import SignUp from "@/views/Authentication/SignUp";
import VerificationCode from "@/views/Authentication/VerificationCode";
import SecretCode from "@/views/Authentication/SecretCode";
import CompleteProfile from "@/views/Authentication/CompleteProfile";
import SignIn from "@/views/Authentication/SignIn.vue";
import CreateNewPassword from "@/views/Authentication/CreateNewPassword.vue";
import LocationCode from "@/views/Authentication/LocationCode.vue";
// dashboard page
import DashboardPage from "@/views/DashboardPage.vue";
// chain view pages
import ChainViewPage from "@/views/ChainView/ChainViewPage.vue";
import ChainComponent from "@/components/ChainView/ChainComponent.vue";
// pickup pages
import PickUpPage from "@/views/PickUp/PickUpPage.vue";
import GeneralComponent from '@/components/PickUp/GeneralComponent.vue';
import SegmentedComponent from '@/components/PickUp/SegmentedComponent.vue';

// competitors analysis
import CompetitorsAnalysis from "@/views/CompetitorAnalysis/CompetitorAnalysisPage.vue";
// forecast pages
import ForecastPage from "@/views/Forecast/ForecastPage.vue";
import GeneralForecastComponent from '@/components/Forecast/GeneralForecastComponent.vue';
import SegmentedForecastComponent from '@/components/Forecast/SegmentedForecastComponent.vue';

// budeget analysis page
import BudgetAnalysis from "@/views/Budget/BudgetAnalysisPage.vue";
// group displacement page
import GroupDisplacement from "@/views/GroupDisplacement/GroupDisplacementPage.vue";
// company settings pages
import CompanySettingsPage from "@/views/CompanySettings/CompanySettingsPage.vue";
import NewCompanyPage from "@/views/CompanySettings/children/NewCompanyPage";
import CompanyActivityPage from "@/views/CompanySettings/children/CompanyActivityPage";
import CompanyUsersList from "@/views/CompanySettings/children/CompanyUsers/CompanyUsersList.vue";
// hotel group pages
import HotelGroupAddPage from "@/views/CompanySettings/children/HotelGroup/Add/HotelGroupAddPage.vue";
import HotelGroupEditPage from "@/views/CompanySettings/children/HotelGroup/Edit/HotelGroupEditPage.vue";
import HotelGroupUsersList from "@/views/CompanySettings/children/HotelGroup/Edit/children/HotelGroupUsersList.vue";
import HotelGroupUserEdit from "@/views/CompanySettings/children/HotelGroup/Edit/children/children/HotelGroupUserEdit.vue";

// hotel pages
import HotelAddPage from "@/views/CompanySettings/children/Hotel/HotelAddPage.vue";
import HotelEditPage from "@/views/CompanySettings/children/Hotel/Edit/HotelEditPage.vue";
import HotelUsersList from "@/views/CompanySettings/children/Hotel/Edit/children/HotelUsersList.vue";
import HotelUserEdit from "@/views/CompanySettings/children/Hotel/Edit/children/children/HotelUserEdit.vue";

// company users pages
import CompanyUserEdit from "@/views/CompanySettings/children/CompanyUsers/children/CompanyUserEdit.vue";
import CompanyUserActivity from "@/views/CompanySettings/children/CompanyUsers/children/children/CompanyUserActivity.vue";

// report pages
import ReportsPage from "@/views/Reports/ReportsPage.vue";
import CreateReportPage from "@/views/Reports/children/CreateReportPage.vue";
// integrations page
import IntegrationsPage from "@/views/Integrations/IntegrationsPage.vue"; 
import IntegrationPage from "@/views/Integrations/children/IntegrationPage.vue";
// My Account pages
import AccountSettingsPage from "@/views/MyAccount/AccountSettings/AccountSettingsPage.vue";
import MyAccountMobile from "@/views/MyAccount/Mobile/MyAccountMobile.vue";
import BillingPage from "@/views/MyAccount/Billing/BillingPage.vue";
import UpgradePlanPage from "@/views/MyAccount/Billing/children/UpgradePlanPage.vue";
import PaymentMethodPage from "@/views/MyAccount/Billing/children/PaymentMethodPage.vue";
import BillingHistoryPage from "@/views/MyAccount/Billing/children/BillingHistoryPage.vue";
import BillingAddressPage from "@/views/MyAccount/Billing/children/BillingAddressPage.vue";
// Launching Soon page
import LaunchingSoonPage from "@/views/LaunchingSoonPage.vue";

// icons for breadcrumbs (header navigation)
import dashboardIcon from '@/assets/sideBar/dashboard.svg';
import ChainViewIcon from '@/assets/sideBar/chain-view.svg';
import RoomNightsIcon from '@/assets/Breadcrumbs/room-nights.svg';
import OccupancyIcon from '@/assets/Breadcrumbs/occupancy.svg';
import RevenueIcon from '@/assets/Breadcrumbs/revenue.svg';
import AdrIcon from '@/assets/Breadcrumbs/adr.svg';
import RevParIcon from '@/assets/Breadcrumbs/rev-par.svg';
import allIcon from '@/assets/Breadcrumbs/collection-fill.svg';
import PickupIcon from '@/assets/sideBar/pickup.svg';
import CompetitorsIcon from '@/assets/Breadcrumbs/competitors.svg';
import ForecastIcon from '@/assets/Breadcrumbs/forecast.svg';
import BudgetIcon from '@/assets/Breadcrumbs/budget.svg';
import GroupDisplacementIcon from '@/assets/Breadcrumbs/group-displacement.svg';
import CompanySettingsIcon from '@/assets/Breadcrumbs/company-settings.svg';
import ReportsIcon from '@/assets/Breadcrumbs/reports.svg';
import NewIcon from '@/assets/Breadcrumbs/new.svg';
import IntegrationsIcon from '@/assets/Breadcrumbs/integrations.svg';
import MyAccountIcon from '@/assets/Breadcrumbs/my-account.svg';
import UpgradePlanIcon from '@/assets/Breadcrumbs/upgrade-plan.svg';
import BillingAddressIcon from '@/assets/Breadcrumbs/billing-address.svg';
import BillingHistoryIcon from '@/assets/Breadcrumbs/billing-history.svg';
import LaunchingSoonIcon from '@/assets/Breadcrumbs/launching-soon.svg';
import CompanyActivityIcon from '@/assets/Breadcrumbs/company-activity.svg';
import Pencil from '@/assets/Breadcrumbs/pencil.svg';
import Calendar from '@/assets/Breadcrumbs/calendar.svg';
import InvitationsPage from "@/views/InvitationsPage.vue";

const routes = [
  // authentication pages
  {
    path: "/sign-up",
    alias: "/sign-up",
    name: 'SignUp',
    components: {
      default: SignUp,
    }
  },
  {
    path: "/verification-code",
    alias: "/verification-code",
    name: 'VerificationCode',
    components: {
      default: VerificationCode,
    }
  },
  {
    path: "/sign-in-secret",
    alias: "/sign-in-secret",
    name: 'SecretCode',
    components: {
      default: SecretCode,
    }
  },
  {
    path: "/complete-profile",
    alias: "/complete-profile",
    name: 'CompleteProfile',
    components: {
      default: CompleteProfile,
    }
  },
  {
    path: "/sign-in",
    alias: "/sign-in",
    name: 'SignIn',
    components: {
      default: SignIn,
    }
  },
  {
    path: "/create-new-password",
    alias: "/create-new-password",
    name: 'CreateNewPassword',
    components: {
      default: CreateNewPassword,
    }
  },
  {
    path: "/location-code",
    alias: "/location-code",
    name: 'LocationCode',
    components: {
      default: LocationCode,
    }
  },
  // dashboard page
  {
    path: "/",
    name: "DashboardPage",
    components: {
      header: AppHeader,
      default: DashboardPage,
      footer: AppFooter
    },
    meta: {
      title: 'Dashboard',
      icon: dashboardIcon
    }
  },
  // chain view pages
  {
    path: "/chain-view",
    redirect: "/launching-soon", // temporary redirect 
    // redirect: "/chain-view/room-nights",
    name: "ChainViewPage",
    components: {
      header: AppHeader,
      default: ChainViewPage,
      footer: AppFooter
    },
    meta: {
      title: "Chain View",
      icon: ChainViewIcon
    },
    children: [
      {
        path: "room-nights",
        name: "ChainRoomNights",
        component: ChainComponent,
        props: {
          metric: 'RoomNights'
        },
        meta: {
          title: "Room Nights",
          icon: RoomNightsIcon
        }
      },
      {
        path: "occupancy",
        name: "ChainOccupancy",
        component: ChainComponent,
        props: {
          metric: 'Occupancy'
        },
        meta: {
          title: "Occupancy",
          icon: OccupancyIcon
        }
      },
      {
        path: "revenue",
        name: "ChainRevenue",
        component: ChainComponent,
        props: {
          metric: 'Revenue'
        },
        meta: {
          title: "Revenue",
          icon: RevenueIcon
        }
      },
      {
        path: "adr",
        name: "ChainAdr",
        component: ChainComponent,
        props: {
          metric: 'Adr'
        },
        meta: {
          title: "ADR",
          icon: AdrIcon
        }
      },
      {
        path: "rev-par",
        name: "ChainRevPar",
        component: ChainComponent,
        props: {
          metric: 'RevPAR'
        },
        meta: {
          title: "RevPAR",
          icon: RevParIcon
        }
      },
      {
        path: "all",
        name: "ChainAll",
        component: ChainComponent,
        props: {
          metric: 'All'
        },
        meta: {
          title: "All",
          icon: allIcon
        }
      }
    ]
  },  
  // pick up pages
  {
    path: "/pick-up",
    redirect: "/pick-up/general-pick-up/room-nights",
    name: 'PickUpPage',
    components: {
      header: AppHeader,
      default: PickUpPage,
      footer: AppFooter,
    },
    meta: {
      title: 'Pick Up',
      icon: PickupIcon,
    },
    children: [
      {
        path: "general-pick-up/room-nights",
        name: "GeneralPickUpRoomNights",
        component: GeneralComponent,
        props: {
          metric: 'RoomNights'
        },
        meta: {
          title: "General Pickup / Room Nights",
          icon: RoomNightsIcon,
        },
      },
      {
        path: "general-pick-up/occupancy",
        name: "GeneralPickUpOccupancy",
        component: GeneralComponent,
        props: {
          metric: 'Occupancy'
        },
        meta: {
          title: "General Pickup / Occupancy",
          icon: OccupancyIcon
        }
      },
      {
        path: "general-pick-up/revenue",
        name: "GeneralPickUpRevenue",
        component: GeneralComponent,
        props: {
          metric: 'Revenue'
        },
        meta: {
          title: "General Pickup / Revenue",
          icon: RevenueIcon
        }
      },
      {
        path: "general-pick-up/adr",
        name: "GeneralPickUpAdr",
        component: GeneralComponent,
        props: {
          metric: 'Adr'
        },
        meta: {
          title: "General Pickup / ADR",
          icon: AdrIcon
        }
      },
      {
        path: "general-pick-up/rev-par",
        name: "GeneralPickUpRevPar",
        component: GeneralComponent,
        props: {
          metric: 'RevPAR'
        },
        meta: {
          title: "General Pickup / RevPAR",
          icon: RevParIcon
        }
      },
      {
        path: "general-pick-up/all",
        name: "GeneralPickUpAll",
        component: GeneralComponent,
        props: {
          metric: 'All'
        },
        meta: {
          title: "General Pickup / All",
          icon: allIcon
        }
      },
      {
        path: "segmented-pick-up/room-nights",
        name: "SegmentedPickUpRoomNights",
        component: SegmentedComponent,
        props: {
          metric: 'RoomNights'
        },
        meta: {
          title: "Segmented Pickup / Room Nights",
          icon: RoomNightsIcon,
        },
      },
      {
        path: "segmented-pick-up/occupancy",
        name: "SegmentedPickUpOccupancy",
        component: SegmentedComponent,
        props: {
          metric: 'Occupancy'
        },
        meta: {
          title: "Segmented Pickup / Occupancy",
          icon: OccupancyIcon
        }
      },
      {
        path: "segmented-pick-up/revenue",
        name: "SegmentedPickUpRevenue",
        component: SegmentedComponent,
        props: {
          metric: 'Revenue'
        },
        meta: {
          title: "Segmented Pickup / Revenue",
          icon: RevenueIcon
        }
      },
      {
        path: "segmented-pick-up/adr",
        name: "SegmentedPickUpAdr",
        component: SegmentedComponent,
        props: {
          metric: 'Adr'
        },
        meta: {
          title: "Segmented Pickup / ADR",
          icon: AdrIcon
        }
      },
      {
        path: "segmented-pick-up/rev-par",
        name: "SegmentedPickUpRevPar",
        component: SegmentedComponent,
        props: {
          metric: 'RevPAR'
        },
        meta: {
          title: "Segmented Pickup / RevPAR",
          icon: RevParIcon
        }
      },
      {
        path: "segmented-pick-up/all",
        name: "SegmentedPickUpAll",
        component: SegmentedComponent,
        props: {
          metric: 'All'
        },
        meta: {
          title: "Segmented Pickup / All",
          icon: allIcon
        }
      },
    ]
  },
  // competitors page
  {
    path: "/competitors",
    name: 'CompetitorsAnalysis',
    components: {
      header: AppHeader,
      default: CompetitorsAnalysis,
      footer: AppFooter
    },
    meta: {
      title: 'Competitors',
      icon: CompetitorsIcon,
    }
  },
  // forecast pages
  {
    path: "/forecast",
    redirect: "/launching-soon", // temporary redirect 
    // redirect: "/forecast/general-forecast/room-nights",
    name: 'ForecastPage',
    components: {
      header: AppHeader,
      default: ForecastPage,
      footer: AppFooter
    },
    meta: {
      title: 'Forecast',
      icon: ForecastIcon,
    },
    children: [
      {
        path: "general-forecast/room-nights",
        name: "GeneralForecastRoomNights",
        component: GeneralForecastComponent,
        props: {
          metric: 'RoomNights'
        },
        meta: {
          title: "General Forecast / Room Nights",
          icon: RoomNightsIcon,
        },
      },
      {
        path: "general-forecast/occupancy",
        name: "GeneralForecastOccupancy",
        component: GeneralForecastComponent,
        props: {
          metric: 'Occupancy'
        },
        meta: {
          title: "General Forecast / Occupancy",
          icon: OccupancyIcon
        }
      },
      {
        path: "general-forecast/revenue",
        name: "GeneralForecastRevenue",
        component: GeneralForecastComponent,
        props: {
          metric: 'Revenue'
        },
        meta: {
          title: "General Forecast / Revenue",
          icon: RevenueIcon
        }
      },
      {
        path: "general-forecast/adr",
        name: "GeneralForecastAdr",
        component: GeneralForecastComponent,
        props: {
          metric: 'Adr'
        },
        meta: {
          title: "General Forecast / ADR",
          icon: AdrIcon
        }
      },
      {
        path: "general-forecast/rev-par",
        name: "GeneralForecastRevPar",
        component: GeneralForecastComponent,
        props: {
          metric: 'RevPAR'
        },
        meta: {
          title: "General Forecast / RevPAR",
          icon: RevParIcon
        }
      },
      {
        path: "general-forecast/all",
        name: "GeneralForecastAll",
        component: GeneralForecastComponent,
        props: {
          metric: 'All'
        },
        meta: {
          title: "General Forecast / All",
          icon: allIcon
        }
      },
      {
        path: "segmented-forecast/room-nights",
        name: "SegmentedForecastRoomNights",
        component: SegmentedForecastComponent,
        props: {
          metric: 'SegmentedRoomNights'
        },
        meta: {
          title: "Segmented Forecast / Room Nights",
          icon: RoomNightsIcon
        }
      },
      {
        path: "segmented-forecast/occupancy",
        name: "SegmentedForecastOccupancy",
        component: SegmentedForecastComponent,
        props: {
          metric: 'SegmentedOccupancy'
        },
        meta: {
          title: "Segmented Forecast / Occupancy",
          icon: OccupancyIcon
        }
      },
      {
        path: "segmented-forecast/revenue",
        name: "SegmentedForecastRevenue",
        component: SegmentedForecastComponent,
        props: {
          metric: 'SegmentedRevenue'
        },
        meta: {
          title: "Segmented Forecast / Revenue",
          icon: RevenueIcon
        }
      },
      {
        path: "segmented-forecast/adr",
        name: "SegmentedForecastAdr",
        component: SegmentedForecastComponent,
        props: {
          metric: 'SegmentedADR'
        },
        meta: {
          title: "Segmented Forecast / ADR",
          icon: AdrIcon
        }
      },
      {
        path: "segmented-forecast/rev-par",
        name: "SegmentedForecastRevPar",
        component: SegmentedForecastComponent,
        props: {
          metric: 'SegmentedRevPAR'
        },
        meta: {
          title: "Segmented Forecast / RevPAR",
          icon: RevParIcon
        }
      },
      {
        path: "segmented-forecast/all",
        name: "SegmentedForecastAll",
        props: {
          metric: 'SegmentedAll'
        },
        component: SegmentedForecastComponent,
        meta: {
          title: "Segmented Forecast / All",
          icon: allIcon
        }
      }
    ]
  },
  // budget analysis page
  {
    path: "/budget",
    name: 'BudgetAnalysis',
    components: {
      header: AppHeader,
      default: BudgetAnalysis,
      footer: AppFooter
    },
    meta: {
      title: 'Budget',
      icon: BudgetIcon,
    }
  },
  // group displacement not yet completed (pages need to be revised)
  {
    path: "/group-displacement",
    redirect: "/launching-soon", // temporary redirect 
    name: 'GroupDisplacement',
    components: {
      header: AppHeader,
      default: GroupDisplacement,
      footer: AppFooter
    },
    meta: {
      title: 'Group Displacement',
      icon: GroupDisplacementIcon
    }
  },
  // company settings not yet completed (pages need to be revised)
  {
    path: "/company-settings",
    name: 'CompanySettingsPage',
    components: {
      header: AppHeader,
      default: CompanySettingsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Company Settings',
      icon: CompanySettingsIcon
    },
    children: [
      {
        path: 'new-company',
        name: 'NewCompanyPage',
        component: NewCompanyPage,
        meta: {
          title: 'New Company',
          icon: NewIcon
        }
      },
      {
        path: 'company-activity',
        name: 'CompanyActivityPage',
        component: CompanyActivityPage,
        meta: {
          title: 'Company Activity',
          icon: CompanyActivityIcon
        }
      },
      {
        path: 'hotel-group-create',
        name: 'HotelGroupAddPage',
        component: HotelGroupAddPage,
        meta: {
          title: 'New Hotel Group',
          icon: NewIcon
        }
      },
      {
        path: 'hotel-groups-edit/:hotelGroupId',
        name: 'HotelGroupEditPage',
        component: HotelGroupEditPage,
        meta: {
          title: 'Edit Hotel Group',
          icon: Pencil
        },
        children: [
          {
            path: 'users',
            name: 'HotelGroupUsersList',
            component: HotelGroupUsersList,
            meta: {
              title: 'Users',
              icon: MyAccountIcon
            },
            children: [
              {
                path: 'user-edit/:userId',
                name: 'HotelGroupUserEdit',
                component: HotelGroupUserEdit,
                meta: {
                  title: 'Edit User',
                  icon: Pencil
                }
              }
            ]
          }
        ]
      },
      {
        path: 'hotel-create',
        name: 'HotelAddPage',
        component: HotelAddPage,
        meta: {
          title: 'New Hotel',
          icon: NewIcon
        }
      },
      {
        path: 'hotel-edit/:hotelId',
        name: 'HotelEditPage',
        component: HotelEditPage,
        meta: {
          title: 'Edit Hotel',
          icon: Pencil
        },
        children: [
          {
            path: 'users',
            name: 'HotelUsersList',
            component: HotelUsersList,
            meta: {
              title: 'Users',
              icon: MyAccountIcon
            },
            children: [
              {
                path: 'user-edit/:userId',
                name: 'HotelUserEdit',
                component: HotelUserEdit,
                meta: {
                  title: 'Edit User',
                  icon: Pencil
                }
              }
            ]
          }
        ]
      },
      {
        path: 'users',
        name: 'CompanyUsersList',
        component: CompanyUsersList,
        meta: {
          title: 'Users',
          icon: MyAccountIcon
        },
        children: [
          {
            path: 'user-edit/:userId',
            name: 'CompanyUserEdit',
            component: CompanyUserEdit,
            meta: {
              // breadcrumb
              title: 'Edit User',
              icon: Pencil
            },
            children: [
              {
                path: 'user-activity',
                name: 'CompanyUserActivity',
                component: CompanyUserActivity,
                meta: {
                  title: 'User Activity',
                  icon: Calendar
                }
              }
            ]
          }
        ]
      },
    ]
  },
  // reports not yet completed (pages need to be revised)
  {
    path: '/reports',
    redirect: "/launching-soon", // temporary redirect 
    name: 'ReportsPage',
    components: {
      header: AppHeader,
      default: ReportsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Reports',
      icon: ReportsIcon
    },
    children: [
      {
        path: 'create',
        name: 'CreateReportPage',
        component: CreateReportPage,
        meta: {
          title: 'New Report',
          icon: NewIcon,
        }
      }
    ]
  },
  // integrations not yet completed (pages need to be revised)
  {
    path: "/integrations",
    name: 'IntegrationsPage',
    components: {
      header: AppHeader,
      default: IntegrationsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Integrations',
      icon: IntegrationsIcon
    },
    children: [
      {
        path: ":integrationType",
        name: 'IntegrationPage',
        component: IntegrationPage,
        meta: {
          // title set dynamically by a prop (integrationType)
          icon: IntegrationsIcon
        }
      }
    ]
  },
  // my account pages
  {
    path: "/my-account",
    redirect: "/my-account/account-settings",
    name: 'MyAccount',
    meta: {
      title: 'My Account',
      icon: MyAccountIcon
    },
    children: [
      {
        path: 'account-settings',
        name: 'AccountSettingsPage',
        components: {
          header: AppHeader,
          default: AccountSettingsPage,
          footer: AppFooter
        },
        meta: {
          title: 'Account Settings',
          icon: IntegrationsIcon,
        }
      },
      {
        path: "/billing",
        name: 'BillingPage',
        components: {
          header: AppHeader,
          default: BillingPage,
          footer: AppFooter
        },
        meta: {
          title: 'Billing',
          icon: BudgetIcon
        },
        children: [
          {
            path: "upgrade-plan",
            name: 'UpgradePlanPage',
            component: UpgradePlanPage,
            meta: {
              title: 'Upgrade Plan',
              icon: UpgradePlanIcon
            }
          },
          {
            path: "payment-method",
            name: 'PaymentMethodPage',
            component: PaymentMethodPage,
            meta: {
              title: 'Payment Method',
              icon: BudgetIcon
            }
          },
          {
            path: "billing-history",
            name: 'BillingHistoryPage',
            component: BillingHistoryPage,
            meta: {
              title: 'Billing History',
              icon: BillingHistoryIcon
            }
          },
          {
            path: "billing-address",
            name: 'BillingAddressPage',
            component: BillingAddressPage,
            meta: {
              title: 'Billing Address',
              icon: BillingAddressIcon
            }
          },
        ]
      },
    ]
  },
  {
    path: "/my-account-mobile",
    name: 'MyAccountMobile',
    components: {
      header: AppHeader,
      default: MyAccountMobile,
      footer: AppFooter
    },
  },
  // launching soon page
  {
    path: "/launching-soon",
    name: 'LaunchingSoonPage',
    components: {
      header: AppHeader,
      default: LaunchingSoonPage,
      footer: AppFooter
    },
    meta: {
      title: 'Launching Soon',
      icon: LaunchingSoonIcon
    }
  },

// Routes below still in a state of reorganization

  {
    path: "/market-analysis",
    name: 'MarketAnalysis',
    components: {
      header: AppHeader,
      default: MarketAnalysis,
      footer: AppFooter
    },
    meta: {
      title: 'Market Analysis',
      icon: 'fas fa-chart-pie'
    }
  },
  {
    path: "/market/demand",
    name: 'DemandPage',
    components: {
      header: AppHeader,
      default: DemandPage,
      footer: AppFooter
    },
    meta: {
      title: 'Demand Page',
      icon: 'fas fa-chart-bar'
    }
  },
  {
    path: "/market/events",
    name: 'EventsPage',
    components: {
      header: AppHeader,
      default: EventsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Events Page',
      icon: 'fas fa-calendar-alt'
    }
  },
  {
    path: "/market/insights",
    name: 'InsightsPage',
    components: {
      header: AppHeader,
      default: InsightsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Insights Page',
      icon: 'fas fa-lightbulb'
    }
  },
  {
    path: "/market/online-distribution",
    name: 'OnlineDistribution',
    components: {
      header: AppHeader,
      default: OnlineDistribution,
      footer: AppFooter
    },
    meta: {
      title: 'Online Distribution',
      icon: 'fas fa-desktop'
    }
  },
  {
    path: "/market/online-reputation",
    name: 'OnlineReputation',
    components: {
      header: AppHeader,
      default: OnlineReputation,
      footer: AppFooter
    },
    meta: {
      title: 'Online Reputation',
      icon: 'fas fa-star'
    }
  },
  {
    path: "/smart-pricing",
    name: 'SmartPricing',
    components: {
      header: AppHeader,
      default: SmartPricing,
      footer: AppFooter
    },
    meta: {
      title: 'Smart Pricing',
      icon: 'fas fa-tags'
    }
  },
  {
    path: "/search-mobile",
    redirect: "/launching-soon", // temporary redirect 
    name: 'SearchMobile',
    components: {
      header: AppHeader,
      default: SearchMobile,
      footer: AppFooter
    },
    meta: {
      title: 'Search Mobile',
      icon: 'fas fa-search'
    }
  },
  {
    path: "/notifications",
    name: 'NotificationsPage',
    components: {
      header: AppHeader,
      default: NotificationsPage,
      footer: AppFooter
    },
    meta: {
      title: 'Notifications',
      icon: BillingAddressIcon,
    }
  },
  {
    path: "/meteorology",
    name: 'MeteorologyPage',
    components: {
      header: AppHeader,
      default: MeteorologyPage,
      footer: AppFooter
    },
    meta: {
      title: 'Meteorology',
      icon: 'fas fa-cloud-sun'
    }
  },
  {
    path: "/invitations/:invitationToken/accept",
    name: 'InvitationsPage',
    components: {
      default: InvitationsPage,
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})


export default router;