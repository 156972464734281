/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const PICKUP_SERVICE = {
    getList,
    getListBySlug,
    createPickup,
    editPickup,
    deletePickupBySlug
};

// Root service to build API url
const API_ROOT_SERVICE = "pickup";

/* Services */
async function getList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getListBySlug(slug) 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function createPickup() 
{
    // this is not needed when its optional parameters, so i commented them temporarily ultil we know that they are really optional
    // if(!name || !integrationsIds)
    // {
        //     return Promise.reject("The parameters are not complete!");
        // }

    // var raw = JSON.stringify({name, integrationsIds});
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        // body: raw
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function editPickup() 
{
    // this is not needed when its optional parameters, so i commented them temporarily ultil we know that they are really optional
    // if(!name || !integrationsIds)
    // {
    //     return Promise.reject("The parameters are not complete!");
    // }
    
    // var raw = JSON.stringify({name, integrationsIds});
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        // body: raw
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
    
    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function deletePickupBySlug(slug) 
    {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Content-Type": "application/json" },
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}