<template>
    <hr class="divider">
</template>

<style scoped>
.divider {
    stroke-width: 1px;
    stroke: var(--theme-border);
    padding: 0;
    margin: 0;
}
</style>