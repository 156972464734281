<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="popup">
                <div class="pop-up-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp">
                </div>
                <div class="modal-container">
                    <div v-if="iconLeftTextBtn && textBtnWithIcon" class="d-flex gap-2 cursor-pointer" @click="clickSmallIconText">
                        <img :src="iconLeftTextBtn" alt="">
                        <span>{{ textBtnWithIcon }}</span>
                    </div>
                    <img v-if="img" :src="img" alt="">
                    <span v-if="titlePopup" id="popup-title">{{ titlePopup }}</span>
                    <span v-if="descriptionPopup" id="popup-desc" v-html="descriptionPopup"></span>
                    <img class="mt-5" v-if="imgPicker" :src="imgPicker" alt="">
                    <div class="d-flex gap-2 flex-column w-100">
                        <div class="d-flex flex-row">
                            <label v-if="labelInput">{{ labelInput }}</label><span v-if="inputRequired == true" class="asterisk"> *</span>
                        </div>
                        <input class="form-control w-100" v-if="inputType !== 'select' && placeholderText" :type="inputType" :placeholder="placeholderText">
                        <select v-model="optionValue" class="form-select w-100" v-if="inputType === 'select'" @change="$emit('update:option', $event.target.value)">                            
                            <option value="" disabled selected>{{$t("chooseNewIntegration")}}</option>
                            <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                        <slot name="form"></slot>
                    </div>
                    <CustomButton 
                        v-if="labelCustomButton && customButtonType"
                        class="btn mt-4 d-flex gap-2" 
                        :label="labelCustomButton"
                        :buttonType="customButtonType" 
                        @clickBtn="clickBtnCustom" 
                        :rightIcon="iconCustomButton" 
                        :buttonStyle="{
                            borderRadius: '8px!important',
                            gap: '8px',
                            padding: '6px 35px'
                        }"
                    />
                    <slot name="body">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
export default {
    components: {
        CustomButton,
    },
    data() {
        return {
            drag: false,
            optionValue: this.option || ''
        }
    },
    props: {
        img: {
            type: String,
            required: false 
        },
        imgPicker: {
            type: String,
            required: false 
        },
        iconLeftTextBtn: {
            type: String,
            required: false 
        },
        textBtnWithIcon: {
            type: String,
            required: false 
        },
        inputType: {
            type: String,
            required: false 
        },
        labelInput: {
            type: String,
            required: false 
        },
        placeholderText: {
            type: String,
            required: false 
        },
        titlePopup: {
            type: String,
            required: false 
        },
        descriptionPopup: {
            type: String,
            required: false 
        },
        inputRequired: {
            type: Boolean,
            required: false 
        },
        labelCustomButton: {
            type: String,
            required: true 
        },
        customButtonType: {
            type: String,
            required: false 
        },
        iconCustomButton: {
            type: String,
            required: false 
        },
        options: {
            type: Array,
            required: false 
        },
        option: {
            type: String,
            required: false 
        }
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        clickSmallIconText() {
            this.$emit("popUpDeleteOpen");
        },
        clickBtnCustom() {
            this.$emit("clickBtn");
        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        }
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function (val) {
            if (val) {
                document.body.classList.add("modal-open");
            }
            else {
                document.body.classList.remove("modal-open");
            }
        },
        option: function (val) {
            this.optionValue = val;
        }
    }

};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 1rem;
}

.popup{
    display: flex;
    flex-direction: column;
    min-width: 40%;
    min-height: 5vh;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 40px;
    box-shadow: 0 0 8px 0 #303942;
    padding: 2rem;
}

.pop-up-close {
    display: flex;
    justify-content: end;
}

.modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
#popup-title{
    color: var(--theme-dark, #212529);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
    word-break: break-word;
}
#popup-desc{
    color: var(--gray-800, #343A40);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: 1rem 0;
}
.asterisk{
  color: var(--theme-primary);
  margin-left: 5px;
}
@media (max-width: 767px) {
    .modal-wrapper {
        height: 100vh;
    }
    .popup{
        height: 100%;
    }
}
</style>