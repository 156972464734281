<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="popup">
                <div class="pop-up-close">
                    <img class="cursor-pointer" src="@/assets/Popup/x.svg" @click="closePopUp">
                </div>
                <div class="modal-container">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <BoldTitle :icon="require('@/assets/editPlan/titleIconPopup.svg')" :title="$t('upgradeYourPlan')"/>
                            </div>
                            <hr>
                            <span class="my-2">Ready to Upgrade your Plan? Please check your billing information bellow.</span>
                            <div class="d-flex gap-2 align-items-center my-2">
                                <img src="@/assets/editPlan/cardDetails.svg" alt="">
                                <span class="small-title">{{ $t('cardDetails') }}</span>
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('cardNumber') }}</label>
                                <input class="form-control" type="number">
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('cardOwner') }}</label>
                                <input class="form-control" type="text">
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('cardExpDate') }}</label>
                                <div class="d-flex flex-row gap-2">
                                    <input class="form-control" type="number">
                                    <input class="form-control" type="number">
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('cvvNumber') }}</label>
                                <input class="form-control" type="number">
                            </div>
                            <!-- Billing Address -->
                            <div class="d-flex gap-2 align-items-center my-2">
                                <img src="@/assets/editPlan/billingAddressDetails.svg" alt="">
                                <span class="small-title">{{ $t('billingAddressDetails') }}</span>
                            </div>
                            <div class="col-12 col-lg-3 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('name') }}</label>
                                <input class="form-control" type="text">
                            </div>
                            <div class="col-12 col-lg-3 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('vat') }}</label>
                                <input class="form-control" type="text">
                            </div>
                            <div class="col-12 col-lg-3 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('address') }}</label>
                                <input class="form-control" type="text">
                            </div>
                            <div class="col-12 col-lg-3 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('doorNumber') }}</label>
                                <input class="form-control" type="number">
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('zipCode') }}</label>
                                <input class="form-control" type="number">
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('reference') }}</label>
                                <input class="form-control" type="text">
                            </div>
                            <div class="col-12 col-lg-4 d-flex flex-column">
                                <label class="label-style" for="">{{ $t('reference') }}</label>
                                <input class="form-control" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-row gap-2 justify-content-end">
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('cancel')"
                            buttonType="theme-light" 
                            @clickBtn="closePopUp" 
                            :rightIcon="require('@/assets/DashboardPage/deleteMenus.svg')" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                        <CustomButton 
                            class="btn mt-4 d-flex gap-2" 
                            :label="$t('upgrade')"
                            buttonType="primary" 
                            @clickBtn="closePopUp" 
                            :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                            :buttonStyle="{
                                borderRadius: '8px!important',
                                gap: '8px',
                                padding: '6px 35px'
                            }"
                        />
                    </div>
                    <slot name="body">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import BoldTitle from '@/components/BoldTitle.vue';
export default {
    components: {
        CustomButton,
        BoldTitle
    },
    data() {
        return {
            drag: false
        }
    },
    props: {
        iconLeftTextBtn: {
            type: String,
            required: false 
        },
    },
    methods: {
        closePopUp() {
            this.$emit("popUpClosed");
        },
        clickSmallIconText() {
            this.$emit("popUpDeleteOpen");

        },
        clickBtnCustom() {
            this.$emit("btnCustom");

        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal-wrapper')) {
                this.closePopUp();
            }

            this.drag = false;
        }
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    unmounted() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    watch: {
        showModal: function (val) {
            if (val) {
                document.body.classList.add("modal-open");
            }
            else {
                document.body.classList.remove("modal-open");
            }
        }
    }

};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 1rem;
}

.popup{
    display: flex;
    flex-direction: column;
    min-width: 35vw;
    min-height: 5vh;
    background-color: #fff;
    transition: all 0.3s ease;
    border-radius: 40px;
    box-shadow: 0 0 8px 0 #303942;
    padding: 2rem;
    width: 70%;
}

.pop-up-close {
    display: flex;
    justify-content: end;
}

.modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
#popup-title{
    color: var(--theme-dark, #212529);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin: 1rem 0;
    word-break: break-word;
}
.small-title{
    color: var(--Theme-Primary, #A81800);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
}
.label-style{
    color: var(--Theme-Secondary, #6C757D);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
@media (max-width: 991.98px){
    .popup{
        width: 100%;
    }
}
</style>