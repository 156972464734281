<template>
    <BasePopUp :showModal="showModal" :closeIcon="false">
        <template v-slot:body>
            <section id="invite" class="p-4">
                <!-- Loading State -->
                <div v-if="isInitialLoading" class="d-flex justify-content-center align-items-center h-100">
                    <span class="spinner-border theme-primary" role="status">
                        <span class="visually-hidden">{{ $t('Loading') }}</span>
                    </span>
                </div>

                <!-- No Invitations State -->
                <template v-else-if="!remainingInvitations?.length && !response.reject && hasCompanies">
                    <div class="modal-header text-center">
                        <div class="w-100">
                            <img class="img-fluid mb-3" src="@/assets/Users/invite-user.svg" alt="no-invitations-image" />
                            <h5 class="modal-title fw-bold mb-2">{{ $t('No Invitations') }}</h5>
                            <p class="text-muted">{{ $t('You currently have no pending invitations to join any company.') }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="navigateToDashboard" class="btn btn-accept w-100">
                            {{ $t('Go To Dashboard') }}
                        </button>
                    </div>
                </template>

                <!-- Access Restricted State -->
                <template v-else-if="response.reject && !hasCompanies">
                    <div class="modal-header text-center">
                        <div class="w-100">
                            <img class="img-fluid mb-3" src="@/assets/Users/invite-user.svg" alt="invite-user-image" />
                            <h5 class="modal-title fw-bold mb-2">{{ $t('Access Restricted') }}</h5>
                            <p class="text-muted">{{ $t('You cannot access the platform without a company. Please accept an invitation or contact support.') }}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" @click="navigateToLogin" class="btn btn-accept w-100">
                            {{ $t('Logout') }}
                        </button>
                    </div>
                </template>

                <!-- Invitation Available State -->
                <template v-else>
                    <div v-if="!response.reject && !response.message" class="text-center">
                        <img class="img-fluid mb-3" src="@/assets/Users/invite-user.svg" alt="invite-user-image" />
                        <h5 class="modal-title fw-bold mb-2">
                            {{ $t('Request received') + ` (${remainingInvitations.length})` }}
                        </h5>
                        <p class="text-muted mb-4">
                            {{ $t('You have been invited to join the company. This invitation is currently pending and awaiting your acceptance.') }}
                        </p>

                        <div v-if="!loading" class="d-flex gap-3 modal-footer">
                            <button type="button" @click="handleDecline" class="btn btn-reject">
                                {{ $t('Reject') }}
                            </button>
                            <button type="button" @click="handleAccept" class="btn btn-accept">
                                <span>{{ $t('Accept') }}</span>
                            </button>
                        </div>
                        <div v-else class="d-flex justify-content-center">
                            <span class="spinner-border" role="status" aria-hidden="true"></span>
                        </div>
                    </div>
                </template>
            </section>
        </template>
    </BasePopUp>
</template>

<script>
import BasePopUp from '@/components/popups/BasePopUp.vue';
import { useAlertStore, useCompanyUsersStore, useUsersStore } from '@/stores';

export default {
    name: 'InvitationsPage',
    components: { BasePopUp },
    setup() {
        const alertStore = useAlertStore();
        const companyUsersStore = useCompanyUsersStore();
        const usersStore = useUsersStore();

        return { alertStore, companyUsersStore, usersStore };
    },
    data() {
        return {
            token: '',
            loading: false,
            showModal: true,
            remainingInvitations: [],
            response: {
                reject: false,
                message: ''
            },
            isInitialLoading: true,
        };
    },
    async created() {
        try {
            await this.initializeData(this.$route.params.invitationToken);
        } finally {
            this.isInitialLoading = false;
        }
    },
    computed: {
        hasCompanies() {
            return this.usersStore.getListOutput?.companies?.length > 0;
        }
    },
    methods: {
        async initializeData(newToken) {
            if (newToken && newToken !== this.token) {
                this.token = newToken;
                this.resetState();
                await this.fetchRemainingInvitations();
            }
        },
        async fetchRemainingInvitations() {
            try {
                await this.usersStore.getUserData();
                const userData = this.usersStore.getListOutput;
                
                this.remainingInvitations = userData?.invites_received || [];
                
                if (!this.remainingInvitations.some(inv => inv.token === this.token) && this.remainingInvitations.length) {
                    this.$router.replace({
                        name: 'InvitationsPage',
                        params: { invitationToken: this.remainingInvitations[0].token }
                    });
                }
            } catch (error) {
                this.alertStore.error(error);
            }
        },
        async handleAccept() {
            this.loading = true;
            try {
                await this.companyUsersStore.acceptInvitation(this.token);
                await this.handleInvitationResponse('accept');
            } catch (error) {
                this.alertStore.error(error);
                this.response.message = error?.message?.replace(/^["']|["']$/g, '') ||
                    this.$t('An error occurred while accepting the invitation. Please try again later.');
            } finally {
                this.loading = false;
            }
        },
        async handleDecline() {
            this.loading = true;
            try {
                await this.companyUsersStore.rejectInvitation(this.token);
                await this.handleInvitationResponse('reject');
            } catch (error) {
                this.alertStore.error(error);
                this.response.message = error?.message?.replace(/^["']|["']$/g, '') ||
                    this.$t('An error occurred while rejecting the invitation. Please try again later.');
            } finally {
                this.loading = false;
            }
        },
        async handleInvitationResponse(action) {
            try {
                await this.usersStore.getUserData();
                const userData = this.usersStore.getListOutput;
                
                // Update user storage
                const userStorage = JSON.parse(localStorage.getItem('user') || '{}');
                
                if (userStorage.user) {
                    // Update companies if accepting
                    if (action === 'accept') {
                        userStorage.user.companies = userData.companies || [];
                    }
                    
                    // Update invites_received 
                    userStorage.user.invites_received = userData?.invites_received || [];
                    
                    try {
                        localStorage.setItem('user', JSON.stringify(userStorage));
                    } catch (storageError) {
                        console.error('Error updating user storage:', storageError);
                        this.alertStore.error('Could not update user storage');
                    }
                }

                // Update component's local state
                this.remainingInvitations = userData?.invites_received || [];

                if (this.remainingInvitations.length) {
                    const nextInvitation = this.remainingInvitations[0];
                    await this.$router.replace({
                        name: 'InvitationsPage',
                        params: { invitationToken: nextInvitation.token }
                    });
                } else {
                    // if user rejects the last invitation and has no companies
                    if(action === 'reject' && !this.hasCompanies) {
                        this.response.reject = true;
                    }
                }

            } catch (error) {
                this.alertStore.error(error);
            }
        },
        resetState() {
            this.loading = false;
            this.showModal = true;
            this.response = {
                reject: false,
                message: ''
            };
        },
        navigateToLogin() {
            this.usersStore.logout();
        },
        navigateToDashboard() {
            this.resetState();
            this.$router.push({ name: 'DashboardPage' });
        }
    },
    watch: {
        '$route.params.invitationToken': {
            handler(newToken) {
                if (this.token) {
                    this.initializeData(newToken);
                }
            }
        }
    }
};
</script>

<style scoped>
.form-group,
input::placeholder {
    font-size: .8rem;
}
.modal-footer .btn-accept {
    background-color: var(--theme-primary);
    border-radius: 4px;
    color: white;
    width: 100%;
}
.modal-footer .btn-accept:active {
    background-color: var(--theme-primary);
    color: white;
}
.modal-footer .btn-reject {
    background-color: var(--theme-secondary);
    border-radius: 4px;
    color: white;
    width: 100%;
}
.modal-footer .btn-reject:active {
    background-color: var(--theme-secondary);
    color: white;
}
.spinner-border{
    color: var(--theme-primary);
}
</style>
