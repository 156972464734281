<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-flex gap-2">
        <BoldTitle :title="$t('onlineDistrubution')"/>
        <div>
          <DropdownButton :label="$t('Crystal Breeze Resort')" @clickBtn="toggleDropdown('hotel')" :icon="require('@/assets/Market/hotel-icon.svg')"/>
          <CheckboxList 
            :isOpen="isDropdownHotelOpen" @close-dropdown="isDropdownHotelOpen = false"
            :customClass="['hotel-dropdown-menu']" 
            :items="dropdownHotelItems"
            @item-click="handleCheckboxClick($event, 'dropdownHotelItems')" :avatar="true">
          </CheckboxList>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <TabsPickup :tabsInfo="tabsInfo"></TabsPickup>
        </div>
      </div>
    </div>
    <div class="container-wrapper mt-5">
      <div class="row align-items-center p-4">
        <div class="col-12 d-flex gap-2">
          <img src="@/assets/Market/distribution.svg" alt="">
          <span class="title-container-wrapper">{{ $t("distribution") }}</span>
        </div>
      </div>
      <DividerComponent />
      <div class="d-flex flex-column flex-wrap my-5 p-4 gap-3">
        <BoldTitle :title="$t('panel')"/>
        <br>
        <div class="row">
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('volumeOfBusiness')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/volumeOfBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">Volume of Business</span>
                          <span class="volumeOfBusiness-subTitle">136 899 EUR</span>
                        </div>
                      </div>
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/volumeOfBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">Volume of Business</span>
                          <span class="volumeOfBusiness-subTitle">184 EUR</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <span>here will go the graph</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('growthInBusinessVolume')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 d-flex flex-column gap-3 py-4">
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/growthInBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">business volume growth</span>
                          <span class="volumeOfBusiness-subTitle">-%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-wrap my-5 p-4 gap-3">
        <BoldTitle :title="$t('channels')"/>
        <br>
        <div class="row">
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('channels')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6">
                      <span>here will go the statistics</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('channelGrowth')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 d-flex flex-column gap-3 py-4">
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/growthInBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">Expedia</span>
                          <span class="volumeOfBusiness-subTitle">-%</span>
                        </div>
                      </div>
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/growthInBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">HyperGuest</span>
                          <span class="volumeOfBusiness-subTitle">-%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('channelReservationWindow')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6">
                      <span>here will go the graph</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-wrap my-5 p-4 gap-3">
        <BoldTitle :title="$t('bookingWindow')"/>
        <br>
        <div class="row">
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('lengthOfStay')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 align-items-center">
                      <img class="icon-style" src="@/assets/Market/averageTime.svg" alt="">
                      <span class="average-span">Average length of stay</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('averageArrivalTime')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 align-items-center">
                      <img class="icon-style" src="@/assets/Market/averageTime.svg" alt="">
                      <span class="average-span">Average length of stay</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('rates')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6">
                      <span>here will go the graph</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('rooms')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 mt-3">
                      <span class="average-span">Average room price per night</span>
                    </div>
                    <div class="col-12 d-flex gap-3 mt-3">
                      <img src="@/assets/Market/pricePerNight.svg" alt="">
                      <span class="priceNight-span">184 EUR</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('bookingEngines')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 d-flex gap-3 align-items-center">
                      <span class="priceNight-span">0%</span>
                      <span class="average-span">of your bookings originate from your booking engines:</span>
                    </div>
                    <div class="col-12">
                      <span>here will go the graph</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('extras')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 mt-3">
                      <span class="average-span">The extras were generated:</span>
                    </div>
                    <div class="col-12 d-flex gap-3 mt-3">
                      <span class="priceNight-span">184 EUR</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-8 my-3">
            <OnlineDistributionwrapper 
              :title="$t('cancellations')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column gap-1 py-1">
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/volumeOfBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">Canceled Recipes</span>
                          <span class="volumeOfBusiness-subTitle">384 052 EUR</span>
                        </div>
                      </div>
                      <div class="d-flex gap-2">
                        <img src="@/assets/Market/volumeOfBusiness.svg" alt="">
                        <div class="d-flex flex-column gap-2">
                          <span class="volumeOfBusiness-title">Cancellation fee Revenue</span>
                          <span class="volumeOfBusiness-subTitle">0 EUR</span>
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-2">
                        <img src="@/assets/Market/volumeOfBusiness.svg" alt="">
                        <span class="volumeOfBusiness-subTitle">765</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('nationalities')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-column gap-3 py-4">
                      <span>here will go the graph</span>
                    </div>
                    <div class="col-12 col-lg-6">
                      <span>here will go the graph</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
          <div class="col-12 col-lg-4 my-3">
            <OnlineDistributionwrapper 
              :title="$t('customerCode')" 
              :hasBottomBtn="true"
            >
              <template v-slot:content>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12 d-flex gap-3 mt-3">
                      <span class="priceNight-span">184 EUR</span>
                    </div>
                  </div>
                </div>
              </template>
            </OnlineDistributionwrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import TabsPickup from '@/components/TabsPickup.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import CheckboxList from '@/components/CheckboxList.vue';
import OnlineDistributionwrapper from '@/components/Market/OnlineDistributionwrapper.vue';

export default {
  components: {
      BoldTitle,
      DividerComponent,
      TabsPickup,
      DropdownButton,
      CheckboxList,
      OnlineDistributionwrapper
  },
  setup() {
      return {}
  },
  data() {
      return {
        isDropdownHotelOpen: false,
        tabsInfo: [
            {title: "Panel"},
            {title: "Channels"},
            {title: "Booking Window"},
            {title: "Nationalities"},
        ],
        dropdownHotelItems: [
          {
            id: 0,
            label: "Cystal Breeze Resort California 1 ",
            avatar: require('@/assets/avatar.png')
          },
          {
            id: 1,
            label: "Cystal Breeze Resort California 2",
            avatar: require('@/assets/avatar.png')
          }
        ],
      }
  },
  methods: {
      toggleDropdown(dropdownName) {
          this.isDropdownPickupOpen = dropdownName === "pickup" && !this.isDropdownPickupOpen;
          this.isDropdownMetricsOpen = dropdownName === "metrics" && !this.isDropdownMetricsOpen;
          this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
      },
      handleCheckboxClick(index, propertyName) {
          const selectedItem = this[propertyName][index];
          selectedItem.checked = !selectedItem.checked;

          // Check if the item is already in the selectedHotelItems array
          const selectedIndex = this.selectedHotelItems.findIndex(item => item.id === selectedItem.id);

          if (selectedItem.checked && selectedIndex === -1) {
              // If the item is selected and not in the array, add it
              this.selectedHotelItems.push(selectedItem);
          } else if (!selectedItem.checked && selectedIndex !== -1) {
              // If the item is deselected and in the array, remove it
              this.selectedHotelItems.splice(selectedIndex, 1);
          }
      },
  },
}
</script>
<style scoped>
.container-wrapper {
  border-radius: 15px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.title-container-wrapper {
  color: var(--Theme-Secondary, #6C757D);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.hotel-dropdown-menu {
  min-width: 450px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 4px 0px #00000013;
  left: 200px;
  top: 100px;
}
.volumeOfBusiness-title {
  color: #212529;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}
.volumeOfBusiness-subTitle {
  color: #212529;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.icon-style {
  width: 75px;
  height: 75px;
}
.average-span {
  color: #6C757D;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.priceNight-span {
  color: #6C757D;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
</style>