<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <DividerComponent class="my-5 d-md-none d-flex" />
        <div class="col-12 col-md-6 d-flex gap-2" id="page-title">
          <BoldTitle :icon="require('@/assets/MyProfile/notifications-icon.svg')" :title="$t('Notifications')" />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <div class="d-flex gap-2 align-items-center">
              <img src="@/assets/MyProfile/notification-settings-icon.svg" alt="">
              <h3 class="section-title">{{ $t("disableAllNotifications") }}</h3>
          </div>
          <p class="section-description">{{ $t("stopReceivingPushNotificationsAndEmailsFromRevenueMasterExceptThoseThatAreMandatory") }}</p>
          <div class="d-flex flex-row gap-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="application-checkbox" v-model="isApplicationChecked" />
                <label class="form-check-label" for="application-checkbox">{{ $t("application") }}</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="email-checkbox" v-model="isEmailChecked" />
                <label class="form-check-label" for="email-checkbox">{{ $t("email") }}</label>
              </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div class="d-flex gap-2 align-items-center">
              <img src="@/assets/MyProfile/notification-settings-icon.svg" alt="">
              <h3 class="section-title">{{ $t("marketingNotifications") }}</h3>
          </div>
          <div class="d-flex gap-2">
            <input class="form-check-input" type="checkbox" id="newsletter-checkbox" v-model="isNewsletterChecked" />
            <label class="section-description">{{ $t("stopReceivingPushNotificationsAndEmailsFromRevenueMasterMarketingTeam") }}</label>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import { useMyStore, useAlertStore } from "@/stores";

export default {
  components: {
    BoldTitle,
    DividerComponent,
  },
  setup() {
      const myStore = useMyStore();
      const alertStore = useAlertStore();
      return {
          myStore,
          alertStore
      }
  },
  data() {
    return {
      isApplicationChecked: false,
      isEmailChecked: false,
      isNewsletterChecked: false,
    };
  },
  async mounted() {
    await this.fetchSettings();
  },
  methods: {
    sendToMyProfileMobile() {
      this.$router.push({ name: 'MyAccountMobile' });
    },
    async fetchSettings() {
      try {
        await this.myStore.getSettingsList();

        const mySettings = this.myStore.getSettingsListOutput;

        
        // Update the checkboxes based on the fetched settings values
        this.isApplicationChecked = mySettings.settings["setting.notifications"] === "on";
        this.isEmailChecked = mySettings.settings["setting.emails"] === "on";
        this.isNewsletterChecked = mySettings.settings["setting.newsletters"] === "on";
        
      } catch (error) {
        this.alertStore.error('Failed to load settings.');
      }
    },
    async updateSettings() {
      // Prepare the settings in the required format
      const payload = {
        settings:{
          "setting.notifications": this.isApplicationChecked ? "on" : "off",
          "setting.emails": this.isEmailChecked ? "on" : "off",
          "setting.newsletters": this.isNewsletterChecked ? "on" : "off",
        }
      };
      
      try {
        await this.myStore.updateSettings(payload);
      } catch (error) {
        this.alertStore.error('Error updating settings:');
      }
    },
  },
  watch: {
    isApplicationChecked() {
      this.updateSettings();
    },
    isEmailChecked() {
      this.updateSettings();
    },
    isNewsletterChecked() {
      this.updateSettings();
    },
  },
};
</script>

<style scoped>
.section-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.section-description {
  font-size: 16px;
  color: #6C757D;
  margin-bottom: 15px;
}

.form-check {
  margin-bottom: 10px;
}
.form-check-input{
  cursor: pointer;
}
.form-check-input:checked{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
}

hr {
  border: none;
  border-top: 1px solid #E0E0E0;
  margin: 20px 0;
}

#page-title {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  #page-title {
    justify-content: center;
  }
  #save-btn-div {
    justify-content: center;
    margin-top: 2rem;
  }
  #user-avatar-img {
    width: 40px;
    height: 40px;
  }
  .btn-save-mobile {
    width: inherit !important;
  }
  #col-account-activity-info {
    flex-direction: column;
    align-items: center;
  }
  #col-avatar-text {
    flex-direction: column !important;
    align-items: center !important;
  }
  #col-info {
    align-items: center !important;
    text-align: center !important;
  }
}
</style>
