<template>
  <div class="d-flex flex-column" :class="{'has-footer': isMobile}">
    <router-view name="header"></router-view>
    <template v-if="!isSpecialRoute">
      <SideMenu />
      <HeaderNavigation :dynamicTitle="dynamicTitle"/>
    </template>
    <div :class="getRootContentClasses" :style="{transition: sideMenuStore.isTransitionEnabled ? 'margin-left 0.5s ease-in-out' : 'none'}">
      <div :class="getContainerClasses">
        <router-view ref="container" />
      </div>
    </div>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { useSideMenuStore } from '@/stores';
import SideMenu from '@/layouts/SideMenu.vue';
import HeaderNavigation from '@/layouts/HeaderNavigation.vue';
import { useResponsive } from '@/composables/responsive.js';

export default {
  name: 'App',
  components: {
    SideMenu,
    HeaderNavigation
  },
  setup() {
    const sideMenuStore = useSideMenuStore();
    const { isMobile } = useResponsive();

    return {
      sideMenuStore,
      isMobile
    }
  },
  data() {
    return {
      userStorage: JSON.parse(localStorage.getItem('user')) ?? null,
    }
  },
  computed: {
    getRootContentClasses() {
      return {
        'root-collapsed-side-menu': !this.isMobile && !this.sideMenuStore.isOpen && !this.isSpecialRoute && !this.navigationBarRoutes,
        'root-expanded-side-menu': !this.isMobile && this.sideMenuStore.isOpen && !this.isSpecialRoute && !this.navigationBarRoutes,
        'root-collapse-side-menu-and-navigation-bar': !this.isMobile && !this.sideMenuStore.isOpen && !this.isSpecialRoute && this.navigationBarRoutes,
        'root-expanded-side-menu-and-navigation-bar': !this.isMobile && this.sideMenuStore.isOpen && !this.isSpecialRoute && this.navigationBarRoutes,
        'root': this.isSpecialRoute || this.isMobile,
      };
    },
    getContainerClasses() {
      return {
        'container-content': !this.isSpecialRoute,
      };
    },
    // routes with navigation bar
    navigationBarRoutes() {
      const parentNames = ['PickUpPage', 'MyAccount', 'ChainViewPage', 'ForecastPage'];
      return this.$route.matched.some(route => parentNames.includes(route.name));
    },
    isSpecialRoute() {
      return ['SignIn', 'SignUp', 'CreateNewPassword', 'TwoFactor', 'LocationCode', 'CompleteProfile', 'VerificationCode', 'SecretCode', 'InvitationsPage'].includes(this.$route.name);
    },
    // For now, only the integration page uses this. 
    // Title for header navigation is defined by a prop "integrationType"
    dynamicTitle() {
      let result = '';
      if(this.$route.name === 'IntegrationPage') {
        const type = this.capitalizeFirstLetter(this.$route.params.integrationType);
        result = type;
      }
      return result;
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (!string) return string; // If string is empty, return it as is
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  watch: {
    $route() {
      this.sideMenuStore.isTransitionEnabled = false; // Avoids unnecessary transitions when changing pages 
    }
  }
};
</script>

<style>
:root {
  --theme-primary: #A81800!important;
}

html,
body {
  height: 100%;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
}

.color-primary {
  color: var(--theme-primary);
}

.color-secondary {
  color: var(--theme-secondary);
}

.container-content {
  padding: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.has-footer .root {
  height: calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  overflow-y: auto;
}

/* vue datepicker theme */
.dp__theme_light {
  --dp-primary-color: var(--theme-primary)!important;
}

/* @supports code is important for solving the height issue on iOS Safari and Chrome */
@supports (height: 100dvh) {
  .has-footer .root{
    height: calc(100dvh - 120px);
    min-height: calc(100dvh - 120px);
    overflow-y: auto;
  }
}
@media only screen and (min-width: 768px) {
  .root-collapsed-side-menu,
  .root-expanded-side-menu,
  .root-collapse-side-menu-and-navigation-bar,
  .root-expanded-side-menu-and-navigation-bar {
    margin-top: 120px;
  }

  .root-collapsed-side-menu {
    margin-left: 60px;
  }

  .root-expanded-side-menu {
    margin-left: 250px;
  }

  .root-collapse-side-menu-and-navigation-bar {
    margin-left: 270px;
  }

  .root-expanded-side-menu-and-navigation-bar {
    margin-left: 460px
  }

}
@media only screen and (max-width: 767px) {
  .container-content {
    padding: 0rem !important;
  }
}
</style>
