<template>
  <section id="container-mobile-menu">
    <nav class="navbar navbar-light fixed-top w-100 p-0">
      <div class="navbar-hamburger-menu">
        <div class="d-flex align-items-center h-100">
          <button class="navbar-toggler" type="button" @click="menuStore.toggleMenu">
            <img src="@/assets/sideBar/hamburger.svg" alt="">
          </button>
        </div>
      </div>
      <div class="navbar-avatar-notifications ms-auto">
        <!-- Notifications Dropdown -->
        <div class="nav-item dropdown">
          <a class="nav-link" @click="sendToNotificationsMobile">
            <img src="@/assets/Header/notificationBell.svg" alt="">
          </a>
        </div>
      </div>
    </nav>
    <div class="mobile-menu" :style="menuContainerStyle">
      <div class="mobile-menu-header">
        <div class="d-flex align-items-center justify-content-start mx-3 h-100">
          <button class="navbar-toggler" type="button" @click="menuStore.toggleMenu">
            <img src="@/assets/sideBar/arrow-back.svg" alt="">
          </button>
        </div>
      </div>
      <div class="mobile-menu-title">
        <img src="@/assets/sideBar/IconMenuTitle.svg" alt="">
        <span class="title">{{$t("menu")}}</span>
      </div>
      <hr class="divider">
      <div class="flex-grow-1 mb-5">
        <div :style="menuStyle" class="mobile-menu-content">
          <div class="d-flex flex-column align-items-start p-3">
            <ul class="navbar-nav me-auto">
              <li v-for="item in menuItems" :key="item.index">
                <div @click="menuStore.toggleMenu" class="item">
                  <router-link 
                    class="nav-link"
                    :to="{ name: item.routeName }"
                    @click="activeItem(item.index)"
                  >
                    <img class="icon" :src="item.icon" alt="">
                    <span class="color-secondary">{{ item.label }}</span>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <hr class="divider">
        <div class="mobile-menu-content">
          <div class="d-flex flex-column align-items-start p-3">
            <ul class="navbar-nav me-auto">
              <li>
                <div class="item cursor-pointer" @click="usersStore.logout()">
                  <div class="nav-link">
                    <img class="icon" src="@/assets/logout-icon.svg" alt="">
                    <span class="color-secondary">{{$t('Sign Out')}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useMenuStore, useUsersStore } from '@/stores';

export default {
  props: {
    currentItem: Number
  },
  setup() {
    const menuStore = useMenuStore();
    const usersStore = useUsersStore();

    return {
      menuStore,
      usersStore
    }
  },
  data() {
    return {
    };
  },
  computed: {
    menuItems() {
      return [
        { index: 0, icon: require("@/assets/sideBar/chain-view.svg"), routeName: 'ChainViewPage', label: "Chain View" },
        { index: 1, icon: require("@/assets/sideBar/pickup.svg"), routeName: 'PickUpPage', label: "Pickup" },
        { index: 2, icon: require("@/assets/sideBar/competitors.svg"), routeName: 'CompetitorsAnalysis', label: "Competitors" },
        { index: 3, icon: require("@/assets/sideBar/forecast.svg"), routeName: 'ForecastPage', label: "Forecast" },
        { index: 4, icon: require("@/assets/sideBar/budget.svg"), routeName: 'BudgetAnalysis', label: "Budget" },
        // { index: 5, icon: require("@/assets/sideBar/smartPricing.svg"), routeName: 'SmartPricing', label: "Smart Pricing" },
        { index: 6, icon: require("@/assets/sideBar/groupDisplacement.svg"), routeName: 'GroupDisplacement', label: "Group Displacement" },
        { index: 7, icon: require("@/assets/sideBar/company-settings.svg"), routeName: 'CompanySettingsPage', label: "Settings" },
        { index: 8, icon: require("@/assets/sideBar/reports.svg"), routeName: 'ReportsPage', label: "Reports" },
        { index: 9, icon: require("@/assets/sideBar/integrations.svg"), routeName: 'IntegrationsPage', label: "Integrations" },
      ];
    },
    menuContainerStyle() {
      return {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        overflow: 'auto',
        zIndex: '9999',
        boxShadow: 'rgba(0, 0, 0, 0.2) -3px 0px 10px 1px',
        transform: this.menuStore.isOpen ? 'translateX(0)' : 'translateX(-100%)',
        transition: 'transform 0.5s ease-in-out'
      }
    }
  },
  methods: {
    activeItem(index) {
      this.$emit('activeItem', index)
    },
    sendToNotificationsMobile() {
      this.$router.push({ name: 'NotificationsPage' });
    }
  },
};
</script>

<style scoped>
#container-mobile-menu{
  height: 60px;
  min-height: 60px;
}

nav.navbar {
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: rgb(247, 248, 249);
  height: 60px;
  min-height: 60px;
}

.navbar-hamburger-menu .navbar-toggler {
  border: none;
}

.navbar-hamburger-menu .navbar-toggler:focus,
.navbar-hamburger-menu .navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

/* Notifications */
.navbar-avatar-notifications {
  justify-content: flex-end;
  gap: 1rem;
  display: flex;
}

.navbar-avatar-notifications .nav-link img {
  width: 30px;
  height: 30px;
}

/* Mobile menu style */
.mobile-menu .mobile-menu-header {
  height: 60px;
  min-height: 60px;
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: rgb(247, 248, 249);
}

.mobile-menu .mobile-menu-title {
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.mobile-menu .mobile-menu-title .title {
  color: var(--theme-secondary, #6C757D);
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
}

.divider {
  stroke-width: 1px;
  stroke: var(--theme-border, #DEE2E6);
  padding: 0;
}

.mobile-menu-content .navbar-nav {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}

.mobile-menu-content .navbar-nav .nav-link,
.nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: .5rem 1rem;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.mobile-menu-content .navbar-nav .item>.router-link-active {
  background-color: rgba(108, 117, 125, .05);
}

.mobile-menu-content .navbar-nav .item>.router-link-active span{
  color: var(--theme-primary) !important;
}

.mobile-menu-content .navbar-nav .router-link-active .icon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
</style>