<template>
  <BaseSign>
    <template v-slot:content>
      <div class="container-fluid">
        <div class="row">
          <Form ref="myForm" class="form" :validation-schema="schema" v-slot="{ errors }">
            <div class="col-12">
              <img class="icon" src="@/assets/authentication/sign-up/logo.svg" alt="">
              <div class="my-2">
                <span class="title-primary">{{ $t('pleaseEnter2FACode') }}</span>
              </div>
              <div class="my-2">
                <p class="title-secondary text-start">{{ $t('Two-factor authentication (2FA) is enabled for your account. Please open the Google Authenticator app and enter the verification code to log in.') }} </p>
              </div>
            </div>
            <div class="form-group">
              <Field 
                v-model="code"
                name="code" 
                class="form-control" 
                :class="{ 'is-invalid': hasError(errors, 'code', ['2fa']) }"
                :placeholder="$t('Two-factor authentication code')" 
              />
              <ErrorMessage class="invalid-feedback text-start" name="code" as="div" />
              <span v-if="apiErrors['2fa']" class="invalid-feedback text-start"> {{ apiErrors['2fa'] }} </span>
            </div>
            <div class="form-group" v-bind:class="{ disabled: loading }">
              <CustomButton 
                class="btn mt-2" 
                :buttonStyle="{ borderRadius: '0 !important'}" 
                :label="$t('Verify')"
                :loading="loading"
                @clickBtn="onSubmit"
              />
            </div>
          </Form>
        </div>
      </div>
    </template>
  </BaseSign>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup'
import { useAlertStore, useUsersStore } from '@/stores';
import CustomButton from '@/components/CustomButton.vue';
import BaseSign from "@/components/BaseSign.vue";

export default {
  components: {
    Form,
    Field,
    CustomButton,
    BaseSign,
    ErrorMessage
  },
  setup() {
    const alertStore = useAlertStore();
    const usersStore = useUsersStore();

    return {
      alertStore,
      usersStore
    };
  },
  data() {
    return {
      loading: false,
      intervalId: null,
      code: ''
    }
  },
  mounted() {
    this.verifyUserData(); // Check for expiration on mount
    this.intervalId = setInterval(() => {
      this.verifyUserData();
    }, 1 * 60 * 1000); // Check every minute
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    verifyUserData() {
      const storedItem = localStorage.getItem('2FAUser');
      if (storedItem) {
        const userData = JSON.parse(storedItem);
        if (userData.expiry <= new Date().getTime()) {
          localStorage.removeItem('2FAUser');
          this.$router.push({ name: 'SignIn' });
        }
      } else {
        this.$router.push({ name: 'SignIn' });
      }
    },
    async onSubmit() {
      const { valid } = await this.$refs.myForm.validate();
      if (!valid) {
        return;
      }
      this.alertStore.clear();
      this.loading = true;
      const storedItem = localStorage.getItem('2FAUser');
      const code = this.code;
      const { email, password } = JSON.parse(storedItem).value;
      try {
        await this.usersStore.loginSecret(email, password, code);
        this.$router.push({ name: 'DashboardPage' });
        this.loading = false;
      }
      catch (error) {
        if(error && error['2fa']) {
          error['2fa'] = this.$t('invalid2faCode'); // create a custom error message for 2fa code
          this.alertStore.error(error);
        } else {
          this.alertStore.error(error);
        }
        this.loading = false;
      }
    },
    hasError(errors, label, keys) {
      let hasLocalError = errors[label];
      let hasApiError = keys ? Object.keys(this.apiErrors).some(key => keys.includes(key)) : false;
      return hasLocalError || hasApiError;
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    }
  },
  computed: {
    schema() {
      return Yup.object({
        code: Yup.string().required(this.$t('codeRequired')),
      })
    },
    apiErrors: function () {
      return this.alertStore.alert && this.alertStore.alert.output ? this.alertStore.alert.output : {};
    },
  },
}
</script>

<style scoped>
.title-primary {
  font-size: 20px;
  font-weight: 900;
  word-break: break-word;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  justify-content: flex-start;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--theme-primary);
  text-decoration: none;
  text-align: center;
}

.form-group a {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--theme-primary) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.icon {
  width: 130px;
}

.title-secondary {
  font-size: 15px;
  color: var(--theme-secondary);
  word-break: break-word;
}

</style>