import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./MyStyleDictionary/build/css/styles.css";
// JQuery
import 'jquery/src/jquery.js'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.css'

//pinia 
import { createPinia } from 'pinia'
const pinia = createPinia()

// vue i18n
import i18n from "./helpers/i18n";

// vueDatePicker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// Import VeeValidate rules for form validation
import '@/helpers/validation-rules';

// Router navigation guard
router.beforeEach((to, from, next) => {
  // Scroll to top between route changes using requestAnimationFrame for better performance
  requestAnimationFrame(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });

  // Define public pages that don't require authentication
  const publicPages = ['SignUp', 'CreateNewPassword', 'SignIn', 'VerificationCode', 'SecretCode'];
  
  // Safely get user data from localStorage
  let loggedIn = null;
  try {
    loggedIn = JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    console.error('Error parsing user data:', error);
    localStorage.removeItem('user'); // Clear invalid data
  }

  const user = loggedIn?.user;
  const hasCompanies = user?.companies?.length > 0;
  const hasInvitation = user?.invites_received?.[0]?.token;
  const isFirstLogin = user?.isFirstLogin === 1;
  const isPublicPage = publicPages.includes(to.name);
  const authRequired = !isPublicPage;

  // Handle authentication routing
  if (authRequired && !loggedIn) {
    // Redirect unauthenticated users
    return next({ 
      name: to.name === 'InvitationsPage' ? 'SignUp' : 'SignIn'
    });
  }

  // Handle logged-in user routing
  if (loggedIn) {
    // Handle invitation flows
    if (hasInvitation && to.name !== 'InvitationsPage') {
      return next({ 
        name: 'InvitationsPage', 
        params: { invitationToken: user.invites_received[0].token }
      });
    }

    // Route based on user state
    if (hasCompanies) {
      // Users with companies
      if (to.name === 'CompleteProfile' || 
          (isPublicPage && !isFirstLogin) ||
          (to.name === 'InvitationsPage' && !hasInvitation && !isFirstLogin)) {
        return next({ name: 'DashboardPage' });
      }
    } else if (!hasInvitation && to.name !== 'CompleteProfile') {
      // Users without companies need to complete profile
      return next({ name: 'CompleteProfile' });
    }
  }

  // Proceed with navigation
  next();
});

const app = createApp(App);
app.component('VueDatePicker', VueDatePicker);
app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');