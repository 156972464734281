<template>
    <div class="dropdown no-select">
      <a class="nav-link" id="dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="@/assets/Pickup/three-dots-vertical.svg" alt="edit-btn">
      </a>
      <ul class="dropdown-menu" :aria-labelledby="`dropdown-${identifier}`" :class="dynamicClass">
        <li class="li-body dropdown-item p-3 px-4 cursor-pointer" v-for="(item, index) in dropdownItems" :key="index" @click="handleItemClick(index)">
          <div class="d-flex align-items-center gap-2">
            <img class="icon" :src="item.icon" :alt="item.alt">
            <span class="label">{{ item.label }}</span>
          </div>
        </li>
      </ul>
    </div>
</template>
  
<script>
  export default {
    props: {
      dynamicClass: String,
      dropdownItems: Array,
      identifier: {
        type: Number,
        default: 0
      }
    },
    methods: {
      handleItemClick(index) {
        this.$emit('itemClick', index)
      },
    },
  };
</script>
  
<style scoped>
.dropdown-menu {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 30px 0px #00000014;
}

.dropdown-menu .li-body .label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6C757D;
}
.dropdown-menu .li-body:hover, .dropdown-menu .li-body:active, .dropdown-menu .li-body:focus {
  background-color: rgba(108, 117, 125, .05);
  border-radius: 5px;
}
.dropdown-menu .li-body:hover .label, .dropdown-menu .li-body:active .label, .dropdown-menu .li-body:focus .label {
  color: var(--theme-primary);
}
.dropdown-menu .li-body:hover .icon, .dropdown-menu .li-body:active .icon, .dropdown-menu .li-body:focus .icon {
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
}
</style>
  