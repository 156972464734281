<template>
    <section>
        <div v-if="$route.name.includes('ReportsPage')" class="container-fluid">
            <div class="row">
                <div class="container-list p-0">
                    <TableComponent 
                        v-model="activeItem" 
                        :layout="{
                            hasHeader: { desktop: false, mobile: false }
                        }" 
                        :tableRows="activeItemBodyColumn" 
                        @item-selected="handleItemSelected"
                        @all-items-selected="handleAllItemsSelected"
                        @rowClicked="rowClicked($event)"
                    >
                        <template v-slot:title>
                            <div class="row">
                                <div v-if="this.isActive == false" class="col-12 col-lg-6 d-flex align-items-center gap-2 my-4 px-4" id="title-div">
                                    <IconTitleButton 
                                        class="page-title" 
                                        :firstIcon="require('@/assets/MyProfile/folders-title-icon.svg')"
                                        :label="$t('reports')" 
                                        @toggleDropdown="toggleDropdown('reportsFolders')" 
                                    />
                                </div>
                                <div v-else class="col-12 col-lg-6 d-flex align-items-center gap-2 my-4 px-4" id="title-div">
                                    <IconTitleButton 
                                        class="page-title" 
                                        :firstIcon="require('@/assets/MyProfile/folders-title-icon.svg')"
                                        :label="$t('sharedReports')" 
                                        @toggleDropdown="toggleDropdown('reportsFolders')" 
                                    />
                                </div>
                                <div class="col-12 col-lg-6 d-flex align-items-center px-4" id="col-switch">
                                    <div class="switch-container" @click="toggleSwitch">
                                        <div class="cursor-pointer" :class="{ 'switch-inactive': isActive, 'switch-active': !isActive }">My Reports</div>
                                        <div class="cursor-pointer" :class="{ 'switch-active': isActive, 'switch-inactive': !isActive }">Shared Reports</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-list px-4">
                                <div class="col d-flex gap-4">
                                    <div>
                                        <div class="form-group has-search">
                                            <img class="form-control-feedback" src="@/assets/HotelGroup/searchBtn.svg"
                                                alt="">
                                            <input type="text" class="form-control search-input"
                                                placeholder="Search by name...">
                                        </div>
                                    </div>
                                    <div>
                                        <CustomButton 
                                            class="w-100" 
                                            :buttonType="'theme-secondary'" 
                                            :label="$t('createReport')" 
                                            :rightIcon="require('@/assets/ReportsPage/create-report-btn.svg')" 
                                            @clickBtn="sendToCreateReport"
                                            :buttonStyle="{
                                                borderRadius: '5px!important',
                                                gap: '8px',
                                                padding: '6px 35px',
                                                border: '1px solid var(--theme-secondary) !important',
                                            }"
                                        />
                                    </div>
                                </div>
                                <div class="col add-new-folder-div">
                                    <img class="cursor-pointer" src="@/assets/FoldersPage/addFolder.svg" alt="edit-dots" @click="showCreateFolderPopup = true">
                                    <!-- <img class="cursor-pointer" src="@/assets/FoldersPage/createFolder.svg" alt="edit-dots" @click="showCreateFolderPopup = true"> -->
                                </div>
                            </div>
                            <DividerComponent class="mt-5" />
                        </template>
                        <template v-slot:actions="{ item, rowIndex }">
                            <div class="d-none d-md-flex justify-content-end align-items-center px-5 gap-2">
                                <ThreeVerticalDots 
                                    :dropdownItems="settingsList" 
                                    :identifier="rowIndex"
                                    @itemClick="itemClick($event, item)"
                                />
                            </div>
                        </template>
                    </TableComponent>
                </div>
            </div>
        </div>
        <router-view></router-view>
    </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import ThreeVerticalDots from '@/components/ThreeVerticalDots.vue';
import CustomButton from '@/components/CustomButton.vue';
import IconTitleButton from '@/components/IconTitleButton.vue';

export default {
    name: 'ReportsPage',
    components: {
        TableComponent,
        DividerComponent,
        ThreeVerticalDots,
        CustomButton,
        IconTitleButton
    },
    data() {
        return {
            isDropdownReportsOpen: false,
            currentForecastItem: 0,
            isActive: false,
            activeItem: null,
            activeItemBodyColumn: null,
            showCreateFolderPopup: false,
            showRenameFolderPopup: false,
            showShareFolderPopup: false,
            showDeleteFolderPopup: false,
            dropdownForecastItems: {
                header: [{ id: 0, icon: require('@/assets/FoldersPage/reports-dropdown.svg'), label: this.$t('reports'), alt: 'menu-hamburger' }],
                body: [
                    {
                        id: 0,
                        icon: require('@/assets/Header/reports.svg'),
                        label: this.$t('manualReports'),
                        alt: 'manualReports'
                    },
                    {
                        id: 1,
                        icon: require('@/assets/Header/reports.svg'),
                        label: this.$t('scheduleReports'),
                        alt: 'scheduleReports'
                    },
                ]
            },
            selectedItems: [],
            bodyMyReportsRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'SimpleText', field: 'date' },
            ],
            itemsFromApiMyReports: [
                { id: 0, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Revenue Crystal Breeze Resort  Oct 2023' }, date: 'Dec, 2023' },
                { id: 1, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Revenue Serenity Sands Inn May 2023' }, date: 'Dec, 2023' },
                { id: 2, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Competitors Aurora Grande Hotel Apr 2022' }, date: 'Dec, 2023' },
            ],
            bodySharedReportsRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'ImageWithText', field: 'sharedBy' },
                { type: 'SimpleText', field: 'date' },
            ],
            itemsFromApiSharedReports: [
                { id: 0, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Revenue Crystal Breeze Resort  Oct 2023' }, sharedBy: { image: require('@/assets/ReportsPage/avatar-shared.svg'), firstText: 'Shared by  Marie Clarke' }, date: 'Dec, 2023' },
                { id: 1, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Revenue Crystal Breeze Resort  Oct 2023' }, sharedBy: { image: require('@/assets/ReportsPage/avatar-shared.svg'), firstText: 'Shared by  Marie Clarke' }, date: 'Dec, 2023' },
                { id: 2, name: { image: require('@/assets/FoldersPage/folder-icon.svg'), firstText: 'Revenue Crystal Breeze Resort  Oct 2023' }, sharedBy: { image: require('@/assets/ReportsPage/avatar-shared.svg'), firstText: 'Shared by  Marie Clarke' }, date: 'Dec, 2023' },
            ],
            settingsList: [
                {
                    id: 0,
                    icon: require('@/assets/FoldersPage/rename-icon.svg'),
                    alt: 'rename-icon',
                    label: this.$t('rename')
                },
                {
                    id: 1,
                    icon: require('@/assets/FoldersPage/share-icon.svg'),
                    alt: 'share-icon',
                    label: this.$t('share')
                },
                {
                    id: 2,
                    icon: require('@/assets/FoldersPage/delete-icon.svg'),
                    alt: 'delete-icon',
                    label: this.$t('delete')
                }
            ]
        }
    },
    methods: {
        toggleDropdown(dropdownName) {
            this.isDropdownReportsOpen = dropdownName === "reportsFolders" && !this.isDropdownReportsOpen;
        },
        handleItemSelected(selectedItemData) {
            const index = this.selectedItems.findIndex(item => item === selectedItemData);
            if (index === -1) {
                // If the item is not in the selectedItems array, add it
                this.selectedItems.push(selectedItemData);
            } else {
                // If the item is already in the selectedItems array, remove it
                this.selectedItems.splice(index, 1);
            }
        },
        handleAllItemsSelected(selectedItemsData) {
            if (selectedItemsData.length > 0) {
                // At least one item is selected, handle accordingly
                this.selectedItems = selectedItemsData;
            }
        },
        itemClick(index, item) {
            console.log(`Dropdown index - ${index}`, item)
            if(index == 0){
                this.showRenameFolderPopup = true;
            }else if (index == 1){
                this.showShareFolderPopup = true;
            }else if (index == 2){
                this.showDeleteFolderPopup = true;
            }
        },
        rowClicked(obj) {
            console.log('Row clicked:', obj);
            // this.$router.push({ name: route, query: { id: obj.id } });
        },
        toggleSwitch() {
            this.isActive = !this.isActive;
        },
        sendToCreateReport() {
            this.$router.push({ name: 'CreateReportPage' });
        }
    },
    created() {
        this.activeItem = this.itemsFromApiMyReports;
        this.activeItemBodyColumn = this.bodyMyReportsRows;
    },
    watch: {
        isActive(newVal) {
            if(newVal == true){
                this.activeItem = this.itemsFromApiSharedReports;
                this.activeItemBodyColumn = this.bodySharedReportsRows;
            }else{
                this.activeItem = this.itemsFromApiMyReports;
                this.activeItemBodyColumn = this.bodyMyReportsRows;
            }
        },
    },
}
</script>

<style scoped>
.container-list {
    border-radius: 15px 15px 0px 0px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.page-title {
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.forecast-dropdown-menu {
    min-width: 240px;
    left: 350px;
    top: 60px;
}

.has-search .form-control {
    padding-left: 40px;
}

.search-input {
    width: 300px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 38px;
    margin-left: 10px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.add-new-folder-div{
    display: flex;
    justify-content: flex-end;
}
.switch-container {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.switch-active,
.switch-inactive {
    min-width: 120px;
    height: 30px;
    transition: background-color 0.3s ease;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--Theme-Secondary, #6C757D);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 0 !important;
}

.switch-active {
    background-color: #d3d3d3;
}

.switch-inactive {
    background-color: rgb(245, 245, 245);
}
#col-switch{
    justify-content: flex-end;
}
@media (max-width: 450px) {
    .add-new-folder-div{
        justify-content: center;
        margin-top: 2rem;
    }
    #title-div{
        justify-content: center;
    }
}
</style>