import { METRICS_SERVICE } from "@/services/metrics.service.js";
import { defineStore } from 'pinia'

export const useMetricsStore = defineStore('metrics', {
    state: () => ({
        getHotelSummary: [],
        bookingWindowOutput: [],
        lengthOfStayOutput: [],
        totalPerformanceOutput: [],
        totalEvolutionOutput: [],
        competitorsAnalysisOutput: [],
        pickupTableOutput: [],
        lighthouseHotelsOutput: [],
        mewsHotelsOutput: []
    }),    
    getters: {
        hotelSummaryOutput: (state) => {
            return state.getHotelSummary ? state.getHotelSummary : [];
        }
    },    
    actions: {
        async fetchHotelSummary(queryParams) {
            try {
                const response = await METRICS_SERVICE.getHotelSummary(queryParams);
                this.getHotelSummary = response;
            } catch (error) {                
                throw error.message;
            }
        },
        async fetchBookingWindow(queryParams) {
            try {
                const response = await METRICS_SERVICE.getBookingWindow(queryParams);
                this.bookingWindowOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async fetchLengthOfStay(queryParams) {
            try {
                const response = await METRICS_SERVICE.getLengthOfStay(queryParams);
                this.lengthOfStayOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async fetchTotalPerformance(queryParams) {
            try {
                const response = await METRICS_SERVICE.getTotalPerformance(queryParams);
                this.totalPerformanceOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async fetchTotalEvolution(queryParams) {
            try {
                const response = await METRICS_SERVICE.getTotalEvolution(queryParams);
                this.totalEvolutionOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async fetchCompetitorsAnalysis(queryParams) {
            try {
                const response = await METRICS_SERVICE.getCompetitorsAnalysis(queryParams);
                this.competitorsAnalysisOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async pickupTable(queryParams) {
            try {
                const response = await METRICS_SERVICE.pickupTable(queryParams);
                this.pickupTableOutput = response;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async fetchLighthouseHotels(queryParams) {
            try {
                const response = await METRICS_SERVICE.getLighthouseHotels(queryParams);
                this.lighthouseHotelsOutput = response;
            } catch (error) {
                throw error.message;
            }
        },
        async fetchMewsHotels(queryParams) {
            try {
                const response = await METRICS_SERVICE.getMewsHotels(queryParams);
                this.mewsHotelsOutput = response;
            } catch (error) {
                throw error.message;
            }
        }
        // async createMetric(payload) {
        //     try {
        //         await METRICS_SERVICE.createMetric(payload);
        //     } catch (e) {
        //         throw e;
        //     }
        // },
        // async updateMetric(payload) {
        //     try{
        //         await METRICS_SERVICE.updateMetric(payload);
        //     } catch (e) {
        //         throw e;
        //     }
        // },
        // async removeMetric(payload) {
        //     try{
        //         await METRICS_SERVICE.removeMetric(payload);
        //     } catch (e) {
        //         throw e;
        //     }
        // }
    }
});