<template>
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-none d-md-flex gap-2" id="page-title">
          <BoldTitle :icon="require('@/assets/MyProfile/personalInfo.svg')" :title="$t('Personal Info')" />
        </div>
        <div class="col-12 d-flex justify-content-center my-5">
          <div class="file-picker">
            <img 
                v-if="Object.keys(picture).length" 
                class="cursor-pointer img-fluid rounded-circle" 
                :src="picture.url || parseImageData(picture)" 
                @click="showimagePicker = true"
            >                       
            <div v-else class="placeholder-img cursor-pointer" @click="showimagePicker = true" >
                <span class="d-flex flex-column placeholder" :class="{ 'bg-danger': !picture.url && imageError }">
                    {{ $t('Choose Image') }}
                    <pre class="m-0 text-muted">1:1</pre>
                </span>   
            </div>  
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="d-flex gap-2 mb-3">
            <img src="@/assets/MyProfile/name-form-icon.svg" alt="">
            <span>{{ $t("name") }}</span>
          </div>
          <input type="text" class="form-control" v-model="name">
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="d-flex gap-2 mb-3">
            <img src="@/assets/MyProfile/phone-form-icon.svg" alt="">
            <span>{{ $t("phoneNumber") }}</span>
          </div>
          <input type="text" class="form-control" v-model="phoneNumber">
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="d-flex gap-2 mb-3">
            <img src="@/assets/MyProfile/role-form-icon.svg" alt="">
            <span>{{ $t("role") }}</span>
          </div>
          <input disabled type="text" class="form-control" v-model="userInfoRole">
        </div>
        <div class="col-12 col-md-6 mt-4 dropdown">
          <label class="mb-3" for="currency">{{ $t('currency') }}</label>
          <DropdownButton
            class="dropdown-btn"
            :label="getLabel('currency')" 
            @clickBtn="toggleDropdown('currency')" 
            style="max-width: 100%; min-width: 100%;"
          />
          <DropdownMenu 
            v-model="selectedItems.currency" 
            :configuration="{
              items: currencyOptions,
              isDropdownOpen: dropdownStates.currency,
              hasHeader: false,
              hasBody: true,
              openDropdownPosition: 'left',
              selectionMode: 'single'
            }" 
            @close-dropdown="dropdownStates.currency = false" 
          />
        </div>
        <div class="col-12 col-md-6 mt-4 dropdown">
          <label class="mb-3" for="language">{{ $t('language') }}</label>
          <DropdownButton
            class="dropdown-btn"
            :label="getLabel('language')" 
            @clickBtn="toggleDropdown('language')" 
            style="max-width: 100%; min-width: 100%;"
          />
          <DropdownMenu 
            v-model="selectedItems.language" 
            :configuration="{
              items: languageOptions,
              isDropdownOpen: dropdownStates.language,
              hasHeader: false,
              hasBody: true,
              openDropdownPosition: 'left',
              selectionMode: 'single'
            }" 
            @close-dropdown="dropdownStates.language = false"
          />
        </div>
        <div class="col-12 col-md-6 mt-4 dropdown">
          <label class="mb-3" for="timeZone">{{ $t('timeZone') }}</label>
          <DropdownButton
            class="dropdown-btn"
            :label="getLabel('country')" 
            @clickBtn="toggleDropdown('country')" 
            style="max-width: 100%; min-width: 100%;"
          />
          <DropdownMenu 
            v-model="selectedItems.country" 
            :configuration="{
              items: countryOptions,
              isDropdownOpen: dropdownStates.country,
              hasHeader: false,
              hasBody: true,
              openDropdownPosition: 'left',
              selectionMode: 'single'
            }" 
            @close-dropdown="dropdownStates.country = false"
          />
        </div>
      </div>
      <!-- CustomPopup for save btn -->
      <CustomPopup v-show="isPopupVisible" @popUpClosed="isPopupVisible = false"
        :img="require('@/assets/MyProfile/successSave.svg')"
        :titlePopup="$t('AllYourSettingsHaveBeenSuccessfullySaved')">
        <template v-slot:body>
          <CustomButton class="btn mt-2 d-flex gap-2" :label="$t('GoBackToDashboard')" :buttonType="'theme-light'"
            @click="sendToDashboard" :rightIcon="require('@/assets/MyProfile/goToDashboardPopupIcon.svg')" :buttonStyle="{
              borderRadius: '8px!important',
              gap: '8px',
              padding: '6px 35px'
            }" />
        </template>
      </CustomPopup>
      <!-- CustomPopup for image picker -->
      <CustomPopup v-show="showimagePicker" @popUpClosed="showimagePicker = false"
        :titlePopup="$t('changeHotelGroupPicture')"
        :descriptionPopup="'Here you can change the photo of your hotel group to make it more easily recognizable.'">
        <template v-slot:body>
          <div class="d-flex flex-column align-items-center gap-3">
            <ImagePicker 
              v-model="selectedPicture" 
              :placeholder="require('@/assets/Company/placeholder-2.png')"
              :ratioWidth="1" 
              :ratioHeight="1"
            ></ImagePicker>
            <CustomButton 
            :class="{ 'disabled': selectedPicture?.url === picture?.url }" 
              :label="$t('addPicture')"
              buttonType="theme-light" 
              :leftIcon="require('@/assets/MyProfile/addProfilePicture-icon.svg')"
              @clickBtn="onChooseImageClicked" :buttonStyle="{
                borderRadius: '8px!important',
                gap: '8px',
                padding: '6px 35px'
              }" 
            />
          </div>
        </template>
      </CustomPopup>
    </div>
  </section>
</template>

<script>
import BoldTitle from '@/components/BoldTitle.vue';
import CustomButton from '@/components/CustomButton.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import DropdownButton from '@/components/Dropdown/DropdownButton.vue';
import DropdownMenu from '@/components/Dropdown/DropdownMenu.vue';

export default {
  components: {
    BoldTitle,
    CustomButton,
    CustomPopup,
    ImagePicker,
    DropdownButton,
    DropdownMenu
  },
  data() {
    return {
      isPopupVisible: false,
      showimagePicker: false,
      imagePicker: {},
      selectedItems: {
        currency: null,
        language: null,
        country: null,
      },
      // dropdown states
      dropdownStates: {
        currency: false,
        language: false,
        country: false,
      },
      picture: {},
      selectedPicture: {},
      name: "",
      phoneNumber: "",
    };
  },
  props: {
    userInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    userInfoRole() {
      return this.userInfo.companies && this.userInfo.companies.length > 0 
        ? this.userInfo.companies[0].pivot?.role || 'Unknown Role' 
        : 'Unknown Role';
    },
    currencyOptions() {
      return {
        header: [],
        body: [
          {
            id: 0,
            label: this.$t("EUR"),
            keyName: 'eur'
          },
          {
            id: 1,
            label: this.$t("USD"),
            keyName: 'usd'
          },
          // {
          //   id: 2,
          //   label: this.$t("gbp"),
          //   keyName: 'GBP'
          // },
          // {
          //   id: 3,
          //   label: this.$t("yen"),
          //   keyName: 'YEN'
          // },
          // {
          //   id: 4,
          //   label: this.$t("inr"),
          //   keyName: 'INR'
          // }
        ]
      }
    },
    languageOptions(){
      return {
        header: [],
        body: [
          {
            id: 0,
            label: this.$t("english"),
            keyName: 'EN'
          },
          {
            id: 1,
            label: this.$t("portuguese"),
            keyName: 'PT'
          },
          // {
          //   id: 2,
          //   label: this.$t("french"),
          //   keyName: 'FR'
          // },
          // {
          //   id: 3,
          //   label: this.$t("italian"),
          //   keyName: 'IT'
          // },
          // {
          //   id: 4,
          //   label: this.$t("dutch"),
          //   keyName: 'NL'
          // }
        ]
      }
    },
    countryOptions(){
      return {
        header: [],
        body: [
          {
            id: 0,
            label: this.$t("lisbon"),
            keyName: 'LIS'
          },
          {
            id: 1,
            label: this.$t("paris"),
            keyName: 'PAR'
          },
          {
            id: 2,
            label: this.$t("london"),
            keyName: 'LON'
          },
          {
            id: 3,
            label: this.$t("berlin"),
            keyName: 'BER'
          },
          {
            id: 4,
            label: this.$t("madrid"),
            keyName: 'MAD'
          }
        ]
      }
    }
  },
  methods: {
    sendToMyProfileMobile() {
      this.$router.push({ name: 'MyAccountMobile' });
    },
    sendToDashboard() {
      this.$router.push({ name: 'DashboardPage' });
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    onChooseImageClicked() {
      this.picture = this.selectedPicture;
      this.showimagePicker = false;
    },
    getLabel(key) {
      const item = this.selectedItems[key];
      return item && item.label ? item.label : this.$t(key);
    },
    toggleDropdown(dropdownName) {
      this.dropdownStates[dropdownName] = !this.dropdownStates[dropdownName];
    },
    parseImageData(imageDataString) {
        try {
          if(!Object.keys(imageDataString).length) return null;
          else {
            const parsedData = JSON.parse(imageDataString);
            if(!Object.keys(parsedData).length) {
              return require('@/assets/no-image.jpg');
            }
            return `data:${parsedData.mime};base64,${parsedData.data}`;
          }
        } catch (e) {
          console.error('Invalid JSON string:', e);
          return null;
        }
    }
  },
  watch: {
    userInfo: {
      handler() {
        this.picture = this.userInfo.picture || {};
        this.name = this.userInfo.name || '';
        this.phoneNumber = this.userInfo.phoneNumber || '';

        // Ensure the correct option is selected in the dropdowns
        this.selectedItems.currency = this.currencyOptions.body.find(currency => currency.keyName === this.userInfo.currency) || null;
        this.selectedItems.language = this.languageOptions.body.find(language => language.keyName === this.userInfo.language) || null;
        this.selectedItems.country = this.countryOptions.body.find(country => country.keyName === this.userInfo.country) || null;
      },
      deep: true,
      immediate: true // This will ensure the watcher is triggered when the component is mounted
    }
  }

};
</script>

<style scoped>
#save-btn-div {
  display: flex;
  justify-content: end;
}
.file-picker .placeholder-img, .file-picker img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    display: block;
    background-size: cover;
    background-position: center center;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.8;
}
.placeholder:hover {
  background: #E0E0E0;
}
.dropdown-btn {
  z-index: 1000;
}

@media only screen and (max-width: 767px) {
  #page-title {
    justify-content: center;
  }

  #save-btn-div {
    justify-content: center;
    margin-top: 2rem;
  }

  .btn-save-mobile {
    width: inherit !important;
  }
}
</style>
