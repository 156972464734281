<template>   
    <section id=list>
        <LayoutList
            v-model="itemsFromApi"
            :titleIcon="require('@/assets/HotelGroup/backBtn.svg')"
            :pageTitle="$t('companyActivity')"
            :hasDate="true"
            :layout="{
                hasHeader: { desktop: false, mobile: false }
            }"
            :tableRows="tableRows"
            :editIcon="require('@/assets/HotelGroup/editDots.svg')"
            :hasAddButtonMobile="false"
            :threeDotsSettings="threeDotsSettingsHeader"
            @pageActionClick="pageActionClick"
            @rowClicked="rowClicked"
        >
        </LayoutList>
    </section>
  </template>
  
  <script>
  import LayoutList from '@/components/LayoutList.vue';
  
  export default {
    components: {
        LayoutList,
    },
    data() {
        return {
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'SimpleText', field: 'time' }
            ],
            itemsFromApi: [
                { id: 0, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
                { id: 1, time:'30 min', name: { image: require('@/assets/Users/avatarMarie.svg'), firstText: 'Marie changed something on the integrations menu painel', secondText: 'Crystal Breeze Resort California' } },
            ],
            threeDotsSettingsHeader: [
                {
                    id: 0,
                    icon: require('@/assets/Popup/smallDeleteBtn.svg'),
                    alt: 'Delete Company',
                    label: this.$t('Delete Company'),
                },
                {
                    id: 1,
                    icon: require('@/assets/Popup/leaveHotel.svg'),
                    alt: 'Leave Company',
                    label: this.$t('Leave Company'),
                },
            ]
        }
    },
    methods: {
        pageActionClick() {
            console.log('pageActionClick')
        },
        rowClicked(obj) {
            console.log("rowClicked", obj)
        },
    },
  }
  </script>