/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { useUsersStore } from '@/stores';

/* Exports */
// API services that can be invoked on the modules
export const COMPANY_SERVICE = {
    getCompanyDetails,
    getCompanyById,
    createCompany,
    editCompany,
    deleteCompany
};

// Root service to build API url
const API_ROOT_SERVICE = "company";

/* Services */
async function getCompanyDetails() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getCompanyById(id)
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "id", id]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function createCompany(payload) 
{
    var raw = JSON.stringify(payload);
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);
    
    return await API_HELPER.apiCall(url, null, requestOptions);
}
async function editCompany(payload) 
{    
    var raw = JSON.stringify(payload);
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.apiCall(url, null, requestOptions);
}
async function deleteCompany() 
{
    const requestOptions = {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
