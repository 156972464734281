<template>
    <div class="row">
        <div class="container-wrapper">
            <slot></slot>
        </div>
    </div>
</template>
<style scoped>
.container-wrapper {
    border-radius: 15px 15px 0px 0px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
</style>