<template>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 d-flex justify-content-center mb-5">
                    <span class="page-title">See how our plans compare</span>
                </div>
                <div class="d-flex" v-if="isSmallScreen">
                    <div class="col d-flex flex-row justify-content-center" v-for="(titleTable, index) in titlesTableMobile" :key="index">
                        <span class="title-table">{{ titleTable.title }}</span>
                    </div>
                </div>
                <div class="d-flex" v-else>
                    <div class="col d-flex justify-content-center" v-for="(titleTable, index) in titlesTable" :key="index">
                        <span class="title-table">{{ titleTable.title }}</span>
                    </div>
                </div>
                <hr class="my-3">
                <div class="col-12 d-flex flex-row py-3" v-for="(inclusionsDetails, index) in inclusions" :key="index" :class="{ 'odd-index': index % 2 !== 0 }">
                    <div class="col d-flex justify-content-center" v-for="(inclusion, index) in inclusionsDetails" :key="index">
                        <span class="title-inclusion" v-if="inclusion.hasText == true">{{ inclusion.titleInclusion }}</span>
                        <img v-if="inclusion.isIconIncluded == true && inclusion.hasText == false" class="img-icon" src="@/assets/editPlan/includedBtn.svg" alt="">
                        <img v-else-if="inclusion.isIconIncluded == false && inclusion.hasText == false" class="img-icon" src="@/assets/editPlan/notIncludedBtn.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
export default{
    data() {
        return {
            titlesTable:[
                { title: "PRODUCT INCLUSION" },
                { title: "BUSINESS" },
                { title: "SMART" },
                { title: "ADVANCED" },
                { title: "MASTER" },
            ],
            titlesTableMobile:[
                { title: "PI" },
                { title: "B" },
                { title: "S" },
                { title: "A" },
                { title: "M" },
            ],
            inclusions:[
                [
                    { hasText: true, titleInclusion: "Reporting & Performance Dashboards" },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Pricing (AI)" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Optimization Level" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Market Insights" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Forecast" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Decisions" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                    { hasText: false, isIconIncluded: true },
                ],
                [
                    { hasText: true, titleInclusion: "Revenue Manager" },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: false },
                    { hasText: false, isIconIncluded: true },
                ],
            ]
        }
    },
    setup() {
        const isSmallScreen = ref(window.innerWidth < 600);

        const handleResize = () => {
            isSmallScreen.value = window.innerWidth < 600;
        };

        onMounted(() => {
            window.addEventListener('resize', handleResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });

        return {
            isSmallScreen,
        };
    },
}
</script>

<style scoped>
.img-icon{
    width: 17px !important;
}
.page-title{
    color: var(--Theme-Dark, #212529);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}
.title-table{
    color: var(--Theme-Primary, #A81800);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}
.title-inclusion{
    text-align: center;
    word-break: break-word;
    color: var(--Theme-Dark, #212529);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}
.odd-index {
    border-radius: 2px;
    background: var(--Theme-Light, #F8F9FA);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
hr{
    border:1px solid #A81800 !important;
    color: #A81800 !important;
    background-color: #A81800 !important;
    opacity: 1 !important;
}
</style>