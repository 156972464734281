<template>
    <div class="chart-container">
        <div class="legend" v-if="chartTitle">
            <div class="title">
                <h4 class="fw-bold">{{ chartTitle }}</h4>
            </div>
        </div>
        <template v-if="chartData && chartData.labels.length && chartData.datasets.length">
            <button 
                v-if="!isMobile"
                class="fullscreen-button" 
                @click="openFullScreen"
            >
                <img src="@/assets/DashboardPage/expandGraph.svg" alt="Fullscreen Icon" />
            </button>
            <Doughnut 
                id="chart-id"
                :options="chartOptions"
                :data="chartData"
            />
        </template>
        <template v-else>
            <div class="d-flex justify-content-center align-items-center h-100">
                <h6 class="text-center text-muted m-0 fs-12">{{ $t('No data to display') }}</h6>
            </div>
        </template>
    </div>
    <div v-if="isFullScreen" class="fullscreen-overlay">
        <div class="fullscreen-chart-container">
            <button class="close-fullscreen-button" @click="closeFullScreen">X</button>
            <div class="legend" v-if="chartTitle">
                <div class="title">
                    <h4 class="fw-bold">{{ chartTitle }}</h4>
                </div>
            </div>
            <Doughnut 
                id="fullscreen-chart-id"
                :options="chartOptions"
                :data="chartData"
            />
        </div>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';
import { useResponsive } from '@/composables/responsive';
ChartJS.register(ArcElement, Tooltip, Legend);

export default {
    name: 'DoughnutChart',
    components: {
        Doughnut,
    },
    props: {
        chartTitle: String,
        chartData: Object,
        chartOptions: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                    }
                }
            })
        }
    },
    setup() {
        const { isMobile } = useResponsive();
        return {
            isMobile,
        };
    },
    data() {
        return {
            isFullScreen: false,
        };
    },
    methods: {
        openFullScreen() {
            this.isFullScreen = true;
        },
        closeFullScreen() {
            this.isFullScreen = false;
        }
    }
}
</script>

<style scoped>
.chart-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}

.fullscreen-chart-container {
    width: 70%;
    height: 70%;
    background: white;
    padding: 60px;
    position: relative;
}

.close-fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.fs-12 {
    font-size: 12px;
}
@media (max-width: 575px) {
    .legend .title h4 {
        font-size: .8rem;
    }
    .growth img {
        width: 15px;
    }
    .growth span {
        font-size: .8rem;
    }
}
</style>