/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { useUsersStore } from '@/stores';

/* Exports */
// API services that can be invoked on the modules
export const MY_SERVICE = {
    getActivityList,
    getSettingsList,
    updateSettings
};

// Root service to build API url
const API_ROOT_SERVICE = "my";

/* Services */
async function getActivityList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "/activity"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function getSettingsList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "/settings"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
  async function updateSettings(settings) 
{ 
    var raw = JSON.stringify(settings);
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw
    };
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "/settings"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);    
}
