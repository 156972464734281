<template>
  <div class="container-fluid container-wrapper">
    <div class="row">
        <div class="col-12 d-flex gap-2 justify-content-center p-3">
            <img :src="rankingItemApi.brandIcon" alt="">
            <span class="title">{{ rankingItemApi.brandName }}</span>
        </div>
        <DividerComponent class="my-3" />
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center flex-column p-3">
            <span class="sub-title">{{ $t("ranking") }}</span>
            <div class="d-flex gap-2 justify-content-center align-items-center">
                <span class="values">{{ rankingItemApi.rankingValue }}</span>
                <div class="retangle-ranking">
                    <span class="rankingPosition-span">{{ rankingItemApi.rankingPosition }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center flex-column p-3">
            <span class="sub-title">{{ $t("reviewScore") }}</span>
            <span class="values">{{ rankingItemApi.reviewValue }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import DividerComponent from "@/components/DividerComponent";
export default {
    components: { DividerComponent },
    props: {
        rankingItemApi: {
            type: Object,
        },
    },
}
</script>

<style scoped>
.container-wrapper{
    border-radius: 15px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.title{
    color: #6C757D;
    font-size: 16px;
    font-weight: 400;
}
.sub-title{
    color: #6C757D;
    font-size: 12px;
    font-weight: 400;
}
.values{
    color: #212529;
    font-size: 12px;
    font-weight: 500;
}
.retangle-ranking{
    background-color: #D1E7DD;
    padding: 3px;
    display: flex;
    align-items: center;
}
.rankingPosition-span{
    color: #006837;
    font-size: 8px;
    font-weight: 600;
}
</style>