<template>
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <BoldTitle :title="$t('onlineReputation')"/>
          </div>
      </div>
      <div class="container-wrapper mt-5 p-4">
        <div class="row">
          <div class="col-12 my-2 d-flex gap-2">
            <img src="@/assets/Market/onlineReputation.svg">
            <span>Ranking</span>
          </div>
          <div class="col-12 col-lg-4" v-for="(rankingItem, index) in rankingItems" :key="index">
          <RankingOnlineReputation :rankingItemApi="rankingItem"></RankingOnlineReputation>
        </div>
        </div>
        <div class="container-wrapper mt-5">
          <div class="col-12 p-3">
            <span>Compset</span>
          </div>
          <DividerComponent />
          <TableComponent v-model="itemsFromApi" :layout="{
            hasHeader: { desktop: true, mobile: false }
          }" :tableHeaders="tableHeaders" :tableRows="tableRows">
          </TableComponent>
        </div>
      </div>
  </div>
</template>
<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import RankingOnlineReputation from '@/components/Market/RankingOnlineReputation.vue';

export default {
  components: {
      BoldTitle,
      DividerComponent,
      TableComponent,
      RankingOnlineReputation
  },
  setup() {
      return {}
  },
  data() {
      return {
          //table
          tableHeaders: [
              {
                  icon: require('@/assets/Market/hotel.svg'),
                  text: 'Hotel'
              },
              {
                  icon: require('@/assets/Market/onlineReputation.svg'),
                  text: 'Ranking'
              },
              {
                  icon: require('@/assets/Pickup/graph-up.svg'),
                  text: 'Score'
              },
          ],
          tableRows: [
              { type: 'ImageWithText', field: 'Hotel' },
              { type: 'SimpleText', field: 'Ranking' },
              { type: 'SimpleText', field: 'Score' },
          ],
          itemsFromApi: [
              { Hotel: { image: require('@/assets/Market/hotel.svg'), firstText: 'Mercure Porto Centro Aliados' }, Ranking: '26,29', Score: '9.1', },
              { Hotel: { image: require('@/assets/Market/hotel.svg'), firstText: 'PortoBay Teatro' }, Ranking: '26,29', Score: '9.1', },
              { Hotel: { image: require('@/assets/Market/hotel.svg'), firstText: 'Axis Porto Club Aliados' }, Ranking: '26,29', Score: '9.1', },
              { Hotel: { image: require('@/assets/Market/hotel.svg'), firstText: 'Chic & Bacic Gravity' }, Ranking: '26,29', Score: '9.1', }
          ],
          // hotel dropdown
          isDropdownHotelOpen: false,
          dropdownHotelItems: [
              {
                  id: 0,
                  label: "Cystal Breeze Resort California 1 ",
                  avatar: require('@/assets/avatar.png')
              },
              {
                  id: 1,
                  label: "Cystal Breeze Resort California 2",
                  avatar: require('@/assets/avatar.png')
              }
          ],
          rankingItems:[
            {brandIcon: require("@/assets/Market/booking.svg"), brandName: "Booking.com", rankingValue: "55", rankingPosition: "+23", reviewValue: "8.6"},
            {brandIcon: require("@/assets/Market/expedia.svg"), brandName: "Expedia", rankingValue: "55", rankingPosition: "+23", reviewValue: "8.6"},
            {brandIcon: require("@/assets/Market/tripadvisor.svg"), brandName: "TripAdvisor", rankingValue: "55", rankingPosition: "+23", reviewValue: "8.6"},
          ]
      }
  },
  methods: {
      toggleDropdown(dropdownName) {
          this.isDropdownPickupOpen = dropdownName === "pickup" && !this.isDropdownPickupOpen;
          this.isDropdownMetricsOpen = dropdownName === "metrics" && !this.isDropdownMetricsOpen;
          this.isDropdownHotelOpen = dropdownName === "hotel" && !this.isDropdownHotelOpen;
      },
      handleCheckboxClick(index, propertyName) {
          const selectedItem = this[propertyName][index];
          selectedItem.checked = !selectedItem.checked;

          // Check if the item is already in the selectedHotelItems array
          const selectedIndex = this.selectedHotelItems.findIndex(item => item.id === selectedItem.id);

          if (selectedItem.checked && selectedIndex === -1) {
              // If the item is selected and not in the array, add it
              this.selectedHotelItems.push(selectedItem);
          } else if (!selectedItem.checked && selectedIndex !== -1) {
              // If the item is deselected and in the array, remove it
              this.selectedHotelItems.splice(selectedIndex, 1);
          }
      },
      updateDateProperty(propertyName, newValue) {
          this[propertyName] = newValue;
      },
      handleClick(index) {
          this.currentMetricsItem = index;
          // this.isSubMenuOpen = true;
      },
      confirmSelectedDate(date) {
          console.log(date)
      },
      confirmRangeValue(value) {
          console.log(value)
      }
  },
  watch: {
      selectedHotelItems: {
          handler(newValue) {
              console.log(newValue)
          },
          deep: true
      }
  },
}
</script>
<style scoped>
.container-wrapper {
  border-radius: 15px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.pickup-dropdown-menu {
  min-width: 240px;
  left: 210px;
  top: -30px;
}

.metrics-dropdown-menu {
  min-width: 300px;
  left: -320px;
  top: 0;
}

.hotel-dropdown-menu {
  min-width: 450px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 2px 4px 0px #00000013;
  right: 0;
  top: 50px;
}
#container-list-folders-popup{
  width: 50vw;
}
#row-list-folders-popup{
  display: flex;
  flex-direction: column;
}
#col-folder-style{
  border-radius: 4px;
  background-color: var(--Gray-White, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
}
:deep(.three-dots-menu) {
  right: 30px !important;
  top: -80px !important;
}
@media only screen and (max-width: 1000px) {
  #container-list-folders-popup{
      width: 90vw;
  }
}

</style>