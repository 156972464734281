/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { useUsersStore } from '@/stores';

/* Exports */
// API services that can be invoked on the modules
export const SUBSCRIPTION_SERVICE = {
    getList,
    createSubscriptionPlanBySlug,
    purchasePlan,
    managePayments
};

// Root service to build API url
const API_ROOT_SERVICE = "plans";

/* Services */
async function getList() 
{
    const requestOptions = {
        method: 'GET',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
async function createSubscriptionPlanBySlug(slug) 
{
    const requestOptions = {
        method: 'POST',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug, "buy"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
// Returns the payment Link
async function purchasePlan(slug, frequency) 
{
    const raw = JSON.stringify({ frequency });
    
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + useUsersStore().user.access_token},
        body: raw,
    };
    console.log("requestOptions subscriptions purchasePlan", raw);
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, slug, "purchase"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}
// Returns the link to manage the payments
async function managePayments() 
{
    const requestOptions = {
        method: 'POST',
    };
    let url = API_HELPER.pathJoin([process.env.VUE_APP_API_URL, API_ROOT_SERVICE, "customer", "portal"]);

    return await API_HELPER.loggedApiCall(url, null, requestOptions);
}