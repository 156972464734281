<template>
    <div class="d-flex cursor-pointer" id="row-style">
        <div class="col" v-for="(tabInfo, index) in tabsInfo" :key="index" :class="{ 'active-tab': index === activeTabIndex }" @click="changeTab(index, tabInfo)">
            <div class="tab d-flex justify-content-center align-items-center h-100 gap-2 px-2">
                <img 
                    :src="tabInfo.icon" 
                    alt=""
                    :style="{ filter: index === activeTabIndex ? 'brightness(0) invert(1)' : 'none' }"
                />
                <span class="label" :style="{ color: index === activeTabIndex ? 'white' : '#6C757D' }">
                    {{ tabInfo.label }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabsInfo: {
            type: Array,
        },
    },
    data() {
        return {
            activeTabIndex: 0, // Default to the first tab
        };
    },
    methods: {
        changeTab(index, tabInfo) {
            this.activeTabIndex = index;
            this.$emit('tabChanged', tabInfo);
        },
    },
}
</script>

<style scoped>
#row-style{
    border-radius: 6px;
    background-color: var(--Gray-White, #FFF);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    min-height: 45px;
    white-space: nowrap;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
 /* Hide scrollbar for Chrome, Safari and Opera */
#row-style::-webkit-scrollbar {
    display: none;
}
.active-tab {
    background-color: var(--Theme-Primary, #A81800);
    border-radius: 6px;
}
.label {
    font-size: 14px;
}
@media (max-width: 767.98px){
    .tab{
        min-width: 140px;
    }
}
</style>