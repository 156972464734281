<template>
    <section id="pick-up">
        <template v-if="isMobile">
            <PageSubMenu class="mobile-side-menu" :class="{ 'mobile-open': menuStore.isSubMenuOpen }" :sections="menuSections" />
        </template>
        <template v-else>
            <PageSubMenu :sections="menuSections" />
        </template>
        <!-- General or Segmented component to display content -->
        <router-view />
    </section>
</template>
<script>
import PageSubMenu from '@/components/PageSubMenu.vue';
import { useResponsive } from '@/composables/responsive.js';
import { useMenuStore } from '@/stores';

export default {
    components: {
        PageSubMenu
    },
    setup() {
        const { isMobile, isTablet, isDesktop} = useResponsive();
        const menuStore = useMenuStore();

        return {
            isMobile,
            isTablet,
            isDesktop,
            menuStore
        }
    },
    mounted() {
        if(this.isMobile && this.menuStore.isSubMenuOpen) {
            this.menuStore.isSubMenuOpen = false;
        }
    },
    data() {
        return {
            menuSections: [
                {
                    title: this.$t('General Pickup'),
                    items: [
                        { label: this.$t('Room Nights'), icon: require('@/assets/Pickup/rn-icon.svg'), pathName: 'GeneralPickUpRoomNights' },
                        { label: this.$t('Occupancy'), icon: require('@/assets/Pickup/occupancy-icon.svg'), pathName: 'GeneralPickUpOccupancy' },
                        { label: this.$t('Revenue'), icon: require('@/assets/Pickup/revenue-icon.svg'), pathName: 'GeneralPickUpRevenue' },
                        { label: this.$t('Adr'), icon: require('@/assets/Pickup/adr-icon.svg'), pathName: 'GeneralPickUpAdr' },
                        { label: this.$t('RevPar'), icon: require('@/assets/Pickup/revPar-icon.svg'), pathName: 'GeneralPickUpRevPar' },
                        { label: this.$t('All'), icon: require('@/assets/Pickup/collection-fill.svg'), pathName: 'GeneralPickUpAll' }
                    ]
                },
                {
                    title: this.$t('Segmented Pickup'),
                    items: [
                        { label: this.$t('Room Nights'), icon: require('@/assets/Pickup/rn-icon.svg'), pathName: 'SegmentedPickUpRoomNights' },
                        { label: this.$t('Occupancy'), icon: require('@/assets/Pickup/occupancy-icon.svg'), pathName: 'SegmentedPickUpOccupancy' },
                        { label: this.$t('Revenue'), icon: require('@/assets/Pickup/revenue-icon.svg'), pathName: 'SegmentedPickUpRevenue' },
                        { label: this.$t('Adr'), icon: require('@/assets/Pickup/adr-icon.svg'), pathName: 'SegmentedPickUpAdr' },
                        { label: this.$t('RevPar'), icon: require('@/assets/Pickup/revPar-icon.svg'), pathName: 'SegmentedPickUpRevPar' },
                        { label: this.$t('All'), icon: require('@/assets/Pickup/collection-fill.svg'), pathName: 'SegmentedPickUpAll' }
                    ]
                }
            ]
        }
    },
    watch: {
        '$route'(to, from) {
            if(to.name !== from.name) {
                this.menuStore.isSubMenuOpen = false;
            }
        }
    }
}
</script>
<style scoped>
/* Mobile side menu */
.mobile-side-menu {
    position: fixed!important;
    top: 105px!important;
    left: 0!important;
    height: calc(100vh - 165px)!important;
    min-height: calc(100vh - 165px)!important;
    width: 250px!important;
    border-right: unset!important;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1)!important;
    transform: translateX(-250px)!important;
    transition: transform 0.5s ease-in-out!important;
}
.mobile-side-menu.mobile-open {
    transform: translateX(0)!important;
}

/* @supports code is important for solving the height issue on iOS Safari and Chrome */
@supports (height: 100dvh) {
.mobile-side-menu {
    height: calc(100dvh - 165px)!important;
    min-height: calc(100dvh - 165px)!important;
    overflow-y: auto;
  }
}
</style>