<template>
  <section>
    <div class="container-fluid">
        <div class="row">
          <div class="col-12 d-flex align-items-center p-2 w-auto d-md-none d-flex" @click="sendToMyProfileMobile">
            <img src="@/assets/HotelGroup/backBtn.svg" alt="">
          </div>
          <DividerComponent class="my-5 d-md-none d-flex"/>
          <div class="col-12 col-md-6 d-flex gap-2" id="page-title">
            <BoldTitle :icon="require('@/assets/MyProfile/user-icon.svg')" :title="$t('Account Activity')"/>
          </div>
          <!-- Button Desktop -->
          <div class="col-12 col-md-6 d-none d-md-flex" id="save-btn-div">
            <CustomButton 
              class="btn mt-2 d-flex gap-2" 
              label="See all"
              buttonType="theme-light" 
              @clickBtn="clickBtn" 
              :rightIcon="require('@/assets/HotelGroup/seeAll.svg')" 
              :buttonStyle="{
                  borderRadius: '8px!important',
                  gap: '8px',
                  padding: '6px 35px'
              }"
            />
          </div>
          <div class="col-12" id="col-account-activity-info">
            <div class="col d-flex gap-5" id="col-avatar-text">
              <img src="@/assets/HotelIndividual/avatarUser.svg" id="user-avatar-img" alt="">
              <div class="d-flex flex-column gap-2" id="col-info">
                <span>You add a new adress in your <b>Billing</b> informations</span>
                <span>Account settings</span>
              </div>
            </div>
            <div class="col d-flex justify-content-end align-items-center gap-3">
              <span id="time-span">30 min</span>
              <ThreeVerticalDots :dynamicClass="'three-dots-menu'" :dropdownItems="threeDotsSettings" @itemClick="((index)=> console.log(index))"/>
            </div>
          </div>
          <!-- Button Mobile -->
          <div class="col-12 col-md-6 d-md-none d-flex" id="save-btn-div">
              <CustomButton 
                class="btn mt-2 d-flex gap-2 btn-save-mobile" 
                label="Save" @clickBtn="clickBtn" 
                :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')" 
                :buttonStyle="{
                    borderRadius: '8px!important',
                    gap: '8px',
                    color: '#FFFFFF',
                    padding: '6px 35px'
                }"
              />
            </div>
        </div>
    </div>
  </section>
</template>
  
<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import ThreeVerticalDots from '@/components/ThreeVerticalDots.vue';
  export default {
    components: {
      BoldTitle,
      DividerComponent,
      CustomButton,
      ThreeVerticalDots
    },
    data() {
      return {
        isChecked: false,
      }
    },
    methods: {
      sendToMyProfileMobile() {
          this.$router.go(0)
      },
      updateChecked(newValue) {
        this.isChecked = newValue;
      },
    },
  }
</script>
  
<style scoped>
#save-btn-div{
  display: flex;
  justify-content: end;
}
#col-account-activity-info{
  border-radius: 8px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}
#time-span{
  color: var(--gray-600, #6C757D);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.btn-user-status{
    display: flex;
    padding: 4.2px 7.8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--theme-success, #198754);
    color: var(--gray-white, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    border: none;
}
@media only screen and (max-width: 767px) {
  #page-title{
    justify-content: center;
  }
  #save-btn-div{
    justify-content: center;
    margin-top: 2rem;
  }
  #user-avatar-img{
    width: 40px;
    height: 40px;
  }
  .btn-save-mobile{
    width: inherit !important;
  }
  #col-account-activity-info{
    flex-direction: column;
    align-items: center;
  }
  #col-avatar-text{
    flex-direction: column !important;
    align-items: center !important;
  }
  #col-info{
    align-items: center !important;
    text-align: center !important;
  }
}
</style>