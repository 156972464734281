<template>
    <div class="d-flex row-style">
        <div class="col-12 col-lg-6 content-body">
            <div class="form-container">
                <slot name="content">
                    <!-- Content goes here -->
                </slot>
            </div>
        </div>
        <div class="d-none d-lg-block col-6">
            <!-- Slides -->
            <swiper :pagination="true" :modules="modules" :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }" class="mySwiper">
                <swiper-slide class="img-background content-slider">
                    <div class="d-flex justify-content-end">
                        <img class="img-title" src="@/assets/authentication/sign-up/revenueTitle.svg">
                    </div>
                    <div class="slider-img-div">
                        <img class="slider-img" src="@/assets/authentication/sign-up/sliderImage.svg" alt="">
                    </div>
                    <span class="title">{{ $t('welcomeToYourNewDashboard') }}</span>
                    <span class="sub-title">{{ $t("signInToExploreChangesWeHaveMade.") }}</span>
                </swiper-slide>
                <swiper-slide class="img-background content-slider">
                    <div class="d-flex justify-content-end">
                        <img class="img-title" src="@/assets/authentication/sign-up/revenueTitle.svg">
                    </div>
                    <div class="slider-img-div">
                        <img class="slider-img" src="@/assets/authentication/sign-up/sliderImage.svg" alt="">
                    </div>
                    <span class="title">{{ $t('welcomeToYourNewDashboard') }}</span>
                    <span class="sub-title">{{ $t("signInToExploreChangesWeHaveMade.") }}</span>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import '@/assets/Slider-Plugin/style.css';
import { Pagination, Autoplay } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination, Autoplay],
        };
    },
    data() {
        return {

        }
    },
};
</script>

<style scoped>
.row-style {
    min-height: 100vh;
}

.form-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.content-body {
    display: flex;
    align-items: center;
    padding: 1.5rem;
}


.content-slider {
    display: flex;
    flex-direction: column;
}

.img-background {
    background-image: url('@/assets/authentication/sign-up/sliderBackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 100px;
}


.img-title {
    width: 225px;
    height: 13px;
    margin: 2rem;
}

.slider-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.slider-img {
    max-width: 500px;
    max-height: 400px;
}

.title {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-top: 5rem;
}

.sub-title {
    color: white;
    font-size: 15px;
    text-align: center;
}
</style>