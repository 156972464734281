<template>
  <section>
    <div v-if="alertType == 'success'" class="container-alert-success">
        <img src="@/assets/ControlerAssets/validationSuccess.svg" alt="">
        <span v-html="title"></span>
    </div>
    <div v-if="alertType == 'error'" class="container-alert-error">
        <img src="@/assets/ControlerAssets/validationError.svg" alt="">
        <span v-html="title"></span>
    </div>
  </section>
</template>

<script>
export default {
props: {
    alertType: {
        type: String,
    },
    title: {
        type: String,
    },
},
}
</script>

<style scoped>
.container-alert-success{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 16px;
    flex-shrink: 0;
    color: #0F5132;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    border-radius: 8px;
    border: 1px solid var(--components-alert-success-border, #BADBCC);
    background: var(--components-alert-success-background, #D1E7DD);
    text-align: left;
}
.container-alert-error{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 16px;
    flex-shrink: 0;
    color: var(--components-alert-danger-text, #842029);
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    border-radius: 8px;
    border: 1px solid var(--components-alert-danger-border, #F5C2C7);
    background: var(--components-alert-danger-background, #F8D7DA);
    text-align: left;
}

.container-alert-error span,
.container-alert-success span{
    word-break: break-word;
}
</style>