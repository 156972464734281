<template>
<section>
    <div class="p-2 p-sm-3">
        <TableComponent 
            v-model="formatedItems" 
            :layout="{ hasHeader: { desktop: false, mobile: false } }" 
            :tableRows="tableRows" 
        >
            <template v-slot:title>
                <div class="d-flex d-lg-none align-items-center justify-content-between mx-0 mx-sm-2 my-2 my-sm-4">
                    <div class="d-flex align-items-center gap-2">
                        <img src="@/assets/NotificationsMobile/notifications-icon.svg" alt="">
                        <span class="page-title">{{$t("notifications")}}</span>
                    </div>
                    <!-- <div class="d-flex align-items-center gap-2 cursor-pointer" v-if="showRead == false" @click="showRead = true">
                        <img src="@/assets/NotificationsMobile/unreadedMessages.svg" alt="">
                        <span>Unread</span>
                    </div>
                    <div class="d-flex align-items-center gap-2 cursor-pointer" v-if="showRead == true" @click="showRead = false">
                        <img src="@/assets/NotificationsMobile/readedMessages.svg" alt="">
                        <span>Read</span>
                    </div> -->
                </div>
                <DividerComponent class="d-block d-md-none my-3" />
            </template>
        </TableComponent>
        <CustomPopup v-show="showNotification" @popUpClosed="showNotification = false">
            <template v-slot:body>
                <CustomButton class="btn mt-5 d-flex gap-2" :label="'Delete this notification'" :buttonType="'theme-light'" :rightIcon="require('@/assets/NotificationsMobile/deny-btn.svg')" 
                    :buttonStyle="{
                        borderRadius: '8px!important',
                        gap: '8px',
                        padding: '6px 35px'
                    }" 
                />
                <CustomButton class="btn mt-5 d-flex gap-2" :label="'Mark as read'" :buttonType="'primary'" :rightIcon="require('@/assets/NotificationsMobile/aprove-btn.svg')" 
                    :buttonStyle="{
                        borderRadius: '8px!important',
                        gap: '8px',
                        padding: '6px 35px'
                    }" 
                />
            </template>
        </CustomPopup>
        <CustomPopup v-show="showAccessRequest" @popUpClosed="showAccessRequest = false" :img="require('@/assets/NotificationsMobile/bigAvatar.svg')" :titlePopup="'Do you want to give acess to Marie Clarke?'">
            <template v-slot:body>
                <CustomButton class="btn mt-5 d-flex gap-2" :label="'Deny'" :buttonType="'theme-light'" :rightIcon="require('@/assets/NotificationsMobile/deny-btn.svg')" 
                    :buttonStyle="{
                        borderRadius: '8px!important',
                        gap: '8px',
                        padding: '6px 35px'
                    }" 
                />
                <CustomButton class="btn mt-5 d-flex gap-2" :label="'Aprove'" :buttonType="'primary'" :rightIcon="require('@/assets/NotificationsMobile/aprove-btn.svg')" 
                    :buttonStyle="{
                        borderRadius: '8px!important',
                        gap: '8px',
                        padding: '6px 35px'
                    }" 
                />
            </template>
        </CustomPopup>
    </div>
</section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import CustomPopup from '@/components/popups/CustomPopup.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    components: {
        TableComponent,
        DividerComponent,
        CustomPopup,
        CustomButton
    },
    props: {
        itemsFromApi: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showRead: false,
            showNotification: false,
            showAccessRequest: false,
            selectedItems: [],
            formatedItems: [],
            tableRows: [{
                type: 'ImageWithText',
                field: 'name'
            }],
            actionTranslations: {
                'user.login': 'user.login',
                'user.update': 'user.update',
                'user.update.password': 'user.update.password',
                'company.create': 'company.create',
                'company.active.read': 'company.active.read',
                'company.active.update': 'company.active.update',
                'company.active.delete': 'company.active.delete',
                'company.active.switch': 'company.active.switch',
                'company.read': 'company.read',
                'company.users.read': 'company.users.read',
                'company.user.read': 'company.user.read',
                'company.user.create': 'company.user.create',
                'company.user.update': 'company.user.update',
                'company.user.delete': 'company.user.delete',
                'hotels.read': 'hotels.read',
                'hotel.read': 'hotel.read',
                'hotel.create': 'hotel.create',
                'hotel.update': 'hotel.update',
                'hotel.delete': 'hotel.delete',
                'hotel.groups.read': 'hotel.groups.read',
                'hotel.group.read': 'hotel.group.read',
                'hotel.group.create': 'hotel.group.create',
                'hotel.group.update': 'hotel.group.update',
                'hotel.group.delete': 'hotel.group.delete',
                'hotel.group.hotel.delete': 'hotel.group.hotel.delete'
            },
        }
    },
    methods: {
        rowClicked(obj) {
            if (obj.type === 'notification') {
                this.showNotification = true;
            } else if (obj.type === 'accessRequest') {
                this.showAccessRequest = true
            }
        },
        timeDifference(timestamp) {
            const now = new Date();
            const createdAt = new Date(timestamp);
            const diffInSeconds = Math.floor((now - createdAt) / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);

            if (diffInDays > 0) {
                return `${diffInDays} ${this.$t('daysAgo')}`;
            } else if (diffInHours > 0) {
                return `${diffInHours} ${this.$t('hoursAgo')}`;
            } else if (diffInMinutes > 0) {
                return `${diffInMinutes} ${this.$t('minutesAgo')}`;
            } else {
                return `${diffInSeconds} ${this.$t('secondsAgo')}`;
            }
        }
    },
    watch: {
        itemsFromApi: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.data) {
                this.formatedItems = newVal.data.map(item => {
                    const actionTranslationKey = this.actionTranslations[item.action] || item.action;
                    return {
                    id: item.userId,
                    name: {
                        image: item.user && item.user.picture ? item.user.picture : require('@/assets/HotelGroup/avatar.svg'),
                        firstText: item.user ? `${item.user.name} ${this.$t('performed')} ${this.$t(actionTranslationKey)}` : 'Unknown user',
                        secondText: this.timeDifference(item.createdAt)
                    }
                    };
                });
                }
            }
        }
    }
}
</script>

  
<style scoped>
.page-title {
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}
</style>
