<template>
  <section>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12">
              <BoldTitle :icon="require('@/assets/Billing/billing-mobile-icon.svg')" :title="$t('Billing')"/>
              <span id="page-sub-title">{{$t("Manage your billing information and plans.")}}</span>
            </div>
            <div class="col-5 col-retangle-style">
              <div class="d-flex flex-column w-100">
                <div class="col d-flex flex-row px-3 flex-wrap">
                  <div class="col-12 col-lg-6 d-flex flex-column gap-2">
                    <div class="d-flex flex-row gap-2 align-items-center">
                      <img src="../../assets/Billing/businessPlan.svg" alt="">
                      <span class="col-retangle-style-title">Business Plan</span>
                    </div>
                    <span class="col-retangle-style-description">Our most popular plan for small teams. </span>
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-row gap-2 justify-content-end align-items-center">
                    <span id="price-span">$20</span><span id="month-span">per month</span>
                  </div>
                </div>
                <DividerComponent class="my-3"/>
                <div class="col d-flex justify-content-end px-3">
                  <router-link :to="{name: 'UpgradePlanPage'}" class="edit-btn">
                    {{ $t('editPlan') }}
                    <img src="@/assets/Billing/btn-arrow.svg" alt="">
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-5 col-retangle-style">
              <div class="d-flex flex-column w-100">
                <div class="col d-flex flex-row px-3 flex-wrap">
                  <div class="col-12 col-lg-6 d-flex flex-column gap-2">
                    <div class="d-flex flex-row gap-2 align-items-center">
                      <img src="../../assets/Billing/payment-method.svg" alt="">
                      <span class="col-retangle-style-title">Payment Method</span>
                    </div>
                    <span class="col-retangle-style-description">Our most popular plan for small teams. </span>
                  </div>
                  <div class="col-12 col-lg-6 d-flex justify-content-end">
                    <router-link :to="{name: 'PaymentMethodPage'}" class="edit-btn">
                      {{ $t('editCard') }}
                      <img src="../../assets/Billing/btn-arrow.svg" alt="">
                    </router-link>
                  </div>
                </div>
                <DividerComponent class="my-3"/>
                <div class="col d-flex flex-row gap-3 justify-content-start align-items-center px-3">
                  <img src="../../assets/Billing/visa-icon.svg" alt="">
                  <div class="d-flex flex-column">
                    <span class="small-title-col">Visa ending in 1234</span>
                    <span class="small-description-col">Expires 06/2024</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5 col-retangle-style">
              <div class="d-flex flex-column w-100">
                <div class="col d-flex flex-row px-3 flex-wrap">
                  <div class="col-12 col-lg-6 d-flex flex-column gap-2">
                    <div class="d-flex flex-row gap-2 align-items-center">
                      <img src="../../assets/Billing/billing-history.svg" alt="">
                      <span class="col-retangle-style-title">Billing History</span>
                    </div>
                    <span class="col-retangle-style-description">Download your previous plan receips and usage details.</span>
                  </div>
                  <div class="col-12 col-lg-6 d-flex justify-content-end">
                    <router-link :to="{name: 'BillingHistoryPage'}" class="edit-btn">
                      {{ $t('editAll') }}
                      <img src="@/assets/Billing/btn-arrow.svg" alt="">
                    </router-link>
                  </div>
                </div>
                <DividerComponent class="my-3"/>
                <div class="col d-flex flex-row align-items-center px-3">
                  <div class="col d-flex flex-row gap-3 justify-content-start align-items-center">
                    <img src="../../assets/Billing/pdf-icon.svg" alt="">
                    <span id="pdf-name-style">Payment_ Jun 2023</span>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a id="paid-btn">Paid</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5 col-retangle-style">
              <div class="d-flex flex-column w-100">
                <div class="col d-flex flex-row px-3 flex-wrap">
                  <div class="col-12 col-lg-6 d-flex flex-column gap-2">
                    <div class="d-flex flex-row gap-2 align-items-center">
                      <img src="../../assets/Billing/billingAddress.svg" alt="">
                      <span class="col-retangle-style-title">Billing Address</span>
                    </div>
                    <span class="col-retangle-style-description">Change your billing address information.</span>
                  </div>
                  <div class="col-12 col-lg-6 d-flex justify-content-end">
                    <router-link :to="{name: 'BillingAddressPage'}" class="edit-btn">
                      {{ $t('editAddress') }}
                      <img src="@/assets/Billing/btn-arrow.svg" alt="">
                    </router-link>
                  </div>
                </div>
                <DividerComponent class="my-3"/>
                <div class="col d-flex flex-row gap-3 justify-content-start align-items-center px-3">
                  <img src="../../assets/Billing/billingAddressHouse.svg" alt="">
                  <div class="d-flex flex-column">
                    <span class="small-title-col">MapleWood Avenue</span>
                    <span class="small-description-col">Address 1</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import BoldTitle from '@/components/BoldTitle.vue';
import DividerComponent from '@/components/DividerComponent.vue';
export default {
    components: {
      BoldTitle,
      DividerComponent
    },
}
</script>

<style scoped>
#page-sub-title{
  color: #6C757D;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
#price-span{
  color: var(--theme-dark, #212529);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
#month-span{
  color: var(--gray-600, #6C757D);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}
.col-retangle-style{
  display: flex;
  padding: 30px;
  border-radius: 10px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  /* margin-top: 3rem; */
  margin: 2rem;
}
.col-retangle-style-title{
  color: var(--theme-dark, #212529);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.col-retangle-style-description{
  color: var(--gray-600, #6C757D);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.edit-btn{
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid var(--theme-border, #DEE2E6);
  background: var(--theme-light, #F8F9FA);
  color: var(--theme-secondary, #6C757D);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  height: fit-content;
}
.small-title-col{
  color: var(--gray-900, #212529);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.small-description-col{
  color: var(--gray-600, #6C757D);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
#pdf-name-style{
  color: var(--gray-900, #212529);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
#paid-btn{
  color: var(--gray-white, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  padding: 4.2px 7.8px;
  border-radius: 4px;
  background: var(--theme-success, #198754);
  height: fit-content;
}
</style>