<template>
  <MobileFooter class="d-block d-md-none"/>
</template>

<script>
import MobileFooter from "@/layouts/MobileFooter.vue"

export default {
  components: {
    MobileFooter,
  },

}
</script>