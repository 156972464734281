import { MY_SERVICE } from "@/services/my.service.js";
import { defineStore } from 'pinia'

export const useMyStore = defineStore('my', {
    state: () => ({
        getMyActivityListOutput: [],
        getSettingsListOutput: [],
    }),
    actions: {
        async getActivityList(input) {
            const response = await MY_SERVICE.getActivityList(input);
            this.getMyActivityListOutput = response;
        },
        async getSettingsList(input) {
            const response = await MY_SERVICE.getSettingsList(input);
            this.getSettingsListOutput = response;
        },
        async updateSettings(settings) {
            await MY_SERVICE.updateSettings(settings);
        }
    }
});