<template>
    <div class="title" :class="{'d-flex align-items-center gap-2': icon}">
        <img v-if="icon" :src="icon" alt="" width="20" height="20">
        <span>{{title}}</span>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            icon: String
    }
}
</script>

<style scoped>
.title span {
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}
</style>