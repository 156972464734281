// This will be used to set the general configuration for the charts
import i18n from './i18n';

export default {
  getTotalPerformanceChartData(labels, datasets, customOptions = {}) {
    const defaultOptions = {
      backgroundColor: "#A81800",
      barThickness: 10,
      maintainAspectRatio: false,
      indexAxis: "y",
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#373A3E",
          titleFont: { size: 16 },
          bodyFont: { size: 12 },
          callbacks: {
            label: (context) => {
              const value = context.raw;
              const metric = customOptions.metric;
              // Tooltip formatting logic based on the metric type
              if (metric === 'Occupancy') {
                // I need to divide by 100 because the API returns the percentage in the wrong format
                // Remove it when the API fixes it.
                return `${context.dataset.label} - ${i18n.global.n(value / 100, 'percent')}`;
              } else if (metric === 'RoomNights') {
                return `${context.dataset.label} - ${i18n.global.n(value, 'integer')}`;
              } else {
                // Currency formatting
                return `${context.dataset.label} - ${i18n.global.n(value, 'currency')}`;
              }
            },
          },
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          border: {
            dash: [5, 5],
            dashOffset: 5,
            display: false,
          },
          ticks: {
            maxTicksLimit: 4,
            padding: 30,
            callback: (value) => {
              if (customOptions.metric === 'Occupancy') {
                // I need to divide by 100 because the API returns the percentage in the wrong format
                // Remove it when the API fixes it.
                return `${i18n.global.n(value / 100, 'percent')}`;
              } else if (customOptions.metric === 'RoomNights') {
                return `${i18n.global.n(value, 'integer')}`;
              } else {
                return `${i18n.global.n(value, 'currency')}`;
              }
            },
          },
        },
        y: {
          ticks: {
            padding: 30,
            callback: function (value, index) {
              const label = this.getLabelForValue(index);
              return label.length > 10 ? label.substring(0, 10) + '...' : label;
            },
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
    };

    // Combine labels and datasets (assuming datasets[0] is current period, datasets[1] is same time last year)
    const combinedData = labels.map((label, index) => ({
      label,
      currentPeriod: datasets[0].data[index], // Assuming first dataset is current period
      lastYear: datasets[1].data[index], // Assuming second dataset is same time last year
    }));

    // Sort by current period (or adapt to your needs)
    combinedData.sort((a, b) => b.currentPeriod - a.currentPeriod);

    // Extract sorted labels and datasets
    const sortedLabels = combinedData.map(item => item.label);
    const sortedCurrentPeriodData = combinedData.map(item => item.currentPeriod);
    const sortedLastYearData = combinedData.map(item => item.lastYear);

    // Create sorted datasets
    const sortedDatasets = [
      { ...datasets[0], data: sortedCurrentPeriodData }, // Sorted current period
      { ...datasets[1], data: sortedLastYearData }, // Sorted last year
    ];

    // Merge custom options with defaults
    const chartOptions = { ...defaultOptions, ...customOptions };

    // Apply custom options to datasets
    const customizedDatasets = sortedDatasets.map((dataset, index) => ({
      ...dataset,
      ...(customOptions.datasetsOptions?.[index] || {}),
    }));

    return {
      chartData: { labels: sortedLabels, datasets: customizedDatasets },
      chartOptions,
    };
  },  
  getTotalEvolutionChartData(labels, datasets, customOptions = {}) {
    const defaultOptions = {
      maintainAspectRatio: false,
      pointRadius: 3,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#373A3E",
          titleFont: { size: 16 },
          bodyFont: { size: 12 },
          callbacks: {
            label: (context) => {
              const value = context.raw;
              const metric = customOptions.metric;

              // Tooltip formatting based on the metric type
              if (metric === 'Occupancy') {
                // I need to divide by 100 because the API returns the percentage in the wrong format
                // Remove it when the API fixes it.
                return `${context.dataset.label} - ${i18n.global.n(value / 100, 'percent')}`;
              } else if (metric === 'RoomNights') {
                return `${context.dataset.label} - ${i18n.global.n(value, 'integer')}`;
              } else {
                // Currency formatting
                return `${context.dataset.label} - ${i18n.global.n(value, 'currency')}`;
              }
            },
          },
        },
      },
      scales: {
        y: {
          border: {
            dash: [5, 5],
            dashOffset: 5,
            display: false,
          },
          ticks: {
            maxTicksLimit: 4,
            padding: 30,
            callback: (value) => {
              if (customOptions.metric === 'Occupancy') {
                // I need to divide by 100 because the API returns the percentage in the wrong format
                // Remove it when the API fixes it.
                return `${i18n.global.n(value / 100, 'percent')}`;
              } else if (customOptions.metric === 'RoomNights') {
                return `${i18n.global.n(value, 'integer')}`;
              } else {
                return `${i18n.global.n(value, 'currency')}`;
              }
            },
          },
        },
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            padding: 30,
            callback: function (value, index) {
              const label = this.getLabelForValue(index);
              return label.length > 10 ? label.substring(0, 10) + '...' : label;
            },
          },
        },
      },
    };

    // Merge custom options with defaults
    const chartOptions = { ...defaultOptions, ...customOptions };

    // Apply custom options to datasets
    const customizedDatasets = datasets.map((dataset, index) => ({
      ...dataset,
      ...(customOptions.datasetsOptions?.[index] || {}),
    }));

    return {
      chartData: { labels, datasets: customizedDatasets },
      chartOptions,
    };
  },
  getLengthOfStayChartData(labels, datasets, customOptions = {}) {
      const defaultOptions = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "#373A3E",
            titleFont: { size: 16 },
            bodyFont: { size: 12 },
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return `${context.dataset.label} - ${i18n.global.n(value, 'decimal')}`;
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              padding: 30,
              callback: function(value, index) {
                // Use the label from the dataset
                const label = this.getLabelForValue(index);
                // Check if the label is a date string and format it
                const date = new Date(label);
                if (!isNaN(date)) {
                  const options = { month: 'short', day: 'numeric' };
                  const locale = i18n.global.locale.value; // Get the current locale
                  return date.toLocaleDateString(locale, options);
                }
                // If not a date, return the label as is
                return label.length > 10 ? label.substring(0, 10) + '...' : label;
              }
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            border: {
              dash: [5, 5],
              dashOffset: 5,
              display: false,
            },
            ticks: {
              maxTicksLimit: 6,
              padding: 30,
            },
          },
        },
      };

      // Merge custom options with defaults
      const chartOptions = { ...defaultOptions, ...customOptions };

      // Apply custom options to datasets
      const customizedDatasets = datasets.map((dataset, index) => ({
        ...dataset,
        ...(customOptions.datasetsOptions?.[index] || {}),
      }));

      return {
        chartData: { labels, datasets: customizedDatasets },
        chartOptions,
      };
  },
  getBookingWindowChartData(labels, datasets, customOptions = {}) {
      const defaultOptions = {
        maintainAspectRatio: false,
        pointRadius: 3,
        pointBorderColor: "#A81800",
        pointBackgroundColor: "#A81800",
        borderColor: "#A81800",
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "#373A3E",
            titleFont: { size: 16 },
            bodyFont: { size: 12 },
            callbacks: {
              label: function (context) {
                const value = context.raw;
                return `${context.dataset.label} - ${i18n.global.n(value, 'decimal')}`;
              },
            },
          },
        },
        scales: {
          y: {
            border: {
              dash: [5, 5],
              dashOffset: 5,
              display: false,
            },
            ticks: {
              maxTicksLimit: 6,
              padding: 30,
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              padding: 30,
              callback: function(value, index) {
                // Use the label from the dataset
                const label = this.getLabelForValue(index);
                // Check if the label is a date string and format it
                const date = new Date(label);
                if (!isNaN(date)) {
                  const options = { month: 'short', day: 'numeric' };
                  const locale = i18n.global.locale.value; // Get the current locale
                  return date.toLocaleDateString(locale, options);
                }
                // If not a date, return the label as is
                return label.length > 10 ? label.substring(0, 10) + '...' : label;
              }
            },
          },
        },
      };

      // Merge custom options with defaults
      const chartOptions = { ...defaultOptions, ...customOptions };

      // Apply custom options to datasets
      const customizedDatasets = datasets.map((dataset, index) => ({
        ...dataset,
        ...(customOptions.datasetsOptions?.[index] || {}),
      }));

      return {
        chartData: { labels, datasets: customizedDatasets },
        chartOptions,
      };
  },
  getSegmentsChartData(labels, dataset, customOptions = {}) {
    const defaultOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          backgroundColor: "#373A3E",
          titleFont: { size: 16 },
          bodyFont: { size: 12 },
          callbacks: {
            label: (context) => {
              const value = context.raw;
              const metric = customOptions.metric;
              // Tooltip formatting logic based on the metric type
              if (metric === 'Occupancy') {
                // I need to divide by 100 because the API returns the percentage in the wrong format
                // Remove it when the API fixes it.
                return `${context.dataset.label} - ${i18n.global.n(value / 100, 'percent')}`;
              } else if (metric === 'RoomNights') {
                return `${context.dataset.label} - ${i18n.global.n(value, 'integer')}`;
              } else {
                // Currency formatting
                return `${context.dataset.label} - ${i18n.global.n(value, 'currency')}`;
              }
            },
          },
        },
      },
    };
  
    // Merge custom options with defaults
    const chartOptions = { ...defaultOptions, ...customOptions };
  
    // Apply custom options to dataset
    const customizedDatasets = [{
      label: dataset.label,
      data: dataset.data, 
      backgroundColor: customOptions.datasetsOptions?.map(opt => opt.backgroundColor) || [],  // set custom background colors
    }];
  
    return {
      chartData: { labels, datasets: customizedDatasets },
      chartOptions,
    };
  },  
  getCompetitorsAnalysisChartData(labels, datasets, customOptions = {}) {
      const defaultOptions = {
        maintainAspectRatio: false,
        pointRadius: 3,
        indexAxis: "x",
        backgroundColor: "#A81800",
        borderColor: "#A81800",
        plugins: {
          legend: {
            align: 'start',
            position: 'bottom'
          },
          tooltip: {
            backgroundColor: "#373A3E",
            titleFont: { size: 16 },
            bodyFont: { size: 12 },
            callbacks: {
              label: function (context) {
                return `${context.dataset.label} - ${i18n.global.n(parseFloat(context.formattedValue), 'currency')}`;
              },
            },
          },
        },
        scales: {
          y: {
            border: {
              dash: [5, 5],
              dashOffset: 5,
              display: false,
            },
            ticks: {
              maxTicksLimit: 7,
              padding: 30,
              callback: (value) => {
                return `${i18n.global.n(value, 'currency')}`;
              },
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              padding: 30,
            },
          },
        },
      };

      // Merge custom options with defaults
      const chartOptions = { ...defaultOptions, ...customOptions };

      // Apply custom options to datasets
      const customizedDatasets = datasets.map((dataset, index) => ({
        ...dataset,
        ...(customOptions.datasetsOptions?.[index] || {}),
      }));

      return {
        chartData: { labels, datasets: customizedDatasets },
        chartOptions,
      };
  }
};
