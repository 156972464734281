import { createI18n } from "vue-i18n";

// Load locale messages
function loadLocaleMessages() {
  const messages = {};
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Get the user language from localStorage
function getUserLanguage() {
  const userStorage = JSON.parse(localStorage.getItem('user'));
  return userStorage && userStorage.user && userStorage.user.language ? userStorage.user.language.toLowerCase() : 'en'; 
}

function getNumberFormats() {
  const userStorage = JSON.parse(localStorage.getItem('user'));
  const numberFormats = {
    'pt': {
      currency: {
        style: 'currency',
        currency: userStorage?.user?.currency ?? 'eur',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      integer: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    },
    'en': {
      currency: {
        style: 'currency',
        currency: userStorage?.user?.currency ?? 'usd',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      integer: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    }
  };
  return numberFormats;
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: getUserLanguage(), 
  fallbackLocale: 'en',
  availableLocales: ['en', 'pt'],
  messages: loadLocaleMessages(),
  numberFormats: getNumberFormats(),
});
