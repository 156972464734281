<template>
    <div class="container-fluid container-wrapper">
        <div class="row">
            <div class="col-12 d-flex align-items-center mt-2">
                <span id="title">{{ title }}</span>
            </div>
            <slot name="content">
                <!-- Content goes here -->
            </slot>
            <div v-if="hasBottomBtn" class="col-12" id="button-style">
                <img src="@/assets/Market/consultReport.svg" alt="">
                <span id="consultReport-span">{{ $t("consultTheReport") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        hasBottomBtn: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<style scoped>
.container-wrapper {
  border-radius: 15px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  min-height: 300px;
  position: relative;
}
#title{
    color: #212529;
    font-size: 20px;
    font-weight: 700;
}
#button-style{
    background-color: #DEE2E6;
    border: 1px solid #cacaca;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 0;
}
#consultReport-span{
    color: #6C757D;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
</style>