<template>
    <div v-show="recoverSuccess == false">
        <div class="col-12">
            <div class="col-12">
                <img class="icon" src="@/assets/authentication/sign-up/resetPassword.svg" alt="">
            </div>
            <div class="d-flex flex-column gap-3 my-4">
                <span class="title-primary">{{ $t('resetPassword') }}</span>
                <span class="title-secondary color-secondary">{{ $t('enterEmailForVerificationCode') }}</span>
            </div>
        </div>
        <ValidationAlerts v-if="hasError(errors, 'email')" class="my-2" :alertType="'error'"
            :title="getErrorMessages(errors, ['email'])" />
        <div class="form-group">
            <label for="email">{{ $t('email') }}<span class="asterisk">*</span></label>
            <Field name="email" type="email" class="form-control input-form"
                :class="{ 'is-invalid': hasError(errors, 'email') }" :placeholder="$t('enterYourEmail')" />
        </div>
        <div class="form-group">
            <button type="submit" class="w-100" v-bind:class="{ disabled: loading }" :disabled="isSubmitting" @click="$emit('validate')">
                <span v-show="!loading">{{ $t('submit') }}</span>
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <div v-if="recoverSuccess">
        <div class="d-flex flex-column gap-3 my-4">
            <span class="title-primary">{{ $t('checkYourEmail') }}</span>
            <span class="title-secondary color-secondary">{{ $t('enterEmailForVerificationCode.') }}</span>
        </div>
        <div class="col-12">
            <div class="d-none d-md-block col-12">
                <img class="icon" src="@/assets/authentication/sign-in/check-email.svg" alt="">
            </div>
        </div>
        <ValidationAlerts class="my-4" alertType="success"
            :title="$t('weHaveSentAPasswordRecoverInstructionsToYourEmail.')" />
    </div>
</template>
  
<script>
import ValidationAlerts from '@/components/ValidationAlerts.vue';
import { Field } from 'vee-validate';

export default {
    components: {
        ValidationAlerts,
        Field
    },
    props: {
        errors: Object,
        isSubmitting: Boolean,
        loading: Boolean,
        hasError: Object,
        getErrorMessages: Object,
        recoverSuccess: Boolean,
    },
    methods: {
        onSubmit(values) {
            this.$emit('recoverClicked', values)
        }
    }
}
</script>
  
<style scoped>
.title-primary {
    font-size: 20px;
    font-weight: 900;
}

.title-secondary {
    font-size: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: flex;
    justify-content: flex-start;
}

.asterisk {
    color: var(--theme-primary);
    margin-left: 5px;
}

.form-group input,
:deep(.form-group #div-global input) {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-form {
    max-width: 500px;
}

.form-group .remember label {
    margin-left: 5px;
}


.form-group button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--theme-primary);
    text-decoration: none;
    text-align: center;
}

.form-group a {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: var(--theme-primary) !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.icon {
    width: 130px;
}
</style>