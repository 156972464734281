<template>
  <template v-if="type === 1">
    <button id="button" type="submit" @click="handleButtonClick" :style="buttonStyle" :class="buttonTypeClass">
      <template v-if="!loading">
        <img v-if="leftIcon" :src="leftIcon" alt="" width="16" height="16">
        <span>{{ label }}</span>
        <img v-if="rightIcon" :src="rightIcon" alt="" width="16" height="16">
      </template>
      <template v-else>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ms-1">{{$t("loading...")}}</span>
      </template>
    </button>
  </template>
  <template v-else-if="type === 2">
    <button type="submit" :class="buttonTypeClass" @click="handleButtonClick" :style="buttonStyle">
      <template v-if="!loading">
        <span>{{ label }}</span>
      </template>
      <template v-else>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ms-1">{{$t("loading...")}}</span>
      </template>
    </button>
  </template>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    leftIcon: String,
    rightIcon: String,
    buttonStyle: Object,
    type: {
      type: Number,
      default: 1
    },
    buttonType: {
      type: String,
      default: 'primary', // Default to 'primary' if not provided
      validator: (value) => {
        return ['primary', 'secondary', 'transparent','bg-primary', 'transparent-dark', 'custom', 'theme-light', 'transparent-dark-no-border', 'text-primary'].includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleButtonClick(event) {
      // Prevent the default action to stop the page from reloading
      event.preventDefault();
      this.$emit('clickBtn');
    }
  },
  computed: {
    buttonTypeClass() {
      return `btn-${this.buttonType} ${this.loading ? 'disabled' : ''}`;
    },
  },
}
</script>

<style scoped>
#button,
button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#button span {
  font-size: .8rem;
  font-weight: 400;
  line-height: 24px;
}

.btn-primary {
  background-color: #6C757D !important;
  border-color: #6C757D !important;
  color: white !important;
  border-radius: 15px !important;
}
.btn-text-primary {
  color: var(--Theme-Primary, #A81800) !important;
  border-radius: 4px !important;
  border: 1px solid var(--Theme-Primary, #A81800) !important;
  background-color: var(--HitBox, rgba(255, 255, 255, 0.00)) !important;
}
.btn-bg-primary {
  color: white !important;
  border-radius: 4px !important;
  border: 1px solid var(--Theme-Primary, #A81800) !important;
  background-color: var(--Theme-Primary, #A81800) !important;
}

.btn-secondary {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
  color: white !important;
  border-radius: 15px !important;
}

.btn-transparent {
  background-color: transparent !important;
  border: 2px solid var(--theme-primary) !important;
  color: var(--theme-primary) !important;
  border-radius: 15px !important;
}

.btn-transparent-dark {
  background-color: transparent !important;
  border: 2px solid var(--theme-secondary) !important;
  border-radius: 15px !important;
  color: var(--theme-secondary) !important;
}
.btn-theme-light {
  border-radius: 15px !important;
  border: 1px solid var(--theme-border, #DEE2E6) !important;
  background: var(--theme-light, #F8F9FA) !important;
}
.transparent-dark-no-border {
  background-color: transparent !important;
  border: none !important;
  border-radius: none !important;
  color: var(--theme-secondary) !important;
}
@media (max-width: 767px) {
  #button span {
    font-size: .7rem;
  }
}
</style>