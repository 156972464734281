<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
            <BoldTitle :title="$t('Events')"/>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end gap-4">
          <DateButton 
            @update-date="updateDateProperty('evolutionChartDate', $event)" 
            typeForm="1"
            :label="$t('endDate')" 
          />
          <div>
            <button @click="decreaseDate">-</button>
            <span>{{ month + '-' + year }}</span>
            <button @click="increaseDate">+</button>
          </div>
        </div>
      </div>
      <div class="container-wrapper mt-5">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-lg-6 d-flex gap-2 p-5">
            <img src="@/assets/Market/events-title-icon.svg" alt="">
            <span class="title-table">Events Page</span>
          </div>
          <div class="col-12 col-lg-6 d-flex gap-2 p-5 justify-content-end">
            <img class="cursor-pointer" @click="showLeaveHotelGroupPopup = true" src="@/assets/Market/add-event.svg" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <CustomCalendar :month="month" :year="year" :events="events"/>
          </div>
        </div>
      </div>
      <CreatePopupEvent 
          v-show="showLeaveHotelGroupPopup" 
          @popUpClosed="showLeaveHotelGroupPopup = false"
        >
          <template v-slot:body>
              <div class="d-flex flex-row gap-2 mt-5 btns-div">
                  <CustomButton 
                      class="w-100 three-dots" 
                      :buttonType="'theme-light'" 
                      :label="$t('decline')" 
                      @clickBtn="showLeaveHotelGroupPopup = false"
                      :rightIcon="require('@/assets/Market/delete-icon.svg')"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
                  <CustomButton 
                      class="w-100 three-dots" 
                      :buttonType="'primary'" 
                      :label="$t('accept')" 
                      @clickBtn="showLeaveHotelGroupPopup = false"
                      :rightIcon="require('@/assets/BudgetAnalysis/check-circle.svg')"
                      :buttonStyle="{
                          borderRadius: '8px!important',
                          gap: '8px',
                          padding: '6px 35px'
                      }"
                  />
              </div>
          </template>
        </CreatePopupEvent> 
    </div>
</template>
  
  <script>
  import CustomCalendar from '@/components/Market/CustomCalendar.vue';
  import BoldTitle from '@/components/BoldTitle.vue';
  import CreatePopupEvent from '@/components/Market/CreatePopupEvent.vue';
  import CustomButton from '@/components/CustomButton.vue';
  import DateButton from '@/components/DateButton.vue';
  export default {
    components: {
      CustomCalendar,
      BoldTitle,
      CreatePopupEvent,
      CustomButton,
      DateButton
    },
    data: function(){
      return {
        date: new Date(2023, 10, 0),
        showLeaveHotelGroupPopup: false,
      }
    },
    computed:
    {
      events: function(){
        return [
        {id: 1, name: 'Event 1', year: 2023, month: 10, day: 1},
        {id: 2, name: 'Event 2', year: 2023, month: 10, day: 1},
        {id: 3, name: 'Event 3', year: 2023, month: 10, day: 12},
        {id: 4, name: 'Event 4', year: 2023, month: 10, day: 22},
        {id: 5, name: 'Event 5', year: 2023, month: 9, day: 22},
        {id: 6, name: 'Event 6', year: 2023, month: 11, day: 22},
      ]},
      month: function(){
        return new Date(this.date).getMonth() + 1;
      },
      year: function(){
        return new Date(this.date).getFullYear();
      }
    },
    methods:
    {
      increaseDate: function(){
        this.date = new Date(this.date).setMonth(new Date(this.date).getMonth() + 1);
      },
      decreaseDate: function(){
        this.date = new Date(this.date).setMonth(new Date(this.date).getMonth() - 1);    
      }
    },
  };

  </script>
  
<style scoped>
.container-wrapper {
  border-radius: 15px;
  background: var(--gray-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.title-table {
  color: var(--Theme-Secondary, #6C757D);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
@media only screen and (max-width: 450px) {
    .btns-div{
      flex-wrap: wrap;
    }
}
</style>