<template>
  <section id="header-navigation" class="d-none d-md-block p-4" :style="dynamicWidth">
    <div class="d-flex align-items-center gap-5 h-100">
      <img
        v-show="!sideMenuStore.isOpen"
        @click="sideMenuStore.toggleMenu"
        class="cursor-pointer"
        src="@/assets/sideBar/collapse-reverse.svg"
        alt="icon"
      />
      <div class="d-flex align-items-center gap-2">
        <div class="breadcrumbs d-flex align-items-center">
          <span
            v-for="(crumb, index) in breadcrumbsWithIcons"
            :key="index"
            class="d-flex align-items-center item"
            @click="index < breadcrumbsWithIcons.length - 1 ? navigateTo(crumb) : null"
            :class="{ 'cursor-pointer': index < breadcrumbsWithIcons.length - 1 }"
          >
            <img
              v-if="crumb.icon"
              :class="{
                'breadcrumb-icon-active': index === breadcrumbsWithIcons.length - 1,
                'breadcrumb-icon-inactive': index < breadcrumbsWithIcons.length - 1
              }"
              class="img-fluid"
              :src="crumb.icon"
              alt="icon"
            />
            <span :class="{
              'active-crumb': index === breadcrumbsWithIcons.length - 1,
              'inactive-crumb': index < breadcrumbsWithIcons.length - 1
            }">
              {{ crumb.title || dynamicTitle }}
            </span>
            <span class="breadcrumb-separator" v-if="index < breadcrumbsWithIcons.length - 1"> > </span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useSideMenuStore } from '@/stores';

export default {
  name: 'HeaderNavigation',
  props: {
    // dynamic title (set by a prop)
    dynamicTitle: {
      type: String
    },
  },
  setup() {
    const sideMenuStore = useSideMenuStore();
    
    return {
      sideMenuStore,
    };
  },
  data() {
    return {
      isNavigating: false,
    };
  },
  methods: {
    async navigateTo(crumb) {
      // prevent multiple clicks while navigating
      if (!this.isNavigating) {
        this.isNavigating = true;  // block clicks while navigating
        try {
          await this.$router.push({ name: crumb.pathName });
        } catch (error) {
          console.error('Error:', error);
        } finally {
          this.isNavigating = false;  // unlock navigation
        }
      }
    },
  },
  computed: {
    dynamicWidth() {
      return {
        width: this.sideMenuStore.isOpen ? 'calc(100% - 250px)' : 'calc(100% - 60px)',
        transition: 'width 0.5s ease-in-out',
      };
    },
    breadcrumbsWithIcons() {
      // returns an array of objects with the title, icon and pathName of each route
      return this.$route.matched.map(route => ({
        title: route.meta.title,
        icon: route.meta.icon,
        pathName: route.name
      }));
    },
  },
};
</script>

<style scoped>
#header-navigation {
  position: fixed;
  top: 60px;
  right: 0;
  background-color: white;
  border-bottom: 1px solid #ced4da;
  height: 60px;
  min-height: 60px;
  z-index: 999;
}

.breadcrumbs {
  display: flex;
  gap: 0.5rem;
}

.item {
  font-weight: bold;
  font-size: .9rem;
}

.active-crumb {
  color: var(--theme-primary); /* Active breadcrumb color */
}

.inactive-crumb {
  color: var(--theme-secondary)
}

/* Default neutral filter for inactive breadcrumb icons */
.breadcrumb-icon-inactive {
  width: 15px;
  height: auto;
  margin-right: 5px;
}

/* Red color for active breadcrumb icon */
.breadcrumb-icon-active {
  width: 15px;
  height: auto;
  filter: brightness(0) saturate(100%) invert(11%) sepia(50%) saturate(7497%) hue-rotate(11deg) brightness(102%) contrast(101%);
  margin-right: 5px;
}

.breadcrumb-separator {
  color: var(--theme-secondary);
  margin-left: 4px;
  margin-right: 20px;
}

</style>