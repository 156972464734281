<template>
  <section>
    <div class="container-fluid">
        <div class="col-12 d-flex align-items-center gap-2 mx-2 my-4 cursor-pointer" @click="sendToBillingList">
            <img src="@/assets/HotelGroup/backBtn.svg" alt="">
            <span class="page-title">Billing Address</span>
        </div>
        <div class="container-list">
            <div class="row">
                <div class="col-6 d-flex gap-2 p-5" v-if="typeForm == 'create'">
                    <img src="@/assets/Billing/create-form-icon.svg" alt="">
                    <span class="sub-title">Add Billing Address</span>
                </div>
                <div class="col-6 d-flex gap-2 p-5" v-if="typeForm == 'edit'">
                    <img src="@/assets/Billing/edit-form-icon.svg" alt="">
                    <span class="sub-title">Edit Billing Address</span>
                </div>
                <!-- <div class="col-6 d-flex justify-content-end p-5">
                    <img class="editDots" src="@/assets/HotelGroup/editDots.svg" alt="">
                </div> -->
            </div>
            <Form @submit="onSubmit" :validation-schema="schemaBillingForm"
            v-slot="{ errors, isSubmitting }">
                <div class="row px-5">
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="name">Name</label>
                        <Field name="name" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'name') }" 
                        />
                    </div>
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="vat">VAT</label>
                        <Field name="vat" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'vat') }" 
                        />
                    </div>
                </div>
                <div class="row px-5">
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="address">Address</label>
                        <Field name="address" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'address') }" 
                        />
                    </div>
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="doorNumber">Door Number</label>
                        <Field name="doorNumber" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'doorNumber') }" 
                        />
                    </div>
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="zipCode">Zip Code</label>
                        <Field name="zipCode" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'zipCode') }" 
                        />
                    </div>
                </div>
                <div class="row px-5">
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="reference">Reference</label>
                        <Field name="reference" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'reference') }" 
                        />
                    </div>
                    <div class="col-12 col-md-4 form-group my-3">
                        <label class="mb-2" for="city">City</label>
                        <Field name="city" type="text" class="form-control input-form"
                            :class="{ 'is-invalid': hasError(errors, 'city') }" 
                        />
                    </div>
                </div>
                    <ValidationAlerts 
                    v-if="hasError(errors,'name') || hasError(errors,'vat') || hasError(errors,'address') || hasError(errors,'doorNumber') || hasError(errors,'zipCode') || hasError(errors,'reference') || hasError(errors,'city')"
                    class="my-2" 
                    :alertType="'error'" 
                    :title="getErrorMessages(errors, ['name', 'vat', 'address', 'doorNumber', 'zipCode', 'reference', 'city'])"
                    />
                    <div class="d-flex justify-content-end px-5 py-5">
                        <div class="d-flex gap-3 btn-div">
                            <button type="submit" class="w-100 mt-5 btn-delete" v-if="typeForm == 'edit'">
                                <span>Delete Address</span><img src="@/assets/HotelGroup/deleteBtn.svg" alt="">
                            </button>
                            <button type="submit" class="w-100 mt-5 btn-delete" v-if="typeForm == 'create'">
                                <span>Cancel</span><img src="@/assets/HotelGroup/deleteBtn.svg" alt="">
                            </button>
                            <button type="submit" class="w-100 mt-5" id="save-btn" v-bind:class="{ disabled: loading }" :disabled="isSubmitting">
                                <span v-show="!loading">save</span><img src="@/assets/HotelGroup/saveBtn.svg" alt="">
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </Form>
        </div>
    </div>
  </section>
</template>

<script>
import ValidationAlerts from '@/components/ValidationAlerts.vue'
import { Form, Field } from 'vee-validate';
import { useUsersStore, useAlertStore } from '@/stores';
import * as Yup from 'yup'
export default {
    components: {
        ValidationAlerts,
        Form,
        Field
    },
    setup() {
        const usersStore = useUsersStore();
        const alertStore = useAlertStore();

        return {
            usersStore,
            alertStore
        };
    },
    data() {
        return {
            loading: false,
            typeForm: this.$route.query.type,
        }
    },
    computed: {
        schemaBillingForm() {
            return Yup.object({
                name: Yup.string().required(this.$t('nameRequired')),
                vat: Yup.string().required(this.$t('vatRequired')),
                address: Yup.string().required(this.$t('addressRequired')),
                doorNumber: Yup.string().required(this.$t('doorNumberRequired')),
                zipCode: Yup.string().required(this.$t('zipCodeRequired')),
                reference: Yup.string().required(this.$t('referenceRequired')),
                city: Yup.string().required(this.$t('cityRequired')),
            })
        },
        apiErrors: function () {
            return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : {};
        },
    },
    methods: {
        onSubmit: function (values) {
            this.billingFormClicked(values);
        },
        async billingFormClicked(values) {
            console.log(values)
            // this.alertStore.clear();
            // this.loading = true;
            // const { email, password } = values;
            // try {
            //     await this.usersStore.login(email, password);
            //     this.loading = false;
            //     this.$router.push({name : 'DashboardPage'});
            // }
            // catch (error) {
            //     this.alertStore.error(error);
            //     alert(error.message);
            //     this.loading = false;
            // }
        },
        hasError(errors, label) {
            return errors[label] || this.hasApiError(label);
        },
        hasApiError(keys) {
            return Object.keys(this.apiErrors).some(key => keys.includes(key));
        },
        getErrorMessages(errors, labels) {
            const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
            return errorMessages.join('<br>');
        },
        sendToBillingList() {
            this.$router.push({ name: 'BillingPage' });
        }
    },
}
</script>

<style scoped>
.container-list{
    border-radius: 15px 15px 0px 0px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.page-title{
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}
.sub-title{
    color: var(--theme-secondary, #6C757D);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.btn-div{
    width: fit-content;
}
.editDots{
  width: 20px;
}
#save-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 8px;
    background: var(--theme-primary, #A81800);
    color: var(--gray-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    text-decoration: none;
    justify-content: center;
}
.btn-delete{
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    color: var(--theme-secondary, #6C757D);
    border-radius: 8px;
    border: 1px solid var(--theme-border, #DEE2E6);
    background: var(--theme-light, #F8F9FA);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: none;
    text-decoration: none;
    min-width: fit-content;
}
@media only screen and (max-width: 400px) {
    .btn-div{
        flex-wrap: wrap;
    }
}
</style>