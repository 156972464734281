<template>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="container-list p-0">
                    <TableComponent v-model="itemsFromApi" :layout="{
                        hasHeader: { desktop: true, mobile: false }
                    }" :tableHeaders="tableHeaders" :tableRows="tableRows" @item-selected="handleItemSelected"
                        @all-items-selected="handleAllItemsSelected">
                        <template v-slot:title>
                            <div class="col-12 d-flex flex-column align-items-start gap-2 mx-2 my-4 px-4">
                                <div class="d-flex gap-2">
                                    <img class="cursor-pointer" src="@/assets/HotelGroup/backBtn.svg" alt=""
                                        @click="sendToBillingPage">
                                    <span class="page-title">Billing History</span>
                                </div>
                                <span>Download the folders you saved in your account</span>
                            </div>
                            <div class="row row-list px-4">
                                <div class="col d-flex gap-4">
                                    <div class="col d-none d-lg-flex">
                                        <div class="form-group has-search">
                                            <img class="form-control-feedback" src="@/assets/HotelGroup/searchBtn.svg"
                                                alt="">
                                            <input type="text" class="form-control search-input"
                                                placeholder="Search by name...">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DividerComponent class="mt-5" />
                        </template>
                        <template v-slot:actions>
                            <div class="justify-content-center d-none d-md-flex">
                                <CustomButton class="btn mt-2 d-flex gap-2" 
                                    label="Download" 
                                    buttonType="theme-light"
                                    :rightIcon="require('@/assets/FoldersPage/download-icon.svg')" :buttonStyle="{
                                        borderRadius: '8px!important',
                                        gap: '8px',
                                        padding: '6px 35px'
                                    }" />
                            </div>
                            <div class="justify-content-center align-items-center d-md-none d-flex">
                                <a href="billing-history-info"><img src="@/assets/Billing/billingHistoryMobileBtn.svg" alt=""></a>
                            </div>
                        </template>
                    </TableComponent>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';
import DividerComponent from '@/components/DividerComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    components: {
        TableComponent,
        DividerComponent,
        CustomButton
    },
    data() {
        return {
            selectedItems: [],
            tableHeaders: [
                {
                    icon: require('@/assets/FoldersPage/file-name-type.svg'),
                    text: 'Payment File'
                },
                {
                    icon: require('@/assets/FoldersPage/date-icon.svg'),
                    text: 'Date'
                },
                {
                    icon: require('@/assets/Billing/amount-icon.svg'),
                    text: 'Amount'
                },
                {
                    icon: require('@/assets/Billing/plan-icon.svg'),
                    text: 'Plan'
                },
                {
                    icon: require('@/assets/FoldersPage/download-icon.svg'),
                    text: 'Download'
                }
            ],
            tableRows: [
                { type: 'ImageWithText', field: 'name' },
                { type: 'SimpleText', field: 'date' },
                { type: 'SimpleText', field: 'amount' },
                { type: 'SimpleText', field: 'plan' },
            ],
            itemsFromApi: [
                { id: 0, name: [{ image: require('@/assets/Billing/pdf-icon.svg'), firstText: 'Payment_Jun 2023', status: 'Paid' }], date: '1 Jun, 2023', amount: '$20,00 USD', plan: 'Business' },
                { id: 1, name: [{ image: require('@/assets/Billing/pdf-icon.svg'), firstText: 'Payment_Jun 2023', status: 'Paid' }], date: '1 Jun, 2023', amount: '$20,00 USD', plan: 'Business' },
                { id: 2, name: [{ image: require('@/assets/Billing/pdf-icon.svg'), firstText: 'Payment_Jun 2023', status: 'UnPaid' }], date: '1 Jun, 2023', amount: '$20,00 USD', plan: 'Business' },
            ],
        }
    },
    methods: {
        handleItemSelected(selectedItemData) {
            const index = this.selectedItems.findIndex(item => item === selectedItemData);
            if (index === -1) {
                // If the item is not in the selectedItems array, add it
                this.selectedItems.push(selectedItemData);
            } else {
                // If the item is already in the selectedItems array, remove it
                this.selectedItems.splice(index, 1);
            }
        },
        handleAllItemsSelected(selectedItemsData) {
            if (selectedItemsData.length > 0) {
                // At least one item is selected, handle accordingly
                this.selectedItems = selectedItemsData;
            }
        },
        sendToBillingPage() {
            this.$router.push({ name: 'BillingPage' });
        }
    },
}
</script>

<style scoped>
.container-list {
    border-radius: 15px 15px 0px 0px;
    background: var(--gray-white, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.page-title {
    color: var(--theme-dark, #212529);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.editDots {
    width: 20px;
}

.has-search .form-control {
    padding-left: 40px;
}

.search-input {
    width: 300px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 20px;
    height: 38px;
    margin-left: 10px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>