<template>
  <BaseSign>
    <template v-slot:content>
      <Form class="form" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
        <div class="col-12">
          <img class="icon" src="@/assets/authentication/sign-up/twoFactor.svg" alt="">
          <div class="my-5">
            <span class="title-primary">{{ $t('insertLocationCode') }}</span>
            <br>
            <span class="title-secondary">{{ $t('pleaseEnterTheLocationCodeSentToYourEmail.') }}</span>
          </div>
        </div>
        <ValidationAlerts v-if="hasError(errors, 'code')" :alertType="'error'" class="my-2"
          :title="getErrorMessages(errors, ['code'])" />
        <div class="form-group">
          <label for="code">{{ $t('locationCode') }}<span class="asterisk">*</span></label>
          <Field name="code" type="password" v-slot="{ field }">
            <div id="input-code">
              <input id="code" v-bind="field" type="password" class="form-control input-form"
                :class="{ 'is-invalid': hasError(errors, 'code') }" :placeholder="$t('insertLocationCode')" />
              <img class="cursor-pointer" v-show="!hasError(errors, 'code')" id="visibility-icon"
                @click="switchVisibility('code')" src="@/assets/MyProfile/visibilityPassword-icon.svg" alt="">
            </div>
          </Field>
        </div>
        <div class="form-group">
          <button type="submit" class="w-100" v-bind:class="{ disabled: loading }" :disabled="isSubmitting"> <span
              v-show="!loading">{{ $t('confirmCode') }}</span>
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
        <div class="col-12 space-top text-start">
          <span :style="{ fontSize: '0.8rem', wordBreak: 'break-word' }">{{ $t('protectedByReCAPTCHAAndSubjectToThe') }}
            <a class="privacy-policy" href="">{{ $t('revenueMasterPrivacyPolicyAndTermsOfService.') }} </a>
          </span>
        </div>
      </form>
    </template>
  </BaseSign>
</template>

<script>
import BaseSign from "@/components/BaseSign.vue";
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { useAlertStore, useUsersStore } from '@/stores';
import ValidationAlerts from '@/components/ValidationAlerts.vue';

export default {
  components: {
    BaseSign,
    Form,
    Field,
    ValidationAlerts
  },
  setup() {
    const alertStore = useAlertStore();
    const usersStore = useUsersStore();

    return {
      alertStore,
      usersStore
    };
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    schema() {
      return Yup.object({
        code: Yup.string().required(this.$t('locationCodeRequired'))
      })
    },
    apiErrors: function () {
      return this.alertStore.alert && this.alertStore.alert.output && this.alertStore.alert.output.errors ? this.alertStore.alert.output.errors : this.alertStore.alert ? this.alertStore.alert : {};
    }
  },
  methods: {
    sendToSignUp() {
      this.$router.push({ name: 'SignUp' });
    },
    async onSubmit(values) {
      this.alertStore.clear();
      this.loading = true;
      const { code } = values;
      try {
        await this.usersStore.login(code); // review
        this.loading = false;
        // this.$router.push({ name: 'DashboardPage' });
      }
      catch (error) {
        this.alertStore.error(error, this.$t('yourLocationCodeIsInvalid.'));
        this.loading = false;
      }
    },
    switchVisibility(selector) {
      const passwordField = document.querySelector(`#${selector}`)
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    },
    hasError(errors, label) {
      return errors[label] || this.hasApiError(label);
    },
    hasApiError(keys) {
      return Object.keys(this.apiErrors).some(key => keys.includes(key)) || this.apiErrors.output;
    },
    getErrorMessages(errors, labels) {
      const errorMessages = labels.map(label => errors[label] || this.apiErrors[label]).filter(message => message);
      return errorMessages.length ? errorMessages.join('<br>') : errorMessages.concat(this.apiErrors.output);
    }
  },
}
</script>

<style scoped>
.title-primary {
  font-size: 20px;
  font-weight: 900;
  word-break: break-word;
}

.title-secondary {
  font-size: 15px;
  color: var(--theme-secondary);
  word-break: break-word;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  justify-content: flex-start;
}

.asterisk {
  color: var(--theme-primary);
  margin-left: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-form {
  max-width: 500px;
}

.privacy-policy {
  color: var(--theme-primary);
}

.space-top {
  margin-top: 5rem;
}

.form-group button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--theme-primary);
  text-decoration: none;
  text-align: center;
}

.form-group a {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: var(--theme-primary) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.icon {
  width: 130px;
}

#input-code {
  position: relative;
}

#visibility-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>